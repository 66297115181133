import BasePage from "../BasePage";
import React from "react";
import { Card, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "../../components";
import { Upload } from "@mui/icons-material";
import FileInput from "../../components/inputs/FileInput";
import TabelaCargaPosicao from "./TabelaCargaPosicao";
import { toast } from "react-toastify";
import CustomAutocomplete from "../../components/inputs/CustomAutocomplete";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;

const StyledPosicaoButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 100%;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-bottom: 15px;
  @media (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const CargaArquivoPage = ({
  loading,
  formProps,
  handleGoBack,
  dataPosicao,
  setDataPosicao,
  dataKml,
  setDataKml,
  handleDeletePosicao,
  handleDeleteKml,
  handleAddKml,
  handleAddPosicao,
  loadingCliente,
  dataCliente,
  loadingKml,
  loadingPosicao,
  handleBaixarArtefato,
  dataRoteiro,
  valueCliente,
  setValueCliente,
  valueRoteiro,
  setValueRoteiro,
  loadingRoteiro,
}) => {
  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Carga de Arquivos</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Carga de Arquivos</StyledTitle>
      <StyledForm>
        <StyledCard>
          <StyledCardTitle>Selecione uma Empresa/Roteiro</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={6}>
              <CustomAutocomplete
                fullWidth
                label="Cliente"
                defaultValue={valueCliente}
                labelField={"nome"}
                idField={"id"}
                value={valueCliente}
                setValue={setValueCliente}
                loading={loadingCliente.toString()}
                domain={dataCliente}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <CustomAutocomplete
                fullWidth
                label="Roteiro"
                defaultValue={valueRoteiro}
                labelField={"nome"}
                idField={"id"}
                value={valueRoteiro}
                setValue={setValueRoteiro}
                loading={loadingRoteiro.toString()}
                domain={dataRoteiro}
              />
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
      </StyledForm>
      <StyledForm>
        {!!valueRoteiro && (
          <StyledCard>
            <StyledGridContainer container>
              <StyledGrid item xs={12} sm={12} md={8}>
                <StyledCardTitle>Posição</StyledCardTitle>
              </StyledGrid>
              <StyledGrid item xs={12} sm={12} md={2}>
                <FileInput
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length === 0) {
                      toast.error("Formato de arquivo inválido", {
                        theme: "colored",
                        icon: false,
                        style: { backgroundColor: "#990a26" },
                      });
                    }
                    if (acceptedFiles.length > 0) {
                      handleAddPosicao(acceptedFiles);
                    }
                  }}
                  maxFiles={1}
                  multiple={false}
                  acceptMime={{
                    "file/csv": [".csv"],
                    // "application/vnd.ms-excel": [".xls"],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                      ".xlsx",
                    ],
                  }}
                >
                  <StyledPosicaoButton loading={loadingPosicao}>
                    <Upload /> Posição
                  </StyledPosicaoButton>
                </FileInput>
              </StyledGrid>
              <StyledGrid item xs={12} sm={12} md={2}>
                <FileInput
                  onDrop={(acceptedFiles) => {
                    handleAddKml(acceptedFiles);
                  }}
                  name={"fileKml"}
                  formProps={formProps}
                  maxFiles={1}
                  acceptMime={{
                    "file/kml": [".kml"],
                  }}
                  multiple={false}
                >
                  <StyledPosicaoButton loading={loadingKml}>
                    <Upload /> KML
                  </StyledPosicaoButton>
                </FileInput>
              </StyledGrid>
            </StyledGridContainer>
            <TabelaCargaPosicao
              handleDeletePosicao={handleDeletePosicao}
              handleDeleteKml={handleDeleteKml}
              dataPosicao={dataPosicao}
              dataKml={dataKml}
              handleBaixarArtefato={handleBaixarArtefato}
            />
          </StyledCard>
        )}
      </StyledForm>

      <Grid justifyContent="center" container></Grid>
    </BasePage>
  );
};

export default CargaArquivoPage;
