import { useForm } from "react-hook-form";
import FormEditarAlertaPage from "./FormEditarAlertaPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/alertas.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import {
  getAlerta
} from "../../../selectors/cadastro/alertas.selectors";
import formatters from "../../../utils/formatters";
import { getPayload } from "../../../selectors/routes.selectors";
import { useEffect, useState } from "react";
import { getUsuarios } from "../../../selectors/cadastro/usuarios.selectors";

const FormEditarAlertaPageConnected = () => {

  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
  const { data: dataAlerta, loading } = useSelector(getAlerta);
  const idAlerta = useSelector(getPayload);
  const usuarios = useSelector(getUsuarios);

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    tipo: validators.string({ required: true }),
    percentualEvolucaoAbaixo: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo === "RA") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    minutosEvolucao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo === "RA") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    minutosParado: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo === "RP") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    maximoHorasSemMensagem: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo === "FM") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    destinatario: validators.string({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("notificaPorEmail");

          if (tipo) {
            return value.length > 0 || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const initialValues = {
    tipo: "",
    percentualEvolucaoAbaixo: "",
    minutosEvolucao: "",
    maximoHorasSemMensagem: "",
    minutosParado: "",
    notificaPorEmail: "",
    destinatario: "",
    nome: "",
    situacao: "A",
    dataHoraCriacao: "",
    dataHoraAtualizacao: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("nome", dataAlerta.nome);
    formProps.setValue("tipo", dataAlerta.tipo ?? "");
    formProps.setValue("percentualEvolucaoAbaixo", dataAlerta?.percentualEvolucaoAbaixo ?? "");
    formProps.setValue("minutosEvolucao", dataAlerta?.minutosEvolucao ?? "");
    formProps.setValue("maximoHorasSemMensagem", dataAlerta?.maximoHorasSemMensagem ?? "");
    formProps.setValue("minutosParado", dataAlerta?.minutosParado ?? "");
    formProps.setValue(
      "notificaPorEmail",
      dataAlerta?.notificaPorEmail === "S" ? true : false ?? ""
    );
    formProps.setValue(
      "destinatario",
      dataAlerta?.destinatario ?? ""
    );
    formProps.setValue("situacao", dataAlerta?.situacao ?? "");
    formProps.setValue(
      "dataHoraCriacao",
      formatters.dates.withHours(new Date(dataAlerta?.dataHoraCriacao))
    );
    formProps.setValue(
      "dataHoraAtualizacao",
      formatters.dates.withHours(new Date(dataAlerta?.dataHoraAtualizacao))
    );
    // eslint-disable-next-line
  }, [dataAlerta]);

  const handleSubmit = (values) => {
    values.id = dataAlerta.id;
    values.idEmpresa = dataAlerta.id;
    values.tipo = values.tipo?.id;
    values.notificaPorEmail = values.notificaPorEmail ? "S" : "N";
    dispatch(actions.editarAlerta.request(values));
  };

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_ALERTA, {
        id: dataAlerta.idEmpresa,
      })
    );
  };

  useEffect(() => {
    if (usuarios?.data?.resultado?.length > 0 && dataAlerta) {
      setUsuariosFiltrados(usuarios?.data?.resultado?.filter((item) => item.empresa === Number.parseInt(dataAlerta.idEmpresa) || item.perfil === 'A'));
    }
  }, [usuarios, dataAlerta]);

  return (
    <FormEditarAlertaPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      dataAlerta={dataAlerta}
      usuarios={usuariosFiltrados}
    />
  );
};

export default FormEditarAlertaPageConnected;
