import React from "react";
import { styled } from "@mui/material";
import { transientOptions } from "../../../styles/theme";
import { FlexContainer } from "../../../components/styled";

const LetterContainer = styled(FlexContainer, transientOptions)`
  background-color: ${({ theme, __disabled }) =>
    !!__disabled ? theme.colors.gray._200 : theme.colors.white};
  border-radius: 3px;
  height: 13px;
  width: 13px;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 800;
  color: ${({ theme, __disabled }) =>
    !!__disabled ? theme.colors.gray._300 : theme.colors.primary._700};
  text-align: center;
`;

const Funcionamento = ({ funcionamento }) => {
  return (
    <FlexContainer __gap={"2px"} __justifyContent={"center"}>
      <Letter disabled={!funcionamento.find((el) => el.dia === "1")}>D</Letter>
      <Letter disabled={!funcionamento.find((el) => el.dia === "2")}>S</Letter>
      <Letter disabled={!funcionamento.find((el) => el.dia === "3")}>T</Letter>
      <Letter disabled={!funcionamento.find((el) => el.dia === "4")}>Q</Letter>
      <Letter disabled={!funcionamento.find((el) => el.dia === "5")}>Q</Letter>
      <Letter disabled={!funcionamento.find((el) => el.dia === "6")}>S</Letter>
      <Letter disabled={!funcionamento.find((el) => el.dia === "7")}>S</Letter>
      <Letter disabled={!funcionamento.find((el) => el.dia === "9")}>F</Letter>
    </FlexContainer>
  );
};

const Letter = ({ children, disabled }) => {
  return <LetterContainer __disabled={disabled}>{children}</LetterContainer>;
};

export default Funcionamento;
