import BasePage from "../../BasePage";
import React from "react";
import { Card, MenuItem, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledCustomTextField,
  CustomControlledAutoComplete,
} from "../../../components/inputs";
import { Button } from "../../../components";
import { Upload } from "@mui/icons-material";
import CustomControlledAutocompleteCreateTable from "../../../components/inputs/CustomAutocompleteCreate";
import HourTextField from "../../../components/inputs/HourTextField";
import TabelaCriarFuncionamento from "./TabelaCriarFuncionamento";
import FileInput from "../../../components/inputs/FileInput";
import TabelaCriarPosicao from "./TabelaCriarPosicao";
import { toast } from "react-toastify";
import CustomControlledSwitch from "../../../components/inputs/CustomControlledSwitch";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;
const StyledControlledCustomTextField = styled(ControlledCustomTextField)``;

const StyledAtualizarButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledIncluirButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 100%;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
  }
`;

const StyledPosicaoButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 100%;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-bottom: 15px;
  @media (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;
const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-bottom: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
`;
const ButtonContainerLeft = styled("div")`
  display: flex;
  margin-left: 0px;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const FormCriarRoteiroPage = ({
  dataFuncionamento,
  handleDeleteFuncionamento,
  loading,
  formProps,
  handleSubmit,
  handleGoBack,
  dataGrupos,
  loadingGrupos,
  formPropsFuncionamento,
  handleSubmitFuncionamento,
  loadingDispositivos,
  dataDispositivos,
  dataTipoDispositivos,
  dataTipoVeiculos,
  dataPosicao,
  setDataPosicao,
  dataKml,
  setDataKml,
  handleDeletePosicao,
  handleDeleteKml,
  handleAddKml,
  handleAddPosicao,
}) => {
  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Novo Roteiro</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Novo Roteiro</StyledTitle>
      <StyledForm id="form1" onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledCardTitle>Dados Básicos</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="nome"
                label="Nome"
                inputProps={{ maxLength: 20 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <CustomControlledAutocompleteCreateTable
                formProps={formProps}
                fullWidth
                name="grupo"
                label="Grupo"
                labelField={"descricao"}
                idField={"id"}
                loading={loadingGrupos.toString()}
                domain={dataGrupos}
                inputPropMax={50}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="metragem"
                label="Metragem"
                type="number"
                inputProps={{ max: 2147483647, min: 0 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="equipe"
                label="Equipe"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="servico"
                label="Serviço"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <CustomControlledSwitch
                formProps={formProps}
                fullWidth
                name="feriado"
                label="Executa no Feriado?"
              ></CustomControlledSwitch>
            </StyledGrid>

            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="situacao"
                label="Situação"
                select
              >
                <MenuItem key={"A"} value={"A"}>
                  Ativo
                </MenuItem>
                <MenuItem key={"I"} value={"I"}>
                  Inativo
                </MenuItem>
              </StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Dispositivos</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <CustomControlledAutoComplete
                formProps={formProps}
                fullWidth
                name="dispositivo"
                label="Dispositivo"
                labelField={"nome"}
                idField={"id"}
                loading={loadingDispositivos.toString()}
                domain={dataDispositivos}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <CustomControlledAutoComplete
                formProps={formProps}
                fullWidth
                name="dispositivoBackup"
                label="Dispositivo Backup"
                labelField={"nome"}
                idField={"id"}
                loading={loadingDispositivos.toString()}
                domain={dataDispositivos}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <CustomControlledAutocompleteCreateTable
                formProps={formProps}
                fullWidth
                name="tipoDispositivo"
                label="Tipo dispositivo"
                labelField={"descricao"}
                idField={"id"}
                loading={loadingGrupos.toString()}
                domain={dataTipoDispositivos}
                inputPropMax={10}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <CustomControlledAutocompleteCreateTable
                formProps={formProps}
                fullWidth
                name="tipoVeiculo"
                label="Tipo veículo"
                labelField={"descricao"}
                idField={"id"}
                loading={loadingGrupos.toString()}
                domain={dataTipoVeiculos}
                inputPropMax={10}
              />
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
      </StyledForm>
      <StyledForm
        id="form2"
        onSubmit={formPropsFuncionamento.handleSubmit(
          handleSubmitFuncionamento
        )}
      >
        <StyledCard>
          <StyledCardTitle>Funcionamento</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={3}>
              <ControlledCustomTextField
                formProps={formPropsFuncionamento}
                fullWidth
                name="dia"
                label="Dia da Semana"
                select
              >
                <MenuItem key={"2"} value={2}>
                  Segunda-Feira
                </MenuItem>
                <MenuItem key={"3"} value={3}>
                  Terça-Feira
                </MenuItem>
                <MenuItem key={"4"} value={4}>
                  Quarta-Feira
                </MenuItem>
                <MenuItem key={"5"} value={5}>
                  Quinta-Feira
                </MenuItem>
                <MenuItem key={"6"} value={6}>
                  Sexta-Feira
                </MenuItem>
                <MenuItem key={"7"} value={7}>
                  Sábado
                </MenuItem>
                <MenuItem key={"1"} value={1}>
                  Domingo
                </MenuItem>
                <MenuItem key={"9"} value={9}>
                  Feriados
                </MenuItem>
              </ControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <ControlledCustomTextField
                formProps={formPropsFuncionamento}
                fullWidth
                name="inicio"
                label="Início"
                InputProps={{
                  inputComponent: HourTextField,
                  maxLength: 4,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <ControlledCustomTextField
                formProps={formPropsFuncionamento}
                name="fim"
                label="Fim"
                fullWidth
                InputProps={{
                  inputComponent: HourTextField,
                  maxLength: 4,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledIncluirButton
                form="form2"
                type={"submit"}
                loading={loading}
              >
                Incluir
              </StyledIncluirButton>
            </StyledGrid>
          </StyledGridContainer>
          <TabelaCriarFuncionamento
            dataFuncionamento={dataFuncionamento.sort((a, b) => a.dia - b.dia)}
            handleDeleteFuncionamento={handleDeleteFuncionamento}
          />
        </StyledCard>
      </StyledForm>
      <StyledForm>
        <StyledCard>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={8}>
              <StyledCardTitle>Posição</StyledCardTitle>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <FileInput
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length === 0) {
                    toast.error("Formato de arquivo inválido", {
                      theme: "colored",
                      icon: false,
                      style: { backgroundColor: "#990a26" },
                    });
                  }
                  if (acceptedFiles.length > 0) {
                    handleAddPosicao(acceptedFiles);
                  }
                }}
                maxFiles={1}
                multiple={false}
                acceptMime={{
                  "file/csv": [".csv"],
                  // "application/vnd.ms-excel": [".xls"],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                    ".xlsx",
                  ],
                }}
              >
                <StyledPosicaoButton loading={loading}>
                  <Upload /> Posição
                </StyledPosicaoButton>
              </FileInput>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <FileInput
                onDrop={(acceptedFiles) => {
                  handleAddKml(acceptedFiles);
                }}
                name={"fileKml"}
                formProps={formProps}
                maxFiles={1}
                acceptMime={{
                  "file/kml": [".kml"],
                }}
                multiple={false}
              >
                <StyledPosicaoButton loading={loading}>
                  <Upload /> KML
                </StyledPosicaoButton>
              </FileInput>
            </StyledGrid>
          </StyledGridContainer>
          <TabelaCriarPosicao
            handleDeletePosicao={handleDeletePosicao}
            handleDeleteKml={handleDeleteKml}
            dataPosicao={dataPosicao}
            dataKml={dataKml}
          />
        </StyledCard>
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainer>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </ButtonContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainerLeft>
              <StyledAtualizarButton
                form="form1"
                type={"submit"}
                loading={loading}
              >
                Incluir
              </StyledAtualizarButton>
            </ButtonContainerLeft>
          </Grid>
        </Grid>
      </StyledForm>
    </BasePage>
  );
};

export default FormCriarRoteiroPage;
