import React from "react";
import BasePage from "../BasePage";
import GoogleMaps from "./components/GoogleMaps";
import FiltersDrawer from "./components/FiltersDrawer.connected";

function MapPage({ position, selecionado }) {
  return (
    <BasePage isLoggedIn={true}>
      <GoogleMaps position={position} selecionado={selecionado} />
      <FiltersDrawer />
    </BasePage>
  );
}

export default MapPage;
