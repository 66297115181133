import React from "react";
import { Controller } from "react-hook-form";
import CustomSwitch from "./CustomSwitch";

const CustomControlledSwitch = ({ formProps, name, ...otherProps }) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <CustomSwitch
            {...otherProps}
            value={value}
            error={isError}
            helperText={errors[name]?.message}
            onChange={(v) => {
              onChange(v.target.checked);
              !!otherProps.onChange && otherProps.onChange(v);
            }}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};

export default CustomControlledSwitch;
