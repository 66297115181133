import { useForm } from "react-hook-form";
import FormCriarFeriadoPage from "./FormCriarFeriadoPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/feriados.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import {
  getFeriado,
  getFeriadosCliente,
} from "../../../selectors/cadastro/feriados.selectors";
import formatters from "../../../utils/formatters";

const FormCriarFeriadoPageConnected = () => {
  const { loading } = useSelector(getFeriado);
  const cliente = useSelector(getFeriadosCliente);

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    dataFeriado: validators.string({
      required: true,
    }),
  };

  const initialValues = {
    dataFeriado: "",
    nome: "",
  };
  const defaultValues = {
    dataFeriado: "",
    nome: "",
    dataHoraCriacao: formatters.dates.withHours(new Date()),
    dataHoraAtualizacao: formatters.dates.withHours(new Date()),
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      values.idEmpresa = cliente;
      dispatch(actions.criarFeriado.request(values));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_FERIADO, { id: cliente }));
  };

  return (
    <FormCriarFeriadoPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
    />
  );
};

export default FormCriarFeriadoPageConnected;
