import { Card, IconButton as Icon } from "@mui/material";
import React from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  height: 1.5em;
  width: 1.5em;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  background: #f6fdff;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    background: #b8d6eeff;
  }
  @media (max-width: 1238px) {
    height: 1.5em;
    width: 1.5em;
  }
  @media (max-width: 899px) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const StyledButtonContainer = styled(Icon)`
  padding: 5px;
`;
const StyledIconContainer = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

function IconButton({ children, handleClick, ...otherProps }) {
  return (
    <StyledButtonContainer onClick={handleClick} {...otherProps}>
      <StyledCard>
        <StyledIconContainer>{children}</StyledIconContainer>
      </StyledCard>
    </StyledButtonContainer>
  );
}

export default IconButton;
