import { actions, types } from "./mapasMensais.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  mapasMensais: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
    cliente: "",
  },
  mapaMensal: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterMapasMensais.REQUEST:
    case actions.obterMapasMensais.SUCCESS:
    case actions.obterMapasMensais.FAILURE:
      return {
        ...state,
        mapasMensais: {
          ...state.MapasMensais,
          loading: action.type === actions.obterMapasMensais.REQUEST,
          data:
            action.type === actions.obterMapasMensais.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterMapasMensais.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          cliente:
            action.type === actions.obterMapasMensais.SUCCESS
              ? action.payload?.response?.data?.cliente ?? ""
              : "",
          nomeCliente:
            action.type === actions.obterMapasMensais.SUCCESS
              ? action.payload?.response?.data?.nomeCliente ?? ""
              : "",
          page:
            action.type === actions.obterMapasMensais.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case types.REMOVER_FILTRO_MAPA_MENSAL:
      return {
        ...state,
        mapasMensais: {
          ...state.MapasMensais,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_MAPA_MENSAL:
      return {
        ...state,
        mapasMensais: {
          ...state.MapasMensais,
          page: 1,
        },
      };

    case actions.editarMapaMensal.REQUEST:
    case actions.editarMapaMensal.SUCCESS:
    case actions.editarMapaMensal.FAILURE:
      return {
        ...state,
        mapaMensal: {
          ...state.mapaMensal,
          loading: action.type === actions.editarMapaMensal.REQUEST,
          data:
            action.type === actions.editarMapaMensal.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDITAR_MAPA_MENSAL:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarMapaMensal.REQUEST:
    case actions.mostrarMapaMensal.SUCCESS:
    case actions.mostrarMapaMensal.FAILURE:
      return {
        ...state,
        mapaMensal: {
          ...state.mapaMensal,
          loading: action.type === actions.mostrarMapaMensal.REQUEST,
          data:
            action.type === actions.mostrarMapaMensal.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarMapaMensal.REQUEST:
    case actions.criarMapaMensal.SUCCESS:
    case actions.criarMapaMensal.FAILURE:
      return {
        ...state,
        mapaMensal: {
          ...state.mapaMensal,
          loading: action.type === actions.criarMapaMensal.REQUEST,
          data:
            action.type === actions.criarMapaMensal.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarMapaMensal.REQUEST:
    case actions.deletarMapaMensal.SUCCESS:
    case actions.deletarMapaMensal.FAILURE:
      return {
        ...state,
        mapaMensal: {
          ...state.mapaMensal,
          loading: action.type === actions.deletarMapaMensal.REQUEST,
        },
      };

    default:
      return state;
  }
};

export default reducer;
