import { useDispatch, useSelector } from "react-redux";
import GeracaoOsPage from "./ReprocessamentoOsPage";
import useCompleteForm from "../../../hooks/useCompleteForm";
import validators from "../../../utils/validators";
import { useCallback, useState } from "react";
import { actions } from "../../../reducers/configuracao/reprocessamento.actions";
import { getReprocessamentoOs } from "../../../selectors/configuracao/reprocessamento.selectors";

const GeracaoOsPageConnected = () => {
  const dispatch = useDispatch();
  const { roteiros, reprocessamento } = useSelector(getReprocessamentoOs);
  const [dialogTitle, setDialogTitle] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [actionValues, setActionValues] = useState({});

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      data: validators.string({
        required: true,
        custom: {
          dataInvalida: (value) => {
            const [ano, mes, dia] = value.split("-");
            if (ano > 9999) return "Ano inválido";
            if (!dia || !mes | !ano) return false;
            const diaNumero = parseInt(dia, 10);
            const mesNumero = parseInt(mes, 10);
            const anoNumero = parseInt(ano, 10);
            const date = new Date(anoNumero, mesNumero - 1, diaNumero, 0, 0, 0);
            if (!isValidDate(date)) return "Data inválida";
          },
        },
      }),
      roteiro: validators.string({}),
    }),
    initialValues: () => ({
      data: "",
      roteiro: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        setActionValues(values);
        if (values.roteiro === "" || values.roteiro === null) {
          setDialogTitle(
            `TEM CERTEZA QUE GOSTARIA DE REPROCESSAR A DATA ${values.data}? TODAS AS INFORMAÇÕES SERÃO PERDIDAS!`
          );
        } else {
          setDialogTitle(
            `TEM CERTEZA QUE GOSTARIA DE REPROCESSAR O ROTEIRO ${values?.roteiro?.nome} DA DATA ${values.data}? TODAS AS INFORMAÇÕES SERÃO PERDIDAS!`
          );
        }
        setActionValues(values);
        setOpenDialog(true);
      },
      // eslint-disable-next-line
      [dispatch]
    ),
  });

  const actionFunction = (values) => {
    const [ano, mes, dia] = values.data.split("-");
    if (!dia || !mes | !ano) return false;
    const diaNumero = parseInt(dia, 10);
    const mesNumero = parseInt(mes, 10);
    const anoNumero = parseInt(ano, 10);
    const date = new Date(anoNumero, mesNumero - 1, diaNumero);

    dispatch(
      actions.reprocessar.request({ data: date, roteiro: values.roteiro })
    );
  };

  const handleGetRoteiros = (values) => {
    const value = formProps.getValues("data");
    const [ano, mes, dia] = value.split("-");
    if (!dia || !mes | !ano) return false;
    const diaNumero = parseInt(dia, 10);
    const mesNumero = parseInt(mes, 10);
    const anoNumero = parseInt(ano, 10);
    const date =
      anoNumero > 1990 &&
      mesNumero <= 12 &&
      diaNumero <= 31 &&
      anoNumero <= 9999
        ? new Date(anoNumero, mesNumero - 1, diaNumero)
        : "date";

    if (!isValidDate(date)) {
      formProps.setError("data", { message: "Data inválida" });
    } else {
      formProps.clearErrors("data");
      dispatch(actions.listarRoteiros.request({ data: date }));
    }
  };

  const handleLimparRoteiros = () => {
    dispatch(actions.limparRoteiros());
  };

  return (
    <GeracaoOsPage
      formProps={formProps}
      handleSubmit={handleSubmit}
      loading={roteiros.loading}
      data={roteiros.data}
      handleGetRoteiros={handleGetRoteiros}
      dialogTitle={dialogTitle}
      setDialogTitle={setDialogTitle}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      actionFunction={actionFunction}
      actionValues={actionValues}
      loadingReprocessamento={reprocessamento.loading}
      handleLimparRoteiros={handleLimparRoteiros}
    />
  );
};

export default GeracaoOsPageConnected;
