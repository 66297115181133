import React, { useRef, useState } from "react";
import { IconButton, styled, Tooltip } from "@mui/material";
import { CloseSharp, LocationSearching } from "@mui/icons-material";
import { Autocomplete } from "@react-google-maps/api";

const Container = styled("div")`
  position: absolute;
  top: 120px;
  right: 20px;
  z-index: 1101;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (min-width: 1217px) {
    right: 170px;
  }
`;
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.map.options};
  color: ${({ theme }) => theme.colors.dark};

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
const ExportButton = styled(StyledIconButton)`
  width: 40px;
  height: 40px;
`;
const StyledTextField = styled("input")`
  max-width: 360px;
  width: 80vw;
  position: relative;
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.inputs.content};
  font-weight: 400;
  margin-top: 0.5rem;
  padding: 0.5rem;
`;
const Box = styled("div")`
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 1.5rem 0.5rem 0.5rem;
  margin-top: 10px;
  isolation: isolate;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.map.options};

  &::before {
    bottom: 100%;
    right: 0.5rem;
    border: solid 10px rgba(102, 102, 102, 0);
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${({ theme }) => theme.colors.white};
  }
`;
const CloseButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark};
  padding: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
const CloseIcon = styled(CloseSharp)`
  font-size: 1rem;
`;

const SearchAddressInputText = ({ handlePlaceSelected, handleClose }) => {
  const anchorRef = useRef(null);
  const instance = useRef(null);
  const [open, setOpen] = useState(false);

  const handleAutocompleteLoad = (autocomplete) => {
    instance.current = autocomplete;
  };
  const handleChangePlace = () => {
    if (instance.current) {
      handlePlaceSelected(instance.current.getPlace());
    }
  };

  return (
    <Container>
      <Tooltip title={"Pesquisar Endereço"}>
        <ExportButton ref={anchorRef} onClick={() => setOpen(true)}>
          <LocationSearching />
        </ExportButton>
      </Tooltip>
      {open && (
        <Box>
          <Tooltip title={"Fechar"}>
            <CloseButton
              onClick={() => {
                handleClose();
                setOpen(false);
              }}
            >
              <CloseIcon />
            </CloseButton>
          </Tooltip>
          <Autocomplete
            onLoad={handleAutocompleteLoad}
            onPlaceChanged={handleChangePlace}
            fields={["geometry", "formatted_address"]}
            bounds={{
              north: -1,
              east: -57,
              west: -34,
              south: -33,
            }}
          >
            <StyledTextField
              type={"text"}
              placeholder={"Digite o endereço aqui..."}
            />
          </Autocomplete>
        </Box>
      )}
    </Container>
  );
};

export default SearchAddressInputText;
