import BasePage from "../../BasePage";
import React from "react";
import { Card, MenuItem, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledCustomTextField,
  CpfCnpjTextField,
  CustomTextField,
  ZipCodeTextField,
} from "../../../components/inputs";
import { Button } from "../../../components";
import { states } from "../../../utils/constants";
import { useState } from "react";
import CustomControlledSwitch from "../../../components/inputs/CustomControlledSwitch";
import { useEffect } from "react";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0 0 !important;
  padding: 25px;
`;
const StyledControlledCustomTextField = styled(ControlledCustomTextField)``;

const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px 24px 10px 24px;
  margin-bottom: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledControlledSwitch = styled(CustomControlledSwitch)``;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;
const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const FormClienteViewPage = ({
  data,
  loading,
  formProps,
  handleSubmit,
  handleGoBack,
  handleZipCodeBlurred,
  dataMatrizes,
  showMatriz,
  matriz,
}) => {
  const [disableRoteiro, setDisableRoteiro] = useState(data);

  const handleDisableRoteiro = () => {
    formProps.setValue("roteiro", !disableRoteiro);
    setDisableRoteiro(!disableRoteiro);
  };

  useEffect(() => {
    formProps.setValue(
      "idMatriz",
      dataMatrizes.find((item) => item.id === data.idMatriz)
    );
    // eslint-disable-next-line
  }, [data, dataMatrizes]);

  useEffect(() => {
    setDisableRoteiro(data?.roteiro === "S" ? true : false);
  }, [data]);

  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Visualizar Cliente</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Visualizar Cliente</StyledTitle>
      <StyledForm onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledCardTitle>Dados Básicos</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="cnpj"
                label="CNPJ"
                type="cnpj"
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                  maxLength: 15,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={5}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="nome"
                label="Nome do cliente"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="nomeAbreviado"
                label="Nome Abreviado"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="tipo"
                label="Tipo"
                select
              >
                <MenuItem key={"M"} value={"M"}>
                  Matriz
                </MenuItem>
                <MenuItem key={"F"} value={"F"}>
                  Filial
                </MenuItem>
              </StyledControlledCustomTextField>
            </StyledGrid>

            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="situacao"
                label="Situação"
                select
              >
                <MenuItem key={"A"} value={"A"}>
                  Ativo
                </MenuItem>
                <MenuItem key={"I"} value={"I"}>
                  Inativo
                </MenuItem>
              </StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledSwitch
                formProps={formProps}
                fullWidth
                name="exibeMapaProUsuarioFinal"
                label="Habilitar Mapa para Usuário Final?"
              ></StyledControlledSwitch>
            </StyledGrid>
            {!showMatriz && (
              <StyledGrid item xs={12} sm={12} md={6}>
                {matriz && data && dataMatrizes.length > 0 && (
                  <CustomTextField
                    disabled
                    value={matriz}
                    fullWidth
                    label="Matriz"
                  />
                )}
              </StyledGrid>
            )}
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="dataHoraCriacao"
                label="Data e hora de criação"
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="dataHoraAtualizacao"
                label="Data e hora de atualização"
              ></StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Permissões do Usuário Final</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledSwitch
                formProps={formProps}
                fullWidth
                name="exibeMapaProUsuarioFinal"
                label="Habilitar Mapa para Usuário Final?"
              ></StyledControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledSwitch
                formProps={formProps}
                fullWidth
                name="exibeOpcaoPercursoOperacaoDiaria"
                label="Habilitar Percurso do Mapa na Operação Diária?"
              ></StyledControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledSwitch
                formProps={formProps}
                fullWidth
                name="exibeOpcaoEfetivoOperacaoDiaria"
                label="Habilitar Efetivo do Mapa na Operação Diária?"
              ></StyledControlledSwitch>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Endereço</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                label="CEP"
                name={"cep"}
                formProps={formProps}
                onBlur={() => handleZipCodeBlurred(formProps.getValues("cep"))}
                InputProps={{
                  inputComponent: ZipCodeTextField,
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                disabled
                label="Logradouro"
                name={"logradouro"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 40 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledCustomTextField
                disabled
                label="Número"
                name={"numeroLogradouro"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 7 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                label="Complemento"
                name={"complemento"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 30 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                disabled
                label="Bairro"
                name={"bairro"}
                formProps={formProps}
                margin={"none"}
                inputProps={{ maxLength: 40 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <StyledControlledCustomTextField
                disabled
                label="Cidade"
                name={"municipio"}
                formProps={formProps}
                margin={"none"}
                flex={3}
                inputProps={{ maxLength: 60 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledCustomTextField
                disabled
                label="UF"
                name={"uf"}
                formProps={formProps}
                margin={"none"}
                select
              >
                {states.map((state) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={state}
                    value={state}
                  >
                    {state}
                  </MenuItem>
                ))}
              </StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <StyledCard>
          <StyledCardTitle>Roteiro</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="lote"
                label="Lote"
                type={"number"}
                inputProps={{ min: 1 }}
              />
            </StyledGrid>

            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="latitude"
                label="Latitude"
                inputProps={{ maxLength: 15 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="longitude"
                label="Longitude"
                inputProps={{ maxLength: 15 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={2}>
              <StyledControlledSwitch
                disabled
                onChange={handleDisableRoteiro}
                formProps={formProps}
                fullWidth
                name="roteiro"
                label="Possui Roteiro"
              ></StyledControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="pontosRegiao"
                label="Pontos na região"
                type="number"
                inputProps={{ min: 1 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="raioPrecisao"
                label="Raio de precisão"
                type="number"
                inputProps={{ min: 1 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                disabled
                formProps={formProps}
                fullWidth
                name="precisao"
                label="Precisão"
                type="number"
                inputProps={{ min: 1 }}
              ></StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainer>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </ButtonContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
        </Grid>
      </StyledForm>
    </BasePage>
  );
};

export default FormClienteViewPage;
