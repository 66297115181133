import { createSelector } from "reselect";

export const getMapaExecucao = (state) => state.mapaExecucao;
export const getDominios = createSelector(
  getMapaExecucao,
  (state) => state.dominios
);
export const getOpcoesMapa = createSelector(
  getMapaExecucao,
  (state) => state.opcoes
);
export const getFiltros = createSelector(
  getMapaExecucao,
  (state) => state.filtros
);
export const getDrawer = createSelector(
  getMapaExecucao,
  (state) => state.drawer
);
export const getOrder = createSelector(getMapaExecucao, (state) => state.order);
export const getSelecionado = createSelector(
  getMapaExecucao,
  (state) => state.selecionado
);

export const getDominioEmpresas = createSelector(
  getDominios,
  (state) => state.empresas
);
export const getDominioDispositivos = createSelector(
  getDominios,
  (state) => state.dispositivos
);
export const getDominioGrupos = createSelector(
  getDominios,
  (state) => state.grupos
);

export const isLoadingItems = createSelector(
  getMapaExecucao,
  (state) => state.loading
);
export const isUpdatingItem = createSelector(
  getMapaExecucao,
  (state) => state.updating
);
export const getItems = createSelector(getMapaExecucao, (state) => state.items);
export const getTotalItems = createSelector(
  getMapaExecucao,
  (state) => state.total
);

export const getSortedItems = (state) => {
  const items = getItems(state);
  if (!items || items.length === 0) {
    return items;
  }
  const order = getOrder(state);
  const { field, direction } = order;

  switch (field) {
    case "roteiro":
      items.sort((a, b) =>
        direction === "asc"
          ? a.nome.localeCompare(b.nome)
          : b.nome.localeCompare(a.nome)
      );
      break;
    case "mes":
      items.sort((a, b) => {
        const valueA = parseFloat(a.mediaMensal);
        const valueB = parseFloat(b.mediaMensal);
        if (direction === "asc") {
          return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      });
      break;
    default:
      const dia = parseInt(field.replace(/\D/g, ""), 10);
      if (dia > items[0].diaOrdemDeServico.length) {
        items.sort((a, b) =>
          direction === "asc"
            ? a.nome.localeCompare(b.nome)
            : b.nome.localeCompare(a.nome)
        );
      } else {
        items.sort((a, b) => {
          const valueA =
            a.diaOrdemDeServico[dia - 1].valor === "-"
              ? -1
              : parseInt(a.diaOrdemDeServico[dia - 1].valor, 10);
          const valueB =
            b.diaOrdemDeServico[dia - 1].valor === "-"
              ? -1
              : parseInt(b.diaOrdemDeServico[dia - 1].valor, 10);
          if (direction === "asc") {
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          } else {
            return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
          }
        });
      }
      break;
  }

  return items;
};

export const getCenter = (state) => {
  const selecionado = getSelecionado(state);

  if (!!selecionado) {
    const primeiroCheckpoint = selecionado.posicoes.find((p) => p.tipo === "C");

    if (!!primeiroCheckpoint) {
      return primeiroCheckpoint.longitudeLatitude;
    }

    const primeiroPonto = selecionado.posicoes.find((p) => p.tipo === "P");

    if (!!primeiroPonto) {
      return primeiroPonto.longitudeLatitude;
    }
  }

  return state.usuario.position;
};
export const getRoteiro = createSelector(
  getMapaExecucao,
  (state) => state.roteiro
);
export const getOs = createSelector(getMapaExecucao, (state) => state.os);
