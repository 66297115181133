import { actions } from "./roteiroInconsistencia.actions";

const initialState = {
  id: null,
  erros: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterRoteiroInconsistencia.REQUEST:
    case actions.obterRoteiroInconsistencia.SUCCESS:
    case actions.obterRoteiroInconsistencia.FAILURE:
      return {
        ...state,
        erros: {
          ...state.baterias,
          loading: action.type === actions.obterRoteiroInconsistencia.REQUEST,
          data:
            action.type === actions.obterRoteiroInconsistencia.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
