import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CargaTrocarDispositivo from "./CargaTrocarDispositivo";
import { actions } from "../../reducers/cadastro/roteiros.actions";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  getInconsistenciasTrocarDispositivos,
  getRoteirosBaixarTrocarDispositivos,
  getRoteirosResumoTrocarDispositivo,
} from "../../selectors/cadastro/roteiros.selectors";
import { useEffect } from "react";

function CargaTrocarDispositivoConnected() {
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [isFile, setIsFile] = useState(false);

  const { data: dataInconsistencias, open: openInconsistencias } = useSelector(
    getInconsistenciasTrocarDispositivos
  );
  const { loading: loadingBaixar } = useSelector(
    getRoteirosBaixarTrocarDispositivos
  );
  const {
    data: dataResumo,
    open: openResumo,
    loading: loadingUpload,
  } = useSelector(getRoteirosResumoTrocarDispositivo);

  useEffect(() => {
    if (openInconsistencias) {
      setIsFile(false);
      setFile([]);
    }
  }, [openInconsistencias]);

  const initialValues = {
    empresa: "",
  };
  const rules = {};

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };
  const handleDownload = () => {
    dispatch(actions.baixarPlanilhaTrocarDispositivos.request());
  };

  const handleSubmit = () => {
    if (file.length === 0) {
      toast.error("Favor selecionar um arquivo para processamento", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      dispatch(
        actions.mostrarErroTrocarDispositivos({ data: [], open: false })
      );

      dispatch(actions.uploadCargaTrocarDispositivos.request({ file }));
    }
  };

  return (
    <CargaTrocarDispositivo
      file={file}
      setFile={setFile}
      formProps={formProps}
      handleDownload={handleDownload}
      handleSubmit={handleSubmit}
      dataInconsistencias={dataInconsistencias}
      openInconsistencias={openInconsistencias}
      dataResumo={dataResumo}
      openResumo={openResumo}
      isFile={isFile}
      setIsFile={setIsFile}
      loadingUpload={loadingUpload}
      loadingBaixar={loadingBaixar}
    />
  );
}

export default CargaTrocarDispositivoConnected;
