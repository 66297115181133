import { useForm } from "react-hook-form";
import FormCriarIntegracaoPage from "./FormCriarAlertaPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/alertas.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback, useEffect, useState } from "react";
import {
  getAlerta
} from "../../../selectors/cadastro/alertas.selectors";
import formatters from "../../../utils/formatters";
import { getPayload } from "../../../selectors/routes.selectors";
import { getCliente } from "../../../selectors/cadastro/clientes.selectors";
import { getUsuarios } from "../../../selectors/cadastro/usuarios.selectors";

const FormCriarAlertaPageConnected = () => {
  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);

  const { loading } = useSelector(getAlerta);
  const usuarios = useSelector(getUsuarios);

  const { idCliente } = useSelector(getPayload);

  const { data: dataCliente } = useSelector(getCliente);

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    tipo: validators.string({ required: true }),
    percentualEvolucaoAbaixo: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo?.id === "RA") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    minutosEvolucao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo?.id === "RA") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    minutosParado: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo?.id === "RP") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    maximoHorasSemMensagem: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("tipo");

          if (tipo?.id === "FM") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    destinatario: validators.string({
      custom: {
        requiredIfOther: (value) => {
          const tipo = formProps.getValues("notificaPorEmail");

          if (tipo) {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const initialValues = {
    tipo: "",
    percentualEvolucaoAbaixo: "",
    minutosEvolucao: "",
    maximoHorasSemMensagem: "",
    minutosParado: "",
    notificaPorEmail: "",
    nome: "",
    situacao: "A",
    dataHoraCriacao: "",
    dataHoraAtualizacao: "",
    destinatario: [],
  };
  const defaultValues = {
    situacao: "A",
    dataHoraCriacao: formatters.dates.withHours(new Date()),
    dataHoraAtualizacao: formatters.dates.withHours(new Date()),
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const handleSubmit = (values) => {
    values.idEmpresa = idCliente;
    values.tipo = values.tipo?.id;
    values.notificaPorEmail = values.notificaPorEmail ? "S" : "N";
    dispatch(actions.criarAlerta.request(values));
  };

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_ALERTA, { id: idCliente.id })
    );
  };

  useEffect(() => {
    if (usuarios?.data?.resultado?.length > 0) {
      setUsuariosFiltrados(usuarios?.data?.resultado?.filter((item) => item.empresa === Number.parseInt(idCliente) || item.perfil === 'A'));
    }
  }, [usuarios]);

  return (
    <FormCriarIntegracaoPage
      handleGoBack={handleClickVoltar}
      loading={loading || usuarios.loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      usuarios={usuariosFiltrados}
    />
  );
};

export default FormCriarAlertaPageConnected;
