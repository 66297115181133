import basicFlow from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as alertas } from "../../reducers/cadastro/alertas.actions";
import { actions as usuarios } from "../../reducers/cadastro/usuarios.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getAlertasCliente,
  getAlertasFilter,
  getAlertasPage,
} from "../../selectors/cadastro/alertas.selectors";

const ListarAlertasApi = (values) => {
  return authenticatedRequest({
    url: `/alerta/listar`,
    isResourceService: true,
    method: "post",
    body: {
      nome: values?.nome ?? "",
      pagina: values?.pagina ?? 1,
      limite: 9,
      idCliente: values?.idCliente ?? "",
    },
  });
};

const ListarAlertas = basicFlow({
  actionGenerator: alertas.obterAlertas,
  api: ListarAlertasApi,
});

function* ListarAlertasRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_ALERTA, function* () {
    const nome = yield select(getAlertasFilter);
    const cliente = yield select(getPayload);

    yield put(
      alertas.obterAlertas.request({
        pagina: 1,
        nome,
        idCliente: cliente.id,
      })
    );
  });
}

const EditarAlertasApi = (values) => {
  return authenticatedRequest({
    url: `/alerta/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const EditarAlertas = basicFlow({
  actionGenerator: alertas.editarAlerta,
  api: EditarAlertasApi,
  postSuccess: function* () {
    yield toast.success("Alerta atualizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getAlertasCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_ALERTA, { id: cliente })
    );
    const pagina = yield select(getAlertasPage);
    const nome = yield select(getAlertasFilter);
    yield put(
      alertas.obterAlertas.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const criarAlertasApi = (values) => {
  return authenticatedRequest({
    url: `/alerta`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const CriarAlertas = basicFlow({
  actionGenerator: alertas.criarAlerta,
  api: criarAlertasApi,
  postSuccess: function* () {
    yield toast.success("Alerta criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getAlertasCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_ALERTA, { id: cliente })
    );
    const pagina = yield select(getAlertasPage);
    const nome = yield select(getAlertasFilter);
    yield put(
      alertas.obterAlertas.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const deletarAlertasApi = (values) => {
  return authenticatedRequest({
    url: `/alerta/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const DeletarAlertas = basicFlow({
  actionGenerator: alertas.deletarAlerta,
  api: deletarAlertasApi,
  postSuccess: function* () {
    yield toast.success("Alerta excluído com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    const cliente = yield select(getAlertasCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_ALERTA, { id: cliente })
    );
    const pagina = yield select(getAlertasPage);
    const nome = yield select(getAlertasFilter);
    yield put(
      alertas.obterAlertas.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const ReativarAlertaApi = (values) => {
  return authenticatedRequest({
    url: `/alerta/ativar`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: values.situacao,
    },
  });
};

const ReativarAlerta = basicFlow({
  actionGenerator: alertas.reativarAlerta,
  api: ReativarAlertaApi,
  postSuccess: function* () {
    yield toast.success("Alerta reativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getAlertasCliente);
    const pagina = yield select(getAlertasPage);
    const nome = yield select(getAlertasFilter);
    yield put(
      alertas.obterAlertas.request({ pagina, nome, idCliente: cliente })
    );
  },
});


const MostrarAlertaApi = (values) => {
  return authenticatedRequest({
    url: `/alerta/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarAlerta = basicFlow({
  actionGenerator: alertas.mostrarAlerta,
  transform: function* (payload) {
    const id = yield select((state) => state.alertas.id);
    return { id, ...payload };
  },

  api: MostrarAlertaApi,
});

function* MostrarAlertaRouteWatcher() {
  yield routeWatcher(routes.EDITAR_ALERTA, function* () {
    const alerta = yield select(getPayload);
    yield put(usuarios.obterUsuarios.request({ pagina: 1, limite: 1000, nome: '' }));
    yield put(alertas.mostrarAlerta.request({ id: alerta.id }));
  });
}

function* CriarAlertaRouteWatcher() {
  yield routeWatcher(routes.CRIAR_ALERTA, function* () {
    yield put(usuarios.obterUsuarios.request({ pagina: 1, limite: 1000, nome: '' }));
  });
}

export const sagas = [
  MostrarAlertaRouteWatcher(),
  ListarAlertasRouteWatcher(),
  ListarAlertas.watcher(),
  MostrarAlerta.watcher(),
  EditarAlertas.watcher(),
  CriarAlertas.watcher(),
  DeletarAlertas.watcher(),
  ReativarAlerta.watcher(),
  CriarAlertaRouteWatcher(),
];
