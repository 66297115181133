import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOpcoesMapa,
  getPosicao,
  getSelecionado,
} from "../../../selectors/mapa.selectors";
import CheckpointsPendentes from "./CheckpointsPendentes";
import { getPerfil } from "../../../selectors/usuario.selectors";
import { actions } from "../../../reducers/mapa.actions";
import formatters from "../../../utils/formatters";

const CheckpointsPendentesConnected = () => {
  const dispatch = useDispatch();
  const selecionado = useSelector(getSelecionado);
  const { pendente } = useSelector(getOpcoesMapa);
  const perfil = useSelector(getPerfil);
  const { ponto, updating } = useSelector(getPosicao);

  const points =
    !!pendente && !!selecionado
      ? selecionado.posicoes.filter((p) => p.tipo === "C" && p.situacao === "P")
      : [];

  const handleDelete = useCallback(
    ({ id, callback }) => {
      dispatch(actions.excluirPosicao.request({ id, callback }));
    },
    [dispatch]
  );

  const handleDragStart = useCallback(
    (origem) => {
      dispatch(actions.comecarMovimento(origem));
    },
    [dispatch]
  );
  const handleDragEnd = useCallback(
    (point, evento, target) => {
      dispatch(actions.perguntarMovimento(point, evento, target));
    },
    [dispatch]
  );
  const handleCloseDialog = useCallback(() => {
    dispatch(actions.fecharMovimento());
  }, [dispatch]);
  const handleConfirmDialog = useCallback(() => {
    dispatch(actions.moverCheckpoint.request());
  }, [dispatch]);

  return (
    <CheckpointsPendentes
      points={points}
      isEndUser={perfil === "E"}
      canDelete={perfil === "A"}
      canMove={
        perfil === "A" &&
        selecionado?.dataBase === formatters.dates.iso(new Date())
      }
      handleDelete={handleDelete}
      handleDragStart={handleDragStart}
      handleDragEnd={handleDragEnd}
      handleCloseDialog={handleCloseDialog}
      handleConfirmDialog={handleConfirmDialog}
      dialogOpen={!!ponto}
      updating={updating}
    />
  );
};

export default CheckpointsPendentesConnected;
