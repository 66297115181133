import basicFlow from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as mapasMensais } from "../../reducers/cadastro/mapasMensais.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getMapasMensaisCliente,
  getMapasMensaisFilter,
  getMapasMensaisPage,
} from "../../selectors/cadastro/mapasMensais.selectors";

const ListarMapasMensaisApi = (values) => {
  return authenticatedRequest({
    url: `/mapa-mensal/listar`,
    isResourceService: true,
    method: "post",
    body: {
      descricao: values?.descricao ?? "",
      pagina: values?.pagina ?? 1,
      limite: 9,
      idCliente: values?.idCliente ?? "",
    },
  });
};

const ListarMapasMensais = basicFlow({
  actionGenerator: mapasMensais.obterMapasMensais,
  api: ListarMapasMensaisApi,
});

function* ListarMapasMensaisRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_MAPA_MENSAL, function* () {
    const descricao = yield select(getMapasMensaisFilter);
    const cliente = yield select(getPayload);

    yield put(
      mapasMensais.obterMapasMensais.request({
        pagina: 1,
        descricao,
        idCliente: cliente.id,
      })
    );
  });
}

const EditarMapasMensaisApi = (values) => {
  return authenticatedRequest({
    url: `/mapa-mensal/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const EditarMapasMensais = basicFlow({
  actionGenerator: mapasMensais.editarMapaMensal,
  api: EditarMapasMensaisApi,
  postSuccess: function* () {
    yield toast.success("Configuração atualizada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getMapasMensaisCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_MAPA_MENSAL, { id: cliente })
    );
    const pagina = yield select(getMapasMensaisPage);
    const nome = yield select(getMapasMensaisFilter);
    yield put(
      mapasMensais.obterMapasMensais.request({
        pagina,
        nome,
        idCliente: cliente,
      })
    );
  },
});

const criarMapasMensaisApi = (values) => {
  return authenticatedRequest({
    url: `/mapa-mensal`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const CriarMapasMensais = basicFlow({
  actionGenerator: mapasMensais.criarMapaMensal,
  api: criarMapasMensaisApi,
  postSuccess: function* () {
    yield toast.success("Configuração criada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getMapasMensaisCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_MAPA_MENSAL, { id: cliente })
    );
    const pagina = yield select(getMapasMensaisPage);
    const nome = yield select(getMapasMensaisFilter);
    yield put(
      mapasMensais.obterMapasMensais.request({
        pagina,
        nome,
        idCliente: cliente,
      })
    );
  },
});

const deletarMapasMensaisApi = (values) => {
  return authenticatedRequest({
    url: `/mapa-mensal/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const DeletarMapasMensais = basicFlow({
  actionGenerator: mapasMensais.deletarMapaMensal,
  api: deletarMapasMensaisApi,
  postSuccess: function* () {
    yield toast.success("Configuração excluída com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    const cliente = yield select(getMapasMensaisCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_MAPA_MENSAL, { id: cliente })
    );
    const pagina = yield select(getMapasMensaisPage);
    const descricao = yield select(getMapasMensaisFilter);
    yield put(
      mapasMensais.obterMapasMensais.request({
        pagina,
        descricao,
        idCliente: cliente,
      })
    );
  },
});

const MostrarMapaMensalApi = (values) => {
  return authenticatedRequest({
    url: `/mapa-mensal/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarMapaMensal = basicFlow({
  actionGenerator: mapasMensais.mostrarMapaMensal,
  transform: function* (payload) {
    const id = yield select((state) => state.mapasMensais.id);
    return { id, ...payload };
  },

  api: MostrarMapaMensalApi,
});

function* MostrarMapaMensalRouteWatcher() {
  yield routeWatcher(routes.EDITAR_MAPA_MENSAL, function* () {
    const MapaMensal = yield select(getPayload);
    yield put(mapasMensais.mostrarMapaMensal.request({ id: MapaMensal.id }));
  });
}

export const sagas = [
  MostrarMapaMensalRouteWatcher(),
  ListarMapasMensaisRouteWatcher(),
  ListarMapasMensais.watcher(),
  MostrarMapaMensal.watcher(),
  EditarMapasMensais.watcher(),
  CriarMapasMensais.watcher(),
  DeletarMapasMensais.watcher(),
];
