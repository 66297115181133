import basicFlow from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as feriados } from "../../reducers/cadastro/feriados.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getFeriadosCliente,
  getFeriadosFilter,
  getFeriadosPage,
} from "../../selectors/cadastro/feriados.selectors";

const ListarFeriadosApi = (values) => {
  return authenticatedRequest({
    url: `/feriado/listar`,
    isResourceService: true,
    method: "post",
    body: {
      nome: values?.nome ?? "",
      pagina: values?.pagina ?? 1,
      limite: 9,
      idCliente: values?.idCliente ?? "",
    },
  });
};

const ListarFeriados = basicFlow({
  actionGenerator: feriados.obterFeriados,
  api: ListarFeriadosApi,
});

function* ListarFeriadosRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_FERIADO, function* () {
    const nome = yield select(getFeriadosFilter);
    const cliente = yield select(getPayload);

    yield put(
      feriados.obterFeriados.request({
        pagina: 1,
        nome,
        idCliente: cliente.id,
      })
    );
  });
}

const EditarFeriadosApi = (values) => {
  return authenticatedRequest({
    url: `/feriado/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const EditarFeriados = basicFlow({
  actionGenerator: feriados.editarFeriado,
  api: EditarFeriadosApi,
  postSuccess: function* () {
    yield toast.success("Feriado atualizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getFeriadosCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_FERIADO, { id: cliente })
    );
    const pagina = yield select(getFeriadosPage);
    const nome = yield select(getFeriadosFilter);
    yield put(
      feriados.obterFeriados.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const criarFeriadosApi = (values) => {
  return authenticatedRequest({
    url: `/feriado`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const CriarFeriados = basicFlow({
  actionGenerator: feriados.criarFeriado,
  api: criarFeriadosApi,
  postSuccess: function* () {
    yield toast.success("Feriado criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getFeriadosCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_FERIADO, { id: cliente })
    );
    const pagina = yield select(getFeriadosPage);
    const nome = yield select(getFeriadosFilter);
    yield put(
      feriados.obterFeriados.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const deletarFeriadosApi = (values) => {
  return authenticatedRequest({
    url: `/feriado/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const DeletarFeriados = basicFlow({
  actionGenerator: feriados.deletarFeriado,
  api: deletarFeriadosApi,
  postSuccess: function* () {
    yield toast.success("Feriado excluído com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    const cliente = yield select(getFeriadosCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_FERIADO, { id: cliente })
    );
    const pagina = yield select(getFeriadosPage);
    const nome = yield select(getFeriadosFilter);
    yield put(
      feriados.obterFeriados.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const MostrarFeriadoApi = (values) => {
  return authenticatedRequest({
    url: `/feriado/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarFeriado = basicFlow({
  actionGenerator: feriados.mostrarFeriado,
  transform: function* (payload) {
    const id = yield select((state) => state.feriados.id);
    return { id, ...payload };
  },

  api: MostrarFeriadoApi,
});

function* MostrarFeriadoRouteWatcher() {
  yield routeWatcher(routes.EDITAR_FERIADO, function* () {
    const feriado = yield select(getPayload);
    yield put(feriados.mostrarFeriado.request({ id: feriado.id }));
  });
}

export const sagas = [
  MostrarFeriadoRouteWatcher(),
  ListarFeriadosRouteWatcher(),
  ListarFeriados.watcher(),
  MostrarFeriado.watcher(),
  EditarFeriados.watcher(),
  CriarFeriados.watcher(),
  DeletarFeriados.watcher(),
];
