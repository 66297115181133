import React from "react";
import { styled, Autocomplete } from "@mui/material";
import CustomTextField from "./CustomTextField";

const StyledAutocomplete = styled(Autocomplete)`
  .css-tlyple-MuiFormControl-root-MuiTextField-root {
    background-color: ${({ disabled }) => (disabled ? "#b8c0c2ff" : "#3F484A")};
  }
`;

const CustomAutocomplete = ({
  disabled,
  formProps,
  domain,
  labelField,
  secondLabelField,
  idField = "id",
  name,
  value,
  setValue,
  ignoreError = false,
  ...otherProps
}) => {
  const getOptionLabel = (option) => {
    return (
      option[labelField] ??
      domain.find((d) => d[idField] === option?.[idField])?.[labelField] ??
      domain.find((d) => d[idField] === option)?.[labelField] ??
      ""
    );
  };

  return (
    <StyledAutocomplete
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      disabled={disabled}
      autoComplete={true}
      freeSolo
      getOptionLabel={getOptionLabel}
      noOptionsText={"Nenhuma opção correspondente"}
      options={domain}
      renderInput={(params) => <CustomTextField {...params} {...otherProps} />}
    />
  );
};

export default CustomAutocomplete;
