import React from "react";
import { Controller } from "react-hook-form";
import { styled, Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/base";
import CustomTextField from "./CustomTextField";

const filterOptions = createFilterOptions({
  ignoreAccents: true,
  ignoreCase: true,
  trim: true,
});

const StyledAutocomplete = styled(Autocomplete)`
  .css-tlyple-MuiFormControl-root-MuiTextField-root {
    background-color: ${({ disabled }) => (disabled ? "#b8c0c2ff" : "#3F484A")};
  }
    
  .MuiChip-root {
    background-color: #b8c0c2ff;    
    margin-top: 15px;  
  }  
`;

const ControlledAutocomplete = ({
  disabled,
  formProps,
  domain,
  labelField,
  secondLabelField,
  idField = "id",
  name,
  ignoreError = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined && !ignoreError;

  const getOptionLabel = (option) => {
    return (
      option[labelField] ??
      domain.find((d) => d[idField] === option?.[idField])?.[labelField] ??
      domain.find((d) => d[idField] === option)?.[labelField] ??
      ""
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value } }) => (
        <StyledAutocomplete
          value={value}
          disabled={disabled}
          autoComplete={true}
          filterOptions={filterOptions}
          freeSolo
          noOptionsText={"Nenhuma opção correspondente"}
          options={domain}
          getOptionLabel={getOptionLabel}
          onChange={(e, v) => {
            onChange(v);
            if (!!otherProps.onChange) {
              otherProps.onChange(v);
            }
          }}
          onBlur={() => {
            onBlur();
            if (!!otherProps.onBlur) {
              otherProps.onBlur();
            }
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              {...otherProps}
              error={isError}
              helperText={!ignoreError ? errors[name]?.message : undefined}
            />
          )}
        />
      )}
    />
  );
};

export default ControlledAutocomplete;
