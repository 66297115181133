import React from "react";
import { Tooltip, styled } from "@mui/material";
import { Error } from "@mui/icons-material";

const Alert = styled(Error)`
  margin-left: 10px;
  fill: #ff6600;
  height: 20px;
  width: 20px;
`;

const AlertIcon = ({ message = "", display }) => {
  if (!display) {
    return null;
  }

  return (
    <Tooltip title={message}>
      <Alert />
    </Tooltip>
  );
};

export default AlertIcon;
