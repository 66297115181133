import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  StreetViewPanorama,
} from "@react-google-maps/api";
import FilterButtons from "./FilterButtons.connected";
import UltimaPosicaoValida from "./UltimaPosicaoValida.connected";
import KmlLayer from "./KmlLayer.connected";
import PosicoesValidas from "./PosicoesValidas.connected";
import CheckpointsEfetivados from "./CheckpointsEfetivados.connected";
import SearchAddressInputText from "./SearchAddressInputText.connected";
import { FilterAltOutlined } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import { getEmpresa } from "../../../selectors/diario.selectors";
import { useSelector } from "react-redux";

const mapStyle = {
  inset: 0,
  position: "absolute",
  overflow: "hidden",
};
const libraries = ["places"];

const LongButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.family.buttons};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 800;
  box-shadow: ${({ theme }) => theme.shadow.map.options};
  padding: 6px 0.6rem;
  border-radius: 300px;
  height: 40px;

  & .MuiButton-startIcon {
    margin-left: 0;
  }

  @media (min-width: 532px) {
    font-size: ${({ theme }) => theme.fonts.size.default};
    padding: 6px 1rem;
  }
`;
const FilterButton = styled(LongButton)`
  color: ${({ theme }) => theme.colors.dark};
  background: ${({ theme }) => theme.colors.white};
  text-transform: unset;
  position: absolute;
  right: 1rem;
  bottom: 2rem;

  & .MuiButton-startIcon {
    margin-right: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;
const ThinText = styled("span")`
  font-weight: 300;
`;

const GoogleMaps = ({
  loading,
  items = [],
  handleClickOpenFilters,
  position,
}) => {
  const [zoom] = useState(15);
  const [mapType] = useState("roadmap");
  const [mapInstance, setMapInstance] = useState(null);
  const empresa = useSelector(getEmpresa);
  const [centerPosition, setCenterPosition] = useState(position);

  useEffect(() => {
    if (empresa?.roteiro === "N") {
      let newPosition = {
        lat: Number(empresa?.latitude),
        lng: Number(empresa?.longitude),
      };

      setCenterPosition(newPosition);
    }
  }, [empresa, setCenterPosition]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      <GoogleMap
        mapContainerStyle={mapStyle}
        center={centerPosition}
        mapTypeId={mapType}
        onLoad={(m) => setMapInstance(m)}
        zoom={zoom}
        options={{
          disableDefaultUI: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
            mapTypeIds: [
              window.google?.maps?.MapTypeId?.ROADMAP,
              window.google?.maps?.MapTypeId?.SATELLITE,
            ],
          },
          zoomControl: true,
          zoomControlOptions: {
            position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
          },
        }}
      >
        <StreetViewPanorama
          options={{
            fullscreenControl: false,
            addressControlOptions: {
              position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
            },
            zoomControlOptions: {
              position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
            },
            panControl: false,
          }}
        />
        {items.length > 0 && <FilterButtons />}

        <KmlLayer />
        <FilterButton
          startIcon={<FilterAltOutlined />}
          onClick={handleClickOpenFilters}
        >
          {loading ? (
            <ThinText>...</ThinText>
          ) : (
            <>
              {items.length}&nbsp;<ThinText>OS</ThinText>
            </>
          )}
        </FilterButton>
        <CheckpointsEfetivados />
        <PosicoesValidas />
        <UltimaPosicaoValida />
        <SearchAddressInputText mapInstance={mapInstance} />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMaps;
