import { Card, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import IconButton from "../../../components/buttons/IconButton";
import {
  ColorLensOutlined,
  DateRange,
  DeleteForeverOutlined,
  EditOutlined,
  RestartAlt,
  Visibility,
  ReportProblemOutlined
} from "@mui/icons-material";
import { DeleteDialog } from "../../../components";
import LinkIcon from "@mui/icons-material/Link";
import EditRoadIcon from "@mui/icons-material/EditRoad";

const StyledGridContainer = styled(Grid)`
  height: 100%;
  @media (max-width: 576px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 0 0;
  background-color: #dbe4e6;
`;

const StyledInactiveCard = styled(Card)`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 0 0;
  background-color: #c4c7c7;
`;

const StyledGridTitle = styled(Grid)`
  margin-top: -25px;
  padding: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledTitle = styled("h1")`
  color: #2f85c2;
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-family: Oswald;
  font-size: 28px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledInactiveTitle = styled("h1")`
  color: #70797b;
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-family: Oswald;
  font-size: 28px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledSubTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledButtonsContainer = styled("div")`
  margin-top: -30px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`;

function ClienteCard({
  item,
  index,
  handleDelete,
  handleEdit,
  handleActivate,
  handleIntegracoes,
  handleRoteiros,
  handleView,
  handleFeriado,
  handleMapasMensais,
  handleAlerta
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogFunction, setDialogFunction] = useState("");
  const [idToAct, setIdToAct] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [isExclusao, setIsExclusao] = useState(false);

  const handleClickEdit = (e) => {
    handleEdit(e, item.id);
  };
  const handleClickView = (e) => {
    handleView(e, item.id);
  };
  const handleClickDelete = (e) => {
    handleDelete(e, item.id);
  };
  const handleClickActivate = (e) => {
    handleActivate(e, item.id);
  };
  const handleClickIntegracao = (e) => {
    handleIntegracoes(e, item.id);
  };
  const handleClickRoteiro = (e) => {
    handleRoteiros(e, item.id);
  };
  const handleClickFeriado = (e) => {
    handleFeriado(e, item.id);
  };
  const handleClickMapasMensais = (e) => {
    handleMapasMensais(e, item.id);
  };
  const handleClickAlerta = (e) => {
    handleAlerta(e, item.id);
  }

  return (
    <StyledGridContainer key={index} item xs={12} sm={12} md={4}>
      {item.situacao === "A" ? (
        <StyledCard>
          <Grid container>
            <StyledGridTitle item xs={12} sm={12} md={12}>
              <StyledTitle>{item.nome}</StyledTitle>
              <StyledSubTitle>Lote {item?.lote ?? "-"}</StyledSubTitle>
            </StyledGridTitle>
            <Grid item xs={12} sm={12} md={12}>
              <StyledButtonsContainer>
                <IconButton
                  handleClick={(e) => {
                    handleClickAlerta(e, item.id);
                  }}
                >
                  <Tooltip title="Alertas">
                    <ReportProblemOutlined sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  handleClick={(e) => {
                    handleClickMapasMensais(e, item.id);
                  }}
                >
                  <Tooltip title="Mapa Mensal">
                    <ColorLensOutlined sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  handleClick={(e) => {
                    handleClickFeriado(e, item.id);
                  }}
                >
                  <Tooltip title="Feriados">
                    <DateRange sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>

                <IconButton
                  handleClick={(e) => {
                    handleClickRoteiro(e, item.id);
                  }}
                >
                  <Tooltip title="Roteiro">
                    <EditRoadIcon sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  handleClick={(e) => {
                    handleClickIntegracao(e, item.id);
                  }}
                >
                  <Tooltip title="Integração">
                    <LinkIcon sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  handleClick={(e) => {
                    handleClickEdit(e, item.id);
                  }}
                >
                  <Tooltip title="Editar">
                    <EditOutlined sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  handleClick={(e) => {
                    setIdToAct(item.id);
                    setDialogFunction("inativar");
                    setIsExclusao(true);
                    setDialogTitle(
                      `Tem certeza que gostaria de inativar o cliente ${item.nome}?`
                    );
                    setOpenDialog(true);
                  }}
                >
                  <Tooltip title="Excluir">
                    <DeleteForeverOutlined sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
              </StyledButtonsContainer>
            </Grid>
          </Grid>
        </StyledCard>
      ) : (
        <StyledInactiveCard>
          <Grid container>
            <StyledGridTitle item xs={12} sm={12} md={12}>
              <StyledInactiveTitle>{item.nome}</StyledInactiveTitle>
              <StyledSubTitle>Lote {item?.lote ?? "-"}</StyledSubTitle>
            </StyledGridTitle>
            <Grid item xs={12} sm={12} md={12}>
              <StyledButtonsContainer>
                <IconButton
                  handleClick={(e) => {
                    handleClickView(e, item.id);
                  }}
                >
                  <Tooltip title="Visualizar">
                    <Visibility sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  handleClick={(e) => {
                    setIdToAct(item.id);
                    setDialogFunction("ativar");
                    setIsExclusao(false);
                    setDialogTitle(
                      `Tem certeza que gostaria de ativar o cliente ${item.nome}?`
                    );
                    setOpenDialog(true);
                  }}
                >
                  <Tooltip title="Reativar">
                    <RestartAlt sx={{ fill: "#51a8d1" }} />
                  </Tooltip>
                </IconButton>
              </StyledButtonsContainer>
            </Grid>
          </Grid>
        </StyledInactiveCard>
      )}
      <DeleteDialog
        title={dialogTitle}
        open={openDialog}
        isExclusao={isExclusao}
        setOpen={setOpenDialog}
        deleteFunction={
          dialogFunction === "ativar" ? handleClickActivate : handleClickDelete
        }
        id={idToAct}
      />
    </StyledGridContainer>
  );
}

export default ClienteCard;
