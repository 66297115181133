import { useForm } from "react-hook-form";
import FormCriarMapaMensalPage from "./FormCriarMapaMensalPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/mapasMensais.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import {
  getMapaMensal,
  getMapasMensais,
} from "../../../selectors/cadastro/mapasMensais.selectors";
import formatters from "../../../utils/formatters";

const FormCriarMapaMensalPageConnected = () => {
  const { loading } = useSelector(getMapaMensal);
  const { cliente } = useSelector(getMapasMensais);

  const dispatch = useDispatch();

  const rules = {
    descricao: validators.string({ required: true }),
    porcentagemInicial: validators.string({ required: true }),
    porcentagemFinal: validators.string({ required: true }),
    cor: validators.string({ required: true }),
  };

  const initialValues = {
    descricao: "",
    porcentagemInicial: "",
    porcentagemFinal: "",
    cor: "",
  };
  const defaultValues = {
    descricao: "",
    porcentagemInicial: "",
    porcentagemFinal: "",
    cor: "",
    dataHoraCriacao: formatters.dates.withHours(new Date()),
    dataHoraAtualizacao: formatters.dates.withHours(new Date()),
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      values.idEmpresa = cliente;
      dispatch(actions.criarMapaMensal.request(values));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_MAPA_MENSAL, { id: cliente })
    );
  };

  return (
    <FormCriarMapaMensalPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
    />
  );
};

export default FormCriarMapaMensalPageConnected;
