import { actions, types as authTypes } from "./autenticacao.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  login: {
    loading: false,
    error: null,
  },
  "esqueci-senha": {
    loading: false,
    error: null,
  },
  "trocar-senha": {
    token: null,
    loading: false,
    error: null,
  },
  "validar-token-senha": {
    valid: false,
    type: null,
    loading: false,
    error: null,
  },
  token: {
    loading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.login.REQUEST:
    case actions.login.SUCCESS:
    case actions.login.FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          loading: action.type === actions.login.REQUEST,
          error:
            action.type === actions.login.FAILURE ? action.payload.error : null,
        },
      };
    case actions.esqueciSenha.REQUEST:
    case actions.esqueciSenha.SUCCESS:
    case actions.esqueciSenha.FAILURE:
      return {
        ...state,
        "esqueci-senha": {
          ...state["esqueci-senha"],
          loading: action.type === actions.esqueciSenha.REQUEST,
          error:
            action.type === actions.esqueciSenha.FAILURE
              ? action.payload.error
              : null,
        },
      };
    case actions.trocarSenha.REQUEST:
    case actions.trocarSenha.SUCCESS:
    case actions.trocarSenha.FAILURE:
      return {
        ...state,
        "trocar-senha": {
          ...state["trocar-senha"],
          loading: action.type === actions.trocarSenha.REQUEST,
          error:
            action.type === actions.trocarSenha.FAILURE
              ? action.payload.error
              : null,
        },
      };
    case routes.TROCAR_SENHA:
      return {
        ...state,
        "trocar-senha": {
          ...state["trocar-senha"],
          token: action.payload.token,
        },
      };

    case actions.validarTokenSenha.REQUEST:
    case actions.validarTokenSenha.FAILURE:
      return {
        ...state,
        "validar-token-senha": {
          ...state["validar-token-senha"],
          loading: action.type === actions.validarTokenSenha.REQUEST,
          error:
            action.type === actions.validarTokenSenha.FAILURE
              ? action.payload.error
              : null,
        },
      };
    case actions.validarTokenSenha.SUCCESS:
      return {
        ...state,
        "validar-token-senha": {
          ...state["validar-token-senha"],
          loading: false,
          ...action.payload.response.data,
        },
      };
    case actions.tokenLogin.REQUEST:
    case actions.tokenLogin.FAILURE:
    case actions.tokenLogin.SUCCESS:
    case actions.reenviarTokenSenha.REQUEST:
    case actions.reenviarTokenSenha.FAILURE:
    case actions.reenviarTokenSenha.SUCCESS:
      return {
        ...state,
        token: {
          loading:
            action.type === actions.tokenLogin.REQUEST ||
            action.type === actions.reenviarTokenSenha.REQUEST,
        },
      };
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
