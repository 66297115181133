import React from "react";
import MapaDiarioPage from "./MapaDiarioPage";
import { useSelector } from "react-redux";
import { getCenter } from "../../selectors/diario.selectors";

function MapaDiarioPageConnected() {
  const position = useSelector(getCenter);

  return <MapaDiarioPage position={position} />;
}

export default MapaDiarioPageConnected;
