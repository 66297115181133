import { useCallback } from "react";
import EsqueciSenhaForm from "./EsqueciSenhaForm";
import validators from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { getEsqueciSenhaLoading } from "../../selectors/autenticacao.selectors";
import { useForm } from "react-hook-form";

const EsqueciSenhaFormConnected = ({ internal, type }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getEsqueciSenhaLoading);

  const rules = {
    user: validators.string({ required: true }),
  };
  const initialValues = {
    user: "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleClickBack = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.LOGIN));
  }, [dispatch]);
  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.esqueciSenha.request({
          ...values,
          type,
        })
      );
    },
    [dispatch, type]
  );

  return (
    <EsqueciSenhaForm
      internal={internal}
      loading={loading}
      formProps={formProps}
      handleClickBack={handleClickBack}
      handleSubmit={handleSubmit}
    />
  );
};

export default EsqueciSenhaFormConnected;
