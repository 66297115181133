import BasePage from "../../BasePage";
import React, { useEffect, useState } from "react";
import { Card, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledCustomTextField,
  CustomControlledAutoComplete,
} from "../../../components/inputs";
import { Button } from "../../../components";
import CustomControlledAutocompleteCreateTable from "../../../components/inputs/CustomAutocompleteCreate";
import CustomControlledSwitch from "../../../components/inputs/CustomControlledSwitch";
import { tipoAlerta, tipoDestinatarioAlerta } from "../../../utils/parametros";
import CustomControlledAutocompleteMultiple from "../../../components/inputs/CustomControlledAutocompleteMultiple";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;
const StyledControlledCustomTextField = styled(ControlledCustomTextField)``;

const StyledAtualizarButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledIncluirButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 100%;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
  }
`;

const StyledPosicaoButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 100%;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-bottom: 15px;
  @media (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;
const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-bottom: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
`;
const ButtonContainerLeft = styled("div")`
  display: flex;
  margin-left: 0px;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const FormEditarAlertaPage = ({
  loading,
  formProps,
  handleSubmit,
  handleGoBack,
  dataAlerta,
  usuarios,
}) => {

  useEffect(() => {
    setIsNotificaPorEmail(dataAlerta.notificaPorEmail)
  }, [dataAlerta.notificaPorEmail]);

  useEffect(() => {
    setTipoAlerta(dataAlerta.tipo)
  }, [dataAlerta.tipo]);

  const [isNotificaPorEmail, setIsNotificaPorEmail] = useState(false);
  const [tipoAlertaSelecionado, setTipoAlerta] = useState(dataAlerta.tipo);

  const changeTipoAlerta = (e) => {
    setTipoAlerta(e.id);
    switch (e.id) {
      case "RA":
        formProps.setValue("maximoHorasSemMensagem", "");
        formProps.setValue("minutosParado", "");
        break;
      case "FM":
        formProps.setValue("percentualEvolucaoAbaixo", "");
        formProps.setValue("minutosEvolucao", "");
        formProps.setValue("minutosParado", "");
        break;
      case "RP":
        formProps.setValue("percentualEvolucaoAbaixo", "");
        formProps.setValue("minutosEvolucao", "");
        formProps.setValue("maximoHorasSemMensagem", "");
        break;
      default:
        break;
    }
  }

  const changeNotificaPorEmail = (checked) => {
    setIsNotificaPorEmail(checked);
  }

  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Editar Alerta</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Editar Alerta</StyledTitle>
      <StyledForm id="form1" onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledCardTitle>Dados Básicos</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="nome"
                label="Nome"
                inputProps={{ maxLength: 20 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <CustomControlledAutoComplete
                formProps={formProps}
                fullWidth
                name="tipo"
                label="Tipo"
                labelField={"nome"}
                idField={"id"}
                domain={tipoAlerta}
                onChange={changeTipoAlerta}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="percentualEvolucaoAbaixo"
                label="Percentual Evolução"
                type="number"
                inputProps={{ max: 2147483647 }}
                disabled={tipoAlertaSelecionado !== 'RA'}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="minutosEvolucao"
                label="Quantidade Minutos Evolução"
                type="number"
                inputProps={{ max: 2147483647 }}
                disabled={tipoAlertaSelecionado !== 'RA'}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="maximoHorasSemMensagem"
                label="Máximo horas sem enviar mensagem"
                type="number"
                inputProps={{ max: 2147483647 }}
                disabled={tipoAlertaSelecionado !== 'FM'}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="minutosParado"
                label="Quantidade de minutos parado"
                type="number"
                inputProps={{ max: 2147483647 }}
                disabled={tipoAlertaSelecionado !== 'RP'}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <CustomControlledSwitch
                formProps={formProps}
                fullWidth
                name="notificaPorEmail"
                label="Envia Notificação por E-mail?"
                onChange={(e) => changeNotificaPorEmail(e.target.checked)}
              ></CustomControlledSwitch>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={8}>
              <CustomControlledAutocompleteMultiple
                formProps={formProps}
                fullWidth
                name="destinatario"
                label="Destinatário"
                labelField={"nome"}
                idField={"id"}
                domain={usuarios}
                disabled={!isNotificaPorEmail}
              />
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainer>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </ButtonContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainerLeft>
              <StyledAtualizarButton type={"submit"} loading={loading}>
                Editar
              </StyledAtualizarButton>
            </ButtonContainerLeft>
          </Grid>
        </Grid>
      </StyledForm>
    </BasePage>
  );
};

export default FormEditarAlertaPage;
