import basicFlow, { genericErrorHandler } from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as roteiros } from "../../reducers/cadastro/roteiros.actions";
import { actions as clientes } from "../../reducers/cadastro/clientes.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getRoteirosCliente,
  getRoteirosFilter,
  getRoteirosPage,
} from "../../selectors/cadastro/roteiros.selectors";
import { saveAs } from "file-saver";
import formatters from "../../utils/formatters";

const ListarRoteirosApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/listar`,
    isResourceService: true,
    method: "post",
    body: {
      nome: values?.nome ?? "",
      pagina: values?.pagina ?? 1,
      limite: 9,
      idCliente: values?.idCliente ?? "",
    },
  });
};

const ListarRoteiros = basicFlow({
  actionGenerator: roteiros.obterRoteiros,
  api: ListarRoteirosApi,
});

const ListarGruposApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/listar/grupos`,
    isResourceService: true,
    method: "post",
    body: {
      idCliente: values?.idCliente ?? "",
    },
  });
};

const ListarGrupos = basicFlow({
  actionGenerator: roteiros.obterGrupos,
  api: ListarGruposApi,
});

const ListarDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/listar/dispositivos`,
    isResourceService: true,
    method: "post",
  });
};

const ListarDispositivos = basicFlow({
  actionGenerator: roteiros.obterDispositivos,
  api: ListarDispositivosApi,
});

const ListarFuncionamentosApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/listar/funcionamentos`,
    isResourceService: true,
    method: "post",
    body: {
      idRoteiro: values?.idRoteiro ?? "",
    },
  });
};
const ListarFuncionamentos = basicFlow({
  actionGenerator: roteiros.obterFuncionamentos,
  api: ListarFuncionamentosApi,
});

const ListarKmlsApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/listar/kmls`,
    isResourceService: true,
    method: "post",
    body: {
      idRoteiro: values?.idRoteiro ?? "",
    },
  });
};
const ListarKmls = basicFlow({
  actionGenerator: roteiros.obterKmls,
  api: ListarKmlsApi,
});

const DeletarKmlApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/deletar/kml`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};
const DeletarKml = basicFlow({
  actionGenerator: roteiros.deletarKml,
  api: DeletarKmlApi,
  postSuccess: function* ({ original }) {
    const roteiro = yield select(getPayload);

    yield toast.success("Arquivo excluído com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(roteiros.obterKmls.request({ idRoteiro: roteiro.id }));
  },
});

const DeletarFuncionamnetoApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/deletar/funcionamento`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};
const DeletarFuncionamento = basicFlow({
  actionGenerator: roteiros.deletarFuncionamento,
  api: DeletarFuncionamnetoApi,
  postSuccess: function* ({ original, values }) {
    const roteiro = yield select(getPayload);

    yield toast.success("Funcionamento excluído com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(roteiros.obterFuncionamentos.request({ idRoteiro: roteiro.id }));
  },
});

const DeletarPosicaoApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/deletar/posicao`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};
const DeletarPosicao = basicFlow({
  actionGenerator: roteiros.deletarPosicao,
  api: DeletarPosicaoApi,
  postSuccess: function* ({ values }) {
    yield toast.success("Arquivo excluído com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(roteiros.obterPosicoes.request({ idRoteiro: values.idRoteiro }));
  },
});

const ListarPosicoesApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/listar/posicoes`,
    isResourceService: true,
    method: "post",
    body: {
      idRoteiro: values?.idRoteiro ?? "",
    },
  });
};
const ListarPosicoes = basicFlow({
  actionGenerator: roteiros.obterPosicoes,
  api: ListarPosicoesApi,
});

const CriarGrupoApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/criar/grupo`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const CriarGrupo = basicFlow({
  actionGenerator: roteiros.criarGrupo,
  api: CriarGrupoApi,
});

function* ListarRoteirosRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_ROTEIRO, function* () {
    const pagina = yield select(getRoteirosPage);
    const nome = yield select(getRoteirosFilter);
    const cliente = yield select(getPayload);

    yield put(
      roteiros.obterRoteiros.request({
        pagina,
        nome,
        idCliente: cliente.id,
      })
    );
  });
}
function* ListarGruposRouteWatcher() {
  yield routeWatcher(routes.CRIAR_ROTEIRO, function* () {
    const cliente = yield select(getPayload);
    yield put(
      roteiros.obterGrupos.request({
        idCliente: cliente.id,
      })
    );
    yield put(roteiros.obterDispositivos.request());
    yield put(clientes.mostrarCliente.request({ id: cliente.id }));
  });
}
function* cargaRoteiroWatcher() {
  yield routeWatcher(routes.CARGA_ROTEIRO, function* () {
    yield put(roteiros.limparTabelas());
  });
}
function* cargaTrocarDispositivoWatcher() {
  yield routeWatcher(routes.CARGA_TROCAR_DISPOSITIVO, function* () {
    yield put(roteiros.limparTabelas());
  });
}
function* cargaDispositivoWatcher() {
  yield routeWatcher(routes.CARGA_DISPOSITIVO, function* () {
    yield put(roteiros.limparTabelas());
  });
}
function* cargaArquivoWatcher() {
  yield routeWatcher(routes.CARGA_ARQUIVO, function* () {
    yield put(roteiros.limparRoteiros());
  });
}

const EditarRoteirosApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const EditarRoteiros = basicFlow({
  actionGenerator: roteiros.editarRoteiro,
  api: EditarRoteirosApi,
  postSuccess: function* ({ original }) {
    yield toast.success("Roteiro atualizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getRoteirosCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_ROTEIRO, {
        id: original.idEmpresa,
      })
    );
    const pagina = yield select(getRoteirosPage);
    const nome = yield select(getRoteirosFilter);
    yield put(
      roteiros.obterRoteiros.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const criarRoteirosApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const CriarRoteiros = basicFlow({
  actionGenerator: roteiros.criarRoteiro,
  transform: function (payload) {
    const form = new FormData();

    const {
      nome,
      metragem,
      equipe,
      servico,
      situacao,
      dispositivo,
      grupo,
      tipoDispositivo,
      tipoVeiculo,
      idEmpresa,
      posicao,
      kml,
      funcionamentos,
      dispositivoBackup,
      feriado,
    } = payload;
    form.append("idEmpresa", idEmpresa);
    form.append("feriado", feriado);
    form.append("nome", nome);
    form.append("equipe", String(equipe));
    form.append("metragem", metragem);
    form.append("servico", String(servico));
    form.append("situacao", situacao);
    form.append("dispositivo", dispositivo.id);
    if (dispositivoBackup?.id) {
      form.append("dispositivoBackup", dispositivoBackup?.id);
    }
    form.append("grupo", JSON.stringify(grupo));
    form.append("tipoDispositivo", JSON.stringify(tipoDispositivo));
    form.append("tipoVeiculo", JSON.stringify(tipoVeiculo));
    form.append("funcionamentos", JSON.stringify(funcionamentos));

    posicao.forEach((file, index) => {
      form.append("arquivos", file[0], file[0].name);
      form.append(file[0].name + file[0].size, JSON.stringify(file.posicoes));
    });
    kml.forEach((file) => {
      form.append("arquivos", file[0], file[0].name);
    });

    return form;
  },

  api: criarRoteirosApi,
  postSuccess: function* ({ original }) {
    yield toast.success("Roteiro criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(
      routeActions.redirectTo(routes.CADASTRO_ROTEIRO, {
        id: original.idEmpresa,
      })
    );
    const pagina = yield select(getRoteirosPage);
    const nome = yield select(getRoteirosFilter);
    yield put(
      roteiros.obterRoteiros.request({
        pagina,
        nome,
        idCliente: original.idEmpresa,
      })
    );
  },
});

const ListarRoteirosSemFiltroApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/listar/all`,
    isResourceService: true,
    method: "post",
    body: {
      idEmpresa: values.idEmpresa,
    },
  });
};

const ListarRoteirosSemFiltro = basicFlow({
  actionGenerator: roteiros.obterRoteirosSemFiltro,
  api: ListarRoteirosSemFiltroApi,
});

const criarKmlApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/criar/kml`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const CriarKml = basicFlow({
  actionGenerator: roteiros.criarKml,
  transform: function (payload) {
    const form = new FormData();
    const { idRoteiro, file } = payload;
    form.append("idRoteiro", idRoteiro);
    form.append("arquivo", file[0], file[0].name);
    return form;
  },
  api: criarKmlApi,
  postSuccess: function* ({ original }) {
    yield toast.success("Arquivo carregado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(roteiros.obterKmls.request({ idRoteiro: original.idRoteiro }));
  },
});

const criarFuncionamentoApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/criar/funcionamento`,
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const CriarFuncionamento = basicFlow({
  actionGenerator: roteiros.criarFuncionamento,
  api: criarFuncionamentoApi,
  postSuccess: function* ({ values }) {
    yield toast.success("Funcionamento salvo com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(
      roteiros.obterFuncionamentos.request({ idRoteiro: values.idRoteiro })
    );
  },
});

const criarPosicaoApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/criar/posicao`,
    isResourceService: true,
    headers: { "Content-Type": "multipart/form-data" },
    method: "post",
    body: values,
  });
};

const CriarPosicao = basicFlow({
  actionGenerator: roteiros.criarPosicao,
  transform: function (payload) {
    const form = new FormData();
    const { idRoteiro, posicoes, file } = payload;
    form.append("idRoteiro", idRoteiro);
    form.append("arquivo", file[0], file[0].name);
    form.append("posicoes", JSON.stringify(posicoes));

    return form;
  },
  api: criarPosicaoApi,
  postSuccess: function* ({ original }) {
    yield toast.success("Arquivo carregado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(
      roteiros.obterPosicoes.request({ idRoteiro: original.idRoteiro })
    );
  },
});

const deletarRoteirosApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const DeletarRoteiros = basicFlow({
  actionGenerator: roteiros.deletarRoteiro,
  api: deletarRoteirosApi,
  postSuccess: function* () {
    yield toast.success("Roteiro inativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    const cliente = yield select(getRoteirosCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_ROTEIRO, { id: cliente })
    );
    const pagina = yield select(getRoteirosPage);
    const nome = yield select(getRoteirosFilter);
    yield put(
      roteiros.obterRoteiros.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const AtivarRoteirosApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/ativar`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const AtivarRoteiros = basicFlow({
  actionGenerator: roteiros.reativarRoteiro,
  api: AtivarRoteirosApi,
  postSuccess: function* () {
    yield toast.success("Roteiro reativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    const cliente = yield select(getRoteirosCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_ROTEIRO, { id: cliente })
    );
    const pagina = yield select(getRoteirosPage);
    const nome = yield select(getRoteirosFilter);
    yield put(
      roteiros.obterRoteiros.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const uploadPlanilhaApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/upload/planilha`,
    headers: { "Content-Type": "multipart/form-data" },
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const uploadPlanilha = basicFlow({
  actionGenerator: roteiros.uploadPlanilha,
  transform: function (payload) {
    const form = new FormData();

    const { idEmpresa, posicao, kml } = payload;
    form.append("idEmpresa", idEmpresa);

    posicao.forEach((file, index) => {
      form.append("arquivos", file[0], file[0].name);
      form.append(file[0].name, JSON.stringify(file.posicoes));
    });
    kml.forEach((file) => {
      form.append("arquivos", file[0], file[0].name);
    });

    return form;
  },
  api: uploadPlanilhaApi,
});

const MostrarRoteiroApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarRoteiro = basicFlow({
  actionGenerator: roteiros.mostrarRoteiro,
  transform: function* (payload) {
    const id = yield select((state) => state.roteiros.id);
    return { id, ...payload };
  },

  api: MostrarRoteiroApi,
});

const BaixarArtefatoApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro/baixar/artefato`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const BaixarArtefato = basicFlow({
  actionGenerator: roteiros.baixarArtefato,
  api: BaixarArtefatoApi,
  postSuccess: ({ response, original }) => {
    downloadArtefatoFile(response, original);
  },
});

const downloadArtefatoFile = (response, original) => {
  const blob = new Blob([response.data], {
    type: "application/vnd.ms-excel",
  });
  saveAs(blob, original.nome);
};

const BaixarPlanilhaRoteiroApi = (values) => {
  return authenticatedRequest({
    url: `/carga/roteiro/download`,
    isResourceService: true,
    method: "post",
    responseType: "arraybuffer",
    body: {
      ...values,
    },
  });
};

const baixarPlanilhaRoteiros = basicFlow({
  actionGenerator: roteiros.baixarPlanilhaRoteiros,
  api: BaixarPlanilhaRoteiroApi,
  postSuccess: ({ response, original }) => {
    downloadPlanilha(response, original);
  },
});

const downloadPlanilha = (response, original) => {
  var bytes = new Uint8Array(response.data);

  var blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(
    blob,
    `cargaRoteiros_${original.nome}_${formatters.dates.withHoursNoSpace(
      new Date()
    )}.xlsx`
  );
};

const uploadCargaPlanilhaRoteiros = basicFlow({
  actionGenerator: roteiros.uploadCargaPlanilhaRoteiros,
  api: (values) => {
    const formData = new FormData();
    formData.append("file", values.file[0]);
    formData.append("id", values.id);
    return authenticatedRequest({
      url: `/carga/roteiro/upload`,
      isResourceService: true,
      method: "post",
      body: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  postFailure: function* ({ error, original, values }) {
    console.log(error.response);
    if (!!error.response.data.error.context) {
      yield toast.error("Processamento não concluído", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });

      yield put(
        roteiros.mostrarErroUpload({
          data: error.response.data.error.context,
          open: true,
        })
      );
    } else {
      yield genericErrorHandler({ error });
    }
  },
  postSuccess: function* ({ original }) {
    yield toast.success("Arquivo processado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

function* MostrarRoteiroRouteWatcher() {
  yield routeWatcher(routes.EDITAR_ROTEIRO, function* () {
    const roteiro = yield select(getPayload);
    yield put(roteiros.obterDispositivos.request());
    yield put(roteiros.mostrarRoteiro.request({ id: roteiro.id }));
  });
}

const BaixarPlanilhaDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/carga/dispositivo/download`,
    isResourceService: true,
    method: "post",
    responseType: "arraybuffer",
    body: {
      ...values,
    },
  });
};

const baixarPlanilhaDispositivos = basicFlow({
  actionGenerator: roteiros.baixarPlanilhaDispositivos,
  api: BaixarPlanilhaDispositivosApi,
  postSuccess: ({ response, original }) => {
    downloadPlanilhaDispositivos(response, original);
  },
});

const downloadPlanilhaDispositivos = (response, original) => {
  var bytes = new Uint8Array(response.data);

  var blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(
    blob,
    `cargaDispositivos_${formatters.dates.withHoursNoSpace(new Date())}.xlsx`
  );
};

const uploadCargaPlanilhaDispositivos = basicFlow({
  actionGenerator: roteiros.uploadCargaPlanilhaDispositivos,
  api: (values) => {
    const formData = new FormData();
    formData.append("file", values.file[0]);
    return authenticatedRequest({
      url: `/carga/dispositivo/upload`,
      isResourceService: true,
      method: "post",
      body: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  postFailure: function* ({ error, original, values }) {
    console.log(error.response);
    if (!!error.response.data.error.context) {
      yield toast.error("Arquivo Inválido. Verificar Inconsistências", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });

      yield put(
        roteiros.mostrarErroDispositivos({
          data: error.response.data.error.context,
          open: true,
        })
      );
    } else {
      yield genericErrorHandler({ error });
    }
  },
  postSuccess: function* ({ original }) {
    yield toast.success("Arquivo processado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

const BaixarPlanilhaTrocarDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/carga/trocar-dispositivo/download`,
    isResourceService: true,
    method: "post",
    responseType: "arraybuffer",
    body: {
      ...values,
    },
  });
};

const baixarPlanilhaTrocarDispositivos = basicFlow({
  actionGenerator: roteiros.baixarPlanilhaTrocarDispositivos,
  api: BaixarPlanilhaTrocarDispositivosApi,
  postSuccess: ({ response, original }) => {
    downloadPlanilhaTrocarDispositivos(response, original);
  },
});

const downloadPlanilhaTrocarDispositivos = (response, original) => {
  var bytes = new Uint8Array(response.data);

  var blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(
    blob,
    `cargaTrocarDispositivos_${formatters.dates.withHoursNoSpace(
      new Date()
    )}.xlsx`
  );
};

const uploadCargaTrocarDispositivos = basicFlow({
  actionGenerator: roteiros.uploadCargaTrocarDispositivos,
  api: (values) => {
    const formData = new FormData();
    formData.append("file", values.file[0]);
    return authenticatedRequest({
      url: `/carga/trocar-dispositivo/upload`,
      isResourceService: true,
      method: "post",
      body: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  postFailure: function* ({ error, original, values }) {
    console.log(error.response);
    if (!!error.response.data.error.context) {
      yield toast.error("Arquivo Inválido. Verificar Inconsistências", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });

      yield put(
        roteiros.mostrarErroTrocarDispositivos({
          data: error.response.data.error.context,
          open: true,
        })
      );
    } else {
      yield genericErrorHandler({ error });
    }
  },
  postSuccess: function* ({ original }) {
    yield toast.success("Arquivo processado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

export const sagas = [
  MostrarRoteiroRouteWatcher(),
  ListarRoteirosRouteWatcher(),
  ListarGruposRouteWatcher(),
  cargaRoteiroWatcher(),
  cargaArquivoWatcher(),
  cargaTrocarDispositivoWatcher(),
  cargaDispositivoWatcher(),
  ListarGrupos.watcher(),
  ListarRoteiros.watcher(),
  MostrarRoteiro.watcher(),
  EditarRoteiros.watcher(),
  CriarRoteiros.watcher(),
  DeletarRoteiros.watcher(),
  CriarGrupo.watcher(),
  ListarPosicoes.watcher(),
  ListarDispositivos.watcher(),
  uploadPlanilha.watcher(),
  ListarFuncionamentos.watcher(),
  ListarKmls.watcher(),
  CriarKml.watcher(),
  CriarPosicao.watcher(),
  DeletarKml.watcher(),
  DeletarPosicao.watcher(),
  CriarFuncionamento.watcher(),
  DeletarFuncionamento.watcher(),
  BaixarArtefato.watcher(),
  AtivarRoteiros.watcher(),
  baixarPlanilhaRoteiros.watcher(),
  uploadCargaPlanilhaRoteiros.watcher(),
  ListarRoteirosSemFiltro.watcher(),
  baixarPlanilhaDispositivos.watcher(),
  uploadCargaPlanilhaDispositivos.watcher(),
  baixarPlanilhaTrocarDispositivos.watcher(),
  uploadCargaTrocarDispositivos.watcher(),
];
