import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_ROTEIROS: "@@reprocessamento-os/LISTAR_ROTEIROS",
  LIMPAR_ROTEIROS: "@@reprocessamento-os/LIMPAR_ROTEIROS",
  REPROCESSAR: "@@reprocessamento-os/REPROCESSAR",
};

export const actions = {
  listarRoteiros: createAsyncAction(types.LISTAR_ROTEIROS),
  limparRoteiros: () => createSyncAction(types.LIMPAR_ROTEIROS),
  reprocessar: createAsyncAction(types.REPROCESSAR),
};
