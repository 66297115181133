import { actions, types } from "./integracoes.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  integracoes: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
    cliente: "",
  },
  integracao: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterIntegracoes.REQUEST:
    case actions.obterIntegracoes.SUCCESS:
    case actions.obterIntegracoes.FAILURE:
      return {
        ...state,
        integracoes: {
          ...state.integracoes,
          loading: action.type === actions.obterIntegracoes.REQUEST,
          data:
            action.type === actions.obterIntegracoes.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterIntegracoes.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          cliente:
            action.type === actions.obterIntegracoes.SUCCESS
              ? action.payload?.response?.data?.cliente ?? ""
              : "",
          nomeCliente:
            action.type === actions.obterIntegracoes.SUCCESS
              ? action.payload?.response?.data?.nomeCliente ?? ""
              : "",
          page:
            action.type === actions.obterIntegracoes.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case types.REMOVER_FILTRO_INTEGRACAO:
      return {
        ...state,
        integracoes: {
          ...state.integracoes,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_INTEGRACAO:
      return {
        ...state,
        integracoes: {
          ...state.integracoes,
          page: 1,
        },
      };

    case actions.editarIntegracao.REQUEST:
    case actions.editarIntegracao.SUCCESS:
    case actions.editarIntegracao.FAILURE:
      return {
        ...state,
        integracao: {
          ...state.integracao,
          loading: action.type === actions.editarIntegracao.REQUEST,
          data:
            action.type === actions.editarIntegracao.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDITAR_INTEGRACAO:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarIntegracao.REQUEST:
    case actions.mostrarIntegracao.SUCCESS:
    case actions.mostrarIntegracao.FAILURE:
      return {
        ...state,
        integracao: {
          ...state.integracao,
          loading: action.type === actions.mostrarIntegracao.REQUEST,
          data:
            action.type === actions.mostrarIntegracao.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarIntegracao.REQUEST:
    case actions.criarIntegracao.SUCCESS:
    case actions.criarIntegracao.FAILURE:
      return {
        ...state,
        integracao: {
          ...state.integracao,
          loading: action.type === actions.criarIntegracao.REQUEST,
          data:
            action.type === actions.criarIntegracao.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarIntegracao.REQUEST:
    case actions.deletarIntegracao.SUCCESS:
    case actions.deletarIntegracao.FAILURE:
      return {
        ...state,
        integracao: {
          ...state.integracao,
          loading: action.type === actions.deletarIntegracao.REQUEST,
        },
      };

    default:
      return state;
  }
};

export default reducer;
