import React, { useState } from "react";
import { InfoWindow } from "@react-google-maps/api";
import formatters from "../../../utils/formatters";
import { FlexContainer } from "../../../components/styled";
import { styled } from "@mui/material";
import { Button, DeleteDialog } from "../../../components";

const ThinText = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._700};
  margin: 0 0 0.25rem 0;
`;
const BoldText = styled("span")`
  font-family: inherit;
  font-size: inherit;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.primary._800};
`;
const DeleteButton = styled(Button)`
  border-radius: 100px;
  width: 100%;
  margin-top: 1rem;

  background-color: ${({ theme }) => theme.colors.primary._600};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary._600};
  }
`;

const InfoWindowPosicao = ({
  item,
  handleClose,
  handleDelete,
  isEndUser,
  canDelete = true,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickDelete = () => {
    handleDelete({ id: item?.id, callback: handleClose });
  };

  return (
    <>
      {!!item && (
        <>
          <InfoWindow
            position={item.longitudeLatitude}
            onCloseClick={handleClose}
            options={{
              pixelOffset: new window.google.maps.Size(0, -68),
            }}
          >
            <FlexContainer __direction={"column"}>
              <ThinText>
                Latitude: <BoldText>{item.longitudeLatitude.lat}</BoldText>
              </ThinText>
              <ThinText>
                Longitude: <BoldText>{item.longitudeLatitude.lng}</BoldText>
              </ThinText>
              {!isEndUser && !!item.horarioPosicao && (
                <ThinText>
                  Horário:{" "}
                  <BoldText>
                    {formatters.dates.long(new Date(item.horarioPosicao))}
                  </BoldText>
                </ThinText>
              )}
              {!!canDelete && (
                <DeleteButton onClick={() => setOpenDialog(true)}>
                  EXCLUIR
                </DeleteButton>
              )}
            </FlexContainer>
          </InfoWindow>
          <DeleteDialog
            title={"Confirma a exclusão da posição selecionada?"}
            open={openDialog}
            isExclusao={true}
            setOpen={setOpenDialog}
            deleteFunction={handleClickDelete}
          />
        </>
      )}
    </>
  );
};

export default InfoWindowPosicao;
