import TrocarSenhaPage from "./TrocarSenhaPage";
import { useSelector } from "react-redux";
import { getValidarToken } from "../../selectors/autenticacao.selectors";

const TrocarSenhaPageConnected = () => {
  const { loading, valid, type } = useSelector(getValidarToken);

  return <TrocarSenhaPage valid={valid} type={type} loading={loading} />;
};

export default TrocarSenhaPageConnected;
