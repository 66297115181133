import { styled } from "@mui/material";
import { transientOptions } from "../../styles/theme";

const FlexContainer = styled("div", transientOptions)`
  display: flex;
  flex-direction: ${({ __direction }) => __direction ?? "row"};
  justify-content: ${({ __justifyContent }) => __justifyContent ?? "unset"};
  flex: ${({ __flex }) => __flex ?? "unset"};
  align-items: ${({ __alignItems }) => __alignItems ?? "unset"};
  align-self: ${({ __alignSelf }) => __alignSelf ?? "unset"};
  gap: ${({ __gap }) => __gap ?? "0"};
`;

export default FlexContainer;
