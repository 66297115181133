import { types, actions } from "./mapa.actions";
import { types as authTypes } from "./autenticacao.actions";

const initialState = {
  dominios: {
    empresas: {
      loading: true,
      data: [],
    },
    dispositivos: {
      loading: true,
      data: [],
    },
    grupos: {
      loading: true,
      data: [],
    },
  },
  opcoes: {
    percurso: false,
    efetivo: false,
    pendente: false,
    atual: false,
  },
  filtros: {
    page: 1,
    inicioPeriodo: null,
    fimPeriodo: null,
    empresa: null,
    os: "",
    roteiro: "",
    dispositivo: null,
    grupo: null,
    minCheckpoints: "",
    maxCheckpoints: "",
    funcionamento: [],
    ordem: "inicio",
    all: null,
  },
  drawer: {
    filters: false,
    showMore: false,
  },
  loading: false,
  updating: false,
  items: [],
  total: 0,
  totalSemMensagem: 0,
  selecionado: null,
  roteiro: {
    loading: false,
    file_name: "",
    file: "",
  },
  os: {
    loading: false,
    file_name: "",
    file: "",
  },
  botao: "voltar",
  posicao: {
    updating: false,
    origem: null,
    evento: null,
    ponto: null,
    target: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case types.SELECIONAR_OPCOES:
      return {
        ...state,
        opcoes: {
          ...state.opcoes,
          [action.payload.propriedade]: action.payload.valor,
        },
      };
    case types.ALTERNAR_MAIS_CARDS:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          showMore: !state.drawer.showMore,
        },
      };
    case types.EXIBIR_FILTROS:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          filters: action.payload.visible,
        },
      };
    case types.ATUALIZAR_FILTROS:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          page: 1,
          ...action.payload.filtros,
        },
      };
    case types.COMECAR_MOVIMENTO_CHECKPOINT:
      return {
        ...state,
        posicao: {
          ...state.posicao,
          origem: action.payload.origem,
        },
      };
    case types.PERGUNTAR_MOVIMENTO_CHECKPOINT:
      return {
        ...state,
        posicao: {
          ...state.posicao,
          ponto: action.payload.ponto,
          evento: action.payload.evento,
          target: action.payload.target,
        },
      };
    case actions.moverCheckpoint.REQUEST:
    case actions.moverCheckpoint.FAILURE:
      return {
        ...state,
        posicao: {
          ...state.posicao,
          updating: action.type === actions.moverCheckpoint.REQUEST,
        },
      };
    case actions.moverCheckpoint.SUCCESS:
      return {
        ...state,
        posicao: {
          ...state.posicao,
          updating: false,
        },
        selecionado: action.payload.response.data,
        items: state.items.map((i) =>
          i.id === action.payload.response.data.id
            ? action.payload.response.data
            : i
        ),
      };
    case types.SELECIONAR_OS:
      return {
        ...state,
        selecionado: action.payload.item,
        opcoes: {
          ...state.opcoes,
          percurso:
            action.payload.item?.roteiro?.empresa?.roteiro === "N"
              ? true
              : state.opcoes.percurso,
          atual:
            action.payload.item?.roteiro?.empresa?.roteiro === "N"
              ? false
              : state.opcoes.atual,
        },
      };
    case actions.pesquisar.REQUEST:
    case actions.pesquisar.SUCCESS:
    case actions.pesquisar.FAILURE:
      return {
        ...state,
        loading: action.type === actions.pesquisar.REQUEST,
        items:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.items
            : state.items,
        total:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        totalSemMensagem:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.totalSemMensagem
            : state.totalSemMensagem,
      };
    case actions.proximaPagina.REQUEST:
    case actions.proximaPagina.SUCCESS:
    case actions.proximaPagina.FAILURE:
      return {
        ...state,
        items:
          action.type === actions.proximaPagina.SUCCESS
            ? [...state.items, ...action.payload.response.data.items]
            : state.items,
        filtros: {
          ...state.filtros,
          page:
            action.type === actions.proximaPagina.SUCCESS
              ? state.filtros.page + 1
              : state.filtros.page,
        },
      };
    case actions.atualizacaoAutomatica.REQUEST:
    case actions.atualizacaoAutomatica.SUCCESS:
    case actions.atualizacaoAutomatica.FAILURE:
      return {
        ...state,
        items:
          action.type === actions.atualizacaoAutomatica.SUCCESS
            ? [...action.payload.response.data.items]
            : state.items,
      };
    case actions.reiniciarPagina.REQUEST:
    case actions.reiniciarPagina.SUCCESS:
    case actions.reiniciarPagina.FAILURE:
      return {
        ...state,
        items:
          action.type === actions.reiniciarPagina.SUCCESS
            ? [...action.payload.response.data.items]
            : state.items,
        filtros: {
          ...state.filtros,
          page: 1,
        },
      };
    case actions.carregarEmpresas.REQUEST:
    case actions.carregarEmpresas.SUCCESS:
    case actions.carregarEmpresas.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          empresas: {
            ...state.dominios.empresas,
            loading: action.type === actions.carregarEmpresas.REQUEST,
            data:
              action.type === actions.carregarEmpresas.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.empresas.data,
          },
        },
      };
    case actions.carregarDispositivos.REQUEST:
    case actions.carregarDispositivos.SUCCESS:
    case actions.carregarDispositivos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          dispositivos: {
            ...state.dominios.dispositivos,
            loading: action.type === actions.carregarDispositivos.REQUEST,
            data:
              action.type === actions.carregarDispositivos.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.dispositivos.data,
          },
        },
      };
    case actions.carregarGrupos.REQUEST:
    case actions.carregarGrupos.SUCCESS:
    case actions.carregarGrupos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          grupos: {
            ...state.dominios.grupos,
            loading: action.type === actions.carregarGrupos.REQUEST,
            data:
              action.type === actions.carregarGrupos.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.dispositivos.data,
          },
        },
      };
    case actions.excluirPosicao.SUCCESS:
      return {
        ...state,
        items: state.items.map((i) => {
          if (i.id === state.selecionado.id) {
            return {
              ...i,
              percentualEfetivo:
                i.posicoes.length > 1
                  ? (i.percentualEfetivo * i.posicoes.length) /
                    (i.posicoes.length - 1)
                  : 0,
              posicoes: i.posicoes.filter(
                (p) => p.id !== action.payload.values.id
              ),
            };
          } else {
            return i;
          }
        }),
        selecionado: {
          ...state.selecionado,
          percentualEfetivo:
            state.selecionado.posicoes.length > 1
              ? (state.selecionado.percentualEfetivo *
                  state.selecionado.posicoes.length) /
                (state.selecionado.posicoes.length - 1)
              : 0,
          posicoes: state.selecionado.posicoes.filter(
            (p) => p.id !== action.payload.values.id
          ),
        },
      };
    case actions.atualizarOs.REQUEST:
    case actions.atualizarOs.SUCCESS:
    case actions.atualizarOs.FAILURE:
      return {
        ...state,
        updating: action.type === actions.atualizarOs.REQUEST,
        selecionado:
          action.type === actions.atualizarOs.SUCCESS
            ? action.payload.response.data
            : action.type === actions.atualizarOs.REQUEST
            ? {
                ...state.selecionado,
                arquivoKml: "",
                arquivoKmlOriginal: state.selecionado.arquivoKml,
              }
            : {
                ...state.selecionado,
                arquivoKml: state.selecionado.arquivoKml,
                arquivoKmlOriginal: undefined,
              },
        items:
          action.type === actions.atualizarOs.SUCCESS
            ? state.items.map((i) =>
                i.id === action.payload.values.id
                  ? action.payload.response.data
                  : i
              )
            : state.items,
      };
    case actions.downloadRoteiro.REQUEST:
    case actions.downloadRoteiro.SUCCESS:
    case actions.downloadRoteiro.FAILURE:
      return {
        ...state,
        roteiro: {
          loading: action.type === actions.downloadRoteiro.REQUEST,
          file_name:
            action.type === actions.downloadRoteiro.SUCCESS
              ? action.payload.response.data.file_name
              : "",
          file:
            action.type === actions.downloadRoteiro.SUCCESS
              ? action.payload.response.data.file
              : "",
        },
      };
    case types.CLEAN_ROTEIRO_DATA:
      return {
        ...state,
        roteiro: {
          loading: false,
          file_name: "",
          file: "",
        },
      };
    case types.ALTERNAR_BOTAO:
      return {
        ...state,
        botao: !!action.payload.origem
          ? action.payload.origem
          : state.botao === "voltar"
          ? "ir"
          : "voltar",
      };
    case actions.downloadOs.REQUEST:
    case actions.downloadOs.SUCCESS:
    case actions.downloadOs.FAILURE:
      return {
        ...state,
        os: {
          loading: action.type === actions.downloadOs.REQUEST,
          file_name:
            action.type === actions.downloadOs.SUCCESS
              ? action.payload.response.data.file_name
              : "",
          file:
            action.type === actions.downloadOs.SUCCESS
              ? action.payload.response.data.file
              : "",
        },
      };
    case types.CLEAN_OS_DATA:
      return {
        ...state,
        os: {
          loading: false,
          file_name: "",
          file: "",
        },
      };
    default:
      return state;
  }
};

export default reducer;
