import basicFlow from "./asyncHandler";
import { put, select, takeEvery } from "redux-saga/effects";
import { actions, types } from "../reducers/diario.actions";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { getFiltros } from "../selectors/diario.selectors";
import formatters from "../utils/formatters";
import { getUsuario } from "../selectors/usuario.selectors";

const pesquisar = basicFlow({
  actionGenerator: actions.pesquisar,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);
    const empresa = currentFilter.empresa?.id
      ? currentFilter.empresa?.id
      : !!usuario?.empresa
      ? usuario.empresa
      : currentFilter.empresa?.id;
    return {
      empresa,
      data: formatters.dates.revert(currentFilter.data),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao-diaria`,
      method: "post",
      body: values,
    });
  },
});

const carregarEmpresas = basicFlow({
  actionGenerator: actions.carregarEmpresas,
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente/dominio?cliente=${values?.cliente ?? "false"}`,
      method: "get",
    });
  },
});
function* atualizarFiltrosWatcher() {
  yield takeEvery([types.ATUALIZAR_FILTROS], function* () {
    const currentFilter = yield select(getFiltros);

    if (!!currentFilter.data && !!currentFilter.empresa) {
      yield put(actions.pesquisar.request());
    }
  });
}

function* diarioRouteWatcher() {
  yield routeWatcher(routes.OPERACAO_DIARIA, function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    if (!usuario?.empresa) {
      yield put(actions.carregarEmpresas.request());
    } else {
      yield put(
        actions.carregarEmpresas.request({ cliente: usuario?.empresa })
      );
    }

    if (currentFilter.data === null) {
      yield put(
        actions.atualizarFiltros({
          data: formatters.dates.short(new Date()),
        })
      );
    } else {
      actions.atualizarFiltros({});
    }
  });
}

export const sagas = [
  pesquisar.watcher(),
  carregarEmpresas.watcher(),
  atualizarFiltrosWatcher(),
  diarioRouteWatcher(),
];
