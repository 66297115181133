import { Card, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import IconButton from "../../../components/buttons/IconButton";
import { DeleteForeverOutlined, EditOutlined } from "@mui/icons-material";
import formatters from "../../../utils/formatters";
import { DeleteDialog } from "../../../components";

const StyledGridContainer = styled(Grid)`
  height: 100%;
  @media (max-width: 576px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 0 0;
  background-color: #dbe4e6;
`;

const StyledGridTitle = styled(Grid)`
  margin-top: -25px;
  padding: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledTitle = styled("h1")`
  color: #2f85c2;
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-family: Oswald;
  font-size: 28px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledSubTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-top: -10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledButtonsContainer = styled("div")`
  margin-top: -30px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`;

function MapaMensalCard({ item, index, handleDelete, handleEdit }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [idToAct, setIdToAct] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  const handleClickEdit = (e) => {
    handleEdit(e, item.id);
  };
  const handleClickDelete = (e) => {
    handleDelete(e, item.id);
  };

  return (
    <StyledGridContainer key={index} item xs={12} sm={12} md={4}>
      <StyledCard>
        <Grid container>
          <StyledGridTitle item xs={12} sm={12} md={12}>
            <StyledTitle>{item.descricao}</StyledTitle>
            <StyledSubTitle>
              {formatters.dates.withHours(new Date(item.dataHoraCriacao))}
            </StyledSubTitle>
          </StyledGridTitle>
          <Grid item xs={12} sm={12} md={12}>
            <StyledButtonsContainer>
              <IconButton
                handleClick={(e) => {
                  handleClickEdit(e, item.id);
                }}
              >
                <Tooltip title="Editar">
                  <EditOutlined sx={{ fill: "#51a8d1" }} />
                </Tooltip>
              </IconButton>
              <IconButton
                handleClick={(e) => {
                  setIdToAct(item.id);
                  setDialogTitle(
                    `Tem certeza que gostaria de exlcuir a Configuração ${item.descricao}?`
                  );
                  setOpenDialog(true);
                }}
              >
                <Tooltip title="Excluir">
                  <DeleteForeverOutlined sx={{ fill: "#51a8d1" }} />
                </Tooltip>
              </IconButton>
            </StyledButtonsContainer>
          </Grid>
        </Grid>
      </StyledCard>
      <DeleteDialog
        title={dialogTitle}
        open={openDialog}
        setOpen={setOpenDialog}
        deleteFunction={handleClickDelete}
        id={idToAct}
      />
    </StyledGridContainer>
  );
}

export default MapaMensalCard;
