import React from "react";
import { styled } from "@mui/material";
import { transientOptions } from "../styles/theme";

const Container = styled("div")`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark};
`;
const Item = styled("span", transientOptions)`
  padding: 0.25rem 0 0.25rem 0.5rem;
  color: ${({ theme, __selected }) =>
    !!__selected ? theme.colors.primary._700 : "inherit"};
  font-weight: ${({ __selected }) => (!!__selected ? 700 : "inherit")};
  cursor: pointer;
`;

const PageSizeSelector = ({ options, handleClick, ...other }) => {
  return (
    <Container {...other}>
      Resultados por página:
      {options.map((option) => {
        return (
          <Item
            key={option.value}
            __selected={option.selected}
            onClick={() => handleClick(option.value)}
          >
            {option.value}
          </Item>
        );
      })}
    </Container>
  );
};

export default PageSizeSelector;
