import basicFlow from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as integracoes } from "../../reducers/cadastro/integracoes.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getIntegracoesCliente,
  getIntegracoesFilter,
  getIntegracoesPage,
} from "../../selectors/cadastro/integracoes.selectors";

const ListarIntegracoesApi = (values) => {
  return authenticatedRequest({
    url: `/integracao/listar`,
    isResourceService: true,
    method: "post",
    body: {
      url: values?.url ?? "",
      pagina: values?.pagina ?? 1,
      limite: 9,
      idCliente: values?.idCliente ?? "",
    },
  });
};

const ListarIntegracoes = basicFlow({
  actionGenerator: integracoes.obterIntegracoes,
  api: ListarIntegracoesApi,
});

function* ListarIntegracoesRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_INTEGRACAO, function* () {
    const url = yield select(getIntegracoesFilter);
    const cliente = yield select(getPayload);

    yield put(
      integracoes.obterIntegracoes.request({
        pagina: 1,
        url,
        idCliente: cliente.id,
      })
    );
  });
}

const EditarIntegracoesApi = (values) => {
  return authenticatedRequest({
    url: `/integracao/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const EditarIntegracoes = basicFlow({
  actionGenerator: integracoes.editarIntegracao,
  api: EditarIntegracoesApi,
  postSuccess: function* () {
    yield toast.success("Integração atualizada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getIntegracoesCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_INTEGRACAO, { id: cliente })
    );
    const pagina = yield select(getIntegracoesPage);
    const nome = yield select(getIntegracoesFilter);
    yield put(
      integracoes.obterIntegracoes.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const criarIntegracoesApi = (values) => {
  return authenticatedRequest({
    url: `/integracao`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const CriarIntegracoes = basicFlow({
  actionGenerator: integracoes.criarIntegracao,
  api: criarIntegracoesApi,
  postSuccess: function* () {
    yield toast.success("Integração criada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    const cliente = yield select(getIntegracoesCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_INTEGRACAO, { id: cliente })
    );
    const pagina = yield select(getIntegracoesPage);
    const nome = yield select(getIntegracoesFilter);
    yield put(
      integracoes.obterIntegracoes.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const deletarIntegracoesApi = (values) => {
  return authenticatedRequest({
    url: `/integracao/delete`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const DeletarIntegracoes = basicFlow({
  actionGenerator: integracoes.deletarIntegracao,
  api: deletarIntegracoesApi,
  postSuccess: function* () {
    yield toast.success("Integração excluída com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    const cliente = yield select(getIntegracoesCliente);
    yield put(
      routeActions.redirectTo(routes.CADASTRO_INTEGRACAO, { id: cliente })
    );
    const pagina = yield select(getIntegracoesPage);
    const nome = yield select(getIntegracoesFilter);
    yield put(
      integracoes.obterIntegracoes.request({ pagina, nome, idCliente: cliente })
    );
  },
});

const MostrarIntegracaoApi = (values) => {
  return authenticatedRequest({
    url: `/integracao/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarIntegracao = basicFlow({
  actionGenerator: integracoes.mostrarIntegracao,
  transform: function* (payload) {
    const id = yield select((state) => state.integracoes.id);
    return { id, ...payload };
  },

  api: MostrarIntegracaoApi,
});

function* MostrarIntegracaoRouteWatcher() {
  yield routeWatcher(routes.EDITAR_INTEGRACAO, function* () {
    const integracao = yield select(getPayload);
    yield put(integracoes.mostrarIntegracao.request({ id: integracao.id }));
  });
}

export const sagas = [
  MostrarIntegracaoRouteWatcher(),
  ListarIntegracoesRouteWatcher(),
  ListarIntegracoes.watcher(),
  MostrarIntegracao.watcher(),
  EditarIntegracoes.watcher(),
  CriarIntegracoes.watcher(),
  DeletarIntegracoes.watcher(),
];
