import { actions, types } from "./dispositivos.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  dispositivos: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
  },
  dispositivo: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterDispositivos.REQUEST:
    case actions.obterDispositivos.SUCCESS:
    case actions.obterDispositivos.FAILURE:
      return {
        ...state,
        dispositivos: {
          ...state.dispositivos,
          loading: action.type === actions.obterDispositivos.REQUEST,
          data:
            action.type === actions.obterDispositivos.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterDispositivos.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          page:
            action.type === actions.obterDispositivos.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case types.REMOVER_FILTRO_DISPOSITIVO:
      return {
        ...state,
        dispositivos: {
          ...state.dispositivos,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_DISPOSITIVO:
      return {
        ...state,
        dispositivos: {
          ...state.dispositivos,
          page: 1,
        },
      };

    case actions.editarDispositivo.REQUEST:
    case actions.editarDispositivo.SUCCESS:
    case actions.editarDispositivo.FAILURE:
      return {
        ...state,
        dispositivo: {
          ...state.dispositivo,
          loading: action.type === actions.editarDispositivo.REQUEST,
          data:
            action.type === actions.editarDispositivo.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDITAR_DISPOSITIVO:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarDispositivo.REQUEST:
    case actions.mostrarDispositivo.SUCCESS:
    case actions.mostrarDispositivo.FAILURE:
      return {
        ...state,
        dispositivo: {
          ...state.dispositivo,
          loading: action.type === actions.mostrarDispositivo.REQUEST,
          data:
            action.type === actions.mostrarDispositivo.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarDispositivo.REQUEST:
    case actions.criarDispositivo.SUCCESS:
    case actions.criarDispositivo.FAILURE:
      return {
        ...state,
        dispositivo: {
          ...state.dispositivo,
          loading: action.type === actions.criarDispositivo.REQUEST,
          data:
            action.type === actions.criarDispositivo.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarDispositivo.REQUEST:
    case actions.deletarDispositivo.SUCCESS:
    case actions.deletarDispositivo.FAILURE:
      return {
        ...state,
        dispositivo: {
          ...state.dispositivo,
          loading: action.type === actions.deletarDispositivo.REQUEST,
        },
      };

    default:
      return state;
  }
};

export default reducer;
