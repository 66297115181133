import React, { useCallback, useEffect, useState } from "react";
import FilterButtons from "./FilterButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../reducers/mapa.actions";
import { getOpcoesMapa } from "../../../selectors/mapa.selectors";
import IconAtual from "../../../assets/m-atual.png";
import IconPendente from "../../../assets/m-pendente.png";
import IconEfetivado from "../../../assets/m-efetivado.png";
import IconPercurso from "../../../assets/m-percurso.png";

const FilterButtonsConnected = ({ selecionado }) => {
  const dispatch = useDispatch();
  const opcoes = useSelector(getOpcoesMapa);
  const [newOpcoes, setNewOpcoes] = useState(opcoes);
  const [data, setData] = useState();
  const handleClick = useCallback(
    (property, value) => {
      dispatch(actions.selecionarOpcoes(property, value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selecionado.roteiro.empresa.roteiro === "N") {
      setNewOpcoes({
        percurso: opcoes.percurso,
        atual: opcoes.atual,
      });
    } else {
      setNewOpcoes(opcoes);
    }
  }, [selecionado, opcoes]);

  useEffect(() => {
    setData(
      Object.keys(newOpcoes).map((k) => ({
        id: k,
        selected: newOpcoes[k],
        handleClick: () => handleClick(k, !newOpcoes[k]),
        label: k.charAt(0).toUpperCase() + k.substring(1).toLowerCase(),
        icon:
          k === "atual"
            ? IconAtual
            : k === "pendente"
            ? IconPendente
            : k === "percurso"
            ? IconPercurso
            : IconEfetivado,
      }))
    );
  }, [newOpcoes, handleClick]);

  return <FilterButtons data={data} />;
};

export default FilterButtonsConnected;
