import React, { Fragment, useState } from "react";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { generateArrayOfDays } from "../constants/mapaExecucao.constants";
import Funcionamento from "./Funcionamento";
import { FlexContainer, Title } from "../../../components/styled";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const StyledTable = styled(Table)`
  margin-left: -10px;
  margin-right: -20px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0px;
  overflow: scroll;
  border-collapse: separate;
  min-width 100%;
`;

const StyledTableRow = styled(TableRow)`
  max-height: 1rem !important;
  padding: 0 !important;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: ${({ islast }) => (islast ? "#dbe4e6" : "#eff1f2")};
    color: #ffffff !important;
  }
`;
const StyledClickableTableRow = styled(TableRow)`
  max-height: 1rem !important;
  padding: 0 !important;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: ${({ islast }) => (islast ? "#dbe4e6" : "#eff1f2")};
    color: #ffffff !important;
  }
`;

const StyledTitleTableCell = styled(TableCell)`
  font-size: 0.7em;
  font-weight: bold;
  max-height: 1rem !important;
  max-width: 3.5rem !important;
  padding: 0 !important;
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
  color: ${({ clickable }) => (clickable ? "#000" : "#444")};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
`;
const StyledTitleMensalTableCell = styled(TableCell)`
  font-size: 0.7em;
  font-weight: bold;
  max-height: 1rem !important;
  max-width: 2em !important;
  padding: 0 !important;
  cursor: pointer;
  color: #000;
`;

const StyledTitleValueTableCell = styled(TableCell)`
  font-size: 0.6em;
  font-weight: bold;
  max-height: 1rem !important;
  max-width: 3rem !important;
  padding: 0 !important;
`;
const StyledTableBody = styled(TableBody)``;

const StyledValueTableCell = styled(TableCell)`
  cursor: ${({ isclickable }) => (isclickable ? "pointer" : "default")};
  font-size: 0.7em;
  font-weight: bold;
  max-height: 1rem !important;
  max-width: 8rem !important;
  padding: 0 !important;
  color: ${({ cordisplay }) => (cordisplay ? cordisplay : "#191C1D")};

  background-color: ${({ iseven }) =>
    iseven ? "rgba(255, 255, 255, 0.4)" : "1"};

  border: ${({ istoday }) => (istoday ? "1.5px dashed #7b61ff" : "hidden")};
  border-top: hidden;
  border-bottom: ${({ islast, istoday }) =>
    islast && istoday ? "1.5px dashed #7B61FF" : "hidden"};
  border-bottom-left-radius: ${({ islast }) => (islast ? "20px" : "0")};
  border-bottom-right-radius: ${({ islast }) => (islast ? "20px" : "0")};
`;
const StyledDayHeaderTableCell = styled(TableCell)`
  padding: 0;
  font-size: 0.6em;
  font-weight: bold;
  max-height: 1rem !important;
  max-width: 8rem !important;
  padding: 0 !important;
  color: ${({ cordisplay }) => (cordisplay ? cordisplay : "#191C1D")};
  background-color: ${({ iseven }) =>
    iseven ? "rgba(255, 255, 255, 0.4)" : "1"};
  border-left: ${({ istoday }) =>
    istoday ? "1.5px dashed #7B61FF" : "hidden"};
  border-right: ${({ istoday }) =>
    istoday ? "1.5px dashed #7B61FF" : "hidden"};
  border-top: ${({ istoday }) => (istoday ? "1.5px dashed #7B61FF" : "hidden")};
  border-bottom: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
`;
const StyledMediaMensalTableCell = styled(TableCell)`
  padding: 0px;
  font-size: 0.8em;
  font-weight: bold;
  color: ${({ cordisplay }) => (cordisplay ? cordisplay : "#191C1D")};
`;

const StyledText = styled(Title)`
  font-family: Oswald;
  font-size: 14px !important;
  margin-top: -5px !important;
  text-align: right;
  margin-right: 20px;
`;

const Arrow = styled(ArrowDropDown)`
  vertical-align: middle;
  font-size: 1rem;
`;

const ExecucaoTable = ({
  mesAno,
  items,
  handleGoToMap,
  cliente,
  order,
  handleOrderClick,
}) => {
  const [openTooltip] = useState(true);

  return (
    <>
      {!!cliente && (
        <>
          <StyledTable>
            <StyledTableBody>
              <StyledTableRow>
                <StyledTitleTableCell
                  align="center"
                  clickable={true}
                  onClick={() => handleOrderClick("roteiro")}
                >
                  <FlexContainer>
                    <Fragment>Plano de Trabalho</Fragment>
                    <OrderIcon order={order} field={"roteiro"} />
                  </FlexContainer>
                </StyledTitleTableCell>
                <StyledTitleTableCell align="center">
                  Frequência
                </StyledTitleTableCell>
                {generateArrayOfDays(mesAno)
                  .reverse()
                  .map((item, index) => {
                    return item.isToday ? (
                      <Tooltip
                        PopperProps={{
                          sx: {
                            zIndex: 999,
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#7B61FF",
                              padding: 0.5,
                              fontSize: "9px",
                              inset: "auto auto -8px 0px",
                            },
                            "& .MuiTooltip-arrow": {
                              color: "#7B61FF",
                            },
                          },
                        }}
                        open={openTooltip}
                        title="hoje"
                        placement="top"
                        arrow
                      >
                        <StyledDayHeaderTableCell
                          clickable={true}
                          onClick={() => handleOrderClick("dia" + item.dia)}
                          istoday={item.isToday}
                          align="center"
                          key={item.dia + item.label}
                          iseven={index % 2 === 0}
                        >
                          <FlexContainer
                            __justifyContent={"center"}
                            __alignItems={"center"}
                          >
                            <FlexContainer __direction={"column"}>
                              <span style={{ fontSize: "1.2em" }}>
                                {item.dia}
                              </span>
                              {item.label}
                            </FlexContainer>
                            <OrderIcon order={order} field={"dia" + item.dia} />
                          </FlexContainer>
                        </StyledDayHeaderTableCell>
                      </Tooltip>
                    ) : (
                      <StyledDayHeaderTableCell
                        clickable={true}
                        onClick={() => handleOrderClick("dia" + item.dia)}
                        istoday={item.isToday}
                        align="center"
                        key={item.dia + item.label}
                        iseven={index % 2 === 0}
                      >
                        <FlexContainer
                          __justifyContent={"center"}
                          __alignItems={"center"}
                        >
                          <FlexContainer __direction={"column"}>
                            <span style={{ fontSize: "1.2em" }}>
                              {item.dia}
                            </span>
                            {item.label}
                          </FlexContainer>
                          <OrderIcon order={order} field={"dia" + item.dia} />
                        </FlexContainer>
                      </StyledDayHeaderTableCell>
                    );
                  })}
                <StyledTitleMensalTableCell
                  align="center"
                  clickable={true}
                  onClick={() => handleOrderClick("mes")}
                >
                  <FlexContainer
                    __justifyContent={"center"}
                    __alignItems={"center"}
                  >
                    <Fragment>Média Mensal</Fragment>
                    <OrderIcon order={order} field={"mes"} />
                  </FlexContainer>
                </StyledTitleMensalTableCell>
              </StyledTableRow>
              {items &&
                items.length > 0 &&
                items.map((item, index) => {
                  return (
                    <StyledClickableTableRow key={item?.funcionamento + index}>
                      <StyledTitleValueTableCell align="center">
                        {item.nome}
                      </StyledTitleValueTableCell>
                      <StyledTitleValueTableCell align="center">
                        <Funcionamento funcionamento={item?.funcionamento} />
                      </StyledTitleValueTableCell>
                      {item.diaOrdemDeServico.map((dia, indexIniside) => {
                        return (
                          <StyledValueTableCell
                            onClick={() => {
                              if (dia.valor !== "-") {
                                handleGoToMap(dia);
                              }
                            }}
                            isclickable={dia.valor === "-" ? false : true}
                            istoday={dia.isToday}
                            align="center"
                            key={dia.corDisplay + indexIniside}
                            cordisplay={dia.corDisplay}
                            iseven={indexIniside % 2 === 0 ? true : false}
                            islast={false}
                          >
                            {dia.valor}
                            {dia.valor !== "-" ? (
                              <span style={{ fontSize: "0.5em" }}>%</span>
                            ) : (
                              <></>
                            )}
                          </StyledValueTableCell>
                        );
                      })}
                      <StyledMediaMensalTableCell
                        cordisplay={item.corMensal}
                        align="center"
                      >
                        {(item?.mediaMensal).toString().replace(".", ",") ??
                          "-"}
                        <span style={{ fontSize: "0.5em" }}>%</span>
                      </StyledMediaMensalTableCell>
                    </StyledClickableTableRow>
                  );
                })}
              <StyledTableRow islast={true}>
                <StyledTitleTableCell></StyledTitleTableCell>
                <StyledTitleTableCell></StyledTitleTableCell>
                {generateArrayOfDays(mesAno)
                  .reverse()
                  .map((dia, indexIniside) => {
                    return dia.isToday ? (
                      <Tooltip
                        PopperProps={{
                          sx: {
                            zIndex: 999,
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#7B61FF",
                              padding: 0.5,
                              fontSize: "9px",
                              inset: "-8px auto auto 0px",
                            },
                            "& .MuiTooltip-arrow": {
                              color: "#7B61FF",
                            },
                          },
                        }}
                        open={openTooltip}
                        title="hoje"
                        placement="bottom"
                        arrow
                      >
                        <StyledValueTableCell
                          key={dia.label + indexIniside}
                          iseven={indexIniside % 2 === 0 ? true : false}
                          islast={true}
                          istoday={dia.isToday}
                        >
                          <br />
                        </StyledValueTableCell>
                      </Tooltip>
                    ) : (
                      <StyledValueTableCell
                        key={dia.label + indexIniside}
                        iseven={indexIniside % 2 === 0 ? true : false}
                        islast={true}
                        istoday={dia.isToday}
                      >
                        <br />
                      </StyledValueTableCell>
                    );
                  })}
                <StyledMediaMensalTableCell></StyledMediaMensalTableCell>
              </StyledTableRow>
            </StyledTableBody>
          </StyledTable>
          {items?.length > 0 && (
            <StyledText>Exibindo {items?.length} planos de trabalho</StyledText>
          )}
        </>
      )}
    </>
  );
};

const OrderIcon = ({ order, field }) => {
  if (!order) {
    return null;
  }
  if (order.field === field) {
    if (order.direction === "asc") {
      return <Arrow as={ArrowDropUp} />;
    } else {
      return <Arrow as={ArrowDropDown} />;
    }
  }

  return null;
};

export default ExecucaoTable;
