import { createTheme } from "@mui/material";

const theme = createTheme({
  sizes: {
    header: {
      mobile: {
        container: 210,
        shadow: 140,
        size: 90,
      },
      desktop: {
        container: 280,
        shadow: 190,
        size: 120,
      },
    },
  },

  backgrounds: {
    opacityWhite: "rgb(255, 255, 255)",
  },
  fonts: {
    family: {
      default: "Noto Sans, sans-serif",
      title: "Oswald, sans-serif",
      buttons: "Noto Sans, sans-serif",
      subTitle: "Noto Sans, sans-serif",
      form: "Noto Sans, sans-serif",
    },
    size: {
      small: "0.8em",
      default: "1em",
      large: "1.3em",
      title: {
        desktop: "2em",
      },
      buttons: "20px",
      inputs: {
        title: "1em",
        content: "1em",
      },
      cards: {
        title: "1.6em",
      },
    },
  },
  colors: {
    primary: {
      _600: "#51A8D1",
      _700: "#2F85C2",
      _800: "#324873",
    },
    gray: {
      _200: "#BFC8CA",
      _300: "#899294",
      _500: "#747778",
      _600: "#3F484A",
      _800: "#191C1D",
    },
    green: {
      _300: "#38C546",
      _500: "#018E06",
      _800: "#2C6732",
    },
    red: {
      pure: "#ff0000",
      _600: "#990a26",
      _800: "#790E18",
    },
    secondary: "#e8ac85",
    darkSecondary: "#793f19",
    lightPrimary: "#F7EAEE",
    lightAlternate: "#F4F2DE",
    darkPrimary: "#ED196E",
    error: "#990a26",
    dark: "#231F20",
    lightGreen: "#c6dec6",
    lightGray: "#dbdce0",
    darkGray: "#444445",
    white: "#FFF",
    background: {
      card: "#dbe4e6",
    },
    gradients: {
      button: "linear-gradient(90deg, #2f85c2 45%, #8ebecd 100%)",
      icon: "linear-gradient(180deg, #3D97CA, #67C2DA)",
      iconDisabled: "linear-gradient(180deg, #BFC8CA, #899294)",
    },
  },
  buttons: {
    primary: {
      background: "#F24F13",
      backgroundHover: "#BB3D0F",
      color: "#FFF",
    },
    secondary: {
      background: "#203d8b",
      backgroundHover: "#192953",
      color: "#FFF",
    },
  },
  shadows: Array(25).fill("0px 10px 15px #00000029"),
  shadow: {
    map: {
      options: "0 0 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
});

export const transientOptions = {
  shouldForwardProp: (propName) => !propName.startsWith("__"),
};

export default theme;
