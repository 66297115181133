import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  styled,
  Switch as MSwitch,
} from "@mui/material";

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0;
  text-align: left;

  & .MuiFormControlLabel-root {
    align-items: flex-start;
    margin: 0;
  }
  & .MuiFormControlLabel-label {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.title};
    font-weight: 800;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    color: ${({ theme }) => theme.colors.gray["300"]};
  }
  & .MuiFormControlLabel-label + .Mui-focused {
    color: ${({ theme }) => theme.colors.gray["800"]};
  }
  & .MuiFormControlLabel-label.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.content};
    font-weight: 400;
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.dark};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiSwitch-root {
    margin-left: -10px;
  }
`;

const Switch = ({
  value,
  label,
  disabled,
  helperText,
  fullWidth = true,
  error,
  labelPlacement = "top",
  ...otherProps
}) => {
  return (
    <StyledFormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <MSwitch
            {...otherProps}
            value={true}
            checked={value}
            disabled={disabled}
          />
        }
        labelPlacement={labelPlacement}
        label={label}
      />
      {Boolean(error) && Boolean(helperText) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default Switch;
