import CargaArquivoPage from "./CargaArquivo";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "../../reducers/cadastro/roteiros.actions";
import {
  getRoteiroKml,
  getRoteiroPosicao,
  getRoteirosLista,
} from "../../selectors/cadastro/roteiros.selectors";
import { useState } from "react";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { validarPlanilha } from "../../utils/validarPlanilha";
import { useEffect } from "react";
import { getClientesLista } from "../../selectors/cadastro/clientes.selectors";

const CargaArquivoPageConnected = () => {
  const [valueCliente, setValueCliente] = useState("");
  const [valueRoteiro, setValueRoteiro] = useState("");

  const { data: dataCliente, loading: loadingCliente } = useSelector(
    getClientesLista
  );
  const { data: dataRoteiro, loading: loadingRoteiro } = useSelector(
    getRoteirosLista
  );

  const { loading: loadingPosicao, data: dataPosicao } = useSelector(
    getRoteiroPosicao
  );
  const { loading: loadingKml, data: dataKml } = useSelector(getRoteiroKml);

  const dispatch = useDispatch();

  useEffect(() => {
    if (valueRoteiro?.id) {
      dispatch(actions.obterPosicoes.request({ idRoteiro: valueRoteiro.id }));
      dispatch(actions.obterKmls.request({ idRoteiro: valueRoteiro.id }));
    }
    // eslint-disable-next-line
  }, [valueRoteiro]);

  useEffect(() => {
    setValueRoteiro("");
    if (valueCliente?.id) {
      dispatch(
        actions.obterRoteirosSemFiltro.request({ idEmpresa: valueCliente.id })
      );
    }
    // eslint-disable-next-line
  }, [valueCliente]);

  const handleAddPosicao = async (values) => {
    if (values.length === 0) {
      toast.error("Arquivo com extensão inválida", {
        theme: "colored",

        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      if (values[0].type === "text/csv") {
        function parseData(fileToParse, callBack) {
          Papa.parse(fileToParse, {
            download: true,
            delimiter: "\t",
            complete: function (results) {
              callBack(results.data);
            },
          });
        }
        parseData(values[0], validarCsv);
        function validarCsv(data) {
          if (data.length === 0) {
            toast.error("Arquivo CSV inválido", {
              theme: "colored",
              icon: false,
              style: { backgroundColor: "#990a26" },
            });
          } else {
            let header = data[0][0];
            if (header.length > 18) {
              toast.error("Arquivo CSV inválido", {
                theme: "colored",
                icon: false,
                style: { backgroundColor: "#990a26" },
              });
            } else {
              const posicoes = data
                .map((item) => {
                  let latitude = item[0].substring(0, item[0].indexOf(";"));
                  let longitude = item[0].substring(item[0].indexOf(";") + 1);
                  return {
                    latitude,
                    longitude,
                  };
                })
                .filter(
                  (item) => item.latitude !== "" && item.longitude !== ""
                );

              const newValues = {
                idRoteiro: valueRoteiro.id,
                posicoes,
                file: values,
              };

              dispatch(actions.criarPosicao.request({ ...newValues }));
            }
          }
        }
      } else {
        const valida = await validarPlanilha(values[0]);
        if (valida.status === "Ok") {
          const newValues = {
            idRoteiro: valueRoteiro.id,
            posicoes: valida.posicoes,
            file: values,
          };

          dispatch(actions.criarPosicao.request({ ...newValues }));
        } else {
          toast.error("Planilha inválida", {
            theme: "colored",
            icon: false,
            style: { backgroundColor: "#990a26" },
          });
        }
      }
    }
  };

  const handleAddKml = (values) => {
    if (values.length === 0) {
      toast.error("Arquivo com extensão inválida", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    }
    const newValues = {
      idRoteiro: valueRoteiro.id,
      file: values,
    };

    dispatch(actions.criarKml.request({ ...newValues }));
  };

  const handleDeletePosicao = (values) => {
    dispatch(
      actions.deletarPosicao.request({ id: values, idRoteiro: valueRoteiro.id })
    );
  };
  const handleDeleteKml = (values) => {
    dispatch(
      actions.deletarKml.request({
        id: values,
        idRoteiro: valueRoteiro.id,
      })
    );
  };

  const handleBaixarArtefato = (values) => {
    dispatch(actions.baixarArtefato.request(values));
  };

  return (
    <CargaArquivoPage
      dataPosicao={dataPosicao}
      dataKml={dataKml}
      handleDeletePosicao={handleDeletePosicao}
      handleDeleteKml={handleDeleteKml}
      handleAddPosicao={handleAddPosicao}
      handleAddKml={handleAddKml}
      loadingPosicao={loadingPosicao}
      loadingKml={loadingKml}
      handleBaixarArtefato={handleBaixarArtefato}
      dataCliente={dataCliente}
      loadingCliente={loadingCliente}
      valueCliente={valueCliente}
      setValueCliente={setValueCliente}
      valueRoteiro={valueRoteiro}
      setValueRoteiro={setValueRoteiro}
      dataRoteiro={dataRoteiro}
      loadingRoteiro={loadingRoteiro}
    />
  );
};

export default CargaArquivoPageConnected;
