import { useForm } from "react-hook-form";
import FormEditarRoteiroPage from "./FormEditarRoteiroPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/roteiros.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import {
  getGrupos,
  getRoteiro,
  getRoteiroFuncionamento,
  getRoteiroKml,
  getRoteiroPosicao,
  getRoteirosDispositivos,
  getTipoDispositivos,
  getTipoVeiculos,
} from "../../../selectors/cadastro/roteiros.selectors";
import formatters from "../../../utils/formatters";
import { useState } from "react";
import { getPayload } from "../../../selectors/routes.selectors";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { validarPlanilha } from "../../../utils/validarPlanilha";
import { useEffect } from "react";

const FormEditarRoteiroPageConnected = () => {
  const { data: dataRoteiro, loading } = useSelector(getRoteiro);
  const { data: dataGrupos, loading: loadingGrupos } = useSelector(getGrupos);
  const [isRoteiro, setIsRoteiro] = useState(false);
  const { data: dataTipoVeiculos, loading: loadingTipoVeiculos } = useSelector(
    getTipoVeiculos
  );
  const {
    data: dataTipoDispositivos,
    loading: loadingTipoDispositivos,
  } = useSelector(getTipoDispositivos);
  const { data: dataDispositivos, loading: loadingDispositivos } = useSelector(
    getRoteirosDispositivos
  );
  const {
    loading: loadingFuncionamento,
    data: dataFuncionamento,
  } = useSelector(getRoteiroFuncionamento);
  const { loading: loadingPosicao, data: dataPosicao } = useSelector(
    getRoteiroPosicao
  );
  const { loading: loadingKml, data: dataKml } = useSelector(getRoteiroKml);
  const [grupo, setGrupo] = useState("");
  const idRoteiro = useSelector(getPayload);

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    grupo: validators.object({
      custom: {
        requiredIfOther: (value) => {},
      },
    }),
    metragem: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && Number(value) < 1) {
            return !!value || "Valor mínimo (1)";
          }
          if (!!value && Number(value) > 2147483647) {
            return !!value || "Valor máximo (2147483647)";
          } else {
            return true;
          }
        },
      },
    }),
    equipe: validators.string({}),
    servico: validators.string({}),
    dispositivo: validators.object({ required: true }),
  };

  const initialValues = {
    nome: "",
    grupos: "",
    dispositivo: "",
    situacao: "A",
    metragem: "",
    equipe: "",
    servico: "",
    tipoDispositivo: "",
    tipoVeiculo: "",
    dataHoraCriacao: "",
    dataHoraAtualizacao: "",
    feriado: "",
  };

  const initialValuesFuncionamento = {
    dia: "",
    inicio: "",
    termino: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("nome", dataRoteiro.nome);
    formProps.setValue("metragem", dataRoteiro.metragem);
    formProps.setValue("equipe", dataRoteiro?.equipe ?? "");
    formProps.setValue("servico", dataRoteiro?.servico ?? "");
    formProps.setValue("situacao", "A");
    formProps.setValue("dispositivo", dataRoteiro?.idDispositivo ?? "");
    formProps.setValue(
      "feriado",
      dataRoteiro?.feriado === "S" ? true : false ?? ""
    );
    formProps.setValue(
      "dispositivoBackup",
      dataRoteiro?.idDispositivoBackup ?? ""
    );
    formProps.setValue("tipoDispositivo", dataRoteiro?.idTipoDispositivo ?? "");
    formProps.setValue("tipoVeiculo", dataRoteiro?.idTipoVeiculo ?? "");
    formProps.setValue("grupo", dataRoteiro?.idTipoGrupo ?? "");
    formProps.setValue(
      "dataHoraCriacao",
      formatters.dates.withHours(new Date(dataRoteiro?.dataHoraCriacao))
    );
    formProps.setValue(
      "dataHoraAtualizacao",
      formatters.dates.withHours(new Date(dataRoteiro?.dataHoraAtualizacao))
    );
    if (dataRoteiro?.empresa?.roteiro === "N") {
      setIsRoteiro(true);
    } else {
      setIsRoteiro(false);
    }
    // eslint-disable-next-line
  }, [dataRoteiro]);

  useEffect(() => {
    formProps.setValue(
      "dispositivo",
      dataDispositivos.find((el) => el.id === dataRoteiro?.idDispositivo) ??
        "" ??
        ""
    );
    formProps.setValue(
      "dispositivoBackup",
      dataDispositivos.find(
        (el) => el.id === dataRoteiro?.idDispositivoBackup
      ) ?? ""
    );
    // eslint-disable-next-line
  }, [dataDispositivos, dataRoteiro]);

  const rulesFuncionamento = {
    dia: validators.string({ required: true }),
    inicio: validators.string({
      require: true,
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return !!value || "Campo obrigatório";
          }
          if (value.length !== 5) {
            return "Horário inválido";
          }

          let hour = Number(value.slice(0, 2));
          let minute = Number(value.slice(3, 5));

          if (hour < 0 || hour > 23) {
            return "Horário inválido";
          }
          if (minute < 0 || minute > 59) {
            return "Horário inválido";
          } else {
            return true;
          }
        },
      },
    }),
    fim: validators.string({
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return !!value || "Campo obrigatório";
          }
          if (value.length !== 5) {
            return "Horário inválido";
          }
          let hour = Number(value.slice(0, 2));
          let minute = Number(value.slice(3, 5));

          if (hour < 0 || hour > 23) {
            return "Horário inválido";
          }
          if (minute < 0 || minute > 59) {
            return "Horário inválido";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const formPropsFuncionamento = {
    ...useForm({ defaultValues: { ...initialValuesFuncionamento } }),
    rules: { ...rulesFuncionamento },
    initialValues: { ...initialValuesFuncionamento },
  };

  const handleSubmit = (values) => {
    const feriado = formProps.getValues("feriado");
    values.id = dataRoteiro.id;
    values.idEmpresa = dataRoteiro.idEmpresa;
    values.metragem = values.metragem === "" ? null : values.metragem;
    values.feriado = feriado ? "S" : "N";
    values.equipe = values.equipe ? values.equipe : null;
    values.servico = values.servico ? values.servico : null;

    if (!isRoteiro) {
      if (
        dataPosicao.length === 0 ||
        dataKml.length === 0 ||
        dataFuncionamento.length === 0
      ) {
        toast.error("Favor preencher Funcionamento, Posições e KML", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else {
        dispatch(actions.editarRoteiro.request(values));
      }
    } else {
      if (dataFuncionamento.length === 0) {
        toast.error("Favor preencher Posições", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else {
        dispatch(actions.editarRoteiro.request(values));
      }
    }
  };

  const handleSubmitFuncionamento = (values, event) => {
    let inicio = new Date();
    let hourInicio = Number(values.inicio.slice(0, 2));
    let minuteInicio = Number(values.inicio.slice(3, 5));
    inicio.setHours(hourInicio);
    inicio.setMinutes(minuteInicio);
    inicio.setSeconds(0);
    let fim = new Date();
    let hourFim = Number(values.fim.slice(0, 2));
    let minuteFim = Number(values.fim.slice(3, 5));
    fim.setHours(hourFim);
    fim.setMinutes(minuteFim);
    fim.setSeconds(0);
    let novoFuncionamento = {
      dia: values.dia,
      inicio: values.inicio,
      fim: values.fim,
      idRoteiro: idRoteiro.id,
    };
    dispatch(actions.criarFuncionamento.request({ ...novoFuncionamento }));
  };

  const handleDeleteFuncionamento = (values) => {
    dispatch(
      actions.deletarFuncionamento.request({
        id: values,
        idRoteiro: idRoteiro.id,
      })
    );
  };

  const handleAddPosicao = async (values) => {
    if (values.length === 0) {
      toast.error("Arquivo com extensão inválida", {
        theme: "colored",

        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      if (values[0].type === "text/csv") {
        function parseData(fileToParse, callBack) {
          Papa.parse(fileToParse, {
            download: true,
            delimiter: "\t",
            complete: function (results) {
              callBack(results.data);
            },
          });
        }
        parseData(values[0], validarCsv);
        function validarCsv(data) {
          if (data.length === 0) {
            toast.error("Arquivo CSV inválido", {
              theme: "colored",
              icon: false,
              style: { backgroundColor: "#990a26" },
            });
          } else {
            let header = data[0][0];
            if (header.length > 18) {
              toast.error("Arquivo CSV inválido", {
                theme: "colored",
                icon: false,
                style: { backgroundColor: "#990a26" },
              });
            } else {
              const posicoes = data
                .map((item) => {
                  let latitude = item[0].substring(0, item[0].indexOf(";"));
                  let longitude = item[0].substring(item[0].indexOf(";") + 1);
                  return {
                    latitude,
                    longitude,
                  };
                })
                .filter(
                  (item) => item.latitude !== "" && item.longitude !== ""
                );

              const newValues = {
                idRoteiro: idRoteiro.id,
                posicoes,
                file: values,
              };

              dispatch(actions.criarPosicao.request({ ...newValues }));
            }
          }
        }
      } else {
        const valida = await validarPlanilha(values[0]);
        if (valida.status === "Ok") {
          const newValues = {
            idRoteiro: idRoteiro.id,
            posicoes: valida.posicoes,
            file: values,
          };

          dispatch(actions.criarPosicao.request({ ...newValues }));
        } else {
          toast.error("Planilha inválida", {
            theme: "colored",
            icon: false,
            style: { backgroundColor: "#990a26" },
          });
        }
      }
    }
  };

  const handleAddKml = (values) => {
    if (values.length === 0) {
      toast.error("Arquivo com extensão inválida", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    }
    const newValues = {
      idRoteiro: idRoteiro.id,
      file: values,
    };

    dispatch(actions.criarKml.request({ ...newValues }));
  };

  const handleDeletePosicao = (values) => {
    dispatch(
      actions.deletarPosicao.request({ id: values, idRoteiro: idRoteiro.id })
    );
  };
  const handleDeleteKml = (values) => {
    dispatch(
      actions.deletarKml.request({
        id: values,
        idRoteiro: idRoteiro.id,
      })
    );
  };

  const handleCriarGrupo = useCallback(
    (values) => {
      values.idEmpresa = dataRoteiro.idEmpresa;
      dispatch(actions.criarGrupo.request(values));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_ROTEIRO, {
        id: dataRoteiro.idEmpresa,
      })
    );
  };

  const handleBaixarArtefato = (values) => {
    dispatch(actions.baixarArtefato.request(values));
  };

  return (
    <FormEditarRoteiroPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      dataGrupos={dataGrupos}
      loadingGrupos={loadingGrupos}
      handleCriarGrupo={handleCriarGrupo}
      grupo={grupo}
      setGrupo={setGrupo}
      formPropsFuncionamento={formPropsFuncionamento}
      handleSubmitFuncionamento={handleSubmitFuncionamento}
      dataFuncionamento={dataFuncionamento.sort((a, b) => a.dia - b.dia)}
      handleDeleteFuncionamento={handleDeleteFuncionamento}
      dataDispositivos={dataDispositivos}
      loadingDispositivos={loadingDispositivos}
      dataTipoVeiculos={dataTipoVeiculos}
      loadingTipoVeiculos={loadingTipoVeiculos}
      dataTipoDispositivos={dataTipoDispositivos}
      loadingTipoDispositivos={loadingTipoDispositivos}
      dataPosicao={dataPosicao}
      dataKml={dataKml}
      handleDeletePosicao={handleDeletePosicao}
      handleDeleteKml={handleDeleteKml}
      handleAddPosicao={handleAddPosicao}
      handleAddKml={handleAddKml}
      loadingPosicao={loadingPosicao}
      loadingKml={loadingKml}
      loadingFuncionamento={loadingFuncionamento}
      handleBaixarArtefato={handleBaixarArtefato}
      dataRoteiro={dataRoteiro}
    />
  );
};

export default FormEditarRoteiroPageConnected;
