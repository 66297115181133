import { useDispatch, useSelector } from "react-redux";
import FormDispositivoPage from "./FormEditarDispositivoPage";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import validators from "../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { actions } from "../../../reducers/cadastro/dispositivos.actions";
import { getDispositivo } from "../../../selectors/cadastro/dispositivos.selectors";
import formatters from "../../../utils/formatters";

const FormEditarDispositivoPageConnected = () => {
  const { loading, data } = useSelector(getDispositivo);
  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    mensagemPadrao: validators.string({ required: true }),
  };

  const initialValues = {
    nome: "",
    email: "",
    dataHoraNivelBateria: "",
    nivelBateria: "",
    porcentagemBateria: "",
    mensagemAtual: "",
    mensagemPadrao: "",
    dataHoraAtualizacao: "",
    dataHoraCriacao: "",
    situacao: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("nome", data.nome);
    formProps.setValue("situacao", data?.situacao ?? "A");
    formProps.setValue("nivelBateria", data?.nivelBateria ?? "");
    formProps.setValue("porcentagemBateria", data?.porcentagemBateria ?? "");
    formProps.setValue("mensagemAtual", data?.mensagemAtual ?? "");
    formProps.setValue("mensagemPadrao", data?.mensagemPadrao ?? "");
    formProps.setValue(
      "dataHoraNivelBateria",
      formatters.dates.withHours(new Date(data?.dataHoraNivelBateria))
    );
    formProps.setValue(
      "dataHoraCriacao",
      formatters.dates.withHours(new Date(data?.dataHoraCriacao))
    );
    formProps.setValue(
      "dataHoraAtualizacao",
      formatters.dates.withHours(new Date(data?.dataHoraAtualizacao))
    );
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = (values) => {
    values.id = data.id;
    values.dataHoraNivelBateria = formatters.dates.withHours(
      new Date(values?.dataHoraNivelBateria)
    );

    dispatch(actions.editarDispositivo.request(values));
  };

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_DISPOSITIVO));
  };

  return (
    <FormDispositivoPage
      handleSubmit={handleSubmit}
      handleGoBack={handleClickVoltar}
      formProps={formProps}
      data={data}
      loading={loading}
    />
  );
};

export default FormEditarDispositivoPageConnected;
