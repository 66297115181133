import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as roteiroInconsistencia } from "../reducers/roteiroInconsistencia.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const ListarRoteiroInconsistenciaApi = (values) => {
  return authenticatedRequest({
    url: `/roteiro-inconsistencia/listar`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const ListarRoteiroInconsistencia = basicFlow({
  actionGenerator: roteiroInconsistencia.obterRoteiroInconsistencia,
  api: ListarRoteiroInconsistenciaApi,
  postSuccess: function* ({ response }) {
    if (response?.data?.length === 0) {
      yield toast.success("Nenhuma inconsistência encontrada", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#203d8b" },
      });
    }
  },
});

function* ListarRoteiroInconsistenciaRouteWatcher() {
  yield routeWatcher(routes.ERRO_OS, function* () {
    yield put(
      roteiroInconsistencia.obterRoteiroInconsistencia.request({
        dataOrdemServico: new Date(),
      })
    );
  });
}

export const sagas = [
  ListarRoteiroInconsistenciaRouteWatcher(),
  ListarRoteiroInconsistencia.watcher(),
];
