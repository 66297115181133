import { actions } from "./geracao.actions";

const initialState = {
  processing: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.processar.REQUEST:
    case actions.processar.SUCCESS:
    case actions.processar.FAILURE:
      return {
        ...state,
        processing: action.type === actions.processar.REQUEST,
      };
    default:
      return state;
  }
};

export default reducer;
