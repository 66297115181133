import React, { useCallback, useEffect, useState } from "react";
import FilterButtons from "./FilterButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../reducers/diario.actions";
import { getEmpresa, getOpcoesMapa } from "../../../selectors/diario.selectors";
import IconAtual from "../../../assets/m-atual.png";
import IconPendente from "../../../assets/m-pendente.png";
import IconEfetivado from "../../../assets/m-efetivado.png";
import IconPercurso from "../../../assets/m-percurso.png";
import jwtDecode from "jwt-decode";
import { getJwt } from "../../../utils/localStorage";
import { getUsuario } from "../../../selectors/usuario.selectors";

const FilterButtonsConnected = () => {
  const dispatch = useDispatch();
  const opcoes = useSelector(getOpcoesMapa);
  const empresa = useSelector(getEmpresa);
  const { perfil } = useSelector(getUsuario);
  const [newOpcoes, setNewOpcoes] = useState(opcoes);

  const handleClick = useCallback(
    (property, value) => {
      dispatch(actions.selecionarOpcoes(property, value));
    },
    [dispatch]
  );
  const { permissions = [] } = jwtDecode(getJwt());

  useEffect(() => {
    if (empresa?.roteiro === "N") {
      setNewOpcoes({
        percurso: opcoes.percurso,
        atual: opcoes.atual,
      });
    } else {
      setNewOpcoes(opcoes);
    }
  }, [opcoes, empresa]);

  const data = Object.keys(newOpcoes)
    .filter((o) => {
      switch (o) {
        case "percurso":
          return (
            perfil !== "E" || !!permissions.find((a) => a === "OPCAO_PERCURSO")
          );
        case "efetivo":
          return (
            perfil !== "E" || !!permissions.find((a) => a === "OPCAO_EFETIVO")
          );
        default:
          return true;
      }
    })
    .map((k) => ({
      id: k,
      selected: newOpcoes[k],
      handleClick: () => handleClick(k, !newOpcoes[k]),
      label: k.charAt(0).toUpperCase() + k.substring(1).toLowerCase(),
      icon:
        k === "atual"
          ? IconAtual
          : k === "pendente"
          ? IconPendente
          : k === "percurso"
          ? IconPercurso
          : IconEfetivado,
    }));

  return <FilterButtons data={data} />;
};

export default FilterButtonsConnected;
