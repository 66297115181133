import React from "react";
import { useSelector } from "react-redux";
import PosicoesValidas from "./PosicoesValidas";
import { getItems, getOpcoesMapa } from "../../../selectors/diario.selectors";

const PosicoesValidasConnected = () => {
  const items = useSelector(getItems);
  const { percurso } = useSelector(getOpcoesMapa);

  const points =
    !!percurso && !!items
      ? items.map((i) =>
          i.posicoes.filter((p) => p.tipo === "P" && p.situacao === "V")
        )
      : [];

  const result = points.reduce((a, c) => {
    if (c.length > 0) {
      return [...a, ...c];
    } else {
      return a;
    }
  }, []);

  result.sort((a, b) => {
    const d1 = new Date(a.horarioPosicao);
    const d2 = new Date(b.horarioPosicao);

    if (d1.getTime() > d2.getTime()) {
      return 1;
    }
    if (d1.getTime() < d2.getTime()) {
      return -1;
    }
    return 0;
  });

  return <PosicoesValidas points={result} />;
};

export default PosicoesValidasConnected;
