import { actions, types } from "./reprocessamento.actions";

const initialState = {
  roteiros: {
    loading: false,
    data: [],
  },
  reprocessamento: {
    loading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarRoteiros.REQUEST:
    case actions.listarRoteiros.SUCCESS:
    case actions.listarRoteiros.FAILURE:
      return {
        ...state,
        roteiros: {
          ...state.roteiros,
          loading: action.type === actions.listarRoteiros.REQUEST,
          data:
            action.type === actions.listarRoteiros.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case types.LIMPAR_ROTEIROS:
      return {
        ...state,
        roteiros: {
          ...state.roteiros,
          data: [],
        },
      };
    case actions.reprocessar.REQUEST:
    case actions.reprocessar.SUCCESS:
    case actions.reprocessar.FAILURE:
      return {
        ...state,
        reprocessamento: {
          ...state.reprocessamento,
          loading: action.type === actions.reprocessar.REQUEST,
        },
      };
    default:
      return state;
  }
};

export default reducer;
