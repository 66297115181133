import { all } from "redux-saga/effects";
import { sagas as routeSagas } from "./rotas.saga";
import { sagas as authSagas } from "./autenticacao.saga";
import { sagas as usuariosSagas } from "./cadastro/usuarios.saga";
import { sagas as dispositivosSagas } from "./cadastro/dispositivos.saga";
import { sagas as clientesSagas } from "./cadastro/clientes.saga";
import { sagas as integracoesSagas } from "./cadastro/integracoes.saga";
import { sagas as feriadosSagas } from "./cadastro/feriados.saga";
import { sagas as roteirosSagas } from "./cadastro/roteiros.saga";
import { sagas as alertasSagas } from "./cadastro/alertas.saga";
import { sagas as mapaSagas } from "./mapa.saga";
import { sagas as diarioSagas } from "./diario.saga";
import { sagas as geracaoSagas } from "./configuracao/geracao.saga";
import { sagas as reprocessamentoSagas } from "./configuracao/reprocessamento.saga";
import { sagas as mapasMensaisSagas } from "./cadastro/mapasMensais.saga";
import { sagas as mapaExecucaoSagas } from "./mapaExecucao.saga";
import { sagas as bateriasSagas } from "./baterias.saga";
import { sagas as roteiroInconsistenciaSagas } from "./roteiroInconsistencia.sagas";
import { sagas as painelAlertasSagas } from "./painelAlertas.saga";

export default function* sagas() {
  yield all([
    ...routeSagas,
    ...authSagas,
    ...usuariosSagas,
    ...dispositivosSagas,
    ...clientesSagas,
    ...integracoesSagas,
    ...roteirosSagas,
    ...mapaSagas,
    ...geracaoSagas,
    ...feriadosSagas,
    ...reprocessamentoSagas,
    ...mapasMensaisSagas,
    ...mapaExecucaoSagas,
    ...diarioSagas,
    ...bateriasSagas,
    ...roteiroInconsistenciaSagas,
    ...alertasSagas,
    ...painelAlertasSagas,
  ]);
}
