import React from "react";
import {
  Button,
  Grid,
  IconButton,
  Skeleton,
  styled,
  Tooltip,
} from "@mui/material";
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  Download,
  FilterAltOutlined,
  Today,
} from "@mui/icons-material";
import theme, { transientOptions } from "../../../styles/theme";
import { FlexContainer } from "../../../components/styled";
import ItemCard from "./ItemCard";
import ItemCardExpandido from "./ItemCardExpandido";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../components/loaders";
import IconLoader from "../../../components/loaders/IconLoader";

const StyledDrawer = styled("div", transientOptions)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.map.options};
  height: max-content;
  max-height: ${({ __showMore }) => (__showMore ? "500px" : "224px")};
  min-height: 224px;
  overflow-y: visible;
`;
const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.map.options};
  color: ${({ theme }) => theme.colors.dark};

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
const ShowMoreButton = styled(StyledIconButton)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
const LongButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.family.buttons};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 800;
  box-shadow: ${({ theme }) => theme.shadow.map.options};
  padding: 6px 0.6rem;
  border-radius: 300px;
  height: 40px;

  & .MuiButton-startIcon {
    margin-left: 0;
  }

  @media (min-width: 532px) {
    font-size: ${({ theme }) => theme.fonts.size.default};
    padding: 6px 1rem;
  }
`;
const CalendarButton = styled(LongButton)`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 20px;
  z-index: 1;
  text-align: left;
  max-width: 130px;
  font-size: 12px;
  line-height: 15px;

  @media (min-width: 532px) {
    font-size: ${({ theme }) => theme.fonts.size.default};
    max-width: 100%;
  }

  @media (min-width: 1217px) {
    left: 170px;
  }
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.gradients.button};

  &:hover {
    background: ${({ theme }) => theme.colors.gradients.button};
  }
`;
const FilterContainer = styled(FlexContainer)`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  right: 20px;
  z-index: 1;
  display: flex;
  gap: 0.5rem;

  @media (min-width: 1217px) {
    right: 170px;
  }
`;

const ExportButton = styled(StyledIconButton)`
  width: 40px;
  height: 40px;
`;

const FilterButton = styled(LongButton)`
  color: ${({ theme }) => theme.colors.dark};
  background: ${({ theme }) => theme.colors.white};
  text-transform: unset;

  & .MuiButton-startIcon {
    margin-right: 0;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const AlertCount = styled(LongButton)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.buttons.primary.background};

  &:hover {
    background: ${({ theme }) => theme.buttons.primary.background};
  }

  @media (max-width: 610px) {
    position: absolute;
    top: -50px;
  }

  cursor: default;
`;

const ThinText = styled("span")`
  font-weight: 300;
`;
const SpacedThinText = styled(ThinText)`
  margin: 3rem 2rem;
  display: inline-block;
  text-align: center;
`;
const ItemsContainer = styled(Grid)`
  overflow-y: auto;
`;

const RoundedSkeleton = styled(Skeleton)`
  border-radius: 12px;
`;

const BottomDrawer = ({
  handleClickToggleShowMore,
  isUserEnd,
  showMore,
  period,
  loading,
  total,
  totalSemMensagem,
  items,
  selecionado,
  handleClickOpenFilters,
  handleClickItem,
  handleFetchMoreData,
  handleDownloadRoteiro,
  roteiroFile,
  handleCleanRoteiroData,
  relatorioOs,
  handleDownloadOs,
  handleCleanOsData,
  updating,
  handleClickUpdate,
}) => {
  const download = (fileBase64, fileName) => {
    if (fileBase64 !== "") {
      const outFile = `data:application/octet-stream;base64,${fileBase64}`;
      const element = document.createElement("a");
      element.href = outFile;
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    }
  };
  if (relatorioOs?.file) {
    download(relatorioOs.file, relatorioOs.file_name);
    handleCleanOsData();
  }

  return (
    <StyledDrawer __showMore={showMore && !selecionado}>
      {!!loading && (
        <ItemsContainer
          container
          spacing={"20px"}
          paddingX={"20px"}
          paddingTop={"10px"}
          paddingBottom={"20px"}
          marginY={0}
        >
          <Grid item xs={12} md={6} lg={3}>
            <RoundedSkeleton
              height={174}
              width={"100%"}
              variant={"rectangular"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <RoundedSkeleton
              height={174}
              width={"100%"}
              variant={"rectangular"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <RoundedSkeleton
              height={174}
              width={"100%"}
              variant={"rectangular"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <RoundedSkeleton
              height={174}
              width={"100%"}
              variant={"rectangular"}
            />
          </Grid>
        </ItemsContainer>
      )}
      {!selecionado && (
        <>
          <CalendarButton
            startIcon={<Today />}
            onClick={handleClickOpenFilters}
          >
            {period}
          </CalendarButton>
          <Tooltip title={showMore ? "Ver Menos" : "Ver Mais"}>
            <ShowMoreButton onClick={handleClickToggleShowMore}>
              {showMore ? <ArrowDropDown /> : <ArrowDropUp />}
            </ShowMoreButton>
          </Tooltip>
          <FilterContainer __justifyContent={"flex-end"} __gap={"1rem"}>
            <Tooltip title={"OS com alerta de falta de mensagem"}>
              <AlertCount disableRipple>
                {loading ? <ThinText>...</ThinText> : <>{totalSemMensagem}</>}
              </AlertCount>
            </Tooltip>
            <FilterButton
              startIcon={<FilterAltOutlined />}
              onClick={handleClickOpenFilters}
            >
              {loading ? (
                <ThinText>...</ThinText>
              ) : (
                <>
                  {total}&nbsp;<ThinText>OS</ThinText>
                </>
              )}
            </FilterButton>
            <Tooltip title={"Exportar"}>
              <ExportButton
                onClick={() => !relatorioOs.loading && handleDownloadOs()}
              >
                {!relatorioOs.loading && <Download />}
                {!!relatorioOs.loading && (
                  <IconLoader style={{ width: "20px", height: "20px" }} />
                )}
              </ExportButton>
            </Tooltip>
          </FilterContainer>
        </>
      )}
      {!!selecionado && (
        <Tooltip title={"Fechar Detalhes"}>
          <ShowMoreButton onClick={() => handleClickItem(null)}>
            <Close />
          </ShowMoreButton>
        </Tooltip>
      )}
      {!loading && (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={handleFetchMoreData}
          hasMore={total > items.length && !selecionado}
          loader={
            <FlexContainer __justifyContent={"center"}>
              <Loader
                type={"dots"}
                color={theme.colors.primary._600}
                size={40}
              />
            </FlexContainer>
          }
          height={showMore ? 500 : 224}
        >
          <ItemsContainer
            container
            spacing={"20px"}
            paddingX={"20px"}
            paddingTop={"10px"}
            paddingBottom={"20px"}
            marginY={0}
            justifyContent={!!selecionado ? "center" : "flex-start"}
          >
            {!selecionado && items.length === 0 && (
              <Grid item xs={12}>
                <FlexContainer
                  __justifyContent={"center"}
                  __alignItems={"center"}
                >
                  <SpacedThinText>
                    Não foram encontradas Ordens de Serviço para o Filtro
                    aplicado.
                  </SpacedThinText>
                </FlexContainer>
              </Grid>
            )}
            {!selecionado &&
              items.map((i) => (
                <Grid key={i.id} item xs={12} md={6} lg={3}>
                  <ItemCard
                    item={i}
                    isUserEnd={isUserEnd}
                    handleClick={() => handleClickItem(i)}
                  />
                </Grid>
              ))}
            {!!selecionado && (
              <Grid item xs={12} lg={6}>
                <ItemCardExpandido
                  isUserEnd={isUserEnd}
                  item={selecionado}
                  handleClick={() => handleClickItem(null)}
                  handleDownload={() => handleDownloadRoteiro(selecionado.id)}
                  handleClickUpdate={handleClickUpdate}
                  updating={updating}
                  roteiroFile={roteiroFile}
                  handleCleanRoteiroData={handleCleanRoteiroData}
                />
              </Grid>
            )}
          </ItemsContainer>
        </InfiniteScroll>
      )}
    </StyledDrawer>
  );
};

export default BottomDrawer;
