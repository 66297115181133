import React, { useCallback, useState } from "react";
import { InputAdornment, Popover } from "@mui/material";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import { CustomTextField } from ".";

const ColorSwatch = styled.div`
  width: 30px;
  height: 20px;
  border-radius: 3px;
  background-color: ${({ color }) => color ?? "transparent"};
  border: 1px solid black;
  cursor: pointer;
`;

const rgbaToHex = (rgba) => {
  if (!rgba) return "";
  if (rgba.a === 1) {
    return `#${[rgba.r, rgba.g, rgba.b]
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("")}`;
  }
  return `#${[rgba.r, rgba.g, rgba.b, Math.round(rgba.a * 255)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("")}`;
};
const hexToRgba = (hex) => {
  if (!hex) return "";
  let r, g, b, a;
  hex = hex.replace("#", "");
  if (3 === hex.length) {
    r = hex.charAt(0);
    g = hex.charAt(1);
    b = hex.charAt(2);
  } else if (4 === hex.length) {
    r = hex.charAt(0);
    g = hex.charAt(1);
    b = hex.charAt(2);
    a = hex.charAt(3);
  } else if (6 === hex.length) {
    r = hex.substring(0, 2);
    g = hex.substring(2, 4);
    b = hex.substring(4, 6);
  } else if (8 === hex.length) {
    r = hex.substring(0, 2);
    g = hex.substring(2, 4);
    b = hex.substring(4, 6);
    a = hex.substring(6, 8);
  } else {
    return "";
  }
  if ("undefined" === typeof a) {
    a = "ff";
  }
  if (1 === r.length) {
    r += r;
  }
  if (1 === g.length) {
    g += g;
  }
  if (1 === b.length) {
    b += b;
  }
  if (1 === a.length) {
    a += a;
  }
  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);
  a = parseInt(a, 16) / 255;
  return {
    r,
    g,
    b,
    a,
  };
};

const ColorPicker = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { value, onChange } = props;

  const handleSwatchChange = useCallback(
    (color) => {
      onChange(rgbaToHex(color.rgb));
    },
    [onChange]
  );

  return (
    <>
      <CustomTextField
        {...props}
        readOnly={true}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <ColorSwatch color={value} />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SketchPicker
          color={hexToRgba(value)}
          onChangeComplete={handleSwatchChange}
        />
      </Popover>
    </>
  );
};

export default ColorPicker;
