import React from "react";
import { Dialog, DialogContent, IconButton, styled } from "@mui/material";
import { TableCell, TableRow } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";

const StyledIconContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
`;
const StyledTableCell = styled(TableCell)`
  font-weight: ${({ isfirst }) => (isfirst ? "bold" : "normal")};
  font-size: 1rem;
  font-family: Noto Sans;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: -10px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 23px;
  }
`;
const StyledSubTitle = styled("h1")`
  font-family: Noto Sans;
  font-size: 1.2rem;
  text-align: left;
  font-weight: normal;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const PosicaoDialog = ({ handleClose, posicoes, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <StyledIconContainer>
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
        </StyledIconContainer>
        <StyledTitle>Posições</StyledTitle>
        <StyledSubTitle>{posicoes[0]?.name}</StyledSubTitle>
        {posicoes.posicoes &&
          posicoes.posicoes.map((item, index) => {
            return (
              <TableRow key={index}>
                <StyledTableCell isfirst={index === 0}>
                  {item.latitude}
                </StyledTableCell>
                <StyledTableCell isfirst={index === 0}>
                  {item.longitude}
                </StyledTableCell>
              </TableRow>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};

export default PosicaoDialog;
