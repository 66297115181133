export const meses = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
export const funcionamento = [
  {
    value: 1,
    label: "Domingo",
    contract: "dom",
  },
  {
    value: 2,
    label: "Segunda",
    contract: "seg",
  },
  {
    value: 3,
    label: "Terça",
    contract: "ter",
  },
  {
    value: 4,
    label: "Quarta",
    contract: "qua",
  },
  {
    value: 5,
    label: "Quinta",
    contract: "qui",
  },
  {
    value: 6,
    label: "Sexta",
    contract: "sex",
  },
  {
    value: 7,
    label: "Sábado",
    contract: "sáb",
  },
  {
    value: 9,
    label: "Feriados",
    contract: "fer",
  },
];

export const years = generateArrayOfYears();

function generateArrayOfYears() {
  let max = new Date().getFullYear();
  let min = 2022;
  let years = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export const generateArrayOfDays = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth();
  let lastDay = new Date(year, month + 1, 0);
  let firstDay = new Date(year, month, 1);

  let max = lastDay.getDate();
  let min = firstDay.getDate();
  let days = [];

  let today = new Date().getDate();
  for (var i = max; i >= min; i--) {
    days.push({
      dia: i,
      label: funcionamento[new Date(year, month, i).getDay()].contract,
      date: new Date(year, month, i),
      isToday: today === i && new Date().getMonth() === month ? true : false,
    });
  }
  return days;
};
