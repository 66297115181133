import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/configuracao/reprocessamento.actions";
import { authenticatedRequest } from "../../utils/api";
import { toast } from "react-toastify";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";

const listarRoteiros = basicFlow({
  actionGenerator: actions.listarRoteiros,
  api: (values) => {
    return authenticatedRequest({
      url: `/reprocessamento/listar/roteiros`,
      method: "post",
      body: values,
    });
  },
});

const reprocessar = basicFlow({
  actionGenerator: actions.reprocessar,
  api: (values) => {
    return authenticatedRequest({
      url: `/reprocessamento/reprocessar`,
      method: "post",
      body: values,
    });
  },
  postSuccess: () => {
    toast.success(
      "Reprocessamento em andamento. Aguarde alguns minutos para visualizar os resultados no mapa.",
      {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#203d8b" },
      }
    );
  },
});

function* LimparRoteirosRouterWatcher() {
  yield routeWatcher(routes.REPROCESSAMENTO_OS, function* () {
    yield put(actions.limparRoteiros());
  });
}

export const sagas = [
  LimparRoteirosRouterWatcher(),
  listarRoteiros.watcher(),
  reprocessar.watcher(),
];
