import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "../../reducers/roteiroInconsistencia.actions";
import { getRoteiroInconsistencia } from "../../selectors/roteiroInconsistencia.selectors";
import formatters from "../../utils/formatters";
import BateriaPage from "./RoteiroInconsistenciaPage";

const RoteiroInconsistenciaPageConnected = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(getRoteiroInconsistencia);

  const initialValues = {
    dataOrdemServico: "",
  };
  const rules = {};

  let date = String(formatters.dates.iso(new Date()));

  const defaultValues = {
    dataOrdemServico: date,
  };

  const formProps = {
    ...useForm({ defaultValues }),
    initialValues,
    rules,
  };

  const handleSubmit = (values) => {
    dispatch(
      actions.obterRoteiroInconsistencia.request({ dataOrdemServico: values })
    );
  };

  return (
    <BateriaPage
      formProps={formProps}
      handleSearch={handleSubmit}
      loading={loading}
      data={data}
    />
  );
};

export default RoteiroInconsistenciaPageConnected;
