import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import theme from "../../../styles/theme";
import InfoWindowPosicao from "./InfoWindowPosicao";
import Icon from "../../../assets/m-percurso-big.png";

const PosicoesValidas = ({ points, isEndUser }) => {
  const [clicked, setClicked] = useState(null);

  return (
    <>
      {points.map((p, i) => {
        return (
          <Marker
            key={p.id}
            position={p.longitudeLatitude}
            icon={Icon}
            label={{
              text: `${i + 1}`,
              color: theme.colors.white,
              fontFamily: theme.fonts.family.default,
              fontSize: theme.fonts.size.large,
              fontWeight: "800",
              className: "maps-label",
            }}
            clickable={true}
            onClick={() => setClicked(p)}
          />
        );
      })}
      <InfoWindowPosicao
        isEndUser={isEndUser}
        item={clicked}
        handleClose={() => setClicked(null)}
        canDelete={false}
      />
    </>
  );
};

export default PosicoesValidas;
