import React from "react";
import { Grid, Skeleton } from "@mui/material";
import BasePage from "../BasePage";

import { styled } from "@mui/material";
import { Card } from "../../components/styled";
import {
  ControlledCustomTextField,
  CustomControlledAutoComplete,
} from "../../components/inputs";
import { Button } from "../../components";
import TabelaBateria from "./TabelaBateria";

const StyledTitle = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledGrid = styled(Grid)`
  margin-top: 10px;
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const StyledCreateButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 100%;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  margin-top: -110px;
  min-height: 500px;
  border-radius: 25px;
`;

const FeriadoPage = ({
  formProps,
  dominioDispositivos,
  dominioEmpresas,
  handleSearch,
  dominioNivel,
  handlePagination,
  handleDownloadCsv,
  handleCleanFile,
  dataCSV,
  data,
  order,
  handleOrderClick,
  loading,
  pageSize,
}) => {
  return (
    <BasePage isLoggedIn={true}>
      <form>
        <StyledTitle>Monitoramento de Baterias</StyledTitle>
        <StyledCard>
          <StyledCardTitle>Filtros</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={8}>
              {dominioEmpresas && dominioEmpresas.length > 0 && (
                <CustomControlledAutoComplete
                  name={"cliente"}
                  formProps={formProps}
                  label={"Cliente"}
                  domain={dominioEmpresas}
                  labelField={"nome"}
                />
              )}
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              {dominioDispositivos && dominioDispositivos.length > 0 && (
                <CustomControlledAutoComplete
                  name={"dispositivo"}
                  formProps={formProps}
                  label={"Dispositivo"}
                  domain={dominioDispositivos}
                  labelField={"nome"}
                />
              )}
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <CustomControlledAutoComplete
                name={"nivelBateria"}
                formProps={formProps}
                label={"Nível"}
                domain={dominioNivel}
                labelField={"tipo"}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <ControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="nivelBateriaMin"
                label="% de Uso da Bateria Mín."
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={3}>
              <ControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="nivelBateriaMax"
                label="% de Uso da Bateria Max."
              />
            </StyledGrid>
            <StyledGrid alignSelf={"center"} item xs={12} sm={12} md={3}>
              <StyledCreateButton onClick={handleSearch}>
                Aplicar
              </StyledCreateButton>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        {loading && <StyledSkeleton />}
        {!loading && data.resultado && data.resultado.length > 0 && (
          <TabelaBateria
            handlePagination={handlePagination}
            data={data}
            pageSize={pageSize}
            handleDownloadCsv={handleDownloadCsv}
            order={order}
            handleOrderClick={handleOrderClick}
            dataCSV={dataCSV}
            handleCleanFile={handleCleanFile}
          />
        )}
      </form>
    </BasePage>
  );
};

export default FeriadoPage;
