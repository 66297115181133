import BasePage from "../../BasePage";
import React from "react";
import { Card, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { ControlledCustomTextField } from "../../../components/inputs";
import { Button } from "../../../components";
import ControlledColorPicker from "../../../components/inputs/ControlledColorPicker";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;
const StyledControlledCustomTextField = styled(ControlledCustomTextField)`
  .Mui-disabled {
    -webkit-text-fill-color: grey;
  }
`;

const StyledAtualizarButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-bottom: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
`;
const ButtonContainerLeft = styled("div")`
  display: flex;
  margin-left: 0px;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const FormEditarMapaMensalPage = ({
  data,
  loading,
  formProps,
  handleSubmit,
  handleGoBack,
}) => {
  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Editar Configuração Mapa Mensal</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Editar Configuração Mapa Mensal</StyledTitle>
      <StyledForm onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledCardTitle>Dados da Configuração</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={12}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="descricao"
                label="Descrição"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="porcentagemInicial"
                label="% Checkpoints Mín."
                type="number"
                InputProps={{
                  inputProps: {
                    step: 0.01,
                    min: 0,
                    max: 100,
                  },
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="porcentagemFinal"
                label="% Checkpoints Máx."
                type="number"
                InputProps={{
                  inputProps: {
                    step: 0.01,
                    min: 0,
                    max: 100,
                  },
                }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={4}>
              <ControlledColorPicker
                formProps={formProps}
                fullWidth
                name="cor"
                label="Cor"
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="dataHoraCriacao"
                label="Data e hora de criação"
                disabled
              ></StyledControlledCustomTextField>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="dataHoraAtualizacao"
                label="Data e hora de atualização"
                disabled
              ></StyledControlledCustomTextField>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainer>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </ButtonContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainerLeft>
              <StyledAtualizarButton type={"submit"} loading={loading}>
                Editar
              </StyledAtualizarButton>
            </ButtonContainerLeft>
          </Grid>
        </Grid>
      </StyledForm>
    </BasePage>
  );
};

export default FormEditarMapaMensalPage;
