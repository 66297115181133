import React from "react";
import NumberFormat from "react-number-format";

const formatDate = (value) => {
  let finalValue = value.slice(0, 2);
  if (value.length > 2) {
    finalValue += ":" + value.slice(2, 4);
  }
  if (value.length > 4) {
    value += ":" + value.slice(4, 7);
  }
  return finalValue;
};

export default function HourTextField({ inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.formattedValue,
          },
        });
      }}
      format={formatDate}
    />
  );
}
