import React from "react";
import { KmlLayer as GKmlLayer } from "@react-google-maps/api";

const KmlLayer = ({ file }) => {
  if (!file) {
    return null;
  }
  return <GKmlLayer url={file} />;
};

export default KmlLayer;
