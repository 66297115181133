import { actions, types } from "./alertas.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  alertas: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
    cliente: "",
  },
  alerta: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterAlertas.REQUEST:
    case actions.obterAlertas.SUCCESS:
    case actions.obterAlertas.FAILURE:
      return {
        ...state,
        alertas: {
          ...state.alertas,
          loading: action.type === actions.obterAlertas.REQUEST,
          data:
            action.type === actions.obterAlertas.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterAlertas.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          cliente:
            action.type === actions.obterAlertas.SUCCESS
              ? action.payload?.response?.data?.cliente ?? ""
              : "",
          nomeCliente:
            action.type === actions.obterAlertas.SUCCESS
              ? action.payload?.response?.data?.nomeCliente ?? ""
              : "",
          page:
            action.type === actions.obterAlertas.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case types.REMOVER_FILTRO_FERIADO:
      return {
        ...state,
        alertas: {
          ...state.alertas,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_FERIADO:
      return {
        ...state,
        alertas: {
          ...state.alertas,
          page: 1,
        },
      };

    case actions.editarAlerta.REQUEST:
    case actions.editarAlerta.SUCCESS:
    case actions.editarAlerta.FAILURE:
      return {
        ...state,
        alerta: {
          ...state.alerta,
          loading: action.type === actions.editarAlerta.REQUEST,
          data:
            action.type === actions.editarAlerta.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDITAR_FERIADO:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarAlerta.REQUEST:
    case actions.mostrarAlerta.SUCCESS:
    case actions.mostrarAlerta.FAILURE:
      return {
        ...state,
        alerta: {
          ...state.alerta,
          loading: action.type === actions.mostrarAlerta.REQUEST,
          data:
            action.type === actions.mostrarAlerta.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarAlerta.REQUEST:
    case actions.criarAlerta.SUCCESS:
    case actions.criarAlerta.FAILURE:
      return {
        ...state,
        alerta: {
          ...state.alerta,
          loading: action.type === actions.criarAlerta.REQUEST,
          data:
            action.type === actions.criarAlerta.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarAlerta.REQUEST:
    case actions.deletarAlerta.SUCCESS:
    case actions.deletarAlerta.FAILURE:
      return {
        ...state,
        alertas: {
          ...state.alertas,
          loading: action.type === actions.deletarAlerta.REQUEST,
        },
      };
    case actions.reativarAlerta.REQUEST:
    case actions.reativarAlerta.SUCCESS:
    case actions.reativarAlerta.FAILURE:
      return {
        ...state,
        alertas: {
          ...state.alertas,
          loading: action.type === actions.reativarAlerta.REQUEST,
        }
      };
    default:
      return state;
  }
};

export default reducer;
