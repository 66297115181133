import React from "react";
import { Grid, Skeleton } from "@mui/material";
import BasePage from "../BasePage";

import { styled } from "@mui/material";
import { Card } from "../../components/styled";
import { ControlledCustomTextField } from "../../components/inputs";
import TabelaBateria from "./TabelaRoteiroInconsistencia";

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledGrid = styled(Grid)`
  margin-top: 10px;
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  margin-top: -110px;
  min-height: 500px;
  border-radius: 25px;
`;

const StyledControlledCustomTextField = styled(ControlledCustomTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const RoteiroInconsistenciaPage = ({
  formProps,
  dominioDispositivos,
  dominioEmpresas,
  handleSearch,
  dominioNivel,
  handlePagination,
  handleDownloadCsv,
  handleCleanFile,
  dataCSV,
  data,
  loading,
}) => {
  const onChangeDate = (e) => {
    handleSearch(e.target.value);
  };

  return (
    <BasePage isLoggedIn={true}>
      <form>
        <StyledTitle>Inconsistências na Geração de OS</StyledTitle>
        <StyledCard>
          <StyledCardTitle>Filtro</StyledCardTitle>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={4}>
              <StyledControlledCustomTextField
                name={"dataOrdemServico"}
                formProps={formProps}
                label={"Data da Ordem de Serviço"}
                type="date"
                onChange={(e) => onChangeDate(e)}
              />
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        {loading && <StyledSkeleton />}
        {!loading && data && data.length > 0 && (
          <TabelaBateria
            handlePagination={handlePagination}
            data={data}
            handleDownloadCsv={handleDownloadCsv}
            dataCSV={dataCSV}
            handleCleanFile={handleCleanFile}
          />
        )}
      </form>
    </BasePage>
  );
};

export default RoteiroInconsistenciaPage;
