import React from "react";
import { styled } from "@mui/material";
import { Battery20, Battery50, BatteryFull } from "@mui/icons-material";

const LowBattery = styled(Battery20)`
  margin-left: 10px;
  -webkit-transform: rotate(270deg);
  fill: #ff0000;
`;
const MidBattery = styled(Battery50)`
  margin-left: 10px;
  -webkit-transform: rotate(270deg);
  fill: #ff6600;
`;
const FullBattery = styled(BatteryFull)`
  margin-left: 10px;
  -webkit-transform: rotate(270deg);
  fill: #018e06;
`;
const DisabledBattery = styled(BatteryFull)`
  margin-left: 10px;
  -webkit-transform: rotate(270deg);
  fill: #899294;
`;

const BatterryIcon = ({ nivel }) => {
  switch (nivel) {
    case "A":
      return <FullBattery />;
    case "N":
      return <MidBattery />;
    case "B":
      return <LowBattery />;
    default:
      return <DisabledBattery />;
  }
};

export default BatterryIcon;
