import { Card } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Loader } from "../loaders";

const StyledCard = styled(Card)`
  height: 40px;
  width: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  background: #f6fdff;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background: #b8d6eeff;
  }
`;

const StyledButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
const StyledIconContainer = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

function RoundIconButton({ children, handleClick, loading, ...otherProps }) {
  return (
    <StyledButtonContainer onClick={handleClick} {...otherProps}>
      <StyledCard>
        <StyledIconContainer>
          {loading ? <Loader sx={{ backgroundColor: "#51a8d1" }} /> : children}
        </StyledIconContainer>
      </StyledCard>
    </StyledButtonContainer>
  );
}

export default RoundIconButton;
