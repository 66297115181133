import { createSelector } from "reselect";

export const getFeriadoCadastro = (state) => state.feriados;

export const getFeriados = createSelector(
  getFeriadoCadastro,
  (state) => state.feriados
);

export const getFeriado = createSelector(
  getFeriadoCadastro,
  (state) => state.feriado
);

export const getFeriadosPage = createSelector(
  getFeriadoCadastro,
  (state) => state.feriados.page
);

export const getFeriadosFilter = createSelector(
  getFeriadoCadastro,
  (state) => state.feriados.filter
);

export const getFeriadosCliente = createSelector(
  getFeriadoCadastro,
  (state) => state.feriados.cliente
);
