import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_ALERTAS: "@@alertas/OBTER_ALERTAS",
  MOSTRAR_ALERTA: "@@alertas/MOSTRAR_ALERTA",
  EDITAR_ALERTA: "@@alertas/EDITAR_ALERTA",
  CRIAR_ALERTA: "@@alertas/CRIAR_ALERTA",
  DELETAR_ALERTA: "@@alertas/DELETAR_ALERTA",
  REATIVAR_ALERTA: "@@alertas/REATIVAR_ALERTA",
  REMOVER_FILTRO_ALERTA: "@@alertas/REMOVER_FILTRO_ALERTA",
  REMOVER_PAGINA_ALERTA: "@@alertas/REMOVER_PAGINA_ALERTA",
};

export const actions = {
  obterAlertas: createAsyncAction(types.OBTER_ALERTAS),
  mostrarAlerta: createAsyncAction(types.MOSTRAR_ALERTA),
  editarAlerta: createAsyncAction(types.EDITAR_ALERTA),
  criarAlerta: createAsyncAction(types.CRIAR_ALERTA),
  deletarAlerta: createAsyncAction(types.DELETAR_ALERTA),
  reativarAlerta: createAsyncAction(types.REATIVAR_ALERTA),
  removerFiltroAlerta: () => createSyncAction(types.REMOVER_FILTRO_ALERTA),
  removerPaginaAlerta: () => createSyncAction(types.REMOVER_PAGINA_ALERTA),
};
