import EsqueciSenhaForm from "./EsqueciSenhaForm.connected";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { Card, Grid } from "@mui/material";
import { styled } from "@mui/system";

const StyledH1 = styled("h2")`
  text-align: center;
  margin-top: -10px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.8px;
  text-align: left;
  font-weight: 500;

  color: #051f23;
  font-size: 20px;
  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const StyledLogo = styled(Logo)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 184px;
  height: 130px;
`;

const StyledLogoGrid = styled(Grid)`
  text-align: center;
`;

const StyledCard = styled(Card)`
  max-width: 300px;
  min-width: 300px;
  margin-top: 40px;
  height: auto;
  padding: 25px;
  margin-bottom: 25px;
  background: linear-gradient(
    156.25deg,
    #ebf7fc 0%,
    #d1eaf4 46.54%,
    #ffffff 96.66%
  );

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledGrid = styled(Grid)`
  background: linear-gradient(
    180deg,
    rgba(81, 168, 209, 0.9) 0%,
    rgba(190, 224, 239, 0.9) 100%
  );
  min-height: 100vh;
`;

const EsqueciSenhaPage = (props) => {
  return (
    <StyledGrid alignItems="center" justifyContent="center" container>
      <StyledCard>
        <StyledLogoGrid item xs={12} md={12}>
          <StyledLogo className={"logo-login col-auto"} />
        </StyledLogoGrid>
        <StyledH1 style={{ textAlign: "center" }}>Esqueceu sua senha?</StyledH1>
        <EsqueciSenhaForm {...props} />
      </StyledCard>
    </StyledGrid>
  );
};

export default EsqueciSenhaPage;
