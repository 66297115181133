import React from "react";
import { Button as MButton } from "@mui/material";
import { styled } from "@mui/material";
import { Loader } from "../loaders";

const StyledButton = styled(MButton)`
  background-color: ${({ theme }) => theme.buttons.primary.background};
  border-radius: 15px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.family.buttons};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fonts.size.buttons}
  color: ${({ theme }) => theme.buttons.primary.color};

  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.buttons.primary.backgroundHover};
  }

`;

const Button = ({
  children,
  loading,
  loaderType = "circle",
  loaderColor = "white",
  loaderSize = 24,
  disableElevation = true,
  ...otherProps
}) => (
  <StyledButton
    {...otherProps}
    disableElevation={disableElevation}
    variant="contained"
    size="large"
    fullWidth={true}
  >
    {!!loading ? (
      <Loader type={loaderType} stroke={loaderColor} size={loaderSize} />
    ) : (
      children
    )}
  </StyledButton>
);

export default Button;
