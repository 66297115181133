import { useDispatch, useSelector } from "react-redux";
import GeracaoOsPage from "./GeracaoOsPage";
import useCompleteForm from "../../../hooks/useCompleteForm";
import validators from "../../../utils/validators";
import { useCallback } from "react";
import { getGeracaoOs } from "../../../selectors/configuracao/geracao.selectors";
import { actions } from "../../../reducers/configuracao/geracao.actions";

const GeracaoOsPageConnected = () => {
  const dispatch = useDispatch();
  const { processing } = useSelector(getGeracaoOs);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      data: validators.string({
        required: true,
        custom: {
          dataPassadaInvalida: (value) => {
            const [ano, mes, dia] = value.split("-");
            if (ano > 9999) return "Ano inválido";
            if (!dia || !mes | !ano) return false;
            const diaNumero = parseInt(dia, 10);
            const mesNumero = parseInt(mes, 10);
            const anoNumero = parseInt(ano, 10);
            const date = new Date(anoNumero, mesNumero - 1, diaNumero, 0, 0, 0);
            const now = new Date();
            now.setHours(0);
            now.setMinutes(0);
            now.setSeconds(0);
            now.setMilliseconds(0);
            return (
              date.getTime() >= now.getTime() || "Data não pode ser no passado"
            );
          },
        },
      }),
    }),
    initialValues: () => ({
      data: "",
    }),
    handleSubmit: useCallback(
      (values) => {
        const data = values.data;
        const [ano, mes, dia] = data.split("-");
        values.data = `${dia}/${mes}/${ano}`;
        dispatch(actions.processar.request(values));
      },
      [dispatch]
    ),
  });

  return (
    <GeracaoOsPage
      formProps={formProps}
      handleSubmit={handleSubmit}
      processing={processing}
    />
  );
};

export default GeracaoOsPageConnected;
