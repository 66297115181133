import React, { useState } from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { getDiaDaSemana } from "../../../utils/constants";
import { DeleteSharp } from "@mui/icons-material";
import { padHour } from "../../../utils/basic";
import { DeleteDialog } from "../../../components";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Noto Sans;
  border-bottom: none !important;
`;

const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
  justify-contend: flex-end;
`;

const TabelaEditarFuncionamento = ({
  dataFuncionamento,
  handleDeleteFuncionamento,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [idToAct, setIdToAct] = useState("");
  if (dataFuncionamento?.length === 0 || !dataFuncionamento) {
    return <></>;
  }

  const handleDelete = (item) => {
    handleDeleteFuncionamento(item);
  };

  return (
    <StyledTable>
      <TableBody>
        <StyledTableRow>
          <StyledTitleTableCell>Dia da semana</StyledTitleTableCell>
          <StyledTitleTableCell>Hora início</StyledTitleTableCell>
          <StyledTitleTableCell>Hora fim</StyledTitleTableCell>
          <StyledTitleTableCell></StyledTitleTableCell>
        </StyledTableRow>
        {dataFuncionamento &&
          dataFuncionamento.map((item, index) => {
            let inicio = new Date(item.inicio);
            let fim = new Date(item.fim);
            return (
              <StyledTableRow key={index + item.inicio}>
                <StyledTableCell>
                  {getDiaDaSemana(Number(item.dia))}
                </StyledTableCell>
                <StyledTableCell>
                  {padHour(inicio.getHours())}:{padHour(inicio.getMinutes())}
                </StyledTableCell>
                <StyledTableCell>
                  {padHour(fim.getHours())}:{padHour(fim.getMinutes())}
                </StyledTableCell>
                <StyledButtonTableCell align="right">
                  <IconButton
                    onClick={(e) => {
                      setDialogTitle(
                        `Tem certeza que gostaria de deletar o funcionamento: ${getDiaDaSemana(
                          Number(item.dia)
                        )} de ${padHour(inicio.getHours())}:${padHour(
                          inicio.getMinutes()
                        )} às ${padHour(fim.getHours())}:${padHour(
                          fim.getMinutes()
                        )}`
                      );
                      setIdToAct(item.id);
                      setOpenDialog(true);
                    }}
                  >
                    <DeleteSharp />
                  </IconButton>
                </StyledButtonTableCell>
              </StyledTableRow>
            );
          })}
      </TableBody>
      <DeleteDialog
        title={dialogTitle}
        open={openDialog}
        setOpen={setOpenDialog}
        deleteFunction={handleDelete}
        id={idToAct}
      />
    </StyledTable>
  );
};

export default TabelaEditarFuncionamento;
