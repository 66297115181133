import React, { useEffect } from "react";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import RoundIconButton from "../../../components/buttons/RoundIconButton";
import { styled, TextField, Tooltip } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getFiltros } from "../../../selectors/mapaExecucao.selectors";
import { actions } from "../../../reducers/mapaExecucao.actions";

const SyledContainer = styled("div")`
  min-width: 200px;
  padding: 10px;
  display: flex;
  flex-flow: column wrap;
`;

const StyledTextField = styled(TextField)`
  min-width: 200px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = useState(false);
  const filtros = useSelector(getFiltros);
  const [value, setValue] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setValue(filtros.mesAnoString);
  }, [filtros]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);

    filtros.mesAno = new Date(
      e.target.value.slice(0, 4),
      e.target.value.slice(5, 7),
      0
    );

    filtros.mesAnoString = e.target.value;

    dispatch(actions.atualizarFiltros({ ...filtros, page: 1 }));
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <RoundIconButton aria-describedby={"idPopover"} handleClick={handleClick}>
        <Tooltip title="Calendário">
          <DateRange sx={{ fill: "#51a8d1" }} />
        </Tooltip>
      </RoundIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SyledContainer>
          <StyledTextField
            name={"mesAnoString"}
            value={value}
            onChange={handleChange}
            type="month"
            inputProps={{
              max: `${new Date().getFullYear()}-${
                new Date().getMonth() > 8
                  ? new Date().getMonth() + 1
                  : "0" + (new Date().getMonth() + 1)
              }`,
              min: "2022-01",
            }}
          />
        </SyledContainer>
      </Popover>
    </>
  );
}
