import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDrawer,
  getFiltros,
  getItems,
  getRoteiro,
  getSelecionado,
  getTotalItems,
  isLoadingItems,
  getOs,
  isUpdatingItem,
  getBotao,
  getTotalSemMensagemItems,
} from "../../../selectors/mapa.selectors";
import { actions } from "../../../reducers/mapa.actions";
import BottomDrawer from "./BottomDrawer";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { getPerfil } from "../../../selectors/usuario.selectors";

const BottomDrawerConnected = () => {
  const dispatch = useDispatch();
  const { showMore } = useSelector(getDrawer);
  const perfil = useSelector(getPerfil);
  const filters = useSelector(getFiltros);
  const loading = useSelector(isLoadingItems);
  const updating = useSelector(isUpdatingItem);
  const selecionado = useSelector(getSelecionado);
  const total = useSelector(getTotalItems);
  const totalSemMensagem = useSelector(getTotalSemMensagemItems);
  const items = useSelector(getItems);
  const roteiro = useSelector(getRoteiro);
  const relatorioOs = useSelector(getOs);
  const botao = useSelector(getBotao);

  const period =
    filters.inicioPeriodo === filters.fimPeriodo
      ? filters.inicioPeriodo
      : `${filters.inicioPeriodo} - ${filters.fimPeriodo}`;

  const handleClickToggleShowMore = useCallback(() => {
    dispatch(actions.alternarMaisCards());
  }, [dispatch]);

  const handleClickItem = useCallback(
    (item) => {
      if (botao === "voltar") {
        dispatch(actions.selecionarOs(item));
      }
      else if (botao === "alerta") {
        dispatch(routeActions.redirectTo(routes.PAINEL_ALERTAS));
        dispatch(actions.alternarBotao());
        dispatch(actions.selecionarOs());
      } else {
        dispatch(routeActions.redirectTo(routes.HOME_PAGE));
        dispatch(actions.alternarBotao());
        dispatch(actions.selecionarOs());
      }
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleFetchMoreData = useCallback(() => {
    dispatch(actions.proximaPagina.request());
  }, [dispatch]);

  const handleClickOpenFilters = useCallback(() => {
    dispatch(actions.exibirFiltros(true));
  }, [dispatch]);
  const handleDownloadRoteiro = useCallback(
    (id) => {
      dispatch(actions.downloadRoteiro.request(id));
    },
    [dispatch]
  );
  const handleClickUpdate = useCallback(() => {
    dispatch(actions.atualizarOs.request({ id: selecionado?.id }));
  }, [dispatch, selecionado]);

  const handleCleanRoteiroData = useCallback(() => {
    dispatch(actions.cleanRoteiroData());
  }, [dispatch]);

  const handleDownloadOs = useCallback(() => {
    dispatch(actions.downloadOs.request());
  }, [dispatch]);

  const handleCleanOsData = useCallback(() => {
    dispatch(actions.cleanOsData());
  }, [dispatch]);

  return (
    <BottomDrawer
      isUserEnd={perfil === "E"}
      items={items}
      period={period}
      total={total}
      totalSemMensagem={totalSemMensagem}
      selecionado={selecionado}
      showMore={showMore}
      loading={loading}
      updating={updating}
      handleClickUpdate={handleClickUpdate}
      handleClickOpenFilters={handleClickOpenFilters}
      handleClickItem={handleClickItem}
      handleClickToggleShowMore={handleClickToggleShowMore}
      handleFetchMoreData={handleFetchMoreData}
      handleDownloadRoteiro={handleDownloadRoteiro}
      roteiroFile={roteiro}
      handleCleanRoteiroData={handleCleanRoteiroData}
      relatorioOs={relatorioOs}
      handleDownloadOs={handleDownloadOs}
      handleCleanOsData={handleCleanOsData}
    />
  );
};

export default BottomDrawerConnected;
