import { actions, types } from "./roteiros.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  roteiros: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
    cliente: "",
    nomeCliente: "",
  },
  roteiro: {
    loading: false,
    data: [],
    kml: {
      loading: false,
      data: [],
    },
    posicao: {
      loading: false,
      data: [],
    },
    funcionamento: {
      loading: false,
      data: [],
    },
  },
  grupos: {
    loading: false,
    data: [],
  },
  tipoVeiculos: {
    loading: false,
    data: [],
  },
  tipoDispositivos: {
    loading: false,
    data: [],
  },
  funcionamento: {
    loading: false,
    data: [],
  },
  dispositivos: {
    loading: false,
    data: [],
  },
  inconsistencias: {
    open: false,
    data: [],
  },
  inconsistenciasTrocarDispositivos: {
    open: false,
    data: [],
  },
  inconsistenciasDispositivos: {
    open: false,
    data: [],
  },
  resumo: {
    loading: false,
    open: false,
    data: [],
  },
  resumoTrocarDispositivo: {
    loading: false,
    open: false,
    data: [],
  },
  resumoDispositivo: {
    loading: false,
    open: false,
    data: [],
  },
  baixar: {
    loading: false,
  },
  baixarDispositivos: {
    loading: false,
  },
  baixarTrocarDispositivos: {
    loading: false,
  },
  roteirosLista: {
    data: [],
    loading: false,
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterRoteiros.REQUEST:
    case actions.obterRoteiros.SUCCESS:
    case actions.obterRoteiros.FAILURE:
      return {
        ...state,
        grupos: {
          ...state.grupos,
          loading: action.type === actions.obterRoteiros.REQUEST,
          data:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload?.response?.data?.grupos ?? []
              : [],
        },
        tipoVeiculos: {
          ...state.tipoVeiculos,
          loading: action.type === actions.obterRoteiros.REQUEST,
          data:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload?.response?.data?.tipoVeiculos ?? []
              : [],
        },
        tipoDispositivos: {
          ...state.tipoDispositivos,
          loading: action.type === actions.obterRoteiros.REQUEST,
          data:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload?.response?.data?.tipoDispositivos ?? []
              : [],
        },
        roteiros: {
          ...state.roteiros,
          loading: action.type === actions.obterRoteiros.REQUEST,
          data:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          cliente:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload?.response?.data?.cliente ?? ""
              : "",
          nomeCliente:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload?.response?.data?.nomeCliente ?? ""
              : "",
          page:
            action.type === actions.obterRoteiros.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case actions.obterRoteirosSemFiltro.REQUEST:
    case actions.obterRoteirosSemFiltro.SUCCESS:
    case actions.obterRoteirosSemFiltro.FAILURE:
      return {
        ...state,
        roteirosLista: {
          ...state.roteirosLista,
          loading: action.type === actions.obterRoteirosSemFiltro.REQUEST,
          data:
            action.type === actions.obterRoteirosSemFiltro.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case actions.obterGrupos.REQUEST:
    case actions.obterGrupos.SUCCESS:
    case actions.obterGrupos.FAILURE:
      return {
        ...state,
        grupos: {
          ...state.grupos,
          loading: action.type === actions.obterGrupos.REQUEST,
          data:
            action.type === actions.obterGrupos.SUCCESS
              ? action.payload?.response?.data ?? []
              : [],
        },
      };
    case actions.obterFuncionamentos.REQUEST:
    case actions.obterFuncionamentos.SUCCESS:
    case actions.obterFuncionamentos.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          funcionamento: {
            ...state.roteiro.funcionamento,
            loading: action.type === actions.obterFuncionamentos.REQUEST,
            data:
              action.type === actions.obterFuncionamentos.SUCCESS
                ? action.payload.response.data
                : [],
          },
        },
      };
    case actions.obterKmls.REQUEST:
    case actions.obterKmls.SUCCESS:
    case actions.obterKmls.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          kml: {
            ...state.roteiro.kml,
            loading: action.type === actions.obterKmls.REQUEST,
            data:
              action.type === actions.obterKmls.SUCCESS
                ? action.payload.response.data
                : [],
          },
        },
      };
    case actions.obterPosicoes.REQUEST:
    case actions.obterPosicoes.SUCCESS:
    case actions.obterPosicoes.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          posicao: {
            ...state.roteiro.posicao,
            loading: action.type === actions.obterPosicoes.REQUEST,
            data:
              action.type === actions.obterPosicoes.SUCCESS
                ? action.payload.response.data
                : [],
          },
        },
      };

    case actions.obterDispositivos.REQUEST:
    case actions.obterDispositivos.SUCCESS:
    case actions.obterDispositivos.FAILURE:
      return {
        ...state,
        dispositivos: {
          ...state.dispositivos,
          loading: action.type === actions.obterDispositivos.REQUEST,
          data:
            action.type === actions.obterDispositivos.SUCCESS
              ? action.payload?.response?.data ?? []
              : [],
        },
      };
    case actions.baixarPlanilhaRoteiros.REQUEST:
    case actions.baixarPlanilhaRoteiros.SUCCESS:
    case actions.baixarPlanilhaRoteiros.FAILURE:
      return {
        ...state,
        baixar: {
          ...state.baixar,
          loading: action.type === actions.baixarPlanilhaRoteiros.REQUEST,
        },
      };
    case actions.baixarPlanilhaDispositivos.REQUEST:
    case actions.baixarPlanilhaDispositivos.SUCCESS:
    case actions.baixarPlanilhaDispositivos.FAILURE:
      return {
        ...state,
        baixarDispositivos: {
          ...state.baixarDispositivos,
          loading: action.type === actions.baixarPlanilhaDispositivos.REQUEST,
        },
      };
    case actions.baixarPlanilhaTrocarDispositivos.REQUEST:
    case actions.baixarPlanilhaTrocarDispositivos.SUCCESS:
    case actions.baixarPlanilhaTrocarDispositivos.FAILURE:
      return {
        ...state,
        baixarTrocarDispositivos: {
          ...state.baixarTrocarDispositivos,
          loading:
            action.type === actions.baixarPlanilhaTrocarDispositivos.REQUEST,
        },
      };
    case types.REMOVER_FILTRO_ROTEIRO:
      return {
        ...state,
        roteiros: {
          ...state.roteiros,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_ROTEIRO:
      return {
        ...state,
        roteiros: {
          ...state.roteiros,
          page: 1,
        },
      };

    case actions.editarRoteiro.REQUEST:
    case actions.editarRoteiro.SUCCESS:
    case actions.editarRoteiro.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          loading: action.type === actions.editarRoteiro.REQUEST,
          data:
            action.type === actions.editarRoteiro.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDITAR_ROTEIRO:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarRoteiro.REQUEST:
    case actions.mostrarRoteiro.SUCCESS:
    case actions.mostrarRoteiro.FAILURE:
      return {
        ...state,
        grupos: {
          ...state.grupos,
          loading: action.type === actions.mostrarRoteiro.SUCCESS,
          data:
            action.type === actions.mostrarRoteiro.SUCCESS
              ? action.payload?.response?.data?.grupos ?? []
              : [],
        },
        tipoVeiculos: {
          ...state.tipoVeiculos,
          loading: action.type === actions.mostrarRoteiro.SUCCESS,
          data:
            action.type === actions.mostrarRoteiro.SUCCESS
              ? action.payload?.response?.data?.tipoVeiculos ?? []
              : [],
        },
        tipoDispositivos: {
          ...state.tipoDispositivos,
          loading: action.type === actions.mostrarRoteiro.SUCCESS,
          data:
            action.type === actions.mostrarRoteiro.SUCCESS
              ? action.payload?.response?.data?.tipoDispositivos ?? []
              : [],
        },
        roteiro: {
          ...state.roteiro,
          loading: action.type === actions.mostrarRoteiro.REQUEST,
          data:
            action.type === actions.mostrarRoteiro.SUCCESS
              ? action.payload.response.data
              : [],
          posicao: {
            ...state.roteiro.posicao,
            loading: action.type === actions.mostrarRoteiro.REQUEST,
            data:
              action.type === actions.mostrarRoteiro.SUCCESS
                ? action.payload.response.data.posicao
                : [],
          },
          kml: {
            ...state.roteiro.kml,
            loading: action.type === actions.mostrarRoteiro.REQUEST,
            data:
              action.type === actions.mostrarRoteiro.SUCCESS
                ? action.payload.response.data.kml
                : [],
          },
          funcionamento: {
            ...state.roteiro.funcionamento,
            loading: action.type === actions.mostrarRoteiro.REQUEST,
            data:
              action.type === actions.mostrarRoteiro.SUCCESS
                ? action.payload.response.data.funcionamento
                : [],
          },
        },
      };
    case actions.criarRoteiro.REQUEST:
    case actions.criarRoteiro.SUCCESS:
    case actions.criarRoteiro.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          loading: action.type === actions.criarRoteiro.REQUEST,
          data:
            action.type === actions.criarRoteiro.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarPosicao.REQUEST:
    case actions.criarPosicao.SUCCESS:
    case actions.criarPosicao.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          posicao: {
            ...state.roteiro.posicao,
            loading: action.type === actions.criarPosicao.REQUEST,
          },
        },
      };
    case actions.criarKml.REQUEST:
    case actions.criarKml.SUCCESS:
    case actions.criarKml.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          kml: {
            ...state.roteiro.kml,
            loading: action.type === actions.criarKml.REQUEST,
          },
        },
      };
    case actions.criarFuncionamento.REQUEST:
    case actions.criarFuncionamento.SUCCESS:
    case actions.criarFuncionamento.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          funcionamento: {
            ...state.roteiro.funcionamento,
            loading: action.type === actions.criarFuncionamento.REQUEST,
          },
        },
      };
    case actions.deletarRoteiro.REQUEST:
    case actions.deletarRoteiro.SUCCESS:
    case actions.deletarRoteiro.FAILURE:
      return {
        ...state,
        roteiro: {
          ...state.roteiro,
          loading: action.type === actions.deletarRoteiro.REQUEST,
        },
      };
    case types.MOSTRAR_ERROS_UPLOAD:
      return {
        ...state,
        inconsistencias: {
          data: action.payload.data,
          open: action.payload.open,
        },
      };
    case types.MOSTRAR_ERROS_TROCAR_DISPOSITIVOS:
      return {
        ...state,
        inconsistenciasTrocarDispositivos: {
          data: action.payload.data,
          open: action.payload.open,
        },
      };
    case types.MOSTRAR_ERROS_DISPOSITIVOS:
      return {
        ...state,
        inconsistenciasDispositivos: {
          data: action.payload.data,
          open: action.payload.open,
        },
      };
    case types.LIMPAR_TABELAS:
      return {
        ...state,
        inconsistencias: {
          data: false,
          open: false,
        },
        resumo: {
          data: false,
          open: false,
          loading: false,
        },
        inconsistenciasTrocarDispositivos: {
          data: false,
          open: false,
        },
        resumoTrocarDispositivo: {
          data: [],
          open: false,
          loading: false,
        },
        inconsistenciasDispositivos: {
          data: false,
          open: false,
        },
        resumoDispositivo: {
          data: [],
          open: false,
          loading: false,
        },
      };
    case types.LIMPAR_ROTEIROS:
      return {
        ...state,
        roteirosLista: {
          data: [],
          loading: false,
        },
      };
    case actions.uploadCargaPlanilhaRoteiros.REQUEST:
    case actions.uploadCargaPlanilhaRoteiros.SUCCESS:
    case actions.uploadCargaPlanilhaRoteiros.FAILURE:
      return {
        ...state,
        resumo: {
          loading: action.type === actions.uploadCargaPlanilhaRoteiros.REQUEST,
          data:
            action.type === actions.uploadCargaPlanilhaRoteiros.SUCCESS
              ? action.payload.response.data
              : [],
          open:
            action.type === actions.uploadCargaPlanilhaRoteiros.SUCCESS
              ? true
              : false,
        },
      };
    case actions.uploadCargaTrocarDispositivos.REQUEST:
    case actions.uploadCargaTrocarDispositivos.SUCCESS:
    case actions.uploadCargaTrocarDispositivos.FAILURE:
      return {
        ...state,
        resumoTrocarDispositivo: {
          loading:
            action.type === actions.uploadCargaTrocarDispositivos.REQUEST,
          data:
            action.type === actions.uploadCargaTrocarDispositivos.SUCCESS
              ? action.payload.response.data
              : [],
          open:
            action.type === actions.uploadCargaTrocarDispositivos.SUCCESS
              ? true
              : false,
        },
      };
    case actions.uploadCargaPlanilhaDispositivos.REQUEST:
    case actions.uploadCargaPlanilhaDispositivos.SUCCESS:
    case actions.uploadCargaPlanilhaDispositivos.FAILURE:
      return {
        ...state,
        resumoDispositivo: {
          loading:
            action.type === actions.uploadCargaPlanilhaDispositivos.REQUEST,
          data:
            action.type === actions.uploadCargaPlanilhaDispositivos.SUCCESS
              ? action.payload.response.data
              : [],
          open:
            action.type === actions.uploadCargaPlanilhaDispositivos.SUCCESS
              ? true
              : false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
