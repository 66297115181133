import React from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { DeleteSharp, MenuTwoTone } from "@mui/icons-material";
import formatters from "../../../utils/formatters";
import { useState } from "react";
import { Fragment } from "react";
import PosicaoDialog from "./PosicaoDialog";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
`;
const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
  justify-contend: flex-end;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Noto Sans;
  border-bottom: none !important;
`;

const TabelaCriarPosicao = ({
  dataPosicao,
  dataKml,
  handleDeleteFuncionamento,
  handleDeletePosicao,
  handleDeleteKml,
}) => {
  const [open, setOpen] = useState(false);
  const [dialogList, setDialogList] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = (posicoes) => {
    setDialogList(posicoes);
    setOpen(true);
  };

  if (dataPosicao.length === 0 && dataKml.length === 0) {
    return <></>;
  }

  return (
    <>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Data</StyledTitleTableCell>
            <StyledTitleTableCell>Tipo</StyledTitleTableCell>
            <StyledTitleTableCell>Arquivo</StyledTitleTableCell>
            <StyledTitleTableCell></StyledTitleTableCell>
          </StyledTableRow>
          {dataPosicao &&
            dataPosicao.map((item, index) => {
              const handleDelete = () => {
                handleDeletePosicao(item);
              };
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {formatters.dates.withHours(item.date)}
                  </StyledTableCell>
                  <StyledTableCell>Posição</StyledTableCell>
                  <StyledTableCell>{item[0].name}</StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton onClick={() => handleOpenDialog(item)}>
                      <MenuTwoTone />
                    </IconButton>
                    <IconButton onClick={handleDelete}>
                      <DeleteSharp />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
          {dataKml &&
            dataKml.map((item, index) => {
              const handleDelete = () => {
                handleDeleteKml(item);
              };
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {formatters.dates.withHours(item.date)}
                  </StyledTableCell>
                  <StyledTableCell>Kml</StyledTableCell>
                  <StyledTableCell>{item[0].name}</StyledTableCell>
                  <StyledButtonTableCell align="right">
                    <IconButton onClick={handleDelete}>
                      <DeleteSharp />
                    </IconButton>
                  </StyledButtonTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
      <PosicaoDialog
        open={open}
        handleClose={handleClose}
        posicoes={dialogList}
      />
    </>
  );
};

export default TabelaCriarPosicao;
