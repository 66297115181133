import { Card, styled } from "@mui/material";
import React from "react";
import { Title } from "../../components/styled";
import BasePage from "../BasePage";
import ExecucaoTable from "./components/ExecucaoTable.connected";
import FiltersDrawer from "./components/FiltersDrawer.connected";
import Header from "./components/Header.connected";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  display: flex;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
  padding-right: 5px;
  overflow: auto;
`;
const StyledContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
`;

const StyledForm = styled("div")`
  max-width: 100%;
`;

function MapPage() {
  return (
    <BasePage isLoggedIn={true}>
      <Title>Mapa Mensal de Execução</Title>
      <StyledForm>
        <StyledCard>
          <StyledContainer>
            <Header />
            <ExecucaoTable />
          </StyledContainer>
          <FiltersDrawer />
        </StyledCard>
      </StyledForm>
    </BasePage>
  );
}

export default MapPage;
