import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_DISPOSITIVOS: "@@dispositivos/OBTER_DISPOSITIVOS",
  MOSTRAR_DISPOSITIVO: "@@dispositivos/MOSTRAR_DISPOSITIVO",
  EDITAR_DISPOSITIVO: "@@dispositivos/EDITAR_DISPOSITIVO",
  CRIAR_DISPOSITIVO: "@@dispositivos/CRIAR_DISPOSITIVO",
  DELETAR_DISPOSITIVO: "@@dispositivos/DELETAR_DISPOSITIVO",
  REATIVAR_DISPOSITIVO: "@@dispositivos/REATIVAR_DISPOSITIVO",
  REMOVER_FILTRO_DISPOSITIVO: "@@usuarios/REMOVER_FILTRO_DISPOSITIVO",
  REMOVER_PAGINA_DISPOSITIVO: "@@usuarios/REMOVER_PAGINA_DISPOSITIVO",
};

export const actions = {
  obterDispositivos: createAsyncAction(types.OBTER_DISPOSITIVOS),
  mostrarDispositivo: createAsyncAction(types.MOSTRAR_DISPOSITIVO),
  editarDispositivo: createAsyncAction(types.EDITAR_DISPOSITIVO),
  criarDispositivo: createAsyncAction(types.CRIAR_DISPOSITIVO),
  deletarDispositivo: createAsyncAction(types.DELETAR_DISPOSITIVO),
  reativarDispositivo: createAsyncAction(types.REATIVAR_DISPOSITIVO),
  removerFiltroDispositivo: () =>
    createSyncAction(types.REMOVER_FILTRO_DISPOSITIVO),
  removerPaginaDispositivo: () =>
    createSyncAction(types.REMOVER_PAGINA_DISPOSITIVO),
};
