import { createSelector } from "reselect";

export const getRoteiroCadastro = (state) => state.roteiros;

export const getRoteiros = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiros
);

export const getGrupos = createSelector(
  getRoteiroCadastro,
  (state) => state.grupos
);
export const getTipoDispositivos = createSelector(
  getRoteiroCadastro,
  (state) => state.tipoDispositivos
);
export const getTipoVeiculos = createSelector(
  getRoteiroCadastro,
  (state) => state.tipoVeiculos
);

export const getFuncionamento = createSelector(
  getRoteiroCadastro,
  (state) => state.funcionamento
);

export const getRoteiro = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiro
);
export const getRoteiroFuncionamento = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiro.funcionamento
);
export const getRoteiroKml = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiro.kml
);
export const getRoteiroPosicao = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiro.posicao
);

export const getRoteirosPage = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiros.page
);

export const getRoteirosFilter = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiros.filter
);

export const getRoteirosCliente = createSelector(
  getRoteiroCadastro,
  (state) => state.roteiros.cliente
);

export const getRoteirosDispositivos = createSelector(
  getRoteiroCadastro,
  (state) => state.dispositivos
);

export const getRoteirosInconsistencias = createSelector(
  getRoteiroCadastro,
  (state) => state.inconsistencias
);
export const getInconsistenciasTrocarDispositivos = createSelector(
  getRoteiroCadastro,
  (state) => state.inconsistenciasTrocarDispositivos
);
export const getInconsistenciasDispositivos = createSelector(
  getRoteiroCadastro,
  (state) => state.inconsistenciasDispositivos
);

export const getRoteirosResumo = createSelector(
  getRoteiroCadastro,
  (state) => state.resumo
);

export const getRoteirosBaixar = createSelector(
  getRoteiroCadastro,
  (state) => state.baixar
);

export const getRoteirosLista = createSelector(
  getRoteiroCadastro,
  (state) => state.roteirosLista
);

export const getRoteirosBaixarTrocarDispositivos = createSelector(
  getRoteiroCadastro,
  (state) => state.baixarTrocarDispositivos
);

export const getRoteirosBaixarDispositivos = createSelector(
  getRoteiroCadastro,
  (state) => state.baixarDispositivos
);

export const getRoteirosResumoTrocarDispositivo = createSelector(
  getRoteiroCadastro,
  (state) => state.resumoTrocarDispositivo
);

export const getRoteirosResumoDispositivo = createSelector(
  getRoteiroCadastro,
  (state) => state.resumoDispositivo
);
