import { ControlledTextField } from "../../components/inputs";
import { Button } from "../../components";
import { PersonOutline } from "@mui/icons-material";
import React, { useCallback } from "react";
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import { Grid } from "@mui/material";

const StyledButton = styled(Button)`
  margin-top: 20px;
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 219px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
`;

const StyledButtonBottom = styled(Button)`
  margin-top: 20px;
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 219px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
`;

const StyledFormGrid = styled(Grid)`
  place-self: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const StyledButtonGrid = styled(Grid)`
  text-align: center;
  align-items: center;
`;

const StyledControlledTextField = styled(ControlledTextField)`

margin-bottom:5px;
margin-top:5px;


flex: 1!important;

@media (max-width: 900px) {
  margin-bottom:5px;
  margin-top:5px;
}


  & .MuiInputLabel-root {
    background-color: #D4ECF5;
    color: #3F484A !important;
    font-family: ${({ theme }) => theme.fonts.family.form};
    font-weight: 700;


    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }


  & .MuiFormHelperText-root.Mui-error {
    color: #d32f2f!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #3F484A;
    },
    &:hover fieldset {
      border-color: #3F484A;
    },
    &.Mui-focused fieldset {
      border: 1.5px solid #3F484A;
    },
    &.Mui-error fieldset {
    border: 1.5px solid #d32f2f;
    },`;

const EsqueciSenhaForm = ({
  internal,
  loading,
  formProps,
  handleSubmit,
  handleClickBack,
}) => {
  const formSubmitHandler = useCallback(
    (values) => {
      handleSubmit(values);
      // eslint-disable-next-line
    },
    [handleSubmit]
  );

  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(formSubmitHandler)}
    >
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Usuário"
          name={"user"}
          formProps={formProps}
          margin={"none"}
          InputProps={{
            style: {
              textTransform: "lowercase",
              fontFamily: "Noto Sans",
              letterSpacing: "1px",
            },
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutline style={{ color: "#3F484A" }} />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <div style={{ marginTop: "15px" }}></div>

      {!internal && (
        <StyledButtonGrid item xs={12} md={12}>
          <StyledButton
            className="button-alternative"
            label={"VOLTAR"}
            onClick={handleClickBack}
          >
            VOLTAR
          </StyledButton>
        </StyledButtonGrid>
      )}
      <StyledButtonGrid item xs={12} md={12}>
        <StyledButtonBottom loading={loading} type={"submit"}>
          ENVIAR
        </StyledButtonBottom>
      </StyledButtonGrid>
    </form>
  );
};

export default EsqueciSenhaForm;
