import React, { useCallback, useEffect } from "react";
import FiltersDrawer from "./FiltersDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../reducers/diario.actions";
import {
  getDominioEmpresas,
  getDrawer,
  getFiltros,
} from "../../../selectors/diario.selectors";
import useCompleteForm from "../../../hooks/useCompleteForm";
import { getUsuario } from "../../../selectors/usuario.selectors";
import validators from "../../../utils/validators";

const FilterDrawerConnected = () => {
  const dispatch = useDispatch();
  const { filters: filtersVisible } = useSelector(getDrawer);
  const filtros = useSelector(getFiltros);
  const { empresa } = useSelector(getUsuario);
  const { data: dataEmpresas } = useSelector(getDominioEmpresas);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirFiltros(false));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: () => ({
      data: validators.date({
        required: true,
      }),
      empresa: validators.object({ required: true }),
    }),
    initialValues: () => ({
      data: filtros.data,
      empresa: dataEmpresas.find((el) => el.id === empresa) ?? null,
    }),
    handleSubmit: (values) => {
      dispatch(actions.atualizarFiltros({ ...values, page: 1 }));
      handleClose();
    },
  });

  const { reset } = formProps;

  useEffect(() => {
    if (!!filtersVisible && !!filtros) {
      reset(filtros);
    }
  }, [filtros, reset, filtersVisible]);

  useEffect(() => {
    if (dataEmpresas.find((el) => el.id === empresa)) {
      dispatch(
        actions.atualizarFiltros({
          empresa: dataEmpresas.find((el) => el.id === empresa),
        })
      );
    }
    // eslint-disable-next-line
  }, [empresa, dataEmpresas]);

  useEffect(() => {
    if (empresa === null) {
      dispatch(actions.atualizarFiltros());
    }
    // eslint-disable-next-line
  }, [empresa]);

  return (
    <FiltersDrawer
      handleClose={handleClose}
      visible={filtersVisible}
      formProps={formProps}
      handleSubmit={handleSubmit}
      domainEmpresas={dataEmpresas}
      showEmpresa={!empresa}
    />
  );
};

export default FilterDrawerConnected;
