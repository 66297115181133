import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import Dropzone from "react-dropzone";

const FileInput = ({
  files,
  name,
  value,
  margin = "none",
  disabled,
  helperText,
  onDrop,
  onFileDialogCancel,
  onDeleteFile,
  fullWidth = true,
  renderFile,
  children,
  acceptMime,
  ...otherValues
}) => (
  <FormControl fullWidth={fullWidth} disabled={disabled} margin={margin}>
    <Dropzone
      {...otherValues}
      onFileDialogCancel={onFileDialogCancel}
      name={name}
      disabled={disabled}
      onDrop={onDrop}
      accept={acceptMime}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
          </div>
        );
      }}
    </Dropzone>
    {!!helperText && (
      <FormHelperText variant={"filled"} error={!!otherValues.error}>
        {helperText}
      </FormHelperText>
    )}
    {!!renderFile &&
      files?.length > 0 &&
      files.map((file, index) => renderFile(file, index, onDeleteFile))}
  </FormControl>
);

export default FileInput;
