import React from "react";
import { Controller } from "react-hook-form";
import TextField from "./TextField";
import { getValueFromObject } from "../../utils/basic";
import { Autocomplete } from "@mui/material";

const ControlledAutocomplete = ({
  formProps,
  domain,
  idField = "id",
  labelField,
  getOptionLabel = (option) =>
    option[labelField] ??
    domain.find((d) => d[idField] === option?.[idField])?.[labelField] ??
    "",
  name,
  ignoreError = false,
  autocompleteProps = {},
  noOptionsText = "",
  disabled,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;

  const isError = getValueFromObject(errors, name) !== undefined;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Autocomplete
            options={domain}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) =>
              option?.[idField] === value?.[idField]
            }
            value={value}
            {...autocompleteProps}
            onChange={(e, v) => {
              onChange(v);
              if (!!autocompleteProps.onChange) {
                autocompleteProps.onChange(v);
              }
            }}
            onBlur={() => {
              onBlur();
              if (!!autocompleteProps.onBlur) {
                autocompleteProps.onBlur();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...otherProps}
                value={value}
                error={isError}
                helperText={!ignoreError ? errors[name]?.message : undefined}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option[idField]}>
                {getOptionLabel(option)}
              </li>
            )}
            noOptionsText={noOptionsText}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default ControlledAutocomplete;
