import { actions, types } from "./usuarios.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  usuarios: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
  },
  usuario: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterUsuarios.REQUEST:
    case actions.obterUsuarios.SUCCESS:
    case actions.obterUsuarios.FAILURE:
      return {
        ...state,
        usuarios: {
          ...state.usuarios,
          loading: action.type === actions.obterUsuarios.REQUEST,
          data:
            action.type === actions.obterUsuarios.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterUsuarios.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          page:
            action.type === actions.obterUsuarios.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case types.REMOVER_FILTRO_USUARIO:
      return {
        ...state,
        usuarios: {
          ...state.usuarios,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_USUARIO:
      return {
        ...state,
        usuarios: {
          ...state.usuarios,
          page: 1,
        },
      };
    case actions.editarUsuario.REQUEST:
    case actions.editarUsuario.SUCCESS:
    case actions.editarUsuario.FAILURE:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          loading: action.type === actions.editarUsuario.REQUEST,
          data:
            action.type === actions.editarUsuario.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDITAR_USUARIO:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarUsuario.REQUEST:
    case actions.mostrarUsuario.SUCCESS:
    case actions.mostrarUsuario.FAILURE:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          loading: action.type === actions.mostrarUsuario.REQUEST,
          data:
            action.type === actions.mostrarUsuario.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarUsuario.REQUEST:
    case actions.criarUsuario.SUCCESS:
    case actions.criarUsuario.FAILURE:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          loading: action.type === actions.criarUsuario.REQUEST,
          data:
            action.type === actions.criarUsuario.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarUsuario.REQUEST:
    case actions.deletarUsuario.SUCCESS:
    case actions.deletarUsuario.FAILURE:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          loading: action.type === actions.deletarUsuario.REQUEST,
        },
      };

    default:
      return state;
  }
};

export default reducer;
