import { useForm } from "react-hook-form";
import FormClienteViewPage from "./FormClienteViewPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useEffect, useState } from "react";
import {
  getCliente,
  getMatrizes,
} from "../../../selectors/cadastro/clientes.selectors";
import formatters from "../../../utils/formatters";

const FormClienteViewPageConnected = () => {
  const { loading, data } = useSelector(getCliente);
  const { data: dataMatrizes, loading: loadingMatrizes } = useSelector(
    getMatrizes
  );
  const [showMatriz, setShowMatriz] = useState(false);
  const [matriz, setMatriz] = useState("");

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    reference: validators.string({ required: true }),
    cep: validators.string({ required: true, length: 9 }),
    logradouro: validators.string({ required: true }),
    numeroLogradouro: validators.string({ required: true }),
    complemento: validators.string({ required: false }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),
    cnpj: validators.cnpj({ required: true }),
    tipo: validators.string({ required: true }),
    idMatriz: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const matriz = formProps.getValues("tipo");

          if (matriz === "F") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    pontosRegiao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    raioPrecisao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    precisao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    latitude: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (isFinite(value) && Math.abs(value) <= 90) {
            return !!value || "Latitude inválida";
          } else {
            return true;
          }
        },
      },
    }),
    longitude: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
  };

  useEffect(() => {
    if (data !== []) {
      formProps.setValue("bairro", data?.bairro ?? "");
      formProps.setValue("cep", data?.cep ?? "");
      formProps.setValue("cnpj", data?.cnpj ?? "");
      formProps.setValue("complemento", data?.complemento ?? "");
      formProps.setValue(
        "dataHoraAtualizacao",
        formatters.dates.withHours(new Date(data?.dataHoraAtualizacao)) ?? ""
      );
      formProps.setValue(
        "dataHoraCriacao",
        formatters.dates.withHours(new Date(data?.dataHoraCriacao)) ?? ""
      );
      formProps.setValue("idMatriz", data?.idMatriz ?? "");
      formProps.setValue("logradouro", data?.logradouro ?? "");
      formProps.setValue("lote", data?.lote ?? "");
      formProps.setValue("municipio", data?.municipio ?? "");
      formProps.setValue("nome", data?.nome ?? "");
      formProps.setValue("nomeAbreviado", data?.nomeAbreviado ?? "");
      formProps.setValue("numeroLogradouro", data?.numeroLogradouro ?? "");
      formProps.setValue("pontosRegiao", data?.pontosRegiao ?? "");
      formProps.setValue("precisao", data?.precisao ?? "");
      formProps.setValue("raioPrecisao", data?.raioPrecisao ?? "");
      formProps.setValue(
        "roteiro",
        data?.roteiro === "S" ? true : false ?? false
      );
      formProps.setValue("situacao", data?.situacao ?? "");
      formProps.setValue("tipo", data?.tipo ?? "");
      formProps.setValue("uf", data?.uf ?? "");
      formProps.setValue("id", data?.id ?? "");
      formProps.setValue(
        "exibeMapaProUsuarioFinal",
        data?.exibeMapaProUsuarioFinal === "S" ? true : false ?? false
      );
      formProps.setValue(
        "exibeOpcaoPercursoOperacaoDiaria",
        data?.exibeOpcaoPercursoOperacaoDiaria === "S" ? true : false ?? false
      );
      formProps.setValue(
        "exibeOpcaoEfetivoOperacaoDiaria",
        data?.exibeOpcaoEfetivoOperacaoDiaria === "S" ? true : false ?? false
      );
      setShowMatriz(data?.tipo === "M");
      setMatriz(dataMatrizes.find((item) => item.id === data?.idMatriz)?.nome);
    }
    // eslint-disable-next-line
  }, [data, dataMatrizes]);

  const initialValues = {
    bairro: "",
    cep: "",
    cnpj: "",
    complemento: "",
    dataHoraAtualizacao: "",
    dataHoraCriacao: "",
    idMatriz: "",
    logradouro: "",
    lote: "",
    municipio: "",
    nome: "",
    nomeAbreviado: "",
    numeroLogradouro: "",
    pontosRegiao: "",
    raioPrecisao: "",
    roteiro: "",
    situacao: "",
    tipo: "",
    uf: "",
    id: "",
    latitude: "",
    longitude: "",
    precisao: "",
    exibeMapaProUsuarioFinal: false,
    exibeOpcaoPercursoOperacaoDiaria: false,
    exibeOpcaoEfetivoOperacaoDiaria: false,
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_CLIENTE));
  };

  return (
    <FormClienteViewPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      dataMatrizes={dataMatrizes}
      loadingMatrizes={loadingMatrizes}
      data={data}
      showMatriz={showMatriz}
      matriz={matriz}
    />
  );
};

export default FormClienteViewPageConnected;
