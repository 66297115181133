import { styled } from "@mui/material";
import { Button } from "../index";
import { transientOptions } from "../../styles/theme";

const GradientButton = styled(Button, transientOptions)`
  background: ${({ theme }) => theme.colors.gradients.button};
  height: 40px;
  width: 100%;
  border-radius: 100px;
  padding: 10px 24px 10px 24px;
  @media (max-width: 576px) {
    width: 276px;
  }
`;

export default GradientButton;
