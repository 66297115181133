import { createSelector } from "reselect";

export const getIntegracaoCadastro = (state) => state.integracoes;

export const getIntegracoes = createSelector(
  getIntegracaoCadastro,
  (state) => state.integracoes
);

export const getIntegracao = createSelector(
  getIntegracaoCadastro,
  (state) => state.integracao
);

export const getIntegracoesPage = createSelector(
  getIntegracaoCadastro,
  (state) => state.integracoes.page
);

export const getIntegracoesFilter = createSelector(
  getIntegracaoCadastro,
  (state) => state.integracoes.filter
);

export const getIntegracoesCliente = createSelector(
  getIntegracaoCadastro,
  (state) => state.integracoes.cliente
);
