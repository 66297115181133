import React from "react";
import MapPage from "./MapPage";
import { useSelector } from "react-redux";
import { getCenter, getSelecionado } from "../../selectors/mapa.selectors";

function MapPageConnected() {
  const position = useSelector(getCenter);
  const selecionado = useSelector(getSelecionado);

  return <MapPage position={position} selecionado={selecionado} />;
}

export default MapPageConnected;
