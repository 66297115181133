import basicFlow from "./asyncHandler";
import { put, select, takeEvery } from "redux-saga/effects";
import { actions, types } from "../reducers/painelAlerta.actions";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { getFiltros } from "../selectors/painelAlerta.selectors";
import formatters from "../utils/formatters";
import { getUsuario } from "../selectors/usuario.selectors";

const pesquisar = basicFlow({
  actionGenerator: actions.pesquisar,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);
    const empresa = currentFilter.empresa?.id
      ? currentFilter.empresa?.id
      : !!usuario?.empresa
        ? usuario.empresa
        : currentFilter.empresa?.id;
    return {
      ...currentFilter,
      empresa,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      dispositivo: currentFilter.dispositivo?.id,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/alerta/alertas-diarios/listar`,
      method: "post",
      body: values
    });
  }
});

const carregarEmpresas = basicFlow({
  actionGenerator: actions.carregarEmpresas,
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente/dominio?cliente=${values?.cliente ?? "false"}`,
      method: "get"
    });
  }
});
const carregarDispositivos = basicFlow({
  actionGenerator: actions.carregarDispositivos,
  api: () => {
    return authenticatedRequest({
      url: `/dispositivo/dominio`,
      method: "get"
    });
  }
});

function* atualizarFiltrosWatcher() {
  yield takeEvery([types.ATUALIZAR_FILTROS], function* () {
    yield put(actions.pesquisar.request());
  });
}

function* mapRouteWatcher() {
  yield routeWatcher(routes.PAINEL_ALERTAS, function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    if (currentFilter.inicioPeriodo === null) {
      currentFilter.inicioPeriodo = formatters.dates.short(new Date());
      currentFilter.fimPeriodo = formatters.dates.short(new Date());
    }

    yield put(actions.carregarDispositivos.request());

    if (usuario?.empresa) {
      yield put(
        actions.carregarEmpresas.request({ cliente: usuario?.empresa })
      );
      yield put(
        actions.atualizarFiltros({
          ...currentFilter,
          empresa: usuario.empresa,
          empresaNome: usuario.empresaNome
        })
      );
    } else {
      yield put(actions.carregarEmpresas.request());
      yield put(
        actions.atualizarFiltros({
          ...currentFilter
        })
      );
    }
  });
}

export const sagas = [
  pesquisar.watcher(),
  carregarEmpresas.watcher(),
  carregarDispositivos.watcher(),
  atualizarFiltrosWatcher(),
  mapRouteWatcher()
];
