import React from "react";
import { Controller } from "react-hook-form";
import ColorPicker from "./ColorPicker";

const ControlledColorPicker = ({
  formProps,
  name,
  ignoreError = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined && !ignoreError;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value } }) => (
        <ColorPicker
          {...otherProps}
          value={value}
          error={isError}
          helperText={!ignoreError ? errors[name]?.message : undefined}
          onChange={(v) => {
            onChange(v);
            if (!!otherProps.onChange) {
              otherProps.onChange(v);
            }
          }}
          onBlur={() => {
            onBlur();
            if (!!otherProps.onBlur) {
              otherProps.onBlur();
            }
          }}
        />
      )}
    />
  );
};

export default ControlledColorPicker;
