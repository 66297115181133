import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  StreetViewPanorama,
} from "@react-google-maps/api";
import FilterButtons from "./FilterButtons.connected";
import BottomDrawer from "./BottomDrawer.connected";
import CheckpointsPendentes from "./CheckpointsPendentes.connected";
import CheckpointsEfetivados from "./CheckpointsEfetivados.connected";
import PosicoesValidas from "./PosicoesValidas.connected";
import UltimaPosicaoValida from "./UltimaPosicaoValida.connected";
import KmlLayer from "./KmlLayer.connected";
import SearchAddressInputText from "./SearchAddressInputText.connected";

const mapStyle = {
  inset: 0,
  position: "absolute",
};
const libraries = ["places"];

const GoogleMaps = ({ selecionado, position }) => {
  const [zoom] = useState(15);
  const [mapType] = useState("roadmap");
  const [mapInstance, setMapInstance] = useState(null);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      <GoogleMap
        mapContainerStyle={mapStyle}
        center={position}
        mapTypeId={mapType}
        onLoad={(m) => setMapInstance(m)}
        zoom={zoom}
        options={{
          disableDefaultUI: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
            mapTypeIds: [
              window.google?.maps?.MapTypeId?.ROADMAP,
              window.google?.maps?.MapTypeId?.SATELLITE,
            ],
          },
          zoomControl: true,
          zoomControlOptions: {
            position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
          },
        }}
      >
        <StreetViewPanorama
          options={{
            fullscreenControl: false,
            addressControlOptions: {
              position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
            },
            zoomControlOptions: {
              position: window.google?.maps?.ControlPosition?.RIGHT_CENTER,
            },
            panControl: false,
          }}
        />
        {!!selecionado && <FilterButtons selecionado={selecionado} />}

        <KmlLayer />
        <CheckpointsPendentes />
        <CheckpointsEfetivados />
        <PosicoesValidas />
        <UltimaPosicaoValida />
        <SearchAddressInputText mapInstance={mapInstance} />
        <BottomDrawer />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMaps;
