import { createSelector } from "reselect";

export const getMapa = (state) => state.mapa;
export const getDominios = createSelector(getMapa, (state) => state.dominios);
export const getOpcoesMapa = createSelector(getMapa, (state) => state.opcoes);
export const getFiltros = createSelector(getMapa, (state) => state.filtros);
export const getDrawer = createSelector(getMapa, (state) => state.drawer);
export const getBotao = createSelector(getMapa, (state) => state.botao);
export const getSelecionado = createSelector(
  getMapa,
  (state) => state.selecionado
);
export const getPosicao = createSelector(getMapa, (state) => state.posicao);

export const getDominioEmpresas = createSelector(
  getDominios,
  (state) => state.empresas
);
export const getDominioDispositivos = createSelector(
  getDominios,
  (state) => state.dispositivos
);
export const getDominioGrupos = createSelector(
  getDominios,
  (state) => state.grupos
);

export const isLoadingItems = createSelector(getMapa, (state) => state.loading);
export const isUpdatingItem = createSelector(
  getMapa,
  (state) => state.updating
);
export const getItems = createSelector(getMapa, (state) => state.items);
export const getTotalItems = createSelector(getMapa, (state) => state.total);
export const getTotalSemMensagemItems = createSelector(
  getMapa,
  (state) => state.totalSemMensagem
);

export const getCenter = (state) => {
  const selecionado = getSelecionado(state);

  if (!!selecionado) {
    const primeiroCheckpoint = selecionado.posicoes.find((p) => p.tipo === "C");

    if (!!primeiroCheckpoint) {
      return primeiroCheckpoint.longitudeLatitude;
    }

    const primeiroPonto = selecionado.posicoes.find((p) => p.tipo === "P");

    if (!!primeiroPonto) {
      return primeiroPonto.longitudeLatitude;
    }
  }

  return state.usuario.position;
};
export const getRoteiro = createSelector(getMapa, (state) => state.roteiro);
export const getOs = createSelector(getMapa, (state) => state.os);
