import { types, actions } from "./mapaExecucao.actions";
import { types as authTypes } from "./autenticacao.actions";

const initialState = {
  dominios: {
    empresas: {
      loading: true,
      data: [],
    },
    dispositivos: {
      loading: true,
      data: [],
    },
    grupos: {
      loading: true,
      data: [],
    },
  },
  opcoes: {
    percurso: false,
    efetivo: false,
    pendente: false,
    atual: false,
  },
  filtros: {
    page: 1,
    mesAno: new Date(),
    mesAnoString: `${new Date().getFullYear()}-${
      new Date().getMonth() > 8
        ? new Date().getMonth() + 1
        : "0" + (new Date().getMonth() + 1)
    }`,
    empresa: null,
    empresaNome: "",
    os: "",
    roteiro: "",
    dispositivo: null,
    grupo: null,
    minCheckpoints: "",
    maxCheckpoints: "",
    funcionamento: [],
    ordem: "inicio",
  },
  drawer: {
    filters: false,
    showMore: false,
  },
  loading: false,
  updating: false,
  items: [],
  total: 0,
  selecionado: null,
  roteiro: {
    loading: false,
    file_name: "",
    file: "",
  },
  os: {
    loading: false,
    file_name: "",
    file: "",
  },
  order: {
    field: "roteiro",
    direction: "asc",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case types.SELECIONAR_OPCOES:
      return {
        ...state,
        opcoes: {
          ...state.opcoes,
          [action.payload.propriedade]: action.payload.valor,
        },
      };
    case types.UPDATE_ORDER:
      return {
        ...state,
        order: {
          field: action.payload.field,
          direction:
            action.payload.field === state.order.field
              ? state.order.direction === "asc"
                ? "desc"
                : "asc"
              : action.payload.field === "roteiro"
              ? "asc"
              : "desc",
        },
      };
    case types.ALTERNAR_MAIS_CARDS:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          showMore: !state.drawer.showMore,
        },
      };
    case types.EXIBIR_FILTROS:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          filters: action.payload.visible,
        },
      };
    case types.ATUALIZAR_FILTROS:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          page: 1,
          ...action.payload.filtros,
        },
      };
    case types.SELECIONAR_OS:
      return {
        ...state,
        selecionado: action.payload.item,
      };
    case actions.pesquisar.REQUEST:
    case actions.pesquisar.SUCCESS:
    case actions.pesquisar.FAILURE:
      return {
        ...state,
        loading: action.type === actions.pesquisar.REQUEST,
        items:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.items
            : state.items,
        total:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.total
            : state.total,
      };
    case actions.proximaPagina.REQUEST:
    case actions.proximaPagina.SUCCESS:
    case actions.proximaPagina.FAILURE:
      return {
        ...state,
        items:
          action.type === actions.proximaPagina.SUCCESS
            ? [...state.items, ...action.payload.response.data.items]
            : state.items,
        filtros: {
          ...state.filtros,
          page:
            action.type === actions.proximaPagina.SUCCESS
              ? state.filtros.page + 1
              : state.filtros.page,
        },
      };
    case actions.reiniciarPagina.REQUEST:
    case actions.reiniciarPagina.SUCCESS:
    case actions.reiniciarPagina.FAILURE:
      return {
        ...state,
        items:
          action.type === actions.reiniciarPagina.SUCCESS
            ? [...action.payload.response.data.items]
            : state.items,
        filtros: {
          ...state.filtros,
          page: 1,
        },
      };
    case actions.carregarEmpresas.REQUEST:
    case actions.carregarEmpresas.SUCCESS:
    case actions.carregarEmpresas.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          empresas: {
            ...state.dominios.empresas,
            loading: action.type === actions.carregarEmpresas.REQUEST,
            data:
              action.type === actions.carregarEmpresas.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.empresas.data,
          },
        },
      };
    case actions.carregarDispositivos.REQUEST:
    case actions.carregarDispositivos.SUCCESS:
    case actions.carregarDispositivos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          dispositivos: {
            ...state.dominios.dispositivos,
            loading: action.type === actions.carregarDispositivos.REQUEST,
            data:
              action.type === actions.carregarDispositivos.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.dispositivos.data,
          },
        },
      };
    case actions.carregarGrupos.REQUEST:
    case actions.carregarGrupos.SUCCESS:
    case actions.carregarGrupos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          grupos: {
            ...state.dominios.grupos,
            loading: action.type === actions.carregarGrupos.REQUEST,
            data:
              action.type === actions.carregarGrupos.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.dispositivos.data,
          },
        },
      };
    case actions.excluirPosicao.SUCCESS:
      return {
        ...state,
        items: state.items.map((i) => {
          if (i.id === state.selecionado.id) {
            return {
              ...i,
              percentualEfetivo:
                i.posicoes.length > 1
                  ? (i.percentualEfetivo * i.posicoes.length) /
                    (i.posicoes.length - 1)
                  : 0,
              posicoes: i.posicoes.filter(
                (p) => p.id !== action.payload.values.id
              ),
            };
          } else {
            return i;
          }
        }),
        selecionado: {
          ...state.selecionado,
          percentualEfetivo:
            state.selecionado.posicoes.length > 1
              ? (state.selecionado.percentualEfetivo *
                  state.selecionado.posicoes.length) /
                (state.selecionado.posicoes.length - 1)
              : 0,
          posicoes: state.selecionado.posicoes.filter(
            (p) => p.id !== action.payload.values.id
          ),
        },
      };
    case actions.atualizarOs.REQUEST:
    case actions.atualizarOs.SUCCESS:
    case actions.atualizarOs.FAILURE:
      return {
        ...state,
        updating: action.type === actions.atualizarOs.REQUEST,
        selecionado:
          action.type === actions.atualizarOs.SUCCESS
            ? action.payload.response.data
            : action.type === actions.atualizarOs.REQUEST
            ? {
                ...state.selecionado,
                arquivoKml: "",
                arquivoKmlOriginal: state.selecionado.arquivoKml,
              }
            : {
                ...state.selecionado,
                arquivoKml: state.selecionado.arquivoKml,
                arquivoKmlOriginal: undefined,
              },
        items:
          action.type === actions.atualizarOs.SUCCESS
            ? state.items.map((i) =>
                i.id === action.payload.values.id
                  ? action.payload.response.data
                  : i
              )
            : state.items,
      };
    case actions.downloadRoteiro.REQUEST:
    case actions.downloadRoteiro.SUCCESS:
    case actions.downloadRoteiro.FAILURE:
      return {
        ...state,
        roteiro: {
          loading: action.type === actions.downloadRoteiro.REQUEST,
          file_name:
            action.type === actions.downloadRoteiro.SUCCESS
              ? action.payload.response.data.file_name
              : "",
          file:
            action.type === actions.downloadRoteiro.SUCCESS
              ? action.payload.response.data.file
              : "",
        },
      };
    case types.CLEAN_ROTEIRO_DATA:
      return {
        ...state,
        roteiro: {
          loading: false,
          file_name: "",
          file: "",
        },
      };
    case actions.downloadOs.REQUEST:
    case actions.downloadOs.SUCCESS:
    case actions.downloadOs.FAILURE:
      return {
        ...state,
        os: {
          loading: action.type === actions.downloadOs.REQUEST,
          file_name:
            action.type === actions.downloadOs.SUCCESS
              ? action.payload.response.data.file_name
              : "",
          file:
            action.type === actions.downloadOs.SUCCESS
              ? action.payload.response.data.file
              : "",
        },
      };
    case types.CLEAN_OS_DATA:
      return {
        ...state,
        os: {
          loading: false,
          file_name: "",
          file: "",
        },
      };
    default:
      return state;
  }
};

export default reducer;
