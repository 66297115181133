import { createSelector } from "reselect";

export const getBateriaState = (state) => state.baterias;

export const getBaterias = createSelector(
  getBateriaState,
  (state) => state.baterias
);

export const getCSV = createSelector(getBateriaState, (state) => state.csv);

export const getBateria = createSelector(
  getBateriaState,
  (state) => state.bateria
);

export const getBateriasPage = createSelector(
  getBateriaState,
  (state) => state.baterias.page
);
export const getBateriasPageSize = createSelector(
  getBateriaState,
  (state) => state.baterias.pageSize
);
export const getBateriasOrder = createSelector(
  getBateriaState,
  (state) => state.baterias.order
);

export const getBateriasFilter = createSelector(
  getBateriaState,
  (state) => state.baterias.filter
);

export const getBateriasCliente = createSelector(
  getBateriaState,
  (state) => state.baterias.cliente
);

export const getDominios = createSelector(
  getBateriaState,
  (state) => state.dominios
);

export const getDominioEmpresas = createSelector(
  getDominios,
  (state) => state.empresas
);
export const getDominioDispositivos = createSelector(
  getDominios,
  (state) => state.dispositivos
);
