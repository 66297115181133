import { types, actions } from "./painelAlerta.actions";
import { types as authTypes } from "./autenticacao.actions";

const initialState = {
  dominios: {
    empresas: {
      loading: true,
      data: [],
    },
    dispositivos: {
      loading: true,
      data: [],
    },
    regras: {
      loading: false,
      data: [],
    },
  },
  opcoes: {
    percurso: false,
    efetivo: false,
    pendente: false,
    atual: false,
  },
  filtros: {
    page: 1,
    inicioPeriodo: null,
    fimPeriodo: null,
    empresa: null,
    empresaNome: "",
    os: "",
    roteiro: "",
    dispositivo: null,
    regras: null,
    tipoAlerta: "",
    ordem: "inicio",
    pageSize: 25
  },
  drawer: {
    filters: false,
    showMore: false,
  },
  loading: false,
  updating: false,
  items: [],
  page: 1,
  pageSize: 25,
  total: 0,
  selecionado: null,
  roteiro: {
    loading: false,
    file_name: "",
    file: "",
  },
  os: {
    loading: false,
    file_name: "",
    file: "",
  },
  order: {
    field: "data",
    direction: "asc",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case types.UPDATE_ORDER:
      return {
        ...state,
        order: {
          field: action.payload.field,
          direction:
            action.payload.field === state.order.field
              ? state.order.direction === "asc"
                ? "desc"
                : "asc"
              : action.payload.field === "roteiro"
              ? "asc"
              : "desc",
        },
      };
    case types.EXIBIR_FILTROS:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          filters: action.payload.visible,
        },
      };
    case types.ATUALIZAR_FILTROS:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          page: 1,
          ...action.payload.filtros,
        },
      };
    case types.UPDATE_PAGE_SIZE:
      return {
        ...state,
        baterias: {
          ...state.baterias,
          pageSize: action.payload.pageSize,
        },
      };
    case actions.pesquisar.REQUEST:
    case actions.pesquisar.SUCCESS:
    case actions.pesquisar.FAILURE:
      return {
        ...state,
        loading: action.type === actions.pesquisar.REQUEST,
        items:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.items
            : state.items,
        total:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.paginaAtual
            : state.page,
      };

    case actions.carregarEmpresas.REQUEST:
    case actions.carregarEmpresas.SUCCESS:
    case actions.carregarEmpresas.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          empresas: {
            ...state.dominios.empresas,
            loading: action.type === actions.carregarEmpresas.REQUEST,
            data:
              action.type === actions.carregarEmpresas.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.empresas.data,
          },
        },
      };
    case actions.carregarDispositivos.REQUEST:
    case actions.carregarDispositivos.SUCCESS:
    case actions.carregarDispositivos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          dispositivos: {
            ...state.dominios.dispositivos,
            loading: action.type === actions.carregarDispositivos.REQUEST,
            data:
              action.type === actions.carregarDispositivos.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.dispositivos.data,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
