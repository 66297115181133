import { useForm } from "react-hook-form";
import FormCriarClientePage from "./FormCriarClientePage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import {
  getCliente,
  getMatrizes,
} from "../../../selectors/cadastro/clientes.selectors";
import formatters from "../../../utils/formatters";
import {
  removeNonDigitsFromString,
  validateLatLng,
} from "../../../utils/basic";
import { actions } from "../../../reducers/cadastro/clientes.actions";

const FormCriarClientePageConnected = () => {
  const { loading } = useSelector(getCliente);
  const { data: dataMatrizes, loading: loadingMatrizes } = useSelector(
    getMatrizes
  );
  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    reference: validators.string({ required: true }),
    cep: validators.string({ required: true, length: 9 }),
    logradouro: validators.string({ required: true }),
    numeroLogradouro: validators.string({ required: true }),
    complemento: validators.string({ required: false }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),
    cnpj: validators.cnpj({ required: true }),
    tipo: validators.string({ required: true }),
    idMatriz: validators.number({
      custom: {
        requiredIfOther: (value) => {
          const matriz = formProps.getValues("tipo");

          if (matriz === "F") {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
    pontosRegiao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    raioPrecisao: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          } else {
            return true;
          }
        },
      },
    }),
    latitude: validators.string({
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return "Campo obrigatório";
          }
          if (isNaN(value * 1)) {
            return "Latitude inválida";
          }
          if (!validateLatLng(value)) {
            return "Latitude inválida";
          } else {
            return true;
          }
        },
      },
    }),
    longitude: validators.string({
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return !!value || "Campo obrigatório";
          }
          if (isNaN(value * 1)) {
            return "Longitude inválida";
          }
          if (!validateLatLng(value)) {
            return !!value || "Longitude inválida";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const initialValues = {
    bairro: "",
    cep: "",
    cnpj: "",
    complemento: "",
    dataHoraAtualizacao: "",
    dataHoraCriacao: "",
    idMatriz: "",
    logradouro: "",
    lote: "",
    municipio: "",
    nome: "",
    nomeAbreviado: "",
    numeroLogradouro: "",
    pontosRegiao: "",
    raioPrecisao: "",
    roteiro: true,
    situacao: "A",
    tipo: "M",
    uf: "",
    id: "",
    latitude: "",
    longitude: "",
    precisao: "",
    exibeMapaProUsuarioFinal: false,
    exibeOpcaoPercursoOperacaoDiaria: false,
    exibeOpcaoEfetivoOperacaoDiaria: false,
  };
  const defaultValues = {
    nome: "",
    situacao: "A",
    dataHoraCriacao: formatters.dates.withHours(new Date()),
    dataHoraAtualizacao: formatters.dates.withHours(new Date()),
    uf: "",
    tipo: "M",
    roteiro: true,
    exibeMapaProUsuarioFinal: false,
    exibeOpcaoPercursoOperacaoDiaria: false,
    exibeOpcaoEfetivoOperacaoDiaria: false,
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      const matriz = formProps.getValues("tipo");
      if (matriz === "M") values.idMatriz = null;
      const roteiro = formProps.getValues("roteiro");
      const exibeMapaProUsuarioFinal = formProps.getValues(
        "exibeMapaProUsuarioFinal"
      );
      const exibeOpcaoPercursoOperacaoDiaria = formProps.getValues(
        "exibeOpcaoPercursoOperacaoDiaria"
      );
      const exibeOpcaoEfetivoOperacaoDiaria = formProps.getValues(
        "exibeOpcaoEfetivoOperacaoDiaria"
      );
      values.exibeMapaProUsuarioFinal = exibeMapaProUsuarioFinal ? "S" : "N";
      values.exibeOpcaoPercursoOperacaoDiaria = exibeOpcaoPercursoOperacaoDiaria
        ? "S"
        : "N";
      values.exibeOpcaoEfetivoOperacaoDiaria = exibeOpcaoEfetivoOperacaoDiaria
        ? "S"
        : "N";
      values.idMatriz = values?.idMatriz?.id ?? null;
      values.roteiro = roteiro ? "S" : "N";
      values.cnpj = removeNonDigitsFromString(values.cnpj);
      values.cep = removeNonDigitsFromString(values.cep);
      dispatch(actions.criarCliente.request(values));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_CLIENTE));
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue("logradouro", data?.logradouro || "", { shouldValidate: true });
      setValue("bairro", data?.bairro || "", { shouldValidate: true });
      setValue("uf", data?.uf || "", { shouldValidate: true });
      setValue("municipio", data?.localidade || "", { shouldValidate: true });
    },
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCep.request({
          value,
          successCallback,
        })
      );
    }
  };

  return (
    <FormCriarClientePage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      handleZipCodeBlurred={handleZipCodeBlurred}
      dataMatrizes={dataMatrizes}
      loadingMatrizes={loadingMatrizes}
    />
  );
};

export default FormCriarClientePageConnected;
