import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../reducers/mapaExecucao.actions";
import { getFiltros, getOs } from "../../../selectors/mapaExecucao.selectors";
import { meses } from "../constants/mapaExecucao.constants";
import Header from "./Header";

const HeaderConnected = ({ mesAtual, setMesAtual }) => {
  const dispatch = useDispatch();
  const filtros = useSelector(getFiltros);
  const osFile = useSelector(getOs);

  const handleClickOpenFilters = useCallback(() => {
    dispatch(actions.exibirFiltros(true));
  }, [dispatch]);

  const handleDownloadCsv = useCallback(() => {
    dispatch(actions.downloadOs.request());
  }, [dispatch]);

  const handleCleanOsData = useCallback(() => {
    dispatch(actions.cleanOsData());
  }, [dispatch]);

  return (
    <Header
      handleDownloadCsv={handleDownloadCsv}
      cliente={
        filtros?.empresa?.nome ? filtros?.empresa?.nome : filtros.empresaNome
      }
      mesAtual={filtros.mesAno}
      mesAno={
        meses[filtros.mesAno?.getMonth()] + "/" + filtros.mesAno?.getFullYear()
      }
      handleClickOpenFilters={handleClickOpenFilters}
      loading={osFile.loading}
      osFile={osFile}
      handleCleanOsData={handleCleanOsData}
    />
  );
};

export default HeaderConnected;
