import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/baterias.actions";
import { getBateriasPageSize } from "../../selectors/baterias.selectors";
import PageSizeSelector from "../../components/PageSizeSelector";
import { styled } from "@mui/material";

const sizes = [25, 50, 100];

const StyledPageSizeSelector = styled(PageSizeSelector)`
  position: absolute;
  right: 1.5rem;
  top: 0.75rem;
`;
const PageSizeSelectorConnected = () => {
  const dispatch = useDispatch();
  const currentPageSize = useSelector(getBateriasPageSize);

  const options = useMemo(() => {
    return sizes.map((s) => ({ value: s, selected: s === currentPageSize }));
  }, [currentPageSize]);

  const handleClick = useCallback(
    (value) => {
      dispatch(actions.atualizarTamanhoPagina(value));
    },
    [dispatch]
  );

  return <StyledPageSizeSelector handleClick={handleClick} options={options} />;
};

export default PageSizeSelectorConnected;
