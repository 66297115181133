import { actions, types } from "./feriados.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  id: null,
  feriados: {
    loading: false,
    data: [],
    page: 1,
    filter: "",
    cliente: "",
  },
  feriado: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterFeriados.REQUEST:
    case actions.obterFeriados.SUCCESS:
    case actions.obterFeriados.FAILURE:
      return {
        ...state,
        feriados: {
          ...state.feriados,
          loading: action.type === actions.obterFeriados.REQUEST,
          data:
            action.type === actions.obterFeriados.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterFeriados.SUCCESS
              ? action.payload?.response?.data?.filtroAtual ?? ""
              : "",
          cliente:
            action.type === actions.obterFeriados.SUCCESS
              ? action.payload?.response?.data?.cliente ?? ""
              : "",
          nomeCliente:
            action.type === actions.obterFeriados.SUCCESS
              ? action.payload?.response?.data?.nomeCliente ?? ""
              : "",
          page:
            action.type === actions.obterFeriados.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case types.REMOVER_FILTRO_FERIADO:
      return {
        ...state,
        feriados: {
          ...state.feriados,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_FERIADO:
      return {
        ...state,
        feriados: {
          ...state.feriados,
          page: 1,
        },
      };

    case actions.editarFeriado.REQUEST:
    case actions.editarFeriado.SUCCESS:
    case actions.editarFeriado.FAILURE:
      return {
        ...state,
        feriado: {
          ...state.feriado,
          loading: action.type === actions.editarFeriado.REQUEST,
          data:
            action.type === actions.editarFeriado.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case routes.EDITAR_FERIADO:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.mostrarFeriado.REQUEST:
    case actions.mostrarFeriado.SUCCESS:
    case actions.mostrarFeriado.FAILURE:
      return {
        ...state,
        feriado: {
          ...state.feriado,
          loading: action.type === actions.mostrarFeriado.REQUEST,
          data:
            action.type === actions.mostrarFeriado.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarFeriado.REQUEST:
    case actions.criarFeriado.SUCCESS:
    case actions.criarFeriado.FAILURE:
      return {
        ...state,
        feriado: {
          ...state.feriado,
          loading: action.type === actions.criarFeriado.REQUEST,
          data:
            action.type === actions.criarFeriado.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.deletarFeriado.REQUEST:
    case actions.deletarFeriado.SUCCESS:
    case actions.deletarFeriado.FAILURE:
      return {
        ...state,
        feriado: {
          ...state.feriado,
          loading: action.type === actions.deletarFeriado.REQUEST,
        },
      };

    default:
      return state;
  }
};

export default reducer;
