import React from "react";
import { useSelector } from "react-redux";
import CheckpointsEfetivados from "./CheckpointsEfetivados";
import { getItems, getOpcoesMapa } from "../../../selectors/diario.selectors";

const CheckpointsEfetivadosConnected = () => {
  const items = useSelector(getItems);
  const { efetivo } = useSelector(getOpcoesMapa);

  const points =
    !!efetivo && !!items
      ? items.map((i) =>
          i.posicoes.filter((p) => p.tipo === "C" && p.situacao === "E")
        )
      : [];

  const result = points.reduce((a, c) => {
    if (c.length > 0) {
      return [...a, ...c];
    } else {
      return a;
    }
  }, []);

  result.sort((a, b) => {
    const d1 = new Date(a.horarioPosicao);
    const d2 = new Date(b.horarioPosicao);

    if (d1.getTime() > d2.getTime()) {
      return 1;
    }
    if (d1.getTime() < d2.getTime()) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    return 0;
  });

  return <CheckpointsEfetivados points={result} />;
};

export default CheckpointsEfetivadosConnected;
