import React from "react";
import BasePage from "../BasePage";
import GoogleMaps from "./components/GoogleMaps.connected";
import FiltersDrawer from "./components/FiltersDrawer.connected";

function MapaDiarioPage({ position }) {
  return (
    <BasePage isLoggedIn={true}>
      <GoogleMaps position={position} />
      <FiltersDrawer />
    </BasePage>
  );
}

export default MapaDiarioPage;
