import basicFlow from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as dispositivos } from "../../reducers/cadastro/dispositivos.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getDispositivosFilter,
  getDispositivosPage,
} from "../../selectors/cadastro/dispositivos.selectors";

const ListarDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/dispositivo/listar`,
    isResourceService: true,
    method: "post",
    body: {
      nome: values?.nome ?? "",
      pagina: values?.pagina ?? 1,
      limite: 9,
    },
  });
};

const ListarDispositivos = basicFlow({
  actionGenerator: dispositivos.obterDispositivos,
  api: ListarDispositivosApi,
});

function* ListarDispositivosRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_DISPOSITIVO, function* () {
    const pagina = yield select(getDispositivosPage);
    const nome = yield select(getDispositivosFilter);
    yield put(dispositivos.obterDispositivos.request({ pagina, nome }));
  });
}

const EditarDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/dispositivo/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const EditarDispositivos = basicFlow({
  actionGenerator: dispositivos.editarDispositivo,
  api: EditarDispositivosApi,
  postSuccess: function* () {
    yield toast.success("Dispositivo atualizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.CADASTRO_DISPOSITIVO));
    const pagina = yield select(getDispositivosPage);
    const nome = yield select(getDispositivosFilter);
    yield put(dispositivos.obterDispositivos.request({ pagina, nome }));
  },
});

const ReativarDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/dispositivo/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: values.situacao,
    },
  });
};

const ReativarDispositivos = basicFlow({
  actionGenerator: dispositivos.reativarDispositivo,
  api: ReativarDispositivosApi,
  postSuccess: function* () {
    yield toast.success("Dispositivo reativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(dispositivos.obterDispositivos.request());
  },
});

const criarDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/dispositivo`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const CriarDispositivos = basicFlow({
  actionGenerator: dispositivos.criarDispositivo,
  api: criarDispositivosApi,
  postSuccess: function* () {
    yield toast.success("Dispositivo criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.CADASTRO_DISPOSITIVO));
  },
});

const deletarDispositivosApi = (values) => {
  return authenticatedRequest({
    url: `/dispositivo/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: "I",
    },
  });
};

const DeletarDispositivos = basicFlow({
  actionGenerator: dispositivos.deletarDispositivo,
  api: deletarDispositivosApi,
  postSuccess: function* () {
    yield toast.success("Dispositivo inativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(dispositivos.obterDispositivos.request());
  },
});

const MostrarDispositivoApi = (values) => {
  return authenticatedRequest({
    url: `/dispositivo/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarDispositivo = basicFlow({
  actionGenerator: dispositivos.mostrarDispositivo,
  transform: function* (payload) {
    const id = yield select((state) => state.dispositivos.id);
    return { id, ...payload };
  },

  api: MostrarDispositivoApi,
});

function* MostrarDispositivoRouteWatcher() {
  yield routeWatcher(routes.EDITAR_DISPOSITIVO, function* () {
    const dispositivo = yield select(getPayload);

    yield put(dispositivos.mostrarDispositivo.request({ id: dispositivo.id }));
  });
}

export const sagas = [
  MostrarDispositivoRouteWatcher(),
  ListarDispositivosRouteWatcher(),
  ListarDispositivos.watcher(),
  MostrarDispositivo.watcher(),
  EditarDispositivos.watcher(),
  CriarDispositivos.watcher(),
  DeletarDispositivos.watcher(),
  ReativarDispositivos.watcher(),
];
