import React from "react";
import { styled, Tooltip } from "@mui/material";
import { SportsScoreOutlined, TimerOutlined } from "@mui/icons-material";
import { transientOptions } from "../../../styles/theme";
import { FlexContainer } from "../../../components/styled";
import formatters from "../../../utils/formatters";
import { convertMinutesInHours } from "../../../utils/basic";
import BatterryIcon from "./BatteryIcon";
import AlertIcon from "./AlertIcon";

const Container = styled(FlexContainer, transientOptions)`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: 12px;
  height: 154px;
  padding: 10px;
  cursor: pointer;
`;
const ItineraryTitle = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray._300};
`;
const ItineraryInside = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 800;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
  white-space: nowrap;
  overflow: hidden;
  align-self: flex-end;
  text-overflow: ellipsis;
  margin-left: 3px;
`;
const Itinerary = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.large};
  font-weight: 800;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
  white-space: nowrap;
  align-self: flex-end;
`;
const Company = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._600};
`;

const MovementContainer = styled(FlexContainer)`
  margin-top: 0.75rem;
`;

const IconContainer = styled(FlexContainer, transientOptions)`
  color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  height: 32px;
  width: 32px;
  background: ${({ theme, __disabled }) =>
    __disabled
      ? theme.colors.gradients.iconDisabled
      : theme.colors.gradients.icon};
`;
const MovementDate = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
`;
const MovementHour = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 600;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
`;
const MovementTextContainer = styled(FlexContainer)`
  margin-left: 0.5rem;
`;

const TimeDiff = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.dark};
`;

const ProgressBarContainer = styled(FlexContainer)`
  margin-top: auto;
  position: relative;
`;
const ProgressBarText = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 600;
  color: ${({ theme, __disabled }) =>
    !!__disabled ? theme.colors.red._800 : theme.colors.green._800};
`;
const ProgressBarFill = styled("div")`
  border-radius: 100px;
  height: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray._500};
`;
const ProgressBarVariable = styled("div", transientOptions)`
  position: absolute;
  bottom: 0;
  border-radius: 100px;
  height: 10px;
  width: ${({ __width }) => __width}%;
  background-color: ${({ theme }) => theme.colors.green._300};
`;

const ItineraryContainer = styled("div")`
  white-space: nowrap;
  overflow: hidden;
  align-self: flex-end;
  text-overflow: ellipsis;
`;

const IconsContainer = styled(FlexContainer)`
  align-items: center;
`;

const ItemCard = ({ item, isUserEnd, handleClick }) => {
  return (
    <Container __direction={"column"} onClick={handleClick}>
      <FlexContainer
        __justifyContent={"space-between"}
        __alignItems={"flex-start"}
      >
        <ItineraryTitle>Roteiro</ItineraryTitle>
        <Company>{item.empresa}</Company>
      </FlexContainer>
      <FlexContainer __direction={"row"} __justifyContent={"space-between"}>
        <ItineraryContainer>
          <Itinerary __disabled={item.inicioEfetivo === null}>
            {item.roteiro.nome}
          </Itinerary>
          <ItineraryInside __disabled={item.inicioEfetivo === null}>
            ({item.roteiro.dispositivo.nome})
          </ItineraryInside>
        </ItineraryContainer>

        <IconsContainer>
          <AlertIcon
            display={item.possuiAlertaFaltaMensagem === "S"}
            message={"Alerta de falta de mensagens"}
          />
          <BatterryIcon
            nivel={
              !item.inicioEfetivo ? "Z" : item.roteiro.dispositivo.nivelBateria
            }
          />
        </IconsContainer>
      </FlexContainer>
      <MovementContainer __direction={"column"} __gap={"8px"}>
        <FlexContainer>
          <MovementSection
            disabled={!item.inicioEfetivo}
            isUserEnd={isUserEnd}
            title={
              !!item.inicioEfetivo ? "Início da Operação" : "Início Previsto"
            }
            date={new Date(item.inicioEfetivo ?? item.inicioPrevisto)}
            icon={<TimerOutlined />}
          />
          <MovementSection
            disabled={!item.inicioEfetivo}
            isUserEnd={isUserEnd}
            title={
              !!item.inicioEfetivo ? "Última atualização" : "Término Previsto"
            }
            date={new Date(item.ultimaPosicao ?? item.terminoPrevisto)}
            icon={<SportsScoreOutlined />}
          />
        </FlexContainer>
        {!isUserEnd && !!item.inicioEfetivo && (
          <TimeDiff>{item.tempoExecucao}</TimeDiff>
        )}
      </MovementContainer>
      {item.roteiro.empresa.roteiro === "S" && (
        <ProgressBarSection item={item} />
      )}
    </Container>
  );
};

const MovementSection = ({ title, icon, date, isUserEnd, disabled }) => {
  return (
    <FlexContainer __flex={1}>
      <Tooltip title={title}>
        <IconContainer
          __justifyContent={"center"}
          __alignItems={"center"}
          __disabled={disabled}
        >
          {icon}
        </IconContainer>
      </Tooltip>
      <MovementTextContainer __direction={"column"}>
        <MovementDate __disabled={disabled}>
          {formatters.dates.short(date)}
        </MovementDate>
        {!isUserEnd && (
          <MovementHour __disabled={disabled}>
            {formatters.dates.onlyHours(date)}
          </MovementHour>
        )}
      </MovementTextContainer>
    </FlexContainer>
  );
};

const ProgressBarSection = ({ item }) => {
  const checkpoints = item.posicoes.filter((p) => p.tipo === "C");
  const checkpointsAtivados = checkpoints.filter((p) => p.situacao === "E");
  return (
    <ProgressBarContainer __direction={"column"} __gap={"4px"}>
      <ProgressBarText __disabled={item.inicioEfetivo === null}>
        Progresso: {checkpointsAtivados.length}/{checkpoints.length} -{" "}
        {!!item.inicioEfetivo
          ? `${formatters.numbers.nFixed(item.percentualEfetivo, 2)}%`
          : "Não Iniciado"}
      </ProgressBarText>
      <ProgressBarFill />
      {!!item.inicioEfetivo && checkpoints.length > 0 && (
        <ProgressBarVariable __width={item.percentualEfetivo.toFixed(0)} />
      )}
    </ProgressBarContainer>
  );
};

const getDiffInMinutes = (a, b) => {
  const m = new Date(a).getTime() - new Date(b).getTime();
  return Math.floor(m / 1000 / 60);
};

export default ItemCard;
