import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_USUARIOS: "@@usuarios/OBTER_USUARIOS",
  MOSTRAR_USUARIO: "@@usuarios/MOSTRAR_USUARIO",
  EDITAR_USUARIO: "@@usuarios/EDITAR_USUARIO",
  CRIAR_USUARIO: "@@usuarios/CRIAR_USUARIO",
  DELETAR_USUARIO: "@@usuarios/DELETAR_USUARIO",
  REATIVAR_USUARIO: "@@usuarios/REATIVAR_USUARIO",
  REMOVER_FILTRO_USUARIO: "@@usuarios/REMOVER_FILTRO_USUARIO",
  REMOVER_PAGINA_USUARIO: "@@usuarios/REMOVER_PAGINA_USUARIO",
};

export const actions = {
  obterUsuarios: createAsyncAction(types.OBTER_USUARIOS),
  mostrarUsuario: createAsyncAction(types.MOSTRAR_USUARIO),
  editarUsuario: createAsyncAction(types.EDITAR_USUARIO),
  criarUsuario: createAsyncAction(types.CRIAR_USUARIO),
  deletarUsuario: createAsyncAction(types.DELETAR_USUARIO),
  reativarUsuario: createAsyncAction(types.REATIVAR_USUARIO),
  removerFiltroUsuario: (status) =>
    createSyncAction(types.REMOVER_FILTRO_USUARIO, status),
  removerPaginaUsuario: (status) =>
    createSyncAction(types.REMOVER_PAGINA_USUARIO, status),
};
