import React from "react";
import { Grid, styled } from "@mui/material";
import { Card, GradientButton } from "../../../components/styled";
import ControlledCustomTextField from "../../../components/inputs/ControlledCustomTextField";
import GridWithMargin from "../../../components/styled/GridWithMargin.styled";

const StyledControlledCustomTextField = styled(ControlledCustomTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const CardGeracaoOs = ({ formProps, handleSubmit, processing }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Grid container>
          <Grid item xs={12} md={6} lg={3}>
            <StyledControlledCustomTextField
              formProps={formProps}
              label={"Data da Ordem de Serviço"}
              name={"data"}
              type="date"
            />
          </Grid>
        </Grid>
      </Card>
      <GridWithMargin
        __margin={"1rem 0 0 0"}
        container
        justifyContent={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <GradientButton type={"submit"} loading={processing}>
            PROCESSAR
          </GradientButton>
        </Grid>
      </GridWithMargin>
    </form>
  );
};

export default CardGeracaoOs;
