import React from "react";
import { Grid } from "@mui/material";
import BasePage from "../../BasePage";
import { Title } from "../../../components/styled";
import CardGeracaoOs from "./CardGeracaoOs";

const GeracaoOsPage = (props) => {
  return (
    <BasePage isLoggedIn={true}>
      <Title>Geração de OS</Title>
      <Grid container>
        <Grid item xs={12}>
          <CardGeracaoOs {...props} />
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default GeracaoOsPage;
