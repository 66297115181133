import React from "react";
import { Button, IconButton, styled } from "@mui/material";
import { FlexContainer } from "../../../components/styled";
import { transientOptions } from "../../../styles/theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledButton = styled(Button, transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.buttons};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 800;
  width: 120px;
  box-shadow: ${({ theme }) => theme.shadow.map.options};
  border-radius: 300px;
  padding: 0;
  text-transform: unset;
  color: ${({ theme, __selected }) =>
    __selected ? theme.colors.white : theme.colors.dark};
  background-color: ${({ theme, __selected }) =>
    __selected ? theme.colors.primary._600 : theme.colors.white};

  &:hover {
    background-color: ${({ theme, __selected }) =>
      __selected ? theme.colors.primary._600 : theme.colors.white};
  }
`;
const Container = styled(FlexContainer)`
  position: absolute;
  top: 120px;
  left: 20px;
  z-index: 1101;

  @media (min-width: 1217px) {
    left: 170px;
  }
`;
const IconButtonContainer = styled(IconButton)`
  box-shadow: ${({ theme }) => theme.shadow.map.options};
  border-radius: 300px;
  background-color: ${({ theme, __selected }) =>
    __selected ? theme.colors.primary._600 : theme.colors.white};
  height: 50px;
  width: 50px;
  &:hover {
    background-color: ${({ theme, __selected }) =>
      __selected ? theme.colors.primary._600 : theme.colors.white};
  }
`;
const Image = styled("img")`
  height: 30px;
`;

const FilterButtons = ({ data }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Container __gap={"1rem"}>
      {data.map((d) =>
        !!isMobile ? (
          <IconButtonContainer
            key={d.id}
            __selected={d.selected}
            onClick={d.handleClick}
          >
            <Image src={d.icon} />
          </IconButtonContainer>
        ) : (
          <StyledButton
            key={d.id}
            __selected={d.selected}
            onClick={d.handleClick}
          >
            {d.label}
          </StyledButton>
        )
      )}
    </Container>
  );
};

export default FilterButtons;
