import React from "react";
import { useSelector } from "react-redux";
import { getKml, getEmpresa } from "../../../selectors/diario.selectors";
import KmlLayer from "./KmlLayer";

const KmlLayerConnected = () => {
  const kml = useSelector(getKml);
  const empresa = useSelector(getEmpresa);

  return <KmlLayer file={empresa?.roteiro === "N" ? null : kml} />;
};

export default KmlLayerConnected;
