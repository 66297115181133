import { createSelector } from "reselect";

export const getDispositivoCadastro = (state) => state.dispositivos;

export const getDispositivos = createSelector(
  getDispositivoCadastro,
  (state) => state.dispositivos
);

export const getDispositivo = createSelector(
  getDispositivoCadastro,
  (state) => state.dispositivo
);

export const getDispositivosPage = createSelector(
  getDispositivoCadastro,
  (state) => state.dispositivos.page
);

export const getDispositivosFilter = createSelector(
  getDispositivoCadastro,
  (state) => state.dispositivos.filter
);
