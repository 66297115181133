import React from "react";
import { CircularProgress, styled } from "@mui/material";

const CenteredLoader = styled(CircularProgress)`
  width: 70px;
  height: 70px;
`;
const StyledContainer = styled("div")`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconLoader = ({ stroke = "white", centered = true, ...otherProps }) => {
  return (
    <StyledContainer>
      <CenteredLoader stroke={stroke} {...otherProps} />
    </StyledContainer>
  );
};

export default IconLoader;
