import { createSelector } from "reselect";

export const getUsuario = (state) => state.usuario;

export const isUsuarioLogado = createSelector(
  getUsuario,
  (state) => !!state.nome
);

export const getPerfil = createSelector(getUsuario, (state) => state.perfil);
