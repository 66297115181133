import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import InfoWindowPosicao from "./InfoWindowPosicao";
import Icon from "../../../assets/m-pendente-big.png";
import { ConfirmDialog } from "../../../components";

const CheckpointsPendentes = ({
  points,
  canDelete,
  canMove,
  isEndUser,
  handleDelete,
  handleDragStart,
  handleDragEnd,
  handleCloseDialog,
  handleConfirmDialog,
  dialogOpen,
  updating,
}) => {
  const [clicked, setClicked] = useState(null);

  return (
    <>
      {points.map((p) => {
        return (
          <Marker
            key={p.id}
            position={p.longitudeLatitude}
            icon={Icon}
            clickable={true}
            draggable={canMove}
            onClick={() => setClicked(p)}
            onDragStart={handleDragStart}
            onDragEnd={function (e) {
              handleDragEnd(p, e, this);
            }}
          />
        );
      })}
      <InfoWindowPosicao
        isEndUser={isEndUser}
        item={clicked}
        handleClose={() => setClicked(null)}
        canDelete={canDelete}
        handleDelete={handleDelete}
      />
      <ConfirmDialog
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmDialog}
        open={dialogOpen}
        updating={updating}
        title={"Confirmação de alteração"}
        question={"Confirma a alteração da posição do checkpoint selecionado?"}
      />
    </>
  );
};

export default CheckpointsPendentes;
