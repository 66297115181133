import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../reducers/diario.actions";
import GoogleMaps from "./GoogleMaps";
import { getItems, isLoadingItems } from "../../../selectors/diario.selectors";

const GoogleMapsConnected = (props) => {
  const dispatch = useDispatch();
  const items = useSelector(getItems);
  const loading = useSelector(isLoadingItems);

  const handleClickOpenFilters = useCallback(() => {
    dispatch(actions.exibirFiltros(true));
  }, [dispatch]);

  return (
    <GoogleMaps
      {...props}
      handleClickOpenFilters={handleClickOpenFilters}
      items={items}
      loading={loading}
    />
  );
};

export default GoogleMapsConnected;
