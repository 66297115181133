import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  SELECIONAR_OPCOES: "@@mapa/SELECIONAR_OPCOES",
  ALTERNAR_MAIS_CARDS: "@@mapa/ALTERNAR_MAIS_CARDS",
  ATUALIZAR_FILTROS: "@@mapa/ATUALIZAR_FILTROS",
  EXIBIR_FILTROS: "@@mapa/EXIBIR_FILTROS",
  SELECIONAR_OS: "@@mapa/SELECIONAR_OS",
  ATUALIZAR_SELECIONAR_OS: "@@mapa/ATUALIZAR_SELECIONAR_OS",
  PESQUISAR: "@@mapa/PESQUISAR",
  PROXIMA_PAGINA: "@@mapa/PROXIMA_PAGINA",
  REINICIAR_PAGINA: "@@mapa/REINICIAR_PAGINA",
  ATUALZIAR_OS: "@@mapa/ATUALIZAR_OS",
  EXCLUIR_POSICAO: "@@mapa/EXCLUIR_POSICAO",

  COMECAR_MOVIMENTO_CHECKPOINT: "@@mapa/COMECAR_MOVIMENTO_CHECKPOINT",
  PERGUNTAR_MOVIMENTO_CHECKPOINT: "@@mapa/PERGUNTAR_MOVIMENTO_CHECKPOINT",
  FECHAR_MOVIMENTO_CHECKPOINT: "@@mapa/FECHAR_MOVIMENTO_CHECKPOINT",
  MOVER_CHECKPOINT: "@@mapa/MOVER_CHECKPOINT",

  ATUALIZACAO_AUTOMATICA: "@@mapa/ATUALIZACAO_AUTOMATICA",

  DOMINIO_EMPRESAS: "@@mapa/DOMINIO_EMPRESAS",
  DOMINIO_DISPOSITIVOS: "@@mapa/DOMINIO_DISPOSITIVOS",
  DOMINIO_GRUPOS: "@@mapa/DOMINIO_GRUPOS",
  DOWNLOAD_ROTEIRO: "@@mapa/DOWNLOAD_ROTEIRO",
  CLEAN_ROTEIRO_DATA: "@@mapa/CLEAN_ROTEIRO_DATA",
  DOWNLOAD_OS: "@@mapa/DOWNLOAD_OS",
  CLEAN_OS_DATA: "@@mapa/CLEAN_OS_DATA",
  ALTERNAR_BOTAO: "@@mapa/ALTERNAR_BOTAO",
};

export const actions = {
  selecionarOpcoes: (propriedade, valor) =>
    createSyncAction(types.SELECIONAR_OPCOES, { propriedade, valor }),
  alternarMaisCards: () => createSyncAction(types.ALTERNAR_MAIS_CARDS),
  selecionarOs: (item) => createSyncAction(types.SELECIONAR_OS, { item }),
  atualizarFiltros: (filtros) =>
    createSyncAction(types.ATUALIZAR_FILTROS, { filtros }),
  exibirFiltros: (visible) =>
    createSyncAction(types.EXIBIR_FILTROS, { visible }),
  alternarBotao: (origem) => createSyncAction(types.ALTERNAR_BOTAO, {origem}),

  pesquisar: createAsyncAction(types.PESQUISAR),
  proximaPagina: createAsyncAction(types.PROXIMA_PAGINA),
  reiniciarPagina: createAsyncAction(types.REINICIAR_PAGINA),
  atualizarOs: createAsyncAction(types.ATUALZIAR_OS),
  excluirPosicao: createAsyncAction(types.EXCLUIR_POSICAO),

  comecarMovimento: (origem) =>
    createSyncAction(types.COMECAR_MOVIMENTO_CHECKPOINT, { origem }),
  fecharMovimento: () => createSyncAction(types.FECHAR_MOVIMENTO_CHECKPOINT),
  perguntarMovimento: (ponto, evento, target) =>
    createSyncAction(types.PERGUNTAR_MOVIMENTO_CHECKPOINT, {
      ponto,
      evento,
      target,
    }),
  moverCheckpoint: createAsyncAction(types.MOVER_CHECKPOINT),

  atualizacaoAutomatica: createAsyncAction(types.ATUALIZACAO_AUTOMATICA),

  carregarEmpresas: createAsyncAction(types.DOMINIO_EMPRESAS),
  carregarDispositivos: createAsyncAction(types.DOMINIO_DISPOSITIVOS),
  carregarGrupos: createAsyncAction(types.DOMINIO_GRUPOS),
  downloadRoteiro: createAsyncAction(types.DOWNLOAD_ROTEIRO),
  cleanRoteiroData: () => createSyncAction(types.CLEAN_ROTEIRO_DATA),
  downloadOs: createAsyncAction(types.DOWNLOAD_OS),
  cleanOsData: () => createSyncAction(types.CLEAN_OS_DATA),
  atualizarSelecionarOs: createAsyncAction(types.ATUALIZAR_SELECIONAR_OS),
};
