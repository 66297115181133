import "./App.scss";
import '@fontsource/oswald'
import '@fontsource/noto-sans/latin.css'
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const App = () => {
  return (
    <>
      <Routes />
      <ToastContainer position="bottom-left" />
    </>
  );
};

export default App;
