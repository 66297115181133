import { styled } from "@mui/material";
import { Card as MCard } from "@mui/material";
import { transientOptions } from "../../styles/theme";

const Card = styled(MCard, transientOptions)`
  background-color: ${({ theme }) => theme.colors.background.card};
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: none;
`;

export default Card;
