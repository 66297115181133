import React from "react";
import { useSelector } from "react-redux";
import {
  getOpcoesMapa,
  getSelecionado,
} from "../../../selectors/mapa.selectors";
import CheckpointsEfetivados from "./CheckpointsEfetivados";
import { getPerfil } from "../../../selectors/usuario.selectors";

const CheckpointsEfetivadosConnected = () => {
  const selecionado = useSelector(getSelecionado);
  const { efetivo } = useSelector(getOpcoesMapa);
  const perfil = useSelector(getPerfil);

  const points =
    !!efetivo && !!selecionado
      ? selecionado.posicoes.filter((p) => p.tipo === "C" && p.situacao === "E")
      : [];

  points.sort((a, b) => {
    const d1 = new Date(a.horarioPosicao);
    const d2 = new Date(b.horarioPosicao);

    if (d1.getTime() > d2.getTime()) {
      return 1;
    }
    if (d1.getTime() < d2.getTime()) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    return 0;
  });

  return <CheckpointsEfetivados isEndUser={perfil === "E"} points={points} />;
};

export default CheckpointsEfetivadosConnected;
