import React from "react";
import { Grid, styled, Tooltip } from "@mui/material";
import {
  Download,
  Refresh,
  SportsScoreOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import { transientOptions } from "../../../styles/theme";
import { FlexContainer } from "../../../components/styled";
import formatters from "../../../utils/formatters";
import Funcionamento from "./Funcionamento";
import IconButton from "../../../components/buttons/IconButton";
import IconLoader from "../../../components/loaders/IconLoader";
import BatterryIcon from "./BatteryIcon";
import AlertIcon from "./AlertIcon";

const Container = styled(FlexContainer, transientOptions)`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: 12px;
  height: 340px;
  padding: 10px;
  position: relative;

  @media (min-width: 600px) {
    height: 154px;
  }
`;
const ItineraryTitle = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray._300};
`;
const Itinerary = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.large};
  font-weight: 800;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
  white-space: nowrap;
  align-self: flex-end;
`;
const ItineraryInside = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 800;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
  white-space: nowrap;
  overflow: hidden;
  align-self: flex-end;
  text-overflow: ellipsis;
  margin-left: 3px;
`;
const Company = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray._600};
`;

const MovementContainer = styled(FlexContainer)`
  margin-top: 0.75rem;
`;

const IconContainer = styled(FlexContainer, transientOptions)`
  color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  height: 32px;
  width: 32px;
  background: ${({ theme, __disabled }) =>
    __disabled
      ? theme.colors.gradients.iconDisabled
      : theme.colors.gradients.icon};
`;
const MovementDate = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 300;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
`;
const MovementHour = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 600;
  color: ${({ theme, __disabled }) =>
    __disabled ? theme.colors.gray._300 : theme.colors.gray._800};
`;
const MovementTextContainer = styled(FlexContainer)`
  margin-left: 0.5rem;
`;

const TimeDiff = styled("span")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.default};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray._600};

  @media (max-width: 1307px) {
    font-size: ${({ theme }) => theme.fonts.size.small};
  }
`;
const TextWithMargin = styled(TimeDiff, transientOptions)`
  margin: ${({ __margin }) => __margin};
  display: block;
`;

const ProgressBarContainer = styled(FlexContainer, transientOptions)`
  margin-top: ${({ __disabled }) => (__disabled ? "2rem" : "auto")};
  position: relative;
  margin-bottom: 3rem;

  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;
const ProgressBarText = styled("span", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: 600;
  color: ${({ theme, __disabled }) =>
    !!__disabled ? theme.colors.red._800 : theme.colors.green._800};
`;
const ProgressBarFill = styled("div")`
  border-radius: 100px;
  height: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray._500};
`;
const ProgressBarVariable = styled("div", transientOptions)`
  position: absolute;
  bottom: 0;
  border-radius: 100px;
  height: 10px;
  width: ${({ __width }) => __width}%;
  background-color: ${({ theme }) => theme.colors.green._300};
`;

const Separator = styled("div")`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.gray._500};
  left: 15px;
  right: 15px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: 600px) {
    top: 15px;
    bottom: 15px;
    width: 1px;
    left: 50%;
    height: auto;
    transform: translateX(-50%);
  }
`;
const ActionButtonsContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
const ActionButton = styled(IconButton)`
  & .MuiPaper-root {
    color: ${({ theme }) => theme.colors.primary._700};
  }
`;

const ItineraryContainer = styled("div")`
  white-space: nowrap;
  overflow: hidden;
  align-self: flex-end;
  text-overflow: ellipsis;
`;

const IconsContainer = styled(FlexContainer)`
  align-items: center;
`;

const ItemCardExpandido = ({
  item,
  isUserEnd,
  handleDownload,
  roteiroFile,
  handleCleanRoteiroData,
  handleClickUpdate,
  updating,
}) => {
  const download = (fileBase64, fileName) => {
    if (fileBase64 !== "") {
      const outFile = `data:application/octet-stream;base64,${fileBase64}`;
      const element = document.createElement("a");
      element.href = outFile;
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    }
  };

  if (roteiroFile?.file) {
    download(roteiroFile.file, roteiroFile.file_name);
    handleCleanRoteiroData();
  }

  return (
    <Container>
      <Grid container columnSpacing={"3rem"}>
        <Grid item xs={12} sm={6} direction={"column"} display={"flex"}>
          <FlexContainer
            __justifyContent={"space-between"}
            __alignItems={"flex-start"}
          >
            <ItineraryTitle>Roteiro</ItineraryTitle>
            <Company>{item.empresa}</Company>
          </FlexContainer>
          <FlexContainer __direction={"row"} __justifyContent={"space-between"}>
            <ItineraryContainer>
              <Itinerary __disabled={item.inicioEfetivo === null}>
                {item.roteiro.nome}
              </Itinerary>
              <ItineraryInside __disabled={item.inicioEfetivo === null}>
                {`(${item.roteiro.dispositivo.nome})`}
              </ItineraryInside>
            </ItineraryContainer>
            <IconsContainer>
              <AlertIcon
                display={item.possuiAlertaFaltaMensagem === "S"}
                message={"Alerta de falta de mensagens"}
              />

              <BatterryIcon
                nivel={
                  !item.inicioEfetivo
                    ? "Z"
                    : item.roteiro.dispositivo.nivelBateria
                }
              />
            </IconsContainer>
          </FlexContainer>
          <MovementContainer __direction={"column"} __gap={"8px"}>
            <FlexContainer>
              <MovementSection
                disabled={!item.inicioEfetivo}
                isUserEnd={isUserEnd}
                title={
                  !!item.inicioEfetivo
                    ? "Início da Operação"
                    : "Início Previsto"
                }
                date={new Date(item.inicioEfetivo ?? item.inicioPrevisto)}
                icon={<TimerOutlined />}
              />
              <MovementSection
                disabled={!item.inicioEfetivo}
                isUserEnd={isUserEnd}
                title={
                  !!item.inicioEfetivo
                    ? "Última atualização"
                    : "Término Previsto"
                }
                date={new Date(item.ultimaPosicao ?? item.terminoPrevisto)}
                icon={<SportsScoreOutlined />}
              />
            </FlexContainer>
            {!isUserEnd && !!item.inicioEfetivo && (
              <TimeDiff>{item.tempoExecucao}</TimeDiff>
            )}
          </MovementContainer>
          {item.roteiro.empresa.roteiro === "S" && (
            <ProgressBarSection item={item} isUserEnd={isUserEnd} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} direction={"column"}>
          <TextWithMargin as={"div"}>OS {item.codigo}</TextWithMargin>
          <TextWithMargin as={"div"} __margin={"6px 0 0 0"}>
            Funcionamento
          </TextWithMargin>
          <Funcionamento funcionamento={item.funcionamento} />
          {item.roteiro.empresa.roteiro === "S" && (
            <>
              <TextWithMargin as={"div"} __margin={"8px 0 0 0"}>
                Metragem Plano:{" "}
                {formatters.numbers.simple(item.roteiro.metragem)}m
              </TextWithMargin>
              <TextWithMargin as={"div"} __margin={"6px 0 0 0"}>
                Metragem Executada:{" "}
                {formatters.numbers.nFixed(
                  (item.roteiro.metragem * getPercentualExecutado(item)) / 100,
                  0
                )}
                m
              </TextWithMargin>
            </>
          )}
          <TextWithMargin
            as={"div"}
            __margin={
              item.roteiro.empresa.roteiro === "S" ? "4px 0 0 0" : "8px 0 0 0"
            }
          >
            Grupo: {item.grupo}
          </TextWithMargin>
          <ActionButtonsContainer __direction={"column"}>
            <ActionButton onClick={() => !updating && handleClickUpdate()}>
              {!updating && <Refresh />}
              {!!updating && (
                <IconLoader style={{ height: "20px", width: "20px" }} />
              )}
            </ActionButton>
            {!isUserEnd && (
              <ActionButton
                onClick={() => !roteiroFile?.loading && handleDownload()}
              >
                {!roteiroFile?.loading && <Download />}
                {!!roteiroFile?.loading && (
                  <IconLoader style={{ height: "20px", width: "20px" }} />
                )}
              </ActionButton>
            )}
          </ActionButtonsContainer>
        </Grid>
        <Separator />
      </Grid>
    </Container>
  );
};

const getPercentualExecutado = (item) => {
  return item.percentualEfetivo;
};

const MovementSection = ({ title, icon, isUserEnd, date, disabled }) => {
  return (
    <FlexContainer __flex={1}>
      <Tooltip title={title}>
        <IconContainer
          __justifyContent={"center"}
          __alignItems={"center"}
          __disabled={disabled}
        >
          {icon}
        </IconContainer>
      </Tooltip>
      <MovementTextContainer __direction={"column"}>
        <MovementDate __disabled={disabled}>
          {formatters.dates.short(date)}
        </MovementDate>
        {!isUserEnd && (
          <MovementHour __disabled={disabled}>
            {formatters.dates.onlyHours(date)}
          </MovementHour>
        )}
      </MovementTextContainer>
    </FlexContainer>
  );
};

const ProgressBarSection = ({ item, isUserEnd }) => {
  const checkpoints = item.posicoes.filter((p) => p.tipo === "C");
  const checkpointsAtivados = checkpoints.filter((p) => p.situacao === "E");
  return (
    <ProgressBarContainer
      __direction={"column"}
      __gap={"4px"}
      __disabled={!item.inicioEfetivo && !isUserEnd}
    >
      <ProgressBarText __disabled={item.inicioEfetivo === null}>
        Progresso: {checkpointsAtivados.length}/{checkpoints.length} -{" "}
        {!!item.inicioEfetivo
          ? `${formatters.numbers.nFixed(getPercentualExecutado(item), 2)}%`
          : "Não Iniciado"}
      </ProgressBarText>
      <ProgressBarFill />
      {!!item.inicioEfetivo && checkpoints.length > 0 && (
        <ProgressBarVariable __width={item.percentualEfetivo.toFixed(0)} />
      )}
    </ProgressBarContainer>
  );
};

export default ItemCardExpandido;
