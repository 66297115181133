import React from "react";
import { Grid, styled } from "@mui/material";
import { Card, GradientButton } from "../../../components/styled";
import { CustomControlledAutoComplete } from "../../../components/inputs";
import ControlledCustomTextField from "../../../components/inputs/ControlledCustomTextField";
import GridWithMargin from "../../../components/styled/GridWithMargin.styled";
import ReprocessamentoDialog from "./ReprocessamentoDialog";

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;
const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledControlledCustomTextField = styled(ControlledCustomTextField)`
  .MuiOutlinedInput-input {
    color-scheme: dark;
  }
`;

const CardGeracaoOs = ({
  formProps,
  handleSubmit,
  processing,
  loading,
  data,
  handleGetRoteiros,
  dialogTitle,
  openDialog,
  setOpenDialog,
  actionFunction,
  actionValues,
  loadingReprocessamento,
  handleLimparRoteiros,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <StyledCardTitle>Selecione uma Data/Roteiro</StyledCardTitle>
        <Grid container>
          <StyledGrid item xs={12} md={6} lg={6}>
            <StyledControlledCustomTextField
              onChange={(e) => {
                formProps.setValue("roteiro", "");
                handleLimparRoteiros();
                handleGetRoteiros(e.target.value);
              }}
              formProps={formProps}
              label={"Data"}
              name={"data"}
              type="date"
            />
          </StyledGrid>
          {data?.length > 0 && (
            <StyledGrid item xs={12} md={6} lg={6}>
              <CustomControlledAutoComplete
                formProps={formProps}
                fullWidth
                name="roteiro"
                label="Roteiro"
                labelField={"nome"}
                idField={"id"}
                loading={loading?.toString()}
                domain={data}
                inputPropMax={50}
              />
            </StyledGrid>
          )}
        </Grid>
      </Card>
      <GridWithMargin
        __margin={"1rem 0 0 0"}
        container
        justifyContent={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <GradientButton type={"submit"} loading={loadingReprocessamento}>
            REPROCESSAR
          </GradientButton>
        </Grid>
      </GridWithMargin>
      <ReprocessamentoDialog
        title={dialogTitle}
        open={openDialog}
        setOpen={setOpenDialog}
        deleteFunction={actionFunction}
        actionValues={actionValues}
      />
    </form>
  );
};

export default CardGeracaoOs;
