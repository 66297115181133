import React from "react";
import { styled, TextField as MTextField } from "@mui/material";

const StyledTextField = styled(MTextField)`
    background-color: #3F484A;
    border-radius: 4px 4px 0px 0px;
    margin-bottom:4px;
    margin-top:4px;
    flex: 1!important;
    font-family: ${({ theme }) => theme.fonts.family.default};
    border: 0px;
    border-radius: 4px 4px 0px 0px;

    & .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
      color: #BFC8CA !important;
    }

    & MuiSelect-iconOutlined MuiSelect-iconOpen {
      color: #BFC8CA !important;
    }


    @media (max-width: 900px) {
      margin-bottom:5px;
      margin-top:5px;
    }


    & .css-186xcr5 {
      display: none;


      }

      
      & .MuiInputBase-input{
      font-family: ${({ theme }) => theme.fonts.family.default};
      font-size: 17px;
      margin-top: 10px;
      color: #BFC8CA !important;
      }


  & .MuiInputLabel-root {
    font-size: 17px;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-weight: 700;
    margin-top: 13px;
    margin-bottom: 10px;
    color: #BFC8CA !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiInputLabel-root.Mui-disabled {
    font-size: 17px;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-weight: 700;
    margin-top: 13px;
    margin-bottom: 10px;
    color: #4d4d4dff !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #D11F44!important;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #dbe4e6;
    margin: -0.5px;

  }

  & .MuiOutlinedInput-root.Mui-disabled {
    background-color: #b8c0c2ff;
  },

  & .MuiOutlinedInput-root {
    & fieldset {
      border: 0px;
    },
    &:hover fieldset {
      border-radius: 4px 4px 0px 0px;
      border-bottom: 2px solid #51a8d1;

    },
    &.Mui-focused fieldset {
      border-radius: 4px 4px 0px 0px;
      border-bottom: 3px solid #51a8d1;
    },
    &.Mui-error fieldset {
    border-radius: 4px 4px 0px 0px;
    border-bottom: 4px solid #D11F44;

    },

    & .Mui-disabled {
      -webkit-text-fill-color: grey;
      background-color: #b8c0c2ff;
    }

    `;

const CustomTextFieldCreate = ({
  shrinked = true,
  fullWidth = true,
  InputLabelProps,
  ...other
}) => (
  <StyledTextField
    InputLabelProps={{
      shrink: shrinked,
      ...InputLabelProps,
    }}
    fullWidth={fullWidth}
    {...other}
  />
);

export default CustomTextFieldCreate;
