import { ControlledTextField } from "../../components/inputs";
import { Button } from "../../components";
import { Lock, NotInterested, Check } from "@mui/icons-material";

import React, { useState } from "react";
import { Container, Grid, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";

const StyledFormGrid = styled(Grid)`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const StyledButtonContainer = styled(Container)`
  margin-top: 25px;
  text-align: center;
`;

const StyledControlledTextField = styled(ControlledTextField)`

margin-bottom:5px;
margin-top:5px;


flex: 1!important;

@media (max-width: 900px) {
  margin-bottom:5px;
  margin-top:5px;
}


  & .MuiInputLabel-root {
    background-color: #D4ECF5;
    color: #3F484A !important;
    font-family: ${({ theme }) => theme.fonts.family.form};
    font-weight: 700;


    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }


  & .MuiFormHelperText-root.Mui-error {
    color: #d32f2f!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #3F484A;
    },
    &:hover fieldset {
      border-color: #3F484A;
    },
    &.Mui-focused fieldset {
      border: 1.5px solid #3F484A;
    },
    &.Mui-error fieldset {
    border: 1.5px solid #d32f2f;
    },`;

const StyledH2 = styled("h2")`
  text-align: center;
  margin-top: -10px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.8px;
  text-align: left;
  font-weight: 500;

  color: #051f23;
  font-size: 12px;
  @media (max-width: 900px) {
    font-size: 15px;
  }
`;
const StyledCheck = styled(Check)`
  color: #018e06;
  font-size: 17px;
  vertical-align: middle;
  margin-top: -2px;
`;
const StyledNotInterested = styled(NotInterested)`
  color: red;
  font-size: 17px;
  vertical-align: middle;
  margin-top: -2px;
`;

const StyledButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 219px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
`;

const ValidationItem = ({ state, item, children }) => (
  <div className="instrucoes mb-3">
    <StyledH2>
      {!!state.dirty && state.list.indexOf(item) === -1 ? (
        <StyledCheck />
      ) : (
        <StyledNotInterested />
      )}{" "}
      {children}
    </StyledH2>
  </div>
);

const TrocarSenhaForm = ({
  loading,
  formProps,
  handleSubmit,
  handlePasswordChange,
}) => {
  const [state, setState] = useState({ dirty: false, list: [] });

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Nova Senha"
          name={"password"}
          formProps={formProps}
          type={"password"}
          margin={"none"}
          onChange={(v) => handlePasswordChange(v, setState)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={"ml-2 mr-3"}>
                <Lock style={{ color: "#3F484A" }} className="color-brand-2" />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Repetir Senha"
          name={"password2"}
          formProps={formProps}
          type={"password"}
          margin={"none"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={"ml-2 mr-3"}>
                <Lock style={{ color: "#3F484A" }} className="color-brand-2" />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <div className="d-flex flex-column align-items-center mt-3">
        <StyledH2 style={{ marginBottom: "20px", marginTop: "10px" }}>
          Sua nova senha deve respeitar as seguintes regras:
        </StyledH2>
        <div className="mt-3">
          <ValidationItem state={state} item={"min"}>
            Ter no mínimo 8 caracteres
          </ValidationItem>
          <ValidationItem state={state} item={"digits"}>
            Ter ao menos um número
          </ValidationItem>
        </div>
      </div>
      <StyledButtonContainer>
        <StyledButton loading={loading} type={"submit"}>
          CONFIRMAR
        </StyledButton>
      </StyledButtonContainer>
    </form>
  );
};

export default TrocarSenhaForm;
