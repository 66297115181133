import React from "react";
import {
  styled,
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MSelect,
} from "@mui/material";

const StyledFormControl = styled(FormControl)`
  & .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.title};
    font-weight: 800;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.gray["800"]};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.content};
    font-weight: 400;
  }
  & .MuiSelect-select:focus {
    background-color: transparent;
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray["800"]};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.error};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
`;

const Select = ({
  id,
  name,
  variant,
  value,
  fullWidth = true,
  margin = "none",
  disabled,
  label,
  helperText,
  MenuProps,
  children,
  ...otherValues
}) => (
  <StyledFormControl margin={margin} fullWidth={fullWidth} disabled={disabled}>
    {!!label && (
      <InputLabel htmlFor={name} focused={!!value} shrink={true}>
        {label}
      </InputLabel>
    )}
    <MSelect
      {...otherValues}
      margin={margin}
      variant={variant}
      MenuProps={MenuProps}
      inputProps={{
        name: name,
        id: id,
        value: value,
        disabled: disabled,
      }}
    >
      {children}
    </MSelect>
    {Boolean(helperText) && (
      <FormHelperText error={Boolean(otherValues.error)}>
        {helperText}
      </FormHelperText>
    )}
  </StyledFormControl>
);

export default Select;
