import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_ROTEIROS: "@@roteiros/OBTER_ROTEIROS",
  MOSTRAR_ROTEIRO: "@@roteiros/MOSTRAR_ROTEIRO",
  EDITAR_ROTEIRO: "@@roteiros/EDITAR_ROTEIRO",
  CRIAR_ROTEIRO: "@@roteiros/CRIAR_ROTEIRO",
  DELETAR_ROTEIRO: "@@roteiros/DELETAR_ROTEIRO",
  REATIVAR_ROTEIRO: "@@roteiros/REATIVAR_ROTEIRO",
  REMOVER_FILTRO_ROTEIRO: "@@roteiros/REMOVER_FILTRO_ROTEIRO",
  REMOVER_PAGINA_ROTEIRO: "@@roteiros/REMOVER_PAGINA_ROTEIRO",
  CRIAR_GRUPO: "@@roteiros/CRIAR_GRUPO",
  OBTER_GRUPOS: "@@roteiros/OBTER_GRUPOS",
  CRIAR_FUNCIONAMENTO: "@@roteiros/CRIAR_FUNCIONAMENTO",
  OBTER_FUNCIONAMENTOS: "@@roteiros/OBTER_FUNCIONAMENTOS",
  DELETAR_FUNCIONAMENTO: "@@roteiros/DELETAR_FUNCIONAMENTO",
  CRIAR_POSICAO: "@@roteiros/CRIAR_POSICAO",
  DELETAR_POSICAO: "@@roteiros/DELETAR_POSICAO",
  OBTER_POSICOES: "@@roteiros/OBTER_POSICOES",
  CRIAR_KML: "@@roteiros/CRIAR_KML",
  DELETAR_KML: "@@roteiros/DELETAR_KML",
  OBTER_KMLS: "@@roteiros/OBTER_KMLS",
  OBTER_DISPOSITIVOS: "@@roteiros/OBTER_DISPOSITIVOS",
  UPLOAD_PLANILHA: "@@roteiros/UPLOAD_PLANILHA",
  BAIXAR_ARTEFATO: "@@roteiros/BAIXAR_ARTEFATO",
  BAIXAR_PLANILHA_ROTEIROS: "@@roteiros/BAIXAR_PLANILHA_ROTEIROS",
  UPLOAD_CARGA_PLANILHA_ROTEIROS: "@@roteiros/UPLOAD_CARGA_PLANILHA_ROTEIROS",
  MOSTRAR_ERROS_UPLOAD: "@@roteiros/MOSTRAR_ERROS_UPLOAD",
  LIMPAR_TABELAS: "@@roteiros/LIMPAR_TABELAS",
  OBTER_ROTEIROS_SEM_FILTRO: "@@roteiros/OBTER_ROTEIROS_SEM_FILTRO",
  LIMPAR_ROTEIROS: "@@roteiros/LIMPAR_ROTEIROS",
  UPLOAD_CARGA_PLANILHA_DISPOSITIVOS:
    "@@roteiros/UPLOAD_CARGA_PLANILHA_DISPOSITIVOS",
  BAIXAR_PLANILHA_DISPOSITIVOS: "@@roteiros/BAIXAR_PLANILHA_DISPOSITIVOS",

  UPLOAD_CARGA_TROCAR_DISPOSITIVOS:
    "@@roteiros/UPLOAD_CARGA_TROCAR_DISPOSITIVOS",
  BAIXAR_PLANILHA_TROCAR_DISPOSITIVOS:
    "@@roteiros/BAIXAR_PLANILHA_TROCAR_DISPOSITIVOS",
  MOSTRAR_ERROS_TROCAR_DISPOSITIVOS:
    "@@roteiros/MOSTRAR_ERROS_TROCAR_DISPOSITIVOS:",
  MOSTRAR_ERROS_DISPOSITIVOS: "@@roteiros/MOSTRAR_ERROS_DISPOSITIVOS:",
};

export const actions = {
  obterRoteiros: createAsyncAction(types.OBTER_ROTEIROS),
  mostrarRoteiro: createAsyncAction(types.MOSTRAR_ROTEIRO),
  editarRoteiro: createAsyncAction(types.EDITAR_ROTEIRO),
  criarRoteiro: createAsyncAction(types.CRIAR_ROTEIRO),
  deletarRoteiro: createAsyncAction(types.DELETAR_ROTEIRO),
  reativarRoteiro: createAsyncAction(types.REATIVAR_ROTEIRO),
  removerFiltroRoteiro: () => createSyncAction(types.REMOVER_FILTRO_ROTEIRO),
  removerPaginaRoteiro: () => createSyncAction(types.REMOVER_PAGINA_ROTEIRO),
  limparTabelas: () => createSyncAction(types.LIMPAR_TABELAS),
  limparRoteiros: () => createSyncAction(types.LIMPAR_ROTEIROS),
  criarGrupo: createAsyncAction(types.CRIAR_GRUPO),
  obterGrupos: createAsyncAction(types.OBTER_GRUPOS),
  obterDispositivos: createAsyncAction(types.OBTER_DISPOSITIVOS),
  criarFuncionamento: createAsyncAction(types.CRIAR_FUNCIONAMENTO),
  obterFuncionamentos: createAsyncAction(types.OBTER_FUNCIONAMENTOS),
  criarKml: createAsyncAction(types.CRIAR_KML),
  deletarKml: createAsyncAction(types.DELETAR_KML),
  obterKmls: createAsyncAction(types.OBTER_KMLS),
  criarPosicao: createAsyncAction(types.CRIAR_POSICAO),
  deletarPosicao: createAsyncAction(types.DELETAR_POSICAO),
  obterPosicoes: createAsyncAction(types.OBTER_POSICOES),
  deletarFuncionamento: createAsyncAction(types.DELETAR_FUNCIONAMENTO),
  uploadPlanilha: createAsyncAction(types.UPLOAD_PLANILHA),
  baixarArtefato: createAsyncAction(types.BAIXAR_ARTEFATO),
  baixarPlanilhaRoteiros: createAsyncAction(types.BAIXAR_PLANILHA_ROTEIROS),
  obterRoteirosSemFiltro: createAsyncAction(types.OBTER_ROTEIROS_SEM_FILTRO),
  uploadCargaPlanilhaRoteiros: createAsyncAction(
    types.UPLOAD_CARGA_PLANILHA_ROTEIROS
  ),
  uploadCargaPlanilhaDispositivos: createAsyncAction(
    types.UPLOAD_CARGA_PLANILHA_DISPOSITIVOS
  ),
  uploadCargaTrocarDispositivos: createAsyncAction(
    types.UPLOAD_CARGA_TROCAR_DISPOSITIVOS
  ),
  baixarPlanilhaDispositivos: createAsyncAction(
    types.BAIXAR_PLANILHA_DISPOSITIVOS
  ),
  baixarPlanilhaTrocarDispositivos: createAsyncAction(
    types.BAIXAR_PLANILHA_TROCAR_DISPOSITIVOS
  ),
  mostrarErroUpload: (values) =>
    createSyncAction(types.MOSTRAR_ERROS_UPLOAD, { ...values }),
  mostrarErroTrocarDispositivos: (values) =>
    createSyncAction(types.MOSTRAR_ERROS_TROCAR_DISPOSITIVOS, { ...values }),
  mostrarErroDispositivos: (values) =>
    createSyncAction(types.MOSTRAR_ERROS_DISPOSITIVOS, { ...values }),
};
