import React from "react";
import {
  Card,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { Clear } from "@mui/icons-material";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
`;

const StyledTableIconCell = styled(TableCell)`
  text-align: center;
`;

const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Noto Sans;
  border-bottom: none !important;
`;
const StyledTitleTableIconCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Noto Sans;
  border-bottom: none !important;
  text-align: center;
`;

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;

const IconClear = styled(Clear)`
  fill: red;
`;

const TabelaRoteiroInconsistencia = ({ data }) => {
  return (
    <StyledCard>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Cliente</StyledTitleTableCell>
            <StyledTitleTableCell>Roteiro</StyledTitleTableCell>
            <StyledTitleTableIconCell>Ausência de KML</StyledTitleTableIconCell>
            <StyledTitleTableIconCell>
              Ausência de Posições
            </StyledTitleTableIconCell>
          </StyledTableRow>
          {data &&
            data?.map((item) => {
              return (
                <StyledTableRow key={item.id}>
                  <StyledTableCell>
                    {item?.roteiro?.empresa?.nome}
                  </StyledTableCell>
                  <StyledTableCell>{item?.roteiro?.nome}</StyledTableCell>
                  <StyledTableIconCell>
                    {item?.arquivoKml === "S" ? <IconClear /> : ""}
                  </StyledTableIconCell>
                  <StyledTableIconCell>
                    {item?.arquivoPosicao === "S" ? <IconClear /> : ""}
                  </StyledTableIconCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </StyledCard>
  );
};

export default TabelaRoteiroInconsistencia;
