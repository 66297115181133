import basicFlow from "../asyncHandler";
import { authenticatedRequest, unauthenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as clientes } from "../../reducers/cadastro/clientes.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getClientesFilter,
  getClientesPage,
} from "../../selectors/cadastro/clientes.selectors";

const ListarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/listar`,
    isResourceService: true,
    method: "post",
    body: {
      nome: values?.nome ?? "",
      pagina: values?.pagina ?? 1,
      limite: 9,
    },
  });
};

const ListarClientes = basicFlow({
  actionGenerator: clientes.obterClientes,
  api: ListarClientesApi,
});

const ListarClientesSemFiltroApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/listar/all`,
    isResourceService: true,
    method: "post",
  });
};

const ListarClientesSemFiltro = basicFlow({
  actionGenerator: clientes.obterClientesSemFiltro,
  api: ListarClientesSemFiltroApi,
});

const ListarMatrizesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/listar-matrizes`,
    isResourceService: true,
    method: "post",
  });
};

const ListarMatrizes = basicFlow({
  actionGenerator: clientes.obterMatrizes,
  api: ListarMatrizesApi,
});

function* ListarClientesRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_CLIENTE, function* () {
    const pagina = yield select(getClientesPage);
    const nome = yield select(getClientesFilter);
    yield put(clientes.obterClientes.request({ pagina, nome }));
  });
}

function* ListarClientesSemFiltroRouteWatcher() {
  yield routeWatcher(
    [
      routes.EDITAR_USUARIO,
      routes.CRIAR_USUARIO,
      routes.CARGA_ROTEIRO,
      routes.CARGA_ARQUIVO,
    ],
    function* () {
      yield put(clientes.obterClientesSemFiltro.request());
    }
  );
}

const EditarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/update`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const EditarClientes = basicFlow({
  actionGenerator: clientes.editarCliente,
  api: EditarClientesApi,
  postSuccess: function* () {
    yield toast.success("Cliente atualizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.CADASTRO_CLIENTE));
    const pagina = yield select(getClientesPage);
    const nome = yield select(getClientesFilter);
    yield put(clientes.obterClientes.request({ pagina, nome }));
  },
});

const ReativarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: values.situacao,
    },
  });
};

const ReativarClientes = basicFlow({
  actionGenerator: clientes.reativarCliente,
  api: ReativarClientesApi,
  postSuccess: function* () {
    yield toast.success("Cliente reativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(clientes.obterClientes.request());
  },
});

const criarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente`,
    isResourceService: true,
    method: "post",
    body: {
      ...values,
    },
  });
};

const CriarClientes = basicFlow({
  actionGenerator: clientes.criarCliente,
  api: criarClientesApi,
  postSuccess: function* () {
    yield toast.success("Cliente criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.CADASTRO_CLIENTE));
  },
});

const deletarClientesApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: "I",
    },
  });
};

const DeletarClientes = basicFlow({
  actionGenerator: clientes.deletarCliente,
  api: deletarClientesApi,
  postSuccess: function* () {
    yield toast.success("Cliente inativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(clientes.obterClientes.request());
  },
});

const MostrarclienteApi = (values) => {
  return authenticatedRequest({
    url: `/cliente/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const Mostrarcliente = basicFlow({
  actionGenerator: clientes.mostrarCliente,
  transform: function* (payload) {
    const id = yield select((state) => state.clientes.id);
    return { id, ...payload };
  },

  api: MostrarclienteApi,
});

function* MostrarclienteRouteWatcher() {
  yield routeWatcher(
    [routes.EDITAR_CLIENTE, routes.VIEW_CLIENTE],
    function* () {
      const cliente = yield select(getPayload);

      yield put(clientes.mostrarCliente.request({ id: cliente.id }));
    }
  );
}

function* ObterMatrizesRouteWatcher() {
  yield routeWatcher(
    [routes.EDITAR_CLIENTE, routes.CRIAR_CLIENTE, routes.VIEW_CLIENTE],
    function* () {
      yield put(clientes.obterMatrizes.request());
    }
  );
}

const buscarCep = basicFlow({
  actionGenerator: clientes.buscarCep,
  api: ({ value }) => {
    return unauthenticatedRequest({
      url: `https://viacep.com.br/ws/${value}/json/`,
      method: "get",
      isCep: true,
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* (props) {
    if (!!props.original.failureCallback) {
      yield props.original.failureCallback(props);
    }
  },
});

export const sagas = [
  MostrarclienteRouteWatcher(),
  ListarClientesRouteWatcher(),
  ObterMatrizesRouteWatcher(),
  ListarClientes.watcher(),
  Mostrarcliente.watcher(),
  EditarClientes.watcher(),
  CriarClientes.watcher(),
  DeletarClientes.watcher(),
  ReativarClientes.watcher(),
  buscarCep.watcher(),
  ListarMatrizes.watcher(),
  ListarClientesSemFiltroRouteWatcher(),
  ListarClientesSemFiltro.watcher(),
];
