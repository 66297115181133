import { createSelector } from "reselect";

export const getUsuarioCadastro = (state) => state.usuarios;

export const getUsuarios = createSelector(
  getUsuarioCadastro,
  (state) => state.usuarios
);

export const getUsuario = createSelector(
  getUsuarioCadastro,
  (state) => state.usuario
);

export const getUsuariosPage = createSelector(
  getUsuarioCadastro,
  (state) => state.usuarios.page
);

export const getUsuariosFilter = createSelector(
  getUsuarioCadastro,
  (state) => state.usuarios.filter
);
