import React from "react";
import { Controller } from "react-hook-form";
import { styled, Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/base";
import CustomTextFieldCreate from "./CustomTextFieldCreate";

const filter = createFilterOptions({
  ignoreAccents: true,
  ignoreCase: true,
  trim: true,
});

const StyledAutocomplete = styled(Autocomplete)`
  .css-tlyple-MuiFormControl-root-MuiTextField-root {
    background-color: ${({ disabled }) => (disabled ? "#b8c0c2ff" : "#3F484A")};
  }
`;

const ControlledAutocompleteCreate = ({
  disabled,
  formProps,
  inputPropMax,
  domain,
  labelField,
  secondLabelField,
  idField = "id",
  name,
  ignoreError = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined && !ignoreError;

  const getOptionLabel = (option) => {
    if (typeof option === "string") {
      return option;
    }
    if (option.inputValue && option.manual) {
      return option.label;
    }
    if (option[labelField] === undefined) {
      return domain.find((d) => d[idField] === option)?.[labelField] ?? "";
    }
    return option[labelField];
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== "") {
      filtered.push({
        [idField]: null,
        inputValue: params.inputValue,
        label: `${params.inputValue}`,
        [labelField]: params.inputValue,
        manual: true,
      });
    }

    return filtered;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value } }) => (
        <StyledAutocomplete
          disabled={disabled}
          value={value}
          autoComplete={true}
          filterOptions={filterOptions}
          freeSolo
          noOptionsText={"Nenhuma opção correspondente"}
          options={domain}
          getOptionLabel={getOptionLabel}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onChange={(e, v, r) => {
            if (r === "createOption") {
              onChange({
                [idField]: null,
                [labelField]: v,
              });
            }
            if (typeof v === "string") {
              onChange({
                [idField]: null,
                [labelField]: v,
              });
            } else {
              onChange(v);
            }
          }}
          onBlur={(e) => {
            onBlur();
            if (!!otherProps.onBlur) {
              otherProps.onBlur();
            }
          }}
          renderInput={(params) => (
            <CustomTextFieldCreate
              {...params}
              {...otherProps}
              error={isError}
              helperText={!ignoreError ? errors[name]?.message : undefined}
            />
          )}
        />
      )}
    />
  );
};

export default ControlledAutocompleteCreate;
