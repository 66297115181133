import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions } from "../../../reducers/cadastro/usuarios.actions";
import { getUsuarios } from "../../../selectors/cadastro/usuarios.selectors";
import UsuarioPage from "./UsuarioPage";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";

const UsuarioPageConnected = () => {
  const { loading, data, filter, page } = useSelector(getUsuarios);
  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterUsuarios.request({
        pagina: page,
        nome: filter,
      })
    );
  };

  const handleDelete = (e, id) => {
    dispatch(actions.deletarUsuario.request({ id }));
  };

  const handleActivate = (e, id) => {
    dispatch(actions.reativarUsuario.request({ id, situacao: "A" }));
  };

  const handleEdit = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.EDITAR_USUARIO, {
        id: id,
      })
    );
  };

  const handleAdd = () => {
    dispatch(routeActions.redirectTo(routes.CRIAR_USUARIO));
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.HOME_PAGE));
    dispatch(
      actions.obterUsuarios.request({
        pagina: page,
        nome: filter,
      })
    );
  };

  return (
    <UsuarioPage
      data={data}
      loading={loading}
      handlePagination={handlePagination}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleGoBack={handleGoBack}
      handleActivate={handleActivate}
      filtroAtual={filter}
    />
  );
};

export default UsuarioPageConnected;
