import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import {
  actions,
  actions as baterias,
  types,
} from "../reducers/baterias.actions";
import { put, select, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  getBateriasFilter,
  getBateriasOrder,
  getBateriasPageSize,
} from "../selectors/baterias.selectors";

const listarBaterias = basicFlow({
  actionGenerator: baterias.obterBaterias,
  transform: function* (payload) {
    const pageSize = yield select(getBateriasPageSize);
    const order = yield select(getBateriasOrder);
    return {
      ...payload,
      pageSize,
      order,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/bateria/listar`,
      isResourceService: true,
      method: "post",
      body: {
        ...values,
      },
    });
  },
});

function* ListarBateriasRouteWatcher() {
  yield routeWatcher(routes.BATERIAS, function* () {
    yield put(baterias.carregarEmpresas.request());
    yield put(baterias.carregarDispositivos.request());
    yield put(baterias.cleanlist());
  });
}

const carregarEmpresas = basicFlow({
  actionGenerator: baterias.carregarEmpresas,
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente/dominio?cliente=${values?.cliente ?? "false"}`,
      method: "get",
    });
  },
});
const carregarDispositivos = basicFlow({
  actionGenerator: baterias.carregarDispositivos,
  api: () => {
    return authenticatedRequest({
      url: `/dispositivo/dominio`,
      method: "get",
    });
  },
});

const downloadCSV = basicFlow({
  actionGenerator: baterias.downloadCSV,
  api: (values) => {
    return authenticatedRequest({
      url: `/bateria/download-csv`,
      method: "post",
      body: { ...values },
    });
  },
  postSuccess: function* () {
    yield toast.success("Arquivo gerado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

function* updatePageSizeOrderWatcher() {
  yield takeEvery([types.UPDATE_PAGE_SIZE, types.UPDATE_ORDER], function* () {
    const filter = yield select(getBateriasFilter);
    yield put(actions.obterBaterias.request({ ...filter, pagina: 1 }));
  });
}

export const sagas = [
  ListarBateriasRouteWatcher(),
  updatePageSizeOrderWatcher(),
  listarBaterias.watcher(),
  carregarEmpresas.watcher(),
  carregarDispositivos.watcher(),
  downloadCSV.watcher(),
];
