import { types, actions } from "./diario.actions";
import { types as authTypes } from "./autenticacao.actions";

const initialState = {
  dominios: {
    empresas: {
      loading: true,
      data: [],
    },
  },
  opcoes: {
    percurso: false,
    efetivo: false,
    atual: true,
  },
  filtros: {
    data: null,
    empresa: null,
  },
  drawer: {
    filters: false,
    showMore: false,
  },
  loading: false,
  updating: false,
  items: [],
  kml: null,
  empresa: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case types.SELECIONAR_OPCOES:
      return {
        ...state,
        opcoes: {
          ...state.opcoes,
          [action.payload.propriedade]: action.payload.valor,
        },
      };
    case types.ALTERNAR_MAIS_CARDS:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          showMore: !state.drawer.showMore,
        },
      };
    case types.EXIBIR_FILTROS:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          filters: action.payload.visible,
        },
      };
    case types.ATUALIZAR_FILTROS:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          page: 1,
          ...action.payload.filtros,
        },
      };
    case types.SELECIONAR_OS:
      return {
        ...state,
        selecionado: action.payload.item,
      };
    case actions.pesquisar.REQUEST:
    case actions.pesquisar.SUCCESS:
    case actions.pesquisar.FAILURE:
      return {
        ...state,
        loading: action.type === actions.pesquisar.REQUEST,
        items:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.items
            : state.items,
        kml:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.kml
            : state.kml,
        empresa:
          action.type === actions.pesquisar.SUCCESS
            ? action.payload.response.data.empresa
            : state.empresa,
      };
    case actions.carregarEmpresas.REQUEST:
    case actions.carregarEmpresas.SUCCESS:
    case actions.carregarEmpresas.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          empresas: {
            ...state.dominios.empresas,
            loading: action.type === actions.carregarEmpresas.REQUEST,
            data:
              action.type === actions.carregarEmpresas.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.empresas.data,
          },
        },
      };
    case types.ALTERNAR_BOTAO:
      return {
        ...state,
        botao: state.botao === "voltar" ? "ir" : "voltar",
      };
    default:
      return state;
  }
};

export default reducer;
