import React from "react";
import {
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { getDiaDaSemana } from "../../../utils/constants";
import { DeleteSharp } from "@mui/icons-material";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Noto Sans;
  border-bottom: none !important;
`;

const StyledButtonTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
  justify-contend: flex-end;
`;

const TabelaCriarFuncionamento = ({
  dataFuncionamento,
  handleDeleteFuncionamento,
}) => {
  if (dataFuncionamento.length === 0) {
    return <></>;
  }

  return (
    <StyledTable>
      <TableBody>
        <StyledTableRow>
          <StyledTitleTableCell>Dia da semana</StyledTitleTableCell>
          <StyledTitleTableCell>Hora início</StyledTitleTableCell>
          <StyledTitleTableCell>Hora fim</StyledTitleTableCell>
          <StyledTitleTableCell></StyledTitleTableCell>
        </StyledTableRow>
        {dataFuncionamento &&
          dataFuncionamento.map((item, index) => {
            const handleDelete = () => {
              handleDeleteFuncionamento(item);
            };
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>{getDiaDaSemana(item.dia)}</StyledTableCell>
                <StyledTableCell>{item.inicio}</StyledTableCell>
                <StyledTableCell>{item.fim}</StyledTableCell>
                <StyledButtonTableCell align="right">
                  <IconButton onClick={handleDelete}>
                    <DeleteSharp />
                  </IconButton>
                </StyledButtonTableCell>
              </StyledTableRow>
            );
          })}
      </TableBody>
    </StyledTable>
  );
};

export default TabelaCriarFuncionamento;
