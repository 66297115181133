export const removeNonDigitsFromString = (s) => {
  if (!s) return s;
  return s.replace(/\D/g, "");
};

export const converterData = (d) => {
  if (!d) return d;
  return d.split("-")[2] + "/" + d.split("-")[1] + "/" + d.split("-")[0];
};

export const getValueFromObject = (obj, name, defaultValue) => {
  if (!!obj && !!name) {
    const paths = name.split(/[,[\].]+?/);
    let p = 0;
    while (obj && p < paths.length) {
      obj = obj[paths[p++]];
    }
    return obj === undefined ? defaultValue : obj;
  }

  return undefined;
};

export const padHour = (num) => {
  num = num.toString();
  if (num.length < 2) num = "0" + num;
  return num;
};

export const validateLatLng = (lat) => {
  let pattern = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}");
  return pattern.test(lat);
};

export const convertMinutesInHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const min = minutes % 60;
  const textHours = `00${hours}`.slice(-2);
  const textMinutes = `00${min}`.slice(-2);
  return `${textHours}h:${textMinutes}m`;
};
