import { useDispatch, useSelector } from "react-redux";
import FormFeriadoPage from "./FormEditarFeriadoPage";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import validators from "../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { actions } from "../../../reducers/cadastro/feriados.actions";
import {
  getFeriadosCliente,
  getFeriado,
} from "../../../selectors/cadastro/feriados.selectors";
import formatters from "../../../utils/formatters";

const FormEditarFeriadoPageConnected = () => {
  const { loading, data } = useSelector(getFeriado);
  const cliente = useSelector(getFeriadosCliente);

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    dataFeriado: validators.string({
      required: true,
    }),
  };

  const initialValues = {
    dataFeriado: "",
    dataHoraCriacao: "",
    dataHoraAtualizacao: "",
    nome: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("nome", data.nome);
    formProps.setValue("dataFeriado", data?.dataFeriado);
    formProps.setValue(
      "dataHoraCriacao",
      formatters.dates.withHours(new Date(data?.dataHoraCriacao))
    );
    formProps.setValue(
      "dataHoraAtualizacao",
      formatters.dates.withHours(new Date(data?.dataHoraAtualizacao))
    );
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = () => {
    const id = data.id;
    const nome = formProps.getValues("nome");
    const dataFeriado = formProps.getValues("dataFeriado");

    dispatch(
      actions.editarFeriado.request({
        id,
        nome,
        dataFeriado,
      })
    );
  };

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_FERIADO, { id: cliente }));
  };

  return (
    <FormFeriadoPage
      handleSubmit={handleSubmit}
      handleGoBack={handleClickVoltar}
      formProps={formProps}
      data={data}
      loading={loading}
    />
  );
};

export default FormEditarFeriadoPageConnected;
