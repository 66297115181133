export const funcionamento = [
  {
    value: 1,
    label: "Domingo",
  },
  {
    value: 2,
    label: "Segunda",
  },
  {
    value: 3,
    label: "Terça",
  },
  {
    value: 4,
    label: "Quarta",
  },
  {
    value: 5,
    label: "Quinta",
  },
  {
    value: 6,
    label: "Sexta",
  },
  {
    value: 7,
    label: "Sábado",
  },
];

export const ordemPesquisa = [
  {
    value: "data",
    label: "Data OS",
  },
  {
    value: "inicio",
    label: "Data/hora Início Previsto",
  },
  {
    value: "os",
    label: "Ordem de Serviço",
  },
  {
    value: "roteiro",
    label: "Roteiro",
  },
  {
    value: "dispositivo",
    label: "Dispositivo",
  },
  {
    value: "funcionamento",
    label: "Frequência",
  },
  {
    value: "exec",
    label: "% Progresso",
  },
];
