import React, { useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  Pagination,
  Skeleton,
  styled,
} from "@mui/material";
import { Grid } from "@mui/material";
import RoteiroCard from "./RoteiroCard";
import BasePage from "../../BasePage";
import { Button } from "../../../components";
import { TextField } from "../../../components/inputs";
import { Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/roteiros.actions";

const StyledContainer = styled(Grid)`
  min-height: 60vmin;
  align-content: baseline;
`;

const StyledBottomGrid = styled(Grid)`
  margin-top: 30px;
  @media (max-width: 576px) {
    display: none;
  }
`;
const StyledBottomGridMobile = styled(Grid)`
  margin-top: 30px;
  @media (min-width: 576px) {
    display: none;
  }
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 34px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 34px;
  }
`;

const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const StyledGridCreateButton = styled(Grid)`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
`;
const StyledGridEditButton = styled(Grid)`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
`;

const StyledPagination = styled(Pagination)`
  & .MuiButtonBase-root {
    background-color: #ffffff !important;
    color: #444746;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  & .Mui-selected {
    color: #ffffff;
    background-color: #2f85c2 !important;
    box-shadow: 0px 0px 0px;
  }
`;

const StyledCreateButton = styled(Button)`
  margin-top: 20px;
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledSkeleton = styled(Skeleton)`
  height: 100%;
  width: 100%;
  border-radius: 12px;
`;
const StyledGridItem = styled(Grid)`
  height: 300px;
  margin-top: -45px;
`;

const StyledFormGrid = styled(Grid)`
  place-self: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const StyledTextFieldContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const StyledTextField = styled(TextField)`
max-width: 400px;
margin-top: 20px;

flex: 1!important;

@media (max-width: 900px) {
  max-width: 100%;
  margin-bottom:5px;
  margin-top:5px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
}


  & .MuiInputLabel-root {
    background-color: inherit;
    color: #3F484A !important;
    font-family: ${({ theme }) => theme.fonts.family.form};
    font-weight: 700;


    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }


  & .MuiFormHelperText-root.Mui-error {
    color: #d32f2f!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #3F484A;
    },
    &:hover fieldset {
      border-color: #3F484A;
    },
    &.Mui-focused fieldset {
      border: 1.5px solid #3F484A;
    },
    &.Mui-error fieldset {
    border: 1.5px solid #d32f2f;
    },`;

const RoteiroPage = ({
  data,
  loading,
  handlePagination,
  handleAdd,
  handleDelete,
  handleEdit,
  handleGoBack,
  handleActivate,
  filtroAtual,
  nomeCliente,
  cliente,
}) => {
  const [value, setValue] = useState(filtroAtual !== "" ? filtroAtual : "");
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(filtroAtual);
  }, [filtroAtual]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      actions.obterRoteiros.request({ nome: value, idCliente: cliente.id })
    );
  };

  return (
    <BasePage isLoggedIn={true}>
      <form onSubmit={handleSearch}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <StyledTitle>
              {nomeCliente ? nomeCliente + " - Roteiros" : ""}
            </StyledTitle>
          </Grid>
          <StyledFormGrid item xs={12} sm={12} md={6}>
            <StyledTextFieldContainer>
              <StyledTextField
                onChange={handleChange}
                value={value}
                label="Pesquisar"
                name={"user"}
                margin={"none"}
                InputProps={{
                  style: {
                    textTransform: "lowercase",
                    fontFamily: "Noto Sans",
                    letterSpacing: "1px",
                  },
                  endAdornment: (
                    <InputAdornment position="end" className={"ml-2 mr-3"}>
                      <IconButton type="submit">
                        <Search style={{ color: "#3F484A" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </StyledTextFieldContainer>
          </StyledFormGrid>
        </Grid>
        <StyledContainer container spacing={2}>
          {loading && (
            <>
              <StyledGridItem item xs={12} sm={12} md={4}>
                <StyledSkeleton />
              </StyledGridItem>
              <StyledGridItem item xs={12} sm={12} md={4}>
                <StyledSkeleton />
              </StyledGridItem>
              <StyledGridItem item xs={12} sm={12} md={4}>
                <StyledSkeleton />
              </StyledGridItem>
            </>
          )}
          {data &&
            data.resultado &&
            data.resultado.map((item, index) => {
              return (
                <RoteiroCard
                  key={index}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  item={item}
                  index={index}
                  handleActivate={handleActivate}
                />
              );
            })}
        </StyledContainer>
        {!loading && (
          <StyledBottomGrid container>
            <StyledGridEditButton item xs={12} sm={12} md={4}>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </StyledGridEditButton>

            <StyledGridPagination item xs={12} sm={12} md={4}>
              <StyledPagination
                count={Math.ceil(data.total / 9)}
                page={data.paginaAtual}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </StyledGridPagination>
            <StyledGridCreateButton item xs={12} sm={12} md={4}>
              <StyledCreateButton onClick={handleAdd}>
                Novo Roteiro
              </StyledCreateButton>
            </StyledGridCreateButton>
          </StyledBottomGrid>
        )}
        {!loading && (
          <StyledBottomGridMobile container>
            <StyledGridPagination item xs={12} sm={12} md={4}>
              <StyledPagination
                count={Math.ceil(data.total / 9)}
                page={data.paginaAtual}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </StyledGridPagination>
            <StyledGridEditButton item xs={12} sm={12} md={4}>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </StyledGridEditButton>
            <StyledGridCreateButton item xs={12} sm={12} md={4}>
              <StyledCreateButton onClick={handleAdd}>
                Novo Roteiro
              </StyledCreateButton>
            </StyledGridCreateButton>
          </StyledBottomGridMobile>
        )}
      </form>
    </BasePage>
  );
};

export default RoteiroPage;
