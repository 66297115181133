import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_FERIADOS: "@@alertas/OBTER_FERIADOS",
  MOSTRAR_FERIADO: "@@alertas/MOSTRAR_FERIADO",
  EDITAR_FERIADO: "@@alertas/EDITAR_FERIADO",
  CRIAR_FERIADO: "@@alertas/CRIAR_FERIADO",
  DELETAR_FERIADO: "@@alertas/DELETAR_FERIADO",
  REATIVAR_FERIADO: "@@alertas/REATIVAR_FERIADO",
  REMOVER_FILTRO_FERIADO: "@@alertas/REMOVER_FILTRO_FERIADO",
  REMOVER_PAGINA_FERIADO: "@@alertas/REMOVER_PAGINA_FERIADO",
};

export const actions = {
  obterFeriados: createAsyncAction(types.OBTER_FERIADOS),
  mostrarFeriado: createAsyncAction(types.MOSTRAR_FERIADO),
  editarFeriado: createAsyncAction(types.EDITAR_FERIADO),
  criarFeriado: createAsyncAction(types.CRIAR_FERIADO),
  deletarFeriado: createAsyncAction(types.DELETAR_FERIADO),
  reativarFeriado: createAsyncAction(types.REATIVAR_FERIADO),
  removerFiltroFeriado: () => createSyncAction(types.REMOVER_FILTRO_FERIADO),
  removerPaginaFeriado: () => createSyncAction(types.REMOVER_PAGINA_FERIADO),
};
