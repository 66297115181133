import { createSelector } from "reselect";

export const getMapaMensalCadastro = (state) => state.mapasMensais;

export const getMapasMensais = createSelector(
  getMapaMensalCadastro,
  (state) => state.mapasMensais
);

export const getMapaMensal = createSelector(
  getMapaMensalCadastro,
  (state) => state.mapaMensal
);

export const getMapasMensaisPage = createSelector(
  getMapaMensalCadastro,
  (state) => state.mapasMensais.page
);

export const getMapasMensaisFilter = createSelector(
  getMapaMensalCadastro,
  (state) => state.mapasMensais.filter
);

export const getMapasMensaisCliente = createSelector(
  getMapaMensalCadastro,
  (state) => state.mapasMensais.cliente
);
