import Header from "./Header";
import { getUsuario } from "../../selectors/usuario.selectors";
import { useSelector, useDispatch } from "react-redux";
import { actions as authActions } from "../../reducers/autenticacao.actions";
import { actions as usuariosActions } from "../../reducers/cadastro/usuarios.actions";
import { actions as dispositivosActions } from "../../reducers/cadastro/dispositivos.actions";
import { actions as clientesActions } from "../../reducers/cadastro/clientes.actions";
import {
  types as routes,
  actions as routeActions,
} from "../../reducers/rotas.actions";
import { useCallback } from "react";
import jwtDecode from "jwt-decode";
import { getJwt } from "../../utils/localStorage";
import { styled } from "@mui/material/styles";
import { Home } from "@mui/icons-material";

const StyledHome = styled(Home)`
  width: 30px;
  height: 30px;
`;

const HeaderConnected = () => {
  const { nome, perfil } = useSelector(getUsuario);
  const dispatch = useDispatch();

  const handleClickMenu = useCallback(
    (route) => {
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );

  const handleClickMenuUsuario = useCallback(
    (route) => {
      dispatch(usuariosActions.removerFiltroUsuario());
      dispatch(usuariosActions.removerPaginaUsuario());
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );

  const handleClickMenuDispositivo = useCallback(
    (route) => {
      dispatch(dispositivosActions.removerFiltroDispositivo());
      dispatch(dispositivosActions.removerPaginaDispositivo());
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );

  const handleClickMenuCliente = useCallback(
    (route) => {
      dispatch(clientesActions.removerFiltroCliente());
      dispatch(clientesActions.removerPaginaCliente());
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );

  const menus = [
    {
      id: "home",
      label: <StyledHome />,
      mobileLabel: "Home",
      route: routes.HOME_PAGE,
      perfil: (p) => p !== "E",
      action: handleClickMenu,
    },
    {
      id: "dashboard",
      label: "Mapa",
      route: routes.MAPA,
      perfil: (p) => {
        if (p === "E") {
          const { permissions = [] } = jwtDecode(getJwt());

          return !!permissions.find((a) => a === "MAPA_EU");
        }
        return true;
      },
      action: handleClickMenu,
    },
    {
      id: "alerta",
      label: "Alerta",
      route: routes.PAINEL_ALERTAS,
      action: handleClickMenu,
    },
    {
      id: "diario",
      label: "Operação",
      route: routes.OPERACAO_DIARIA,
      action: handleClickMenu,
    },
    {
      id: "configuracoes",
      label: "Configurações",
      perfil: ["A"],

      menus: [
        {
          id: "cliente",
          label: "Clientes",
          route: routes.CADASTRO_CLIENTE,
          action: handleClickMenuCliente,
          perfil: ["A"],
        },
        {
          id: "dispostivo",
          label: "Dispositivos",
          route: routes.CADASTRO_DISPOSITIVO,
          action: handleClickMenuDispositivo,
          perfil: ["A"],
        },
        {
          id: "usuario",
          label: "Usuários",
          route: routes.CADASTRO_USUARIO,
          action: handleClickMenuUsuario,
          perfil: ["A"],
        },
        {
          id: "cargas",
          label: "Cargas",
          perfil: ["A"],
          menus: [
            {
              id: "cargaArquivo",
              label: "Arquivos",
              route: routes.CARGA_ARQUIVO,
              action: handleClickMenuUsuario,
              perfil: ["A"],
            },
            {
              id: "cargaDispositivos",
              label: "Dispositivos",
              route: routes.CARGA_DISPOSITIVO,
              action: handleClickMenu,
              perfil: ["A"],
            },
            {
              id: "cargaRoteiro",
              label: "Roteiros",
              route: routes.CARGA_ROTEIRO,
              action: handleClickMenuUsuario,
              perfil: ["A"],
            },
            {
              id: "cargaTrocaDispositivos",
              label: "Troca de Dispositivos",
              route: routes.CARGA_TROCAR_DISPOSITIVO,
              action: handleClickMenu,
              perfil: ["A"],
            },
          ],
        },
        {
          id: "ordem-de-servico",
          label: "Ordem de Serviço",
          perfil: ["A"],
          menus: [
            {
              id: "batch-os",
              label: "Geração Manual",
              route: routes.GERACAO_OS,
              action: handleClickMenu,
              perfil: ["A"],
            },
            {
              id: "inconsistencias-os",
              label: "Inconsistências na Geração",
              route: routes.ERRO_OS,
              action: handleClickMenu,
              perfil: ["A"],
            },
            {
              id: "reprocessamento-os",
              label: "Reprocessamento",
              route: routes.REPROCESSAMENTO_OS,
              action: handleClickMenu,
              perfil: ["A"],
            },
          ],
        },

        {
          id: "baterias",
          label: "Baterias",
          route: routes.BATERIAS,
          action: handleClickMenu,
          perfil: ["A"],
        },
      ],
    },
  ];

  const handleClickLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const restrictedMenus = restrictMenus(menus, [], perfil);

  return (
    <Header
      menus={restrictedMenus}
      username={nome}
      handleClickLogout={handleClickLogout}
    />
  );
};

const restrictMenus = (menus, current, perfil) => {
  menus.forEach((menu) => {
    if (!menu.perfil) {
      if (!menu.menus) {
        current.push(menu);
      } else {
        const restricted = {
          ...menu,
          menus: restrictMenus(menu.menus, [], perfil),
        };
        current.push(restricted);
      }
    } else {
      const canAccess =
        typeof menu.perfil === "function"
          ? menu.perfil(perfil)
          : menu.perfil.indexOf(perfil) > -1;
      if (canAccess) {
        if (!menu.menus) {
          current.push(menu);
        } else {
          const restricted = {
            ...menu,
            menus: restrictMenus(menu.menus, [], perfil),
          };
          current.push(restricted);
        }
      }
    }
  });
  return current;
};

export default HeaderConnected;
