import { styled } from "@mui/material";
import { transientOptions } from "../../styles/theme";

const Title = styled("h1", transientOptions)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-weight: 400;
  letter-spacing: -0.25px;
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 43px;

  @media (min-width: 576px) {
    font-size: 57px;
    margin-top: 25px;
    margin-left: unset;
  }
`;

export default Title;
