import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  SELECIONAR_OPCOES: "@@diario/SELECIONAR_OPCOES",
  ALTERNAR_MAIS_CARDS: "@@diario/ALTERNAR_MAIS_CARDS",
  ATUALIZAR_FILTROS: "@@diario/ATUALIZAR_FILTROS",
  EXIBIR_FILTROS: "@@diario/EXIBIR_FILTROS",
  SELECIONAR_OS: "@@diario/SELECIONAR_OS",
  ATUALIZAR_SELECIONAR_OS: "@@diario/ATUALIZAR_SELECIONAR_OS",
  PESQUISAR: "@@diario/PESQUISAR",

  DOMINIO_EMPRESAS: "@@diario/DOMINIO_EMPRESAS",
  CLEAN_ROTEIRO_DATA: "@@diario/CLEAN_ROTEIRO_DATA",
  CLEAN_OS_DATA: "@@diario/CLEAN_OS_DATA",
  ALTERNAR_BOTAO: "@@diario/ALTERNAR_BOTAO",
};

export const actions = {
  selecionarOpcoes: (propriedade, valor) =>
    createSyncAction(types.SELECIONAR_OPCOES, { propriedade, valor }),
  alternarMaisCards: () => createSyncAction(types.ALTERNAR_MAIS_CARDS),
  selecionarOs: (item) => createSyncAction(types.SELECIONAR_OS, { item }),
  atualizarFiltros: (filtros) =>
    createSyncAction(types.ATUALIZAR_FILTROS, { filtros }),
  exibirFiltros: (visible) =>
    createSyncAction(types.EXIBIR_FILTROS, { visible }),
  alternarBotao: () => createSyncAction(types.ALTERNAR_BOTAO),

  pesquisar: createAsyncAction(types.PESQUISAR),

  carregarEmpresas: createAsyncAction(types.DOMINIO_EMPRESAS),
  cleanRoteiroData: () => createSyncAction(types.CLEAN_ROTEIRO_DATA),
  cleanOsData: () => createSyncAction(types.CLEAN_OS_DATA),
  atualizarSelecionarOs: createAsyncAction(types.ATUALIZAR_SELECIONAR_OS),
};
