import BasePage from "../BasePage";
import React from "react";
import { Box, Card, IconButton, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { Button } from "../../components";
import {
  CloudUpload,
  Delete,
  Description,
  Download,
} from "@mui/icons-material";
import FileInput from "../../components/inputs/FileInput";
import { toast } from "react-toastify";
import IconLoader from "../../components/loaders/IconLoader";
import TabelaInconsistenciaDispositivo from "./TabelaInconsistenciaDispositivo";
import TabelaResumoDispositivo from "./TabelaResumoDispositivo";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;

const StyledAtualizarButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const ButtonContainerLeft = styled("div")`
  display: flex;
  margin-left: 0px;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;
const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;
const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #3f484a85;
  border-radius: 15px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  align-items: center;
  background-color: #ebf2f8ff;

  border: 2px solid #3f484a85;
  border-radius: 15px;
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledH5 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 1.2px;
  font-size: 16px;
  fot-weight: 800;
  margin-bottom: -37.5px;

  @media (max-width: 900px) {
    margin-bottom: auto;
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;
const StyledSpan = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 1.2px;
  font-size: 16px;
  fot-weight: 800;

  @media (max-width: 900px) {
    margin-bottom: auto;
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const CargaDispositivo = ({
  data,
  loading,
  formProps,
  handleSubmit,
  loadingCliente,
  submitting,
  dataCliente,
  handleDownload,
  file,
  setFile,
  dataInconsistencias,
  openInconsistencias,
  dataResumo,
  openResumo,
  isFile,
  setIsFile,
  loadingUpload,
  loadingBaixar,
}) => {
  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Carga de Dispositivos</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Carga de Dispositivos</StyledTitle>
      <StyledForm onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledCardTitle>Carga de Dispositivos</StyledCardTitle>
          <StyledGridContainer sx={{ marginTop: "-5px" }} container spacing={2}>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledBoxFile
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {!loadingBaixar && (
                  <>
                    <StyledH4>
                      <Description
                        style={{
                          fontSize: "60px",
                          marginTop: "15px",
                          color: "#33c481",
                        }}
                      />
                    </StyledH4>
                    <StyledH4>
                      Baixar planilha padrão para Dispositivos
                    </StyledH4>
                    <StyledH4>
                      <IconButton onClick={handleDownload}>
                        <Download
                          style={{
                            color: "#33c481",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </StyledH4>
                  </>
                )}
                {loadingBaixar && (
                  <IconLoader
                    sx={{
                      color: "#3F484A",
                      opacity: "0.8",
                    }}
                  />
                )}
              </StyledBoxFile>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <FileInput
                name={"arquivo"}
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length === 0) {
                    toast.error("Formato de arquivo inválido", {
                      theme: "colored",
                      icon: false,
                      style: { backgroundColor: "#990a26" },
                    });
                  }
                  if (acceptedFiles.length > 0) {
                    setFile(acceptedFiles);
                    setIsFile(true);
                  }
                }}
                formProps={formProps}
                maxFiles={1}
                multiple={false}
                acceptMime={{
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                    ".xlsx",
                  ],
                }}
              >
                {isFile && (
                  <StyledBoxFile
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {!loadingUpload && (
                      <>
                        <StyledH4>
                          <Description
                            style={{
                              fontSize: "60px",
                              marginTop: "15px",
                              color: "#33c481",
                            }}
                          />
                        </StyledH4>
                        <StyledH4
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            fontSize: `${
                              file[0].name.length > 25 ? "10px" : "12px"
                            }`,
                          }}
                        >
                          {file[0].name}
                        </StyledH4>
                        <StyledH4>
                          <IconButton
                            onClick={(e) => {
                              setFile([]);
                              setIsFile(false);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <Delete
                              style={{
                                color: "#f00e0e",
                                cursor: "pointer",
                                fontSize: "30px",
                              }}
                            />
                          </IconButton>
                        </StyledH4>
                      </>
                    )}
                    {loadingUpload && (
                      <IconLoader
                        sx={{
                          color: "#3F484A",
                          opacity: "0.8",
                        }}
                      />
                    )}
                  </StyledBoxFile>
                )}

                {!isFile && (
                  <StyledBox
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      "&:hover": {
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <StyledH4 style={{ marginTop: "50px" }}>
                      Arraste o arquivo para cá
                    </StyledH4>
                    <StyledH4>ou clique para selecioná-lo</StyledH4>
                    <StyledH4>(somente arquivos com extensão .xlsx)</StyledH4>
                    <StyledH4>
                      <CloudUpload
                        style={{ fontSize: "30px", marginTop: "-5px" }}
                      />
                    </StyledH4>
                  </StyledBox>
                )}
              </FileInput>
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={12}>
              <StyledH5>ORIENTAÇÕES:</StyledH5>
              <StyledSpan style={{ marginLeft: "130px" }}>
                1 – Para subir novas Configurações, é necessário primeiramente
                baixar uma nova planilha.
              </StyledSpan>
              <StyledSpan style={{ marginLeft: "130px" }}>
                2 – O arquivo não deve ter seu nome ou estrutura interna
                modificada.
              </StyledSpan>
            </StyledGrid>
          </StyledGridContainer>
        </StyledCard>
        {openInconsistencias && (
          <TabelaInconsistenciaDispositivo
            dataInconsistencias={dataInconsistencias}
          />
        )}
        {openResumo && <TabelaResumoDispositivo dataResumo={dataResumo} />}
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12} md={12}>
            {!openResumo && (
              <ButtonContainerLeft>
                <StyledAtualizarButton
                  disabled={loadingUpload}
                  type={"submit"}
                  loading={loadingUpload}
                >
                  Processar
                </StyledAtualizarButton>
              </ButtonContainerLeft>
            )}
          </Grid>
        </Grid>
      </StyledForm>
    </BasePage>
  );
};

export default CargaDispositivo;
