import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/baterias.actions";
import {
  getBaterias,
  getBateriasOrder,
  getBateriasPageSize,
  getCSV,
  getDominioDispositivos,
  getDominioEmpresas,
} from "../../selectors/baterias.selectors";
import BateriaPage from "./BateriaPage";

const BateriaPageConnected = () => {
  const dispatch = useDispatch();

  const { data: dominioDispositivos } = useSelector(getDominioDispositivos);
  const { data: dominioEmpresas } = useSelector(getDominioEmpresas);
  const { data, loading } = useSelector(getBaterias);
  const dataCSV = useSelector(getCSV);
  const pageSize = useSelector(getBateriasPageSize);
  const order = useSelector(getBateriasOrder);

  const handleDownloadCsv = useCallback(() => {
    let cliente = formProps.getValues("cliente");
    let dispositivo = formProps.getValues("dispositivo");
    let dataHoraNivelBateria = formProps.getValues("dataHoraNivelBateria");
    let nivelBateriaMin = formProps.getValues("nivelBateriaMin");
    let nivelBateriaMax = formProps.getValues("nivelBateriaMax");
    let nivelBateria = formProps.getValues("nivelBateria");

    if (nivelBateriaMin && !nivelBateriaMax) {
      nivelBateriaMax = 100;
    }
    if (!nivelBateriaMin && nivelBateriaMax) {
      nivelBateriaMin = 0;
    }

    const request = {
      cliente,
      dispositivo,
      dataHoraNivelBateria,
      nivelBateriaMin,
      nivelBateriaMax,
      nivelBateria,
    };

    dispatch(actions.downloadCSV.request(request));
    // eslint-disable-next-line
  }, [dispatch]);

  const handleOrderClick = useCallback(
    (field) => {
      dispatch(actions.atualizarOrdem(field));
    },
    [dispatch]
  );

  const handleCleanFile = useCallback(() => {
    dispatch(actions.cleanFile());
  }, [dispatch]);

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterBaterias.request({
        ...data.filtros,
        pagina: page,
      })
    );
  };

  const dominioNivel = [
    { tipo: "Baixo", valor: "B", id: 1 },
    { tipo: "Normal", valor: "N", id: 2 },
    {
      tipo: "Alto",
      valor: "A",
      id: 3,
    },
  ];

  const rules = {};

  const initialValues = {
    cliente: "",
    dispositivo: "",
    dataHoraNivelBateria: "",
    nivelBateriaMin: "",
    nivelBateriaMax: "",
    nivelBateria: "",
  };
  const defaultValues = {
    cliente: "",
    dispositivo: "",
    dataHoraNivelBateria: "",
    nivelBateriaMin: "",
    nivelBateriaMax: "",
    nivelBateria: "",
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const handleSubmit = (values) => {
    let cliente = formProps.getValues("cliente");
    let dispositivo = formProps.getValues("dispositivo");
    let dataHoraNivelBateria = formProps.getValues("dataHoraNivelBateria");
    let nivelBateriaMin = formProps.getValues("nivelBateriaMin");
    let nivelBateriaMax = formProps.getValues("nivelBateriaMax");
    let nivelBateria = formProps.getValues("nivelBateria");

    if (nivelBateriaMin && !nivelBateriaMax) {
      nivelBateriaMax = 100;
    }
    if (!nivelBateriaMin && nivelBateriaMax) {
      nivelBateriaMin = 0;
    }

    const request = {
      cliente,
      dispositivo,
      dataHoraNivelBateria,
      nivelBateriaMin,
      nivelBateriaMax,
      nivelBateria,
    };

    dispatch(actions.obterBaterias.request(request));
  };

  return (
    <BateriaPage
      formProps={formProps}
      dominioEmpresas={dominioEmpresas}
      dominioDispositivos={dominioDispositivos}
      handleSearch={handleSubmit}
      dominioNivel={dominioNivel}
      data={data}
      pageSize={pageSize}
      order={order}
      handleOrderClick={handleOrderClick}
      handlePagination={handlePagination}
      handleDownloadCsv={handleDownloadCsv}
      handleCleanFile={handleCleanFile}
      dataCSV={dataCSV}
      loading={loading}
    />
  );
};

export default BateriaPageConnected;
