import basicFlow from "../asyncHandler";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { actions as usuarios } from "../../reducers/cadastro/usuarios.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getPayload } from "../../selectors/routes.selectors";
import {
  getUsuariosFilter,
  getUsuariosPage,
} from "../../selectors/cadastro/usuarios.selectors";

const ListarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/listar`,
    isResourceService: true,
    method: "post",
    body: {
      nome: values?.nome ?? "",
      pagina: values?.pagina ?? 1,
      limite: values?.limite ?? 9,
    },
  });
};

const ListarUsuarios = basicFlow({
  actionGenerator: usuarios.obterUsuarios,
  api: ListarUsuariosApi,
});

function* ListarUsuariosRouteWatcher() {
  yield routeWatcher(routes.CADASTRO_USUARIO, function* () {
    const pagina = yield select(getUsuariosPage);
    const nome = yield select(getUsuariosFilter);
    yield put(usuarios.obterUsuarios.request({ nome, pagina }));
  });
}

const EditarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      nome: values.nome,
      email: values.email,
      codigo: values.codigo,
      situacao: values.situacao,
      perfil: values.perfil,
      empresa: values?.empresa?.id ?? values?.empresa ?? null,
    },
  });
};

const EditarUsuarios = basicFlow({
  actionGenerator: usuarios.editarUsuario,
  api: EditarUsuariosApi,
  postSuccess: function* () {
    yield toast.success("Usuário atualizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(routeActions.redirectTo(routes.CADASTRO_USUARIO));
    const pagina = yield select(getUsuariosPage);
    const nome = yield select(getUsuariosFilter);
    yield put(usuarios.obterUsuarios.request({ nome, pagina }));
  },
});

const ReativarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: values.situacao,
    },
  });
};

const ReativarUsuarios = basicFlow({
  actionGenerator: usuarios.reativarUsuario,
  api: ReativarUsuariosApi,
  postSuccess: function* () {
    yield toast.success("Usuário reativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(usuarios.obterUsuarios.request());
  },
});

const criarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario`,
    isResourceService: true,
    method: "post",
    body: {
      nome: values.nome,
      email: values.email,
      codigo: values.codigo,
      perfil: values.perfil,
      empresa: values.empresa.id,
    },
  });
};

const CriarUsuarios = basicFlow({
  actionGenerator: usuarios.criarUsuario,
  api: criarUsuariosApi,
  postSuccess: function* () {
    yield toast.success("Usuário criado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
    yield put(routeActions.redirectTo(routes.CADASTRO_USUARIO));
  },
});

const deletarUsuariosApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/update`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      situacao: "I",
    },
  });
};

const DeletarUsuarios = basicFlow({
  actionGenerator: usuarios.deletarUsuario,
  api: deletarUsuariosApi,
  postSuccess: function* () {
    yield toast.success("Usuário inativado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });

    yield put(usuarios.obterUsuarios.request());
  },
});

const MostrarUsuarioApi = (values) => {
  return authenticatedRequest({
    url: `/usuario/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarUsuario = basicFlow({
  actionGenerator: usuarios.mostrarUsuario,
  transform: function* (payload) {
    const id = yield select((state) => state.usuarios.id);
    return { id, ...payload };
  },

  api: MostrarUsuarioApi,
});

function* MostrarUsuarioRouteWatcher() {
  yield routeWatcher(routes.EDITAR_USUARIO, function* () {
    const usuario = yield select(getPayload);

    yield put(usuarios.mostrarUsuario.request({ id: usuario.id }));
  });
}

export const sagas = [
  MostrarUsuarioRouteWatcher(),
  ListarUsuariosRouteWatcher(),
  ListarUsuarios.watcher(),
  MostrarUsuario.watcher(),
  EditarUsuarios.watcher(),
  CriarUsuarios.watcher(),
  DeletarUsuarios.watcher(),
  ReativarUsuarios.watcher(),
];
