import { actions, types } from "./baterias.actions";

const initialState = {
  id: null,
  baterias: {
    loading: false,
    data: [],
    page: 1,
    pageSize: 50,
    filter: "",
    cliente: "",
    order: {
      field: "dispositivo.nome",
      direction: "asc",
    },
  },
  dominios: {
    empresas: {
      loading: true,
      data: [],
    },
    dispositivos: {
      loading: true,
      data: [],
    },
  },
  csv: {
    loading: false,
    file_name: "",
    file: "",
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterBaterias.REQUEST:
    case actions.obterBaterias.SUCCESS:
    case actions.obterBaterias.FAILURE:
      return {
        ...state,
        baterias: {
          ...state.baterias,
          loading: action.type === actions.obterBaterias.REQUEST,
          data:
            action.type === actions.obterBaterias.SUCCESS
              ? action.payload.response.data
              : [],
          filter:
            action.type === actions.obterBaterias.SUCCESS
              ? action.payload?.response?.data?.filtros ?? ""
              : "",
          cliente:
            action.type === actions.obterBaterias.SUCCESS
              ? action.payload?.response?.data?.cliente ?? ""
              : "",
          nomeCliente:
            action.type === actions.obterBaterias.SUCCESS
              ? action.payload?.response?.data?.nomeCliente ?? ""
              : "",
          page:
            action.type === actions.obterBaterias.SUCCESS
              ? action.payload?.response?.data?.paginaAtual ?? 1
              : 1,
        },
      };
    case types.UPDATE_PAGE_SIZE:
      return {
        ...state,
        baterias: {
          ...state.baterias,
          pageSize: action.payload.pageSize,
        },
      };
    case types.UPDATE_ORDER:
      return {
        ...state,
        baterias: {
          ...state.baterias,
          order: {
            field: action.payload.field,
            direction:
              action.payload.field === state.baterias.order.field
                ? state.baterias.order.direction === "asc"
                  ? "desc"
                  : "asc"
                : "asc",
          },
        },
      };
    case types.REMOVER_FILTRO_BATERIA:
      return {
        ...state,
        baterias: {
          ...state.baterias,
          filter: "",
        },
      };
    case types.REMOVER_PAGINA_BATERIA:
      return {
        ...state,
        baterias: {
          ...state.baterias,
          page: 1,
        },
      };

    case actions.carregarEmpresas.REQUEST:
    case actions.carregarEmpresas.SUCCESS:
    case actions.carregarEmpresas.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          empresas: {
            ...state.dominios.empresas,
            loading: action.type === actions.carregarEmpresas.REQUEST,
            data:
              action.type === actions.carregarEmpresas.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.empresas.data,
          },
        },
      };
    case actions.carregarDispositivos.REQUEST:
    case actions.carregarDispositivos.SUCCESS:
    case actions.carregarDispositivos.FAILURE:
      return {
        ...state,
        dominios: {
          ...state.dominios,
          dispositivos: {
            ...state.dominios.dispositivos,
            loading: action.type === actions.carregarDispositivos.REQUEST,
            data:
              action.type === actions.carregarDispositivos.SUCCESS
                ? [...action.payload.response.data]
                : state.dominios.dispositivos.data,
          },
        },
      };

    case actions.downloadCSV.REQUEST:
    case actions.downloadCSV.SUCCESS:
    case actions.downloadCSV.FAILURE:
      return {
        ...state,
        csv: {
          loading: action.type === actions.downloadCSV.REQUEST,
          file_name:
            action.type === actions.downloadCSV.SUCCESS
              ? action.payload.response.data.file_name
              : "",
          file:
            action.type === actions.downloadCSV.SUCCESS
              ? action.payload.response.data.file
              : "",
        },
      };
    case types.CLEAN_FILE:
      return {
        ...state,
        csv: {
          ...initialState.csv,
        },
      };
    case types.CLEAN_LIST:
      return {
        ...state,
        baterias: {
          ...initialState.baterias,
        },
      };

    default:
      return state;
  }
};

export default reducer;
