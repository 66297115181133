import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions } from "../../../reducers/cadastro/integracoes.actions";
import { getIntegracoes } from "../../../selectors/cadastro/integracoes.selectors";
import IntegracaoPage from "./IntegracaoPage";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { getPayload } from "../../../selectors/routes.selectors";

const IntegracaoPageConnected = () => {
  const { loading, data, filter, nomeCliente } = useSelector(getIntegracoes);
  const cliente = useSelector(getPayload);

  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterIntegracoes.request({
        pagina: page,
        url: filter,
        idCliente: cliente?.id,
      })
    );
  };

  const handleDelete = (e, id) => {
    dispatch(actions.deletarIntegracao.request({ id }));
  };

  const handleActivate = (e, id) => {
    dispatch(actions.reativarIntegracao.request({ id, situacao: "A" }));
  };

  const handleEdit = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.EDITAR_INTEGRACAO, {
        id: id,
      })
    );
  };

  const handleAdd = () => {
    dispatch(routeActions.redirectTo(routes.CRIAR_INTEGRACAO));
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_CLIENTE));
  };

  return (
    <IntegracaoPage
      data={data}
      loading={loading}
      handlePagination={handlePagination}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleGoBack={handleGoBack}
      handleActivate={handleActivate}
      filtroAtual={filter}
      nomeCliente={nomeCliente}
      cliente={cliente}
    />
  );
};

export default IntegracaoPageConnected;
