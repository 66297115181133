import { useForm } from "react-hook-form";
import FormCriarIntegracaoPage from "./FormCriarRoteiroPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/roteiros.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import {
  getGrupos,
  getRoteiro,
  getRoteirosDispositivos,
  getTipoDispositivos,
  getTipoVeiculos,
} from "../../../selectors/cadastro/roteiros.selectors";
import formatters from "../../../utils/formatters";
import { useState } from "react";
import { getPayload } from "../../../selectors/routes.selectors";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { validarPlanilha } from "../../../utils/validarPlanilha";
import { getCliente } from "../../../selectors/cadastro/clientes.selectors";
import { useEffect } from "react";

const FormCriarRoteiroPageConnected = () => {
  const { loading } = useSelector(getRoteiro);
  const { data: dataGrupos, loading: loadingGrupos } = useSelector(getGrupos);
  const { data: dataTipoVeiculos, loading: loadingTipoVeiculos } = useSelector(
    getTipoVeiculos
  );
  const {
    data: dataTipoDispositivos,
    loading: loadingTipoDispositivos,
  } = useSelector(getTipoDispositivos);
  const { data: dataDispositivos, loading: loadingDispositivos } = useSelector(
    getRoteirosDispositivos
  );
  const [dataFuncionamento, setDataFuncionamento] = useState([]);
  const [dataPosicao, setDataPosicao] = useState([]);
  const [dataKml, setDataKml] = useState([]);
  const [grupo, setGrupo] = useState("");
  const idCliente = useSelector(getPayload);
  const [isRoteiro, setIsRoteiro] = useState(false);
  const { data: dataCliente } = useSelector(getCliente);

  useEffect(() => {
    if (dataCliente?.roteiro === "N") {
      setIsRoteiro(true);
    } else {
      setIsRoteiro(false);
    }
  }, [dataCliente]);

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    grupo: validators.object({
      custom: {
        requiredIfOther: (value) => {},
      },
    }),
    metragem: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          }
          if (!!value && value > 2147483647) {
            return !!value || "Valor máximo (2147483647)";
          } else {
            return true;
          }
        },
      },
    }),
    equipe: validators.string({}),
    servico: validators.string({}),
    dispositivo: validators.object({ required: true }),
  };

  const initialValues = {
    nome: "",
    grupos: "",
    dispositivo: "",
    dispositivoBackup: "",
    situacao: "A",
    metragem: "",
    equipe: "",
    servico: "",
    tipoDispositivo: "",
    tipoVeiculo: "",
    dataHoraCriacao: "",
    dataHoraAtualizacao: "",
    feriado: false,
  };
  const defaultValues = {
    situacao: "A",
    dataHoraCriacao: formatters.dates.withHours(new Date()),
    dataHoraAtualizacao: formatters.dates.withHours(new Date()),
  };

  const initialValuesFuncionamento = {
    dia: "",
    inicio: "",
    termino: "",
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const rulesFuncionamento = {
    dia: validators.string({ required: true }),
    inicio: validators.string({
      require: true,
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return !!value || "Campo obrigatório";
          }
          if (value.length !== 5) {
            return "Horário inválido";
          }

          let hour = Number(value.slice(0, 2));
          let minute = Number(value.slice(3, 5));

          if (hour < 0 || hour > 23) {
            return "Horário inválido";
          }
          if (minute < 0 || minute > 59) {
            return "Horário inválido";
          } else {
            return true;
          }
        },
      },
    }),
    fim: validators.string({
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            return !!value || "Campo obrigatório";
          }
          if (value.length !== 5) {
            return "Horário inválido";
          }
          let hour = Number(value.slice(0, 2));
          let minute = Number(value.slice(3, 5));

          if (hour < 0 || hour > 23) {
            return "Horário inválido";
          }
          if (minute < 0 || minute > 59) {
            return "Horário inválido";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const formPropsFuncionamento = {
    ...useForm({ defaultValues: { ...initialValuesFuncionamento } }),
    rules: { ...rulesFuncionamento },
    initialValues: { ...initialValuesFuncionamento },
  };

  const handleSubmit = (values) => {
    const feriado = formProps.getValues("feriado");
    values.funcionamentos = dataFuncionamento;
    values.idEmpresa = idCliente.id;
    values.posicao = dataPosicao;
    values.kml = dataKml;
    values.feriado = feriado ? "S" : "N";

    if (!isRoteiro) {
      if (
        dataPosicao.length === 0 ||
        dataKml.length === 0 ||
        dataFuncionamento.length === 0
      ) {
        toast.error("Favor preencher Funcionamento, Posições e KML", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else {
        dispatch(actions.criarRoteiro.request(values));
      }
    } else {
      if (dataFuncionamento.length === 0) {
        toast.error("Favor preencher Posições", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else {
        dispatch(actions.criarRoteiro.request(values));
      }
    }
  };

  const handleSubmitFuncionamento = (values, event) => {
    let inicio = new Date();
    let hourInicio = Number(values.inicio.slice(0, 2));
    let minuteInicio = Number(values.inicio.slice(3, 5));
    inicio.setHours(hourInicio);
    inicio.setMinutes(minuteInicio);
    inicio.setSeconds(0);
    let fim = new Date();
    let hourFim = Number(values.fim.slice(0, 2));
    let minuteFim = Number(values.fim.slice(3, 5));
    fim.setHours(hourFim);
    fim.setMinutes(minuteFim);
    fim.setSeconds(0);

    let novoFuncionamento = {
      dia: values.dia,
      inicio: values.inicio,
      fim: values.fim,
    };

    let existe = dataFuncionamento.find((data) => {
      return data.dia === novoFuncionamento.dia;
    });

    if (existe) {
      toast.error("Horário já cadastrado para este dia", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    }

    if (!existe) {
      const novaDataFuncionamento = [
        ...dataFuncionamento,
        { ...novoFuncionamento },
      ];
      setDataFuncionamento(novaDataFuncionamento.sort((a, b) => a.dia - b.dia));
    }
  };

  const handleDeleteFuncionamento = (values) => {
    setDataFuncionamento(dataFuncionamento.filter((item) => item !== values));
  };

  const handleAddPosicao = async (values) => {
    if (values.length === 0) {
      toast.error("Arquivo com extensão inválida", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      let existe = dataPosicao.find((data) => {
        return (
          data[0].name === values[0].name && data[0].size === values[0].size
        );
      });

      if (existe) {
        toast.error("O arquivo já se encontra na lista", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else {
        if (values[0].type === "text/csv") {
          function parseData(fileToParse, callBack) {
            Papa.parse(fileToParse, {
              download: true,
              delimiter: "\t",
              complete: function (results) {
                callBack(results.data);
              },
            });
          }

          parseData(values[0], validarCsv);

          function validarCsv(data) {
            if (data.length === 0) {
              toast.error("Arquivo CSV inválido", {
                theme: "colored",
                icon: false,
                style: { backgroundColor: "#990a26" },
              });
            } else {
              let header = data[0][0];
              if (header.length > 18) {
                toast.error("Arquivo CSV inválido", {
                  theme: "colored",
                  icon: false,
                  style: { backgroundColor: "#990a26" },
                });
              } else {
                const posicoes = data
                  .map((item) => {
                    let latitude = item[0].substring(0, item[0].indexOf(";"));
                    let longitude = item[0].substring(item[0].indexOf(";") + 1);
                    return {
                      latitude,
                      longitude,
                    };
                  })
                  .filter(
                    (item) => item.latitude !== "" && item.longitude !== ""
                  );
                values.date = new Date();
                values.posicoes = posicoes;
                setDataPosicao([...dataPosicao, values]);
              }
            }
          }
        } else {
          const valida = await validarPlanilha(values[0]);

          if (valida.status === "Ok") {
            values.date = new Date();
            values.posicoes = valida.posicoes;
            setDataPosicao([...dataPosicao, values]);
          } else {
            toast.error("Planilha inválida", {
              theme: "colored",
              icon: false,
              style: { backgroundColor: "#990a26" },
            });
          }
        }
      }
    }
  };

  const handleAddKml = (values) => {
    if (values.length === 0) {
      toast.error("Arquivo com extensão inválida", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      let existe = dataKml.find((data) => {
        return (
          data[0].name === values[0].name && data[0].size === values[0].size
        );
      });

      if (existe) {
        toast.error("O arquivo já se encontra na lista", {
          theme: "colored",
          icon: false,
          style: { backgroundColor: "#990a26" },
        });
      } else {
        values.date = new Date();
        setDataKml([...dataKml, values]);
      }
    }
  };

  const handleDeletePosicao = (values) => {
    setDataPosicao(dataPosicao.filter((item) => item !== values));
  };
  const handleDeleteKml = (values) => {
    setDataKml(dataKml.filter((item) => item !== values));
  };

  const handleCriarGrupo = useCallback(
    (values) => {
      values.idEmpresa = idCliente.id;
      dispatch(actions.criarGrupo.request(values));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_ROTEIRO, { id: idCliente.id })
    );
  };

  return (
    <FormCriarIntegracaoPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      dataGrupos={dataGrupos}
      loadingGrupos={loadingGrupos}
      handleCriarGrupo={handleCriarGrupo}
      grupo={grupo}
      setGrupo={setGrupo}
      formPropsFuncionamento={formPropsFuncionamento}
      handleSubmitFuncionamento={handleSubmitFuncionamento}
      dataFuncionamento={dataFuncionamento}
      handleDeleteFuncionamento={handleDeleteFuncionamento}
      dataDispositivos={dataDispositivos}
      loadingDispositivos={loadingDispositivos}
      dataTipoVeiculos={dataTipoVeiculos}
      loadingTipoVeiculos={loadingTipoVeiculos}
      dataTipoDispositivos={dataTipoDispositivos}
      loadingTipoDispositivos={loadingTipoDispositivos}
      dataPosicao={dataPosicao}
      setDataPosicao={setDataPosicao}
      dataKml={dataKml}
      setDataKml={setDataKml}
      handleDeletePosicao={handleDeletePosicao}
      handleDeleteKml={handleDeleteKml}
      handleAddPosicao={handleAddPosicao}
      handleAddKml={handleAddKml}
    />
  );
};

export default FormCriarRoteiroPageConnected;
