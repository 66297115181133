import React from "react";
import {
  Card,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: Noto Sans;
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: Noto Sans;
  border-bottom: none !important;
`;

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;

const TabelaInconsistencia = ({ dataInconsistencias }) => {
  return (
    <StyledCard>
      <StyledCardTitle>Inconsistências</StyledCardTitle>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell>Linha</StyledTitleTableCell>
            <StyledTitleTableCell>Campo</StyledTitleTableCell>
            <StyledTitleTableCell>Inconsitência</StyledTitleTableCell>
          </StyledTableRow>
          {dataInconsistencias &&
            dataInconsistencias.map((item, index) => {
              return (
                <StyledTableRow key={index + item.campo}>
                  <StyledTableCell>{item.linha}</StyledTableCell>
                  <StyledTableCell>{item.campo}</StyledTableCell>
                  <StyledTableCell>{item.detalhe}</StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </StyledCard>
  );
};

export default TabelaInconsistencia;
