import { createSelector } from "reselect";

export const getPainelAlerta = (state) => state.painelAlerta;
export const getDominios = createSelector(
  getPainelAlerta,
  (state) => state.dominios
);

export const getFiltros = createSelector(
  getPainelAlerta,
  (state) => state.filtros
);
export const getDrawer = createSelector(
  getPainelAlerta,
  (state) => state.drawer
);
export const getOrder = createSelector(getPainelAlerta, (state) => state.order);

export const getDominioEmpresas = createSelector(
  getDominios,
  (state) => state.empresas
);
export const getDominioDispositivos = createSelector(
  getDominios,
  (state) => state.dispositivos
);

export const isLoadingItems = createSelector(
  getPainelAlerta,
  (state) => state.loading
);
export const isUpdatingItem = createSelector(
  getPainelAlerta,
  (state) => state.updating
);
export const getItems = createSelector(
  getPainelAlerta,
  (state) => state.items
);
export const getTotalItems = createSelector(
  getPainelAlerta,
  (state) => state.total
);
export const getPageSize = createSelector(
  getPainelAlerta,
  (state) => state.pageSize
);
export const getPage = createSelector(
  getPainelAlerta,
  (state) => state.page
);

export const getOs = createSelector(getPainelAlerta, (state) => state.os);
