import { useDispatch, useSelector } from "react-redux";
import FormMapaMensalPage from "./FormEditarMapaMensalPage";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import validators from "../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { actions } from "../../../reducers/cadastro/mapasMensais.actions";
import {
  getMapaMensal,
  getMapasMensais,
} from "../../../selectors/cadastro/mapasMensais.selectors";
import formatters from "../../../utils/formatters";

const FormEditarMapaMensalPageConnected = () => {
  const { loading, data } = useSelector(getMapaMensal);
  const { cliente } = useSelector(getMapasMensais);

  const dispatch = useDispatch();

  const rules = {
    descricao: validators.string({ required: true }),
    porcentagemInicial: validators.string({ required: true }),
    porcentagemFinal: validators.string({ required: true }),
    cor: validators.string({ required: true }),
  };

  const initialValues = {
    descricao: "",
    porcentagemInicial: "",
    porcentagemFinal: "",
    cor: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("descricao", data.descricao);
    formProps.setValue("porcentagemInicial", Number(data?.porcentagemInicial));
    formProps.setValue("porcentagemFinal", Number(data?.porcentagemFinal));
    formProps.setValue("cor", data?.cor);
    formProps.setValue(
      "dataHoraCriacao",
      formatters.dates.withHours(new Date(data?.dataHoraCriacao))
    );
    formProps.setValue(
      "dataHoraAtualizacao",
      formatters.dates.withHours(new Date(data?.dataHoraAtualizacao))
    );
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = () => {
    const id = data.id;
    const descricao = formProps.getValues("descricao");
    const porcentagemInicial = formProps.getValues("porcentagemInicial");
    const porcentagemFinal = formProps.getValues("porcentagemFinal");
    const cor = formProps.getValues("cor");

    dispatch(
      actions.editarMapaMensal.request({
        id,
        descricao,
        porcentagemInicial,
        porcentagemFinal,
        cor,
      })
    );
  };

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_MAPA_MENSAL, { id: cliente })
    );
  };

  return (
    <FormMapaMensalPage
      handleSubmit={handleSubmit}
      handleGoBack={handleClickVoltar}
      formProps={formProps}
      data={data}
      loading={loading}
    />
  );
};

export default FormEditarMapaMensalPageConnected;
