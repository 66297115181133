import React from "react";
import {
  Card,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import formatters from "../../utils/formatters";
import RoundIconButton from "../../components/buttons/RoundIconButton";
import { ArrowDropDown, ArrowDropUp, Download } from "@mui/icons-material";
import PageSizeSelector from "./PageSizeSelector.connected";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const StyledTableRow = styled(TableRow)`
  padding: 0;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
  cursor: pointer;
`;
const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto auto 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: none;
  padding: 2rem 1.5rem 1.5rem;
`;
const StyledPagination = styled(Pagination)`
  & .MuiButtonBase-root {
    background-color: #ffffff !important;
    color: #444746;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  & .Mui-selected {
    color: #ffffff;
    background-color: #2f85c2 !important;
    box-shadow: none;
  }
`;
const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const StyledGridPDownload = styled(Grid)`
  display: flex;
  justify-content: end;
  margin-top: -30px;
`;
const Arrow = styled(ArrowDropDown)`
  vertical-align: bottom;
`;

const TabelaBateria = ({
  data,
  pageSize,
  handlePagination,
  handleDownloadCsv,
  dataCSV,
  order,
  handleOrderClick,
  handleCleanFile,
}) => {
  const download = (fileBase64, fileName) => {
    if (fileBase64 !== "") {
      const outFile = `data:application/octet-stream;base64,${fileBase64}`;
      const element = document.createElement("a");
      element.href = outFile;
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    }
  };
  if (dataCSV?.file) {
    download(dataCSV?.file, dataCSV?.file_name);
    handleCleanFile();
  }

  return (
    <StyledCard>
      <PageSizeSelector />
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell
              onClick={() => handleOrderClick("empresa.nomeAbreviado")}
            >
              Cliente
              <OrderIcon order={order} field={"empresa.nomeAbreviado"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell onClick={() => handleOrderClick("equipe")}>
              Equipe
              <OrderIcon order={order} field={"equipe"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell
              onClick={() => handleOrderClick("dispositivo.nome")}
            >
              Dispositivo
              <OrderIcon order={order} field={"dispositivo.nome"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell
              onClick={() =>
                handleOrderClick("dispositivo.dataHoraNivelBateria")
              }
            >
              Horário Última Atualização
              <OrderIcon
                order={order}
                field={"dispositivo.dataHoraNivelBateria"}
              />
            </StyledTitleTableCell>
            <StyledTitleTableCell
              onClick={() => handleOrderClick("dispositivo.porcentagemBateria")}
            >
              Nível da Bateria
              <OrderIcon
                order={order}
                field={"dispositivo.porcentagemBateria"}
              />
            </StyledTitleTableCell>
            <StyledTitleTableCell
              onClick={() => handleOrderClick("dispositivo.mensagemAtual")}
            >
              Qtd. de Mensagens
              <OrderIcon order={order} field={"dispositivo.mensagemAtual"} />
            </StyledTitleTableCell>
          </StyledTableRow>
          {data.resultado.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.empresa.nomeAbreviado}</StyledTableCell>
                <StyledTableCell>{item.equipe ?? ""}</StyledTableCell>
                <StyledTableCell>{item.dispositivo.nome}</StyledTableCell>
                <StyledTableCell>
                  {item.dispositivo.dataHoraNivelBateria
                    ? formatters.dates.withHours(
                        new Date(item.dispositivo.dataHoraNivelBateria)
                      )
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {formatters.numbers.currency(
                    item.dispositivo.porcentagemBateria
                  )}
                  % (
                  {item.dispositivo.nivelBateria === "B"
                    ? "Baixo"
                    : item.dispositivo.nivelBateria === "N"
                    ? "Normal"
                    : item.dispositivo.nivelBateria === "A"
                    ? "Alto"
                    : "Indisponível"}
                  )
                </StyledTableCell>
                <StyledTableCell>
                  {item.dispositivo.mensagemAtual}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
      <StyledGridPagination>
        <StyledPagination
          count={Math.ceil(data.total / pageSize)}
          page={data.paginaAtual}
          variant="outlined"
          shape="rounded"
          onChange={handlePagination}
        />
      </StyledGridPagination>

      <StyledGridPDownload>
        <RoundIconButton
          loading={dataCSV?.loading}
          handleClick={(e) => {
            handleDownloadCsv();
          }}
        >
          <Tooltip title={"Download"}>
            <Download sx={{ fill: "#51a8d1" }} />
          </Tooltip>
        </RoundIconButton>
      </StyledGridPDownload>
    </StyledCard>
  );
};

const OrderIcon = ({ order, field }) => {
  if (!order) {
    return null;
  }
  if (order.field === field) {
    if (order.direction === "asc") {
      return <Arrow as={ArrowDropDown} />;
    } else {
      return <Arrow as={ArrowDropUp} />;
    }
  }

  return null;
};

export default TabelaBateria;
