import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material";

const StyledInactiveTitle = styled(DialogTitle)`
  color: #70797b;
  font-family: ${({ theme }) => theme.fonts.family.title};
  font-family: Oswald;
  font-size: 24px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  margin-left: -25px;
`;
const StyledSubTitle = styled(DialogContentText)`
  font-family: ${({ theme }) => theme.fonts.family.default};
  font-size: 16pxx;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-bottom: 10px;
  text-transform: uppercase;
`;
const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;
const StyledAtualizarButton = styled(Button)`
  margin-top: 20px;
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 35px;
  width: 176px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  @media (max-width: 576px) {
    margin-left: 0px;
    width: 100%;
  }
  color: white;
`;
const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 35px;
  width: 176px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  @media (max-width: 576px) {
    margin-right: 0px;
    width: 100%;
  }
`;

const StyledDialog = styled(Dialog)``;

export default function ReprocessamentoDialog({
  open,
  setOpen,
  deleteFunction,
  title,
  actionValues,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDelete = () => {
    deleteFunction(actionValues);
    setOpen(false);
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContent>
        <StyledInactiveTitle>
          Confirmação de Reprocessamento
        </StyledInactiveTitle>
        <StyledSubTitle id="alert-dialog-description">{title}</StyledSubTitle>

        <StyledDialogActions>
          <StyledVoltarButton onClick={handleClose}>
            Cancelar
          </StyledVoltarButton>
          <StyledAtualizarButton onClick={handleCloseDelete}>
            Confirmar
          </StyledAtualizarButton>
        </StyledDialogActions>
      </DialogContent>
    </StyledDialog>
  );
}
