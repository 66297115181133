import { useForm } from "react-hook-form";
import FormCriarUsuarioPage from "./FormCriarUsuarioPage";
import { useSelector } from "react-redux";
import { getUsuario } from "../../../selectors/cadastro/usuarios.selectors";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/usuarios.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import { getClientesLista } from "../../../selectors/cadastro/clientes.selectors";

const FormDeletarUsuarioPageConnected = () => {
  const { loading } = useSelector(getUsuario);
  const { data: dataCliente, loading: loadingCliente } = useSelector(
    getClientesLista
  );
  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    email: validators.email({ required: true }),
    codigo: validators.string({ required: true }),
    perfil: validators.string({ required: true }),
    empresa: validators.string({
      custom: {
        requiredIfOther: (value) => {
          const perfil = formProps.getValues("perfil");
          if ((perfil === "C" || perfil === "E") && !value) {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const initialValues = {
    nome: "",
    email: "",
    codigo: "",
    empresa: "",
    perfil: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      dispatch(actions.criarUsuario.request(values));
    },
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_USUARIO));
  };

  return (
    <FormCriarUsuarioPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
      dataCliente={dataCliente}
      loadingCliente={loadingCliente}
    />
  );
};

export default FormDeletarUsuarioPageConnected;
