const ExcelJS = require("exceljs");

export const validarPlanilha = async (file) => {
  let final = "error";

  const wb = new ExcelJS.Workbook();

  try {
    let workbook = await wb.xlsx.load(file);
    const worksheet = workbook.getWorksheet(1);

    let columns = 0;
    worksheet.columns.forEach((column) => {
      columns += 1;
    });

    let latitudeFinal = [];
    let posicaoFinal = [];

    const latitude = worksheet.getColumn(1);

    latitude.values.forEach((value, index) => {
      latitudeFinal.push(value);
    });

    const longitude = worksheet.getColumn(2);

    longitude.values.forEach((value, index) => {
      posicaoFinal.push({
        latitude: latitudeFinal[index - 1],
        longitude: value,
      });
    });

    if (columns === 2) {
      final = { status: "Ok", posicoes: [...posicaoFinal] };
    }
  } catch (e) {
    final = { status: "error" };
  }
  return final;
};
