import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";
import InfoWindowPosicao from "./InfoWindowPosicao";
import Icon from "../../../assets/m-atual-big.png";

const UltimaPosicaoValida = ({ points }) => {
  const [clicked, setClicked] = useState(null);
  return (
    <>
      {points.map((p) => {
        return (
          <Marker
            key={p.id}
            position={p.longitudeLatitude}
            icon={Icon}
            clickable={true}
            onClick={() => setClicked(p)}
          />
        );
      })}
      <InfoWindowPosicao
        item={clicked}
        handleClose={() => setClicked(null)}
        canDelete={false}
      />
    </>
  );
};

export default UltimaPosicaoValida;
