import {
  actions as authActions,
  types as authTypes,
} from "./autenticacao.actions";

const initialState = {
  nome: "",
  perfil: "",
  empresa: "",
  empresaNome: "",
  position: {},
  roteiro: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case authActions.login.SUCCESS:
      if (!action.payload.response.data.usuario) {
        return state;
      }
      return {
        ...state,
        ...action.payload.response.data.usuario,
      };
    case authActions.trocarSenha.SUCCESS:
      return {
        ...state,
        ...action.payload.response.data.usuario,
      };
    default:
      return state;
  }
};

export default reducer;
