import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getClientesLista } from "../../selectors/cadastro/clientes.selectors";
import validators from "../../utils/validators";
import CargaRoteiro from "./CargaRoteiro";
import { actions } from "../../reducers/cadastro/roteiros.actions";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  getRoteirosBaixar,
  getRoteirosInconsistencias,
  getRoteirosResumo,
} from "../../selectors/cadastro/roteiros.selectors";
import { useEffect } from "react";

function CargaRoteiroConnected() {
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [isFile, setIsFile] = useState(false);

  const { data: dataInconsistencias, open: openInconsistencias } = useSelector(
    getRoteirosInconsistencias
  );
  const { loading: loadingBaixar } = useSelector(getRoteirosBaixar);
  const {
    data: dataResumo,
    open: openResumo,
    loading: loadingUpload,
  } = useSelector(getRoteirosResumo);

  useEffect(() => {
    if (openInconsistencias) {
      setIsFile(false);
      setFile([]);
    }
  }, [openInconsistencias]);

  const initialValues = {
    empresa: "",
  };
  const rules = {
    empresa: validators.object({
      custom: {
        requiredIfOther: (value) => {
          if (!value) {
            toast.error("Favor selecionar um Cliente para processamento", {
              theme: "colored",
              icon: false,
              style: { backgroundColor: "#990a26" },
            });
            return "Campo obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const { data: dataCliente, loading: loadingCliente } = useSelector(
    getClientesLista
  );

  const handleDownload = () => {
    const id = formProps.getValues("empresa");
    if (!id?.id) {
      toast.error("Favor selecionar um Cliente para baixar o arquivo", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      dispatch(actions.mostrarErroUpload({ data: [], open: false }));
      dispatch(
        actions.baixarPlanilhaRoteiros.request({ id: id.id, nome: id.nome })
      );
    }
  };

  const handleSubmit = () => {
    const id = formProps.getValues("empresa");
    if (file.length === 0) {
      toast.error("Favor selecionar um arquivo para processamento", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    } else {
      dispatch(actions.mostrarErroUpload({ data: [], open: false }));

      dispatch(
        actions.uploadCargaPlanilhaRoteiros.request({ id: id.id, file })
      );
    }
  };

  return (
    <CargaRoteiro
      file={file}
      setFile={setFile}
      dataCliente={dataCliente}
      loadingCliente={loadingCliente}
      formProps={formProps}
      handleDownload={handleDownload}
      handleSubmit={handleSubmit}
      dataInconsistencias={dataInconsistencias}
      openInconsistencias={openInconsistencias}
      dataResumo={dataResumo}
      openResumo={openResumo}
      isFile={isFile}
      setIsFile={setIsFile}
      loadingUpload={loadingUpload}
      loadingBaixar={loadingBaixar}
    />
  );
}

export default CargaRoteiroConnected;
