import React from "react";
import { useSelector } from "react-redux";
import { getOpcoesMapa, getItems } from "../../../selectors/diario.selectors";
import UltimaPosicaoValida from "./UltimaPosicaoValida";

const UltimaPosicaoValidaConnected = () => {
  const items = useSelector(getItems);
  const { atual } = useSelector(getOpcoesMapa);

  const points =
    !!atual && !!items
      ? items.map((i) => i.posicoes.filter((p) => p.tipo === "P"))
      : [];

  points.forEach((array) => {
    array.sort((a, b) => {
      const d1 = new Date(a.horarioPosicao);
      const d2 = new Date(b.horarioPosicao);

      if (d1.getTime() > d2.getTime()) {
        return 1;
      }
      if (d1.getTime() < d2.getTime()) {
        return -1;
      }
      return 0;
    });
  });

  const result = points.reduce((a, c) => {
    if (c.length > 0) {
      return [...a, c.slice(-1)[0]];
    } else {
      return a;
    }
  }, []);

  return <UltimaPosicaoValida points={result} />;
};

export default UltimaPosicaoValidaConnected;
