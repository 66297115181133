import React from "react";
import { useSelector } from "react-redux";
import {
  getOpcoesMapa,
  getSelecionado,
} from "../../../selectors/mapa.selectors";
import PosicoesValidas from "./PosicoesValidas";
import { getPerfil } from "../../../selectors/usuario.selectors";

const PosicoesValidasConnected = () => {
  const selecionado = useSelector(getSelecionado);
  const { percurso } = useSelector(getOpcoesMapa);
  const perfil = useSelector(getPerfil);

  const points =
    !!percurso && !!selecionado
      ? selecionado.posicoes.filter((p) => p.tipo === "P" && p.situacao === "V")
      : [];

  points.sort((a, b) => {
    const d1 = new Date(a.horarioPosicao);
    const d2 = new Date(b.horarioPosicao);

    if (d1.getTime() > d2.getTime()) {
      return 1;
    }
    if (d1.getTime() < d2.getTime()) {
      return -1;
    }
    return 0;
  });

  return <PosicoesValidas isEndUser={perfil === "E"} points={points} />;
};

export default PosicoesValidasConnected;
