import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_INTEGRACOES: "@@integracoes/OBTER_INTEGRACOES",
  MOSTRAR_INTEGRACAO: "@@integracoes/MOSTRAR_INTEGRACAO",
  EDITAR_INTEGRACAO: "@@integracoes/EDITAR_INTEGRACAO",
  CRIAR_INTEGRACAO: "@@integracoes/CRIAR_INTEGRACAO",
  DELETAR_INTEGRACAO: "@@integracoes/DELETAR_INTEGRACAO",
  REATIVAR_INTEGRACAO: "@@integracoes/REATIVAR_INTEGRACAO",
  REMOVER_FILTRO_INTEGRACAO: "@@integracoes/REMOVER_FILTRO_INTEGRACAO",
  REMOVER_PAGINA_INTEGRACAO: "@@integracoes/REMOVER_PAGINA_INTEGRACAO",
};

export const actions = {
  obterIntegracoes: createAsyncAction(types.OBTER_INTEGRACOES),
  mostrarIntegracao: createAsyncAction(types.MOSTRAR_INTEGRACAO),
  editarIntegracao: createAsyncAction(types.EDITAR_INTEGRACAO),
  criarIntegracao: createAsyncAction(types.CRIAR_INTEGRACAO),
  deletarIntegracao: createAsyncAction(types.DELETAR_INTEGRACAO),
  reativarIntegracao: createAsyncAction(types.REATIVAR_INTEGRACAO),
  removerFiltroIntegracao: () =>
    createSyncAction(types.REMOVER_FILTRO_INTEGRACAO),
  removerPaginaIntegracao: () =>
    createSyncAction(types.REMOVER_PAGINA_INTEGRACAO),
};
