import React from "react";
import Header from "./header/Header.connected";
import { styled } from "@mui/system";
import { useWindowSize } from "../hooks/useWindowSize";
import { Grid } from "@mui/material";

const StyledContainer = styled(Grid)`
  width: 100%;
  flex-direction: column;
  min-height: ${({ fullheight }) => fullheight}px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${({ theme, color }) => color ?? theme.colors.white};
  padding-top: ${({ theme, paddingTop }) =>
    paddingTop ?? theme.sizes.header.mobile.size}px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1217px) {
    padding-top: ${({ theme, paddingTop }) =>
      paddingTop ?? theme.sizes.header.desktop.size}px;
    padding-left: 170px;
    padding-right: 170px;
  }
`;

const BasePage = ({
  children,
  color,
  withHeader = true,
  paddingTop,
  isLoggedIn,
  ...otherProps
}) => {
  const [, innerHeight] = useWindowSize();

  if (isLoggedIn) {
    window.zESettings = {
      webWidget: {
        authenticate: {},
      },
    };
  }

  return (
    <>
      {withHeader && <Header isLoggedIn={isLoggedIn} />}

      <StyledContainer
        container
        color={color}
        paddingTop={paddingTop}
        fullheight={innerHeight}
        {...otherProps}
      >
        {children}
      </StyledContainer>
    </>
  );
};
export default BasePage;
