import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/configuracao/geracao.actions";
import { toast } from "react-toastify";
import { authenticatedRequest } from "../../utils/api";
import formatters from "../../utils/formatters";

const processar = basicFlow({
  actionGenerator: actions.processar,
  transform: ({ data }) => {
    return {
      date: formatters.dates.revert(data),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/geracao-manual`,
      method: "post",
      body: values,
    });
  },
  postSuccess: () => {
    toast.success("Processamento realizado com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

export const sagas = [processar.watcher()];
