// prettier-ignore
export const states = ["AC", "AL", "AP", "AM", "BA", "CE",
"DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA",
"PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO",
"RR", "SC", "SP", "SE", "TO"];

export const getDiaDaSemana = (value) => {
  switch (value) {
    case 1:
      return "Domingo";
    case 2:
      return "Segunda-feira";
    case 3:
      return "Terça-feira";
    case 4:
      return "Quarta-feira";
    case 5:
      return "Quinta-feira";
    case 6:
      return "Sexta-feira";
    case 7:
      return "Sábado";
    case 9:
      return "Feriados";
    default:
      break;
  }
};
