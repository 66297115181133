import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions } from "../../../reducers/cadastro/clientes.actions";
import { getClientes } from "../../../selectors/cadastro/clientes.selectors";
import ClientePage from "./ClientePage";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";

const ClientePageConnected = () => {
  const { loading, data, filter, page } = useSelector(getClientes);
  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterClientes.request({
        pagina: page,
        nome: filter,
      })
    );
  };

  const handleDelete = (e, id) => {
    dispatch(actions.deletarCliente.request({ id }));
  };

  const handleActivate = (e, id) => {
    dispatch(actions.reativarCliente.request({ id, situacao: "A" }));
  };

  const handleEdit = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.EDITAR_CLIENTE, {
        id: id,
      })
    );
  };
  const handleView = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.VIEW_CLIENTE, {
        id: id,
      })
    );
  };
  const handleFeriado = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_FERIADO, {
        id: id,
      })
    );
  };

  const handleAlerta = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_ALERTA, {
        id: id,
      })
    );
  };

  const handleIntegracoes = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_INTEGRACAO, {
        id: id,
      })
    );
  };
  const handleMapasMensais = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_MAPA_MENSAL, {
        id: id,
      })
    );
  };
  const handleRoteiros = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_ROTEIRO, {
        id: id,
      })
    );
  };

  const handleAdd = () => {
    dispatch(routeActions.redirectTo(routes.CRIAR_CLIENTE));
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.HOME_PAGE));
    dispatch(
      actions.obterClientes.request({
        pagina: page,
        nome: filter,
      })
    );
  };

  return (
    <ClientePage
      data={data}
      loading={loading}
      handlePagination={handlePagination}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleGoBack={handleGoBack}
      handleActivate={handleActivate}
      filtroAtual={filter}
      handleIntegracoes={handleIntegracoes}
      handleRoteiros={handleRoteiros}
      handleView={handleView}
      handleFeriado={handleFeriado}
      handleMapasMensais={handleMapasMensais}
      handleAlerta={handleAlerta}
    />
  );
};

export default ClientePageConnected;
