import React from "react";
import { ReactComponent as EllipsisSameColor } from "../../assets/ellipsis-same.svg";
import { ReactComponent as EllipsisBrand } from "../../assets/ellipsis-brand.svg";
const Dots = ({
  className,
  color = "brand",
  size = "auto",
  style = {},
  ...otherProps
}) => {
  const Ellipsis = color === "brand" ? EllipsisBrand : EllipsisSameColor;

  style.width = size;
  style.height = size;
  style.color = color;

  return <Ellipsis fill={color} style={style} {...otherProps} />;
};

export default Dots;
