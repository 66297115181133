import { useForm } from "react-hook-form";
import FormCriarDispositivoPage from "./FormCriarDispositivoPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/dispositivos.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import { getDispositivo } from "../../../selectors/cadastro/dispositivos.selectors";
import formatters from "../../../utils/formatters";

const FormCriarDispositivoPageConnected = () => {
  const { loading } = useSelector(getDispositivo);
  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    mensagemPadrao: validators.string({ required: true }),
  };

  const initialValues = {
    nome: "",
    situacao: "A",
    dataHoraNivelBateria: "",
    nivelBateria: "",
    porcentagemBateria: "",
    mensagemAtual: "",
    mensagemPadrao: "",
    dataHoraAtualizacao: "",
    dataHoraCriacao: "",
  };
  const defaultValues = {
    nome: "",
    situacao: "A",
    dataHoraCriacao: formatters.dates.withHours(new Date()),
    dataHoraAtualizacao: formatters.dates.withHours(new Date()),
    dataHoraNivelBateria: "",
    nivelBateria: "",
    porcentagemBateria: "",
    mensagemAtual: "",
    mensagemPadrao: "",
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      dispatch(actions.criarDispositivo.request(values));
    },
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_DISPOSITIVO));
  };

  return (
    <FormCriarDispositivoPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
    />
  );
};

export default FormCriarDispositivoPageConnected;
