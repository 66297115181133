export const mock = (value) => {
  return new Promise((resolve) => {
    setTimeout(
      () =>
        resolve({
          data: value,
        }),
      2000
    );
  });
};

export const getRandomValueBetween = (min, max) => {
  return Math.random() * (max - min) + min;
};
