import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  SELECIONAR_OPCOES: "@@mapaExecucao/SELECIONAR_OPCOES",
  ALTERNAR_MAIS_CARDS: "@@mapaExecucao/ALTERNAR_MAIS_CARDS",
  ATUALIZAR_FILTROS: "@@mapaExecucao/ATUALIZAR_FILTROS",
  EXIBIR_FILTROS: "@@mapaExecucao/EXIBIR_FILTROS",
  SELECIONAR_OS: "@@mapaExecucao/SELECIONAR_OS",
  PESQUISAR: "@@mapaExecucao/PESQUISAR",
  PROXIMA_PAGINA: "@@mapaExecucao/PROXIMA_PAGINA",
  REINICIAR_PAGINA: "@@mapaExecucao/REINICIAR_PAGINA",
  ATUALIZAR_OS: "@@mapaExecucao/ATUALIZAR_OS",
  EXCLUIR_POSICAO: "@@mapaExecucao/EXCLUIR_POSICAO",
  UPDATE_ORDER: "@@mapaExecucao/UPDATE_ORDER",

  DOMINIO_EMPRESAS: "@@mapaExecucao/DOMINIO_EMPRESAS",
  DOMINIO_DISPOSITIVOS: "@@mapaExecucao/DOMINIO_DISPOSITIVOS",
  DOMINIO_GRUPOS: "@@mapaExecucao/DOMINIO_GRUPOS",
  DOWNLOAD_ROTEIRO: "@@mapaExecucao/DOWNLOAD_ROTEIRO",
  CLEAN_ROTEIRO_DATA: "@@mapaExecucao/CLEAN_ROTEIRO_DATA",
  DOWNLOAD_OS: "@@mapaExecucao/DOWNLOAD_OS",
  CLEAN_OS_DATA: "@@mapaExecucao/CLEAN_OS_DATA",
};

export const actions = {
  selecionarOpcoes: (propriedade, valor) =>
    createSyncAction(types.SELECIONAR_OPCOES, { propriedade, valor }),
  alternarMaisCards: () => createSyncAction(types.ALTERNAR_MAIS_CARDS),
  selecionarOs: (item) => createSyncAction(types.SELECIONAR_OS, { item }),
  atualizarFiltros: (filtros) =>
    createSyncAction(types.ATUALIZAR_FILTROS, { filtros }),
  exibirFiltros: (visible) =>
    createSyncAction(types.EXIBIR_FILTROS, { visible }),
  atualizarOrdem: (field) => createSyncAction(types.UPDATE_ORDER, { field }),

  pesquisar: createAsyncAction(types.PESQUISAR),
  proximaPagina: createAsyncAction(types.PROXIMA_PAGINA),
  reiniciarPagina: createAsyncAction(types.REINICIAR_PAGINA),
  atualizarOs: createAsyncAction(types.ATUALIZAR_OS),
  excluirPosicao: createAsyncAction(types.EXCLUIR_POSICAO),

  carregarEmpresas: createAsyncAction(types.DOMINIO_EMPRESAS),
  carregarDispositivos: createAsyncAction(types.DOMINIO_DISPOSITIVOS),
  carregarGrupos: createAsyncAction(types.DOMINIO_GRUPOS),
  downloadRoteiro: createAsyncAction(types.DOWNLOAD_ROTEIRO),
  cleanRoteiroData: () => createSyncAction(types.CLEAN_ROTEIRO_DATA),
  downloadOs: createAsyncAction(types.DOWNLOAD_OS),
  cleanOsData: () => createSyncAction(types.CLEAN_OS_DATA),
};
