import React, { forwardRef } from "react";
import NumberFormat from "react-number-format";

const IntegerTextField = forwardRef(
  (
    { thousandSeparator = ".", allowLeadingZeros = false, onChange, ...other },
    ref
  ) => {
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        allowLeadingZeros={allowLeadingZeros}
        onValueChange={(values) => {
          onChange(
            allowLeadingZeros ? values.formattedValue : values.floatValue
          );
        }}
        thousandSeparator={thousandSeparator}
        decimalSeparator={","}
        decimalScale={0}
      />
    );
  }
);

export default IntegerTextField;
