import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions } from "../../../reducers/cadastro/dispositivos.actions";
import { getDispositivos } from "../../../selectors/cadastro/dispositivos.selectors";
import DispositivoPage from "./DispositivoPage";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";

const DispositivoPageConnected = () => {
  const { loading, data, filter, page } = useSelector(getDispositivos);
  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterDispositivos.request({
        pagina: page,
        nome: filter,
      })
    );
  };

  const handleDelete = (e, id) => {
    dispatch(actions.deletarDispositivo.request({ id }));
  };

  const handleActivate = (e, id) => {
    dispatch(actions.reativarDispositivo.request({ id, situacao: "A" }));
  };

  const handleEdit = (e, id) => {
    dispatch(
      routeActions.redirectTo(routes.EDITAR_DISPOSITIVO, {
        id: id,
      })
    );
  };

  const handleAdd = () => {
    dispatch(routeActions.redirectTo(routes.CRIAR_DISPOSITIVO));
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.HOME_PAGE));
    dispatch(
      actions.obterDispositivos.request({
        pagina: page,
        nome: filter,
      })
    );
  };

  return (
    <DispositivoPage
      data={data}
      loading={loading}
      handlePagination={handlePagination}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleGoBack={handleGoBack}
      handleActivate={handleActivate}
      filtroAtual={filter}
    />
  );
};

export default DispositivoPageConnected;
