import { useForm } from "react-hook-form";

export default function useCompleteForm({
  rules,
  initialValues,
  handleSubmit,
}) {
  const form = useForm();

  const formProps = {
    ...form,
    initialValues: initialValues(form),
    rules: rules(form),
  };

  return [formProps, formProps.handleSubmit(handleSubmit)];
}
