import { Download, FilterAltOutlined } from "@mui/icons-material";
import { styled, Tooltip } from "@mui/material";
import React from "react";
import RoundIconButton from "../../../components/buttons/RoundIconButton";
import DatePickerPopover from "./DatePickerPopover";

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-right: 10px;
`;

const StyledClientTitle = styled("h1")`
  font-family: Oswald;
  font-size: 22px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
`;

const StyledHeaderContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const StyledEndContainer = styled("div")`
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
`;
const StyledStartContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
`;

function Header({
  mesAno,
  handleClickOpenFilters,
  cliente,
  handleDownloadCsv,
  loading,
  osFile,
  handleCleanOsData,
}) {
  const download = (fileBase64, fileName) => {
    if (fileBase64 !== "") {
      const outFile = `data:application/octet-stream;base64,${fileBase64}`;
      const element = document.createElement("a");
      element.href = outFile;
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    }
  };

  if (osFile?.file) {
    download(osFile.file, osFile.file_name);
    handleCleanOsData();
  }
  return (
    <StyledHeaderContainer>
      <StyledStartContainer>
        <StyledCardTitle>{mesAno}</StyledCardTitle>
        <DatePickerPopover />
        {!!cliente && (
          <RoundIconButton
            loading={loading}
            handleClick={(e) => {
              handleDownloadCsv();
            }}
          >
            <Tooltip title={"Download"}>
              <Download sx={{ fill: "#51a8d1" }} />
            </Tooltip>
          </RoundIconButton>
        )}
      </StyledStartContainer>
      <StyledEndContainer>
        <RoundIconButton
          handleClick={(e) => {
            handleClickOpenFilters();
          }}
        >
          <Tooltip title="Filtros">
            <FilterAltOutlined sx={{ fill: "#51a8d1" }} />
          </Tooltip>
        </RoundIconButton>
        <StyledClientTitle>{cliente}</StyledClientTitle>
      </StyledEndContainer>
    </StyledHeaderContainer>
  );
}

export default Header;
