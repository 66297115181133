import { useForm } from "react-hook-form";
import FormCriarIntegracaoPage from "./FormCriarIntegracaoPage";
import { useSelector } from "react-redux";
import validators from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers/cadastro/integracoes.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { useCallback } from "react";
import {
  getIntegracao,
  getIntegracoes,
} from "../../../selectors/cadastro/integracoes.selectors";
import formatters from "../../../utils/formatters";

const FormCriarIntegracaoPageConnected = () => {
  const { loading } = useSelector(getIntegracao);
  const { cliente } = useSelector(getIntegracoes);

  const dispatch = useDispatch();

  const rules = {
    url: validators.string({ required: true }),
    password: validators.string({ required: true }),
    user: validators.string({ required: true }),
    port: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          }
          if (!!value && value > 65535) {
            return !!value || "Valor máximo (65535)";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const initialValues = {
    user: "",
    password: "",
    port: "",
    url: "",
  };
  const defaultValues = {
    user: "",
    password: "",
    port: "",
    url: "",
    dataHoraCriacao: formatters.dates.withHours(new Date()),
    dataHoraAtualizacao: formatters.dates.withHours(new Date()),
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      values.idEmpresa = cliente;
      dispatch(actions.criarIntegracao.request(values));
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_INTEGRACAO, { id: cliente })
    );
  };

  return (
    <FormCriarIntegracaoPage
      handleGoBack={handleClickVoltar}
      loading={loading}
      formProps={formProps}
      handleSubmit={handleSubmit}
    />
  );
};

export default FormCriarIntegracaoPageConnected;
