import React from "react";
import { Grid } from "@mui/material";
import BasePage from "../../BasePage";
import { Title } from "../../../components/styled";
import CardReprocessamentoOs from "./CardReprocessamentoOs";

const GeracaoOsPage = (props) => {
  return (
    <BasePage isLoggedIn={true}>
      <Title>Reprocessamento de OS</Title>
      <Grid container>
        <Grid item xs={12}>
          <CardReprocessamentoOs {...props} />
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default GeracaoOsPage;
