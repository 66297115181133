import { useDispatch, useSelector } from "react-redux";
import FormIntegracaoPage from "./FormEditarIntegracaoPage";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import validators from "../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { actions } from "../../../reducers/cadastro/integracoes.actions";
import {
  getIntegracao,
  getIntegracoes,
} from "../../../selectors/cadastro/integracoes.selectors";
import formatters from "../../../utils/formatters";

const FormEditarIntegracaoPageConnected = () => {
  const { loading, data } = useSelector(getIntegracao);
  const { cliente } = useSelector(getIntegracoes);

  const dispatch = useDispatch();

  const rules = {
    url: validators.string({ required: true }),
    password: validators.string({ required: true }),
    user: validators.string({ required: true }),
    port: validators.number({
      custom: {
        requiredIfOther: (value) => {
          if (!!value && value < 1) {
            return !!value || "Valor mínimo (1)";
          }
          if (!!value && value > 65535) {
            return !!value || "Valor máximo (65535)";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const initialValues = {
    user: "",
    password: "",
    port: "",
    url: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("url", data.url);
    formProps.setValue("user", data?.user);
    formProps.setValue("password", data.password);
    formProps.setValue("port", data?.port);
    formProps.setValue(
      "dataHoraCriacao",
      formatters.dates.withHours(new Date(data?.dataHoraCriacao))
    );
    formProps.setValue(
      "dataHoraAtualizacao",
      formatters.dates.withHours(new Date(data?.dataHoraAtualizacao))
    );
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = () => {
    const id = data.id;
    const url = formProps.getValues("url");
    const user = formProps.getValues("user");
    const password = formProps.getValues("password");
    const port = formProps.getValues("port");

    dispatch(
      actions.editarIntegracao.request({
        id,
        url,
        user,
        password,
        port,
      })
    );
  };

  const handleClickVoltar = () => {
    dispatch(
      routeActions.redirectTo(routes.CADASTRO_INTEGRACAO, { id: cliente })
    );
  };

  return (
    <FormIntegracaoPage
      handleSubmit={handleSubmit}
      handleGoBack={handleClickVoltar}
      formProps={formProps}
      data={data}
      loading={loading}
    />
  );
};

export default FormEditarIntegracaoPageConnected;
