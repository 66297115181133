import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  OBTER_MAPAS_MENSAIS: "@@mapasMensais/OBTER_MAPAS_MENSAIS",
  MOSTRAR_MAPA_MENSAL: "@@mapasMensais/MOSTRAR_MAPA_MENSAL",
  EDITAR_MAPA_MENSAL: "@@mapasMensais/EDITAR_MAPA_MENSAL",
  CRIAR_MAPA_MENSAL: "@@mapasMensais/CRIAR_MAPA_MENSAL",
  DELETAR_MAPA_MENSAL: "@@mapasMensais/DELETAR_MAPA_MENSAL",
  REATIVAR_MAPA_MENSAL: "@@mapasMensais/REATIVAR_MAPA_MENSAL",
  REMOVER_FILTRO_MAPA_MENSAL: "@@mapasMensais/REMOVER_FILTRO_MAPA_MENSAL",
  REMOVER_PAGINA_MAPA_MENSAL: "@@mapasMensais/REMOVER_PAGINA_MAPA_MENSAL",
};

export const actions = {
  obterMapasMensais: createAsyncAction(types.OBTER_MAPAS_MENSAIS),
  mostrarMapaMensal: createAsyncAction(types.MOSTRAR_MAPA_MENSAL),
  editarMapaMensal: createAsyncAction(types.EDITAR_MAPA_MENSAL),
  criarMapaMensal: createAsyncAction(types.CRIAR_MAPA_MENSAL),
  deletarMapaMensal: createAsyncAction(types.DELETAR_MAPA_MENSAL),
  reativarMapaMensal: createAsyncAction(types.REATIVAR_MAPA_MENSAL),
  removerFiltroMapaMensal: () =>
    createSyncAction(types.REMOVER_FILTRO_MAPA_MENSAL),
  removerPaginaMapaMensal: () =>
    createSyncAction(types.REMOVER_PAGINA_MAPA_MENSAL),
};
