import React, { useCallback, useRef } from "react";
import SearchAddressInputText from "./SearchAddressInputText";

const SearchAddressInputTextConnected = ({ mapInstance }) => {
  const markerInstance = useRef();

  const handlePlaceSelected = useCallback(
    (place) => {
      if (place.geometry) {
        mapInstance.panTo(place.geometry.location);
        mapInstance.setZoom(17);

        if (markerInstance.current) {
          markerInstance.current.setMap(null);
        }

        markerInstance.current = new window.google.maps.Marker({
          position: place.geometry.location,
          title: place.formatted_address,
          map: mapInstance,
        });
      }
    },
    [mapInstance, markerInstance]
  );

  const handleClose = useCallback(() => {
    if (markerInstance.current) {
      markerInstance.current.setMap(null);
    }
  }, [markerInstance]);

  return (
    <SearchAddressInputText
      handlePlaceSelected={handlePlaceSelected}
      handleClose={handleClose}
    />
  );
};

export default SearchAddressInputTextConnected;
