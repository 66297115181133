import basicFlow from "./asyncHandler";
import { put, select, takeEvery } from "redux-saga/effects";
import { actions, types } from "../reducers/mapaExecucao.actions";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { getFiltros } from "../selectors/mapaExecucao.selectors";
import formatters from "../utils/formatters";
import { getUsuario } from "../selectors/usuario.selectors";

const pesquisar = basicFlow({
  actionGenerator: actions.pesquisar,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    const empresa = currentFilter.empresa?.id
      ? currentFilter.empresa?.id
      : !!usuario?.empresa
      ? usuario.empresa
      : currentFilter.empresa?.id;

    return {
      ...currentFilter,
      empresa,
      dispositivo: currentFilter.dispositivo?.id,
      grupo: currentFilter.grupo?.id,
      funcionamento: currentFilter.funcionamento.map((f) => f.value),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/mapa-execucao/listar`,
      method: "post",
      body: values,
    });
  },
});
const atualizarOs = basicFlow({
  actionGenerator: actions.atualizarOs,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/os/${id}`,
      method: "get",
    });
  },
});
const proximaPagina = basicFlow({
  actionGenerator: actions.proximaPagina,
  transform: function* () {
    const currentFilter = yield select(getFiltros);

    return {
      ...currentFilter,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      page: currentFilter.page + 1,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/os`,
      method: "get",
      queryParams: values,
    });
  },
});
const reiniciarPagina = basicFlow({
  actionGenerator: actions.reiniciarPagina,
  transform: function* () {
    const currentFilter = yield select(getFiltros);

    return {
      ...currentFilter,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      page: 1,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/os`,
      method: "get",
      queryParams: values,
    });
  },
});

const carregarEmpresas = basicFlow({
  actionGenerator: actions.carregarEmpresas,
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente/dominio?cliente=${values?.cliente ?? "false"}`,
      method: "get",
    });
  },
});
const carregarDispositivos = basicFlow({
  actionGenerator: actions.carregarDispositivos,
  api: () => {
    return authenticatedRequest({
      url: `/dispositivo/dominio`,
      method: "get",
    });
  },
});
const carregarGrupos = basicFlow({
  actionGenerator: actions.carregarGrupos,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/roteiro/listar/grupos`,
      method: "post",
      body: {
        idCliente: id,
      },
    });
  },
});
const excluirPosicao = basicFlow({
  actionGenerator: actions.excluirPosicao,
  transform: ({ id }) => {
    return { id };
  },
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/roteiro/delete-posicao/${id}`,
      method: "post",
    });
  },
  postSuccess: ({ original }) => {
    if (!!original.callback) {
      original.callback();
    }
  },
});
function* atualizarFiltrosWatcher() {
  yield takeEvery([types.ATUALIZAR_FILTROS], function* () {
    yield put(actions.pesquisar.request());
  });
}

function* mapRouteWatcher() {
  yield routeWatcher(routes.HOME_PAGE, function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);

    yield put(actions.carregarDispositivos.request());

    if (usuario?.empresa) {
      yield put(
        actions.carregarEmpresas.request({ cliente: usuario?.empresa })
      );
      yield put(actions.carregarGrupos.request({ id: usuario.empresa }));
      yield put(
        actions.atualizarFiltros({
          ...currentFilter,
          empresa: usuario.empresa,
          empresaNome: usuario.empresaNome,
        })
      );
    } else {
      yield put(actions.carregarEmpresas.request());
    }
  });
}

const downloadRoteiro = basicFlow({
  actionGenerator: actions.downloadRoteiro,
  api: (id) => {
    return authenticatedRequest({
      url: `/roteiro/gerarRoteiroCsv/${id}`,
      method: "get",
    });
  },
});

const downloadOs = basicFlow({
  actionGenerator: actions.downloadOs,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);
    const empresa = !!usuario?.empresa
      ? usuario.empresa
      : currentFilter.empresa?.id;

    return {
      ...currentFilter,
      empresa,
      dispositivo: currentFilter.dispositivo?.id,
      grupo: currentFilter.grupo?.id,
      funcionamento: currentFilter.funcionamento.map((f) => f.value),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/mapa-execucao/download-csv`,
      method: "post",
      body: values,
    });
  },
});

export const sagas = [
  pesquisar.watcher(),
  atualizarOs.watcher(),
  proximaPagina.watcher(),
  carregarEmpresas.watcher(),
  carregarDispositivos.watcher(),
  carregarGrupos.watcher(),
  excluirPosicao.watcher(),
  reiniciarPagina.watcher(),
  atualizarFiltrosWatcher(),
  downloadRoteiro.watcher(),
  downloadOs.watcher(),
  mapRouteWatcher(),
];
