import { createSelector } from "reselect";

export const getAlertaCadastro = (state) => state.alertas;

export const getAlertas = createSelector(
  getAlertaCadastro,
  (state) => state.alertas
);

export const getAlerta = createSelector(
  getAlertaCadastro,
  (state) => state.alerta
);

export const getAlertasPage = createSelector(
  getAlertaCadastro,
  (state) => state.alertas.page
);

export const getAlertasFilter = createSelector(
  getAlertaCadastro,
  (state) => state.alertas.filter
);

export const getAlertasCliente = createSelector(
  getAlertaCadastro,
  (state) => state.alertas.cliente
);
