import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_BATERIAS: "@@baterias/OBTER_BATERIAS",
  REMOVER_FILTRO_BATERIA: "@@baterias/REMOVER_FILTRO_BATERIA",
  REMOVER_PAGINA_BATERIA: "@@baterias/REMOVER_PAGINA_BATERIA",
  DOMINIO_EMPRESAS: "@@baterias/DOMINIO_EMPRESAS",
  DOMINIO_DISPOSITIVOS: "@@baterias/DOMINIO_DISPOSITIVOS",
  DOWNLOAD_CSV: "@@baterias/DOWNLOAD_CSV",
  UPDATE_PAGE_SIZE: "@@baterias/UPDATE_PAGE_SIZE",
  UPDATE_ORDER: "@@baterias/UPDATE_ORDER",
  CLEAN_FILE: "@@baterias/CLEAN_FILE",
  CLEAN_LIST: "@@baterias/CLEAN_LIST",
};

export const actions = {
  obterBaterias: createAsyncAction(types.OBTER_BATERIAS),
  removerFiltroBateria: () => createSyncAction(types.REMOVER_FILTRO_BATERIA),
  removerPaginaBateria: () => createSyncAction(types.REMOVER_PAGINA_BATERIA),
  atualizarTamanhoPagina: (pageSize) =>
    createSyncAction(types.UPDATE_PAGE_SIZE, { pageSize }),
  atualizarOrdem: (field) => createSyncAction(types.UPDATE_ORDER, { field }),
  carregarEmpresas: createAsyncAction(types.DOMINIO_EMPRESAS),
  carregarDispositivos: createAsyncAction(types.DOMINIO_DISPOSITIVOS),
  downloadCSV: createAsyncAction(types.DOWNLOAD_CSV),
  cleanFile: () => createSyncAction(types.CLEAN_FILE),
  cleanlist: () => createSyncAction(types.CLEAN_LIST),
};
