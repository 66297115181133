import React from "react";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import Dots from "./Dots";
import { styled } from "@mui/material";

const StyledLoader = styled(Loader)`
  transform: rotate(-90deg);
  width: ${({ size = 128 }) => size}px;
  height: ${({ size = 128 }) => size}px;
`;
const CenteredLoader = styled(Loader)`
  transform: translate(-50%, -50%) rotate(-90deg);
  width: ${({ size = 128 }) => size}px;
  height: ${({ size = 128 }) => size}px;
  position: absolute;
  top: 50%;
  left: 50%;
`;

const MainLoader = ({
  stroke = "white",
  type,
  centered = false,
  ...otherProps
}) => {
  return (
    <>
      {type === "dots" ? (
        <Dots {...otherProps} />
      ) : !!centered ? (
        <CenteredLoader stroke={stroke} {...otherProps} />
      ) : (
        <StyledLoader stroke={stroke} {...otherProps} />
      )}
    </>
  );
};

export default MainLoader;
