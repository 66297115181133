import React from "react";
import { useSelector } from "react-redux";
import { getSelecionado } from "../../../selectors/mapa.selectors";
import KmlLayer from "./KmlLayer";

const KmlLayerConnected = () => {
  const selecionado = useSelector(getSelecionado);

  const file = !!selecionado ? selecionado.arquivoKml : undefined;

  return <KmlLayer file={file} />;
};

export default KmlLayerConnected;
