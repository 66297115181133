import { createSelector } from "reselect";

export const getDiario = (state) => state.diario;
export const getDominios = createSelector(getDiario, (state) => state.dominios);
export const getOpcoesMapa = createSelector(getDiario, (state) => state.opcoes);
export const getFiltros = createSelector(getDiario, (state) => state.filtros);
export const getDrawer = createSelector(getDiario, (state) => state.drawer);
export const getBotao = createSelector(getDiario, (state) => state.botao);
export const getDominioEmpresas = createSelector(
  getDominios,
  (state) => state.empresas
);

export const isLoadingItems = createSelector(
  getDiario,
  (state) => state.loading
);
export const isUpdatingItem = createSelector(
  getDiario,
  (state) => state.updating
);
export const getItems = createSelector(getDiario, (state) => state.items);
export const getKml = createSelector(getDiario, (state) => state.kml);
export const getEmpresa = createSelector(getDiario, (state) => state.empresa);

export const getCenter = (state) => {
  return state.usuario.position;
};
export const getRoteiro = createSelector(getDiario, (state) => state.roteiro);
export const getOs = createSelector(getDiario, (state) => state.os);
